import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, FormInputSelect } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter , apiFormInit , apiFormSave, ChannelDescription,
    ContractTypesSelect, ApiMultiSelect, ContractStatesSelect, Breadcumbs, LocalCache, ChannelSelect } from './AppCommon';
import { ImportConfigurationSelect } from './Contracts';

class ContractStates extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      contractStateModal: false,
      filterTypeId: false,
      filterParentEntityType: ''
    };

  }

  onDelete( id ){
    fetch( 'api' , 'ContractStates' , 'delete' , id ).then( msg => {
      window.location.reload();
    });
  }

  render(){

    const columns = { 
      name: 'Nome' , 
      actions: 'Canale',
      type: 'Tipo stato',
      createdAt: 'Creato il',
      flag0: '',
      flag1: '',
      parentEntityType: 'Stato per',
      action: '',
  };

    const row = contractState => {
      const style= contractState.active ? {} : { color: '#c3c3c3'};
      let type = contractState.type;
      if( type == 'success' ) type = 'Positivo';
      if( type == 'warning' ) type = 'Neutro';
      if( type == 'error' ) type = 'Negativo';

        return <tr style={style}>
          <td>{contractState.name}</td>
          <td><ChannelDescription channel={contractState.Channel} /></td>
          <td>{type}</td>
          <td>{moment(contractState.createdAt).format('D MMMM YY HH:mm')}</td>
          <td>
            {contractState.canBeInitialState && <span className="label">Iniziale</span>} 
            {contractState.isClosingState == 'positive' && <span className="label label-success">Chiusura OK</span>}
            {contractState.isClosingState == 'negative' && <span className="label label-danger">Chiusura KO</span>}
          </td>
          <td>
            {contractState.canBeSelected && <span className="label">Selezionabile</span>}
          </td>
          <td>
            {contractState.parentEntityType==='contract' ? 'Contratto' : ''}
            {contractState.parentEntityType==='practice' ? 'Pratica' : ''}
            {contractState.parentEntityType==='enelxSellInstallation' ? 'Installazioni Enel X' : ''}
          </td>
          <td>
            {!contractState.readOnly && <div class="btn-group">
                {can('ContractStates.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({contractStateModal: contractState.id});
                }}>Vedi</button> }

                {can('ContractStates.delete') && <button class="btn-white btn btn-xs" onClick={se=>{
                  this.onDelete( contractState.id );
                }}><i className="fa fa-trash"></i></button> }

            </div>}
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Stati contratto" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
        <div className="col-12">
          { can( 'ContractStates.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({contractStateModal: true});
          }}>Nuovo stato contratto</button>}
        </div>
      </div>

      {this.state.contractStateModal && <NewContractStateModal onDone={res=>{
        this.setState({contractStateModal: false});
      }} id={this.state.contractStateModal === true ? false: this.state.contractStateModal} />}

      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-lg-4">
                    <FormInputSelect label="Stato per" value={this.state.filterParentEntityType}
                                     direction={FormInputContainer.HORIZONTAL}
                                     validation={this.state.validation} name="filterParentEntityType"
                                     values={{
                                       '': 'Mostra tutti',
                                       'contract': 'Contratto',
                                       'practice': 'Pratica',
                                       'Leads': 'Lead',
                                       'enelxSellInstallation': 'Installazioni Enel X'
                                     }}
                                     onChange={se=>{this.setState({filterParentEntityType: se})}} />
                  </div>

                    <div className="col-lg-4">
                        <ImportConfigurationSelect value={this.state.filterType}
                            onChange={se=>{this.setState({filterType:se, filterTypeId: se ? se.id : null})}}
                            label="Filtro tipo contratto" />
                    </div>

                </div>
              </div>
          </div>
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="ContractStates" 
                    filters={{ ChannelId: this.state.filterTypeId, parentEntityType: this.state.filterParentEntityType.length>0 ? this.state.filterParentEntityType : null }}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>];
  }
}



class NewContractStateModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: '',
          isClosingState: 'no',
          parentEntityType: 'contract'
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'ContractStates', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'ContractStates', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>

            <FormInputSelect label="Stato per" value={this.state.parentEntityType}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="parentEntityType"
                             values={{
                               'contract': 'Contratto',
                               'practice': 'Pratica',
                               'Leads': 'Lead',
                               'enelxSellInstallation': 'Installazioni Enel X'
                             }}
                             onChange={se=>{this.setState({parentEntityType: se})}} />
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                enable={this.state.readOnly?false:true}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />

            <FormInputSelect label="Tipo stato" value={this.state.type}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="type"
                values={{
                    'success': 'Positivo',
                    'warning': 'Neutro',
                    'error': 'Negativo',
                }}
                onChange={se=>{this.setState({type: se})}} />

            <FormInputRadio label="Abilitato"
                            checked={this.state.active}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>{this.setState({active:
                                this.state.active ? false : true })}} />


            {this.state.parentEntityType==='contract' && <>
              <ChannelSelect value={this.state.Channel}
                label="Canale"
                validation={this.state.validation}
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>this.setState({Channel: se,ChannelId: se ? se.id : null})} />
            </>}
    
              <FormInputRadio label="Può essere uno stato inziale" 
                checked={this.state.canBeInitialState}
                direction={FormInputContainer.HORIZONTAL}
                onClick={se=>{
                  /*if( !this.state.canBeInitialState )
                    this.setState({isClosingState: 'no'});*/
                  this.setState({canBeInitialState:
                    this.state.canBeInitialState ? false : true })}} />

              <FormInputRadio label="Può essere assegnato manualmente" 
                checked={this.state.canBeSelected}
                direction={FormInputContainer.HORIZONTAL}
                onClick={se=>{this.setState({canBeSelected:
                 this.state.canBeSelected ? false : true })}} />

            {/*<FormInputRadio label="Stato di inserimento a report"
                checked={this.state.isMarketingReportAssignment}
                direction={FormInputContainer.HORIZONTAL}
                onClick={se=>{this.setState({isMarketingReportAssignment:
                 this.state.isMarketingReportAssignment ? false : true })}} />*/}

            {this.state.parentEntityType==='contract' && <>
              <FormInputSelect values={{
                'no': 'No',
                'positive': 'Chiusura Positiva',
                'negative': 'Chiusura Negativa',
              }} label="Stato di chiusura contratto"
                value={this.state.isClosingState}
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>{
                  /*if( se !== 'no' )
                    this.setState({canBeInitialState: false});*/
                  this.setState({isClosingState: se})}} />

              </>}

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva stato"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class ContractStateViewer extends React.Component {
  render(){
    const st = this.props.contract.state;

    if( this.props.contract.ContractState ){
      let className="label ";

      if( this.props.contract.ContractState.type == 'warning' )
        className += " label-warning";
      if( this.props.contract.ContractState.type == 'error' )
        className += " label-danger";
      if( this.props.contract.ContractState.type == 'success' )
        className += " label-primary";

      return <span className={className}>{st}</span>
    }

    return <span className="label">{st}</span>
  }
}

class ContractStateSelect extends React.Component {

  constructor(props) {
    super(props);

    this.formatRow = this.formatRow.bind( this );
  }

  formatRow( row ){

    if( row.parentEntityType == 'Leads' )
      return row.name;

    return <React.Fragment>
      <ChannelDescription channel={row.Channel} /> / {row.name}</React.Fragment>;
  }

  render(){

    const parentEntityType = this.props.parentEntityType ? this.props.parentEntityType : 'contract';

    let filters = { active: true, parentEntityType,  ...this.props.filters };

    if( this.props.canBeInitialState )
      filters = {...filters, canBeInitialState: true };
    if( this.props.canBeSelected )
      filters = {...filters, canBeSelected: true };

    return <ApiMultiSelect label="Stato contratto" {...this.props} model="ContractStates" 
      formatRow={this.formatRow}
      filters={filters} />;
  }
}

class PracticeStateSelect extends React.Component {
  render(){
    let filters = { active: true, parentEntityType: 'practice',  ...this.props.filters };

    if( this.props.canBeInitialState )
      filters = {...filters, canBeInitialState: true };
    if( this.props.canBeSelected )
      filters = {...filters, canBeSelected: true };

    return <ApiMultiSelect label="Stato pratica" {...this.props} model="ContractStates"
      formatRow={row => { return <React.Fragment>{row.name}</React.Fragment>  }}
      filters={filters} />;
  }
}

export { ContractStates , ContractStateSelect, NewContractStateModal, ContractStateViewer, PracticeStateSelect };