import React from 'react';
import './App.css';
import moment from 'moment';

import {
    Loader, FormDashline, fetch,
    FormInputText, FormButtons, FormInputContainer,
    Modal, BoxContainer, FormInputDate, FormInputSelect
} from './Common.js';

import {
    AbstractTableViewer, can, AbstractApiFormFooter,
    apiFormInit, apiFormSave,
    ApiMultiSelect, Breadcumbs, FileUploadInput
} from './AppCommon';
import {UserSelect} from "./Users";
import {EvaluationFormStateSelect} from "./EvaluationFormStates";

class TelephonesWhitelist extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            telephonesWhitelistModal: false,
            telephones: false,

            filterNumber: null,
            filterList: null,
            filterDateStart: moment().startOf('year'),
            filterDateEnd: moment().endOf('year').add('1','year'),
        };

    }

    componentDidMount() {
        fetch('api', 'TelephonesWhitelist', 'select', {filters: {}}).then(msg => {
            this.setState({telephones: msg.body});
        });
    }

    getFilters() {

        return {
            number: this.state.filterNumber,
            list: this.state.filterList,
            dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
            dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm')
        };
    }


    render() {

        const columns = {
            number: 'Numero',
            list: 'Lista',
            validTo: 'Data Scadenza',
            actions: 'Azioni'
        };

        const row = telephonesWhitelist => {
            return <tr>
                <td>{telephonesWhitelist.number}</td>
                <td>{telephonesWhitelist.list}</td>
                <td>{telephonesWhitelist.validTo ? moment(telephonesWhitelist.validTo).format('D MMMM YY HH:mm') : '/'}</td>
                <td>
                    <div class="btn-group">
                        {can('TelephonesWhitelist.save') && <button class="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({telephonesWhitelistModal: telephonesWhitelist.id});
                        }}>Vedi</button>}
                    </div>
                </td>
            </tr>
        };

        return [<Breadcumbs title="Numeri TP2" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">
            {this.state.importModal && <TelephonesWhitelistImportModal onDone={se=>{
                window.location.reload();
                this.setState({importModal: false })}} />}
            <div className="row" style={{marginBottom: '15px'}}>
                <div className={'col-12'}>
                    {can('TelephonesWhitelist.save') && <button className="btn btn-primary" onClick={se => {
                        se.preventDefault();
                        this.setState({telephonesWhitelistModal: true});
                    }}>Aggiungi numero</button>}
                    { can( 'TelephonesWhitelist.import' ) && <button className="btn btn-primary" onClick={se=>{
                        se.preventDefault();
                        this.setState({importModal: true});
                    }} style={{marginLeft: '10px'}}>Importa file</button>}
                </div>
            </div>


            <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">
                            <div className="row">


                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <FormInputText label="Numero" value={this.state.filterNumber}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="list"
                                                   onChange={se => this.setState({filterNumber: se.target.value})}/>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <FormInputText label="Lista" value={this.state.filterList}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="list"
                                                   onChange={se => this.setState({filterList: se.target.value})}/>
                                </div>




                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <FormInputDate value={this.state.filterDateStart}
                                                   key="sdate"
                                                   label="Data scadenza da"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterDateStart: se})}/>
                                </div>

                                <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                                    <FormInputDate value={this.state.filterDateEnd}
                                                   key="edate"
                                                   label="Data scadenza a"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterDateEnd: se})}/>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-12">
                    {this.state.loading ? <Loader/> :
                        <div className="ibox">
                            <div className="ibox-content">
                                <AbstractTableViewer model="TelephonesWhitelist"
                                                     filters={this.getFilters()}
                                                     row={row}
                                                     columns={columns}/>

                            </div>
                        </div>}
                </div>


            </div>

            {this.state.telephonesWhitelistModal && <NewTelephonesWhitelistModal onDone={res => {
                this.setState({telephonesWhitelistModal: false});
            }} id={this.state.telephonesWhitelistModal === true ? false : this.state.telephonesWhitelistModal}/>}


        </div>];
    }
}


class TelephonesWhitelistImportModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            configId: false,
            phase: 'form' // form | loading | results
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(se){
        se.preventDefault();


        this.setState({ phase: 'results' });

        fetch( 'api' , 'TelephonesWhitelist' , 'import' , this.state ).then( data => {
        });

    }

    render(){
        return <Modal onClose={this.props.onDone} >
            {this.state.phase == 'form' && <form onSubmit={this.onSubmit}>

                <label>File da importare</label>

                <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
                                 filesId={this.state.filesId} />

                <FormInputText label="Lista" value={this.state.list}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="list"
                               onChange={se => this.setState({list: se.target.value})}/>

                <FormInputDate value={this.state.validTo}
                               key="sdate"
                               label="Data scadenza"
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({validTo: se})}/>

                <FormButtons saveLabel="Carica file"  direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
            </form>}

            {this.state.phase == 'results' && <React.Fragment>
                <h4>Importazione avviata</h4>

                <button className="btn btn-primary" onClick={se=>{this.props.onDone()}}>
                    Chiudi</button>


            </React.Fragment> }
        </Modal>;
    }
}


class NewTelephonesWhitelistModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: 1,
            number: '',
            list: '',
            validTo: moment().add(1,'year')
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        apiFormInit('TelephonesWhitelist', this);
    }

    onSubmit(se) {
        se.preventDefault();
        apiFormSave('TelephonesWhitelist', this);
    }

    render() {

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <FormInputText label="Numero" value={this.state.number}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="number"
                               onChange={se => this.setState({number: se.target.value})}/>

                <FormInputText label="Lista" value={this.state.list}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="list"
                               onChange={se => this.setState({list: se.target.value})}/>

                <FormInputDate value={this.state.validTo}
                               key="sdate"
                               label="Data scadenza"
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se => this.setState({validTo: se})}/>



                <FormDashline/>

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva numero" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </form>
        </Modal>;
    }

}

class TelephonesWhitelistSelect extends React.Component {
    render() {
        return <ApiMultiSelect label="Numero" {...this.props} model="TelephonesWhitelist"
                               formatRow={row => {
                                   return row.number;
                               }} filters={{}}
        />;
    }
}

export {TelephonesWhitelist, TelephonesWhitelistSelect, NewTelephonesWhitelistModal, TelephonesWhitelistImportModal};