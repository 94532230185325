import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, Row, FormInputDate,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputSelect, BoxContainer, FormInputRadio } from './Common.js';

  
import { Link } from "react-router-dom";

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
    apiFormInit , apiFormSave, formatEur, BrandSelect, FileUploadInput,
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';

import { SellingPointSelect } from './SellingPoints';

import { UserSelect } from './Users';
import { OperationTypes, OperationTypeSelect } from './OperationsTypes';


class ImportModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
        configId: false,
        phase: 'form' // form | loading | results
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(se){
    se.preventDefault();

    this.setState({phase: 'loading'});

    fetch( 'api' , 'Operations' , 'import' , this.state ).then( data => {
      this.setState({phase: 'results', results: data.body});
    });
  }

  render(){
    return <Modal onClose={this.props.onDone} >
      {this.state.phase == 'form' && <form onSubmit={this.onSubmit}>
      
        <label>File da importare</label>

        <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
          filesId={this.state.filesId} />

        <FormButtons saveLabel="Carica file"  direction={FormInputContainer.HORIZONTAL} 
          onSave={this.onSubmit} 
          onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
      </form>}

      {this.state.phase == 'loading' && <Loader /> }

      {this.state.phase == 'results' && <React.Fragment>
        <h4>Importazione completata</h4>

        <p>Operazioni importate: {this.state.results.contractImported}</p>
        <p>Errori: {this.state.results.errors}</p>

        {this.state.results.infos.map( info => {
          return <p>{info}</p>;
        })}

        <button className="btn btn-primary" onClick={se=>{this.props.onDone()}}>
          Chiudi</button>


      </React.Fragment> }
    </Modal>;
  }
}

class OperationsReport extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      reportDay: moment(),
      reports: false,
      filterAuthor: false,
      filterType: 'total',
    };

    this.updateReport();
  }

  updateReport(){
    this.setState({
      reports: false});

    fetch( 'api' , 'Operations' , 'totals' , this.state ).then( msg => {
      this.setState({reports: msg.body});
    });

  }

  componentDidUpdate( prevPros , prevState ){
    if( moment(prevState.reportDay).format() != moment(this.state.reportDay).format() || 
      this.state.filterAuthor != prevState.filterAuthor ||
      this.state.filterType != prevState.filterType ){
      this.updateReport();
    }
  }

  render(){

    return <Row>
        <div className="col-md-12 col-lg-12">
          <BoxContainer title="Report mensile">
            <Row>
              <div className="col-md-4">
                <FormInputDate value={this.state.reportDay}
                  label="Da" direction={FormInputContainer.HORIZONTAL}
                  onChange={se=>{this.setState({reportDay: se})}} />
              </div>
            
              <div className="col-md-4">
                <FormInputSelect value={this.state.filterType}
                  label="Tipo Report"
                  values={{'total': 'Totali' , 'users': 'Utenti'}}
                  direction={FormInputContainer.HORIZONTAL}
                  onChange={se=>{this.setState({filterType: se})}} />
              </div>
              {/*<div className="col-md-4">
                { this.state.filterType == 'users' && <UserSelect label="Caricato da" 
                  all={true}
                  value={this.state.filterAuthor}
                  direction={FormInputContainer.HORIZONTAL}
    onChange={se=>this.setState({filterAuthor: se,filterAuthorId: se ? se.id : null})} /> }
                </div>*/}

            </Row>
            <Row>
            
            {!this.state.reports && <Loader />}
            {this.state.reports && <table className="table table-striped table-hover">
              <tr>
                <th>Anno</th>
                <th>Mese</th>
                {this.state.filterAuthor && <th>Utente</th>}
                <th>Semplici</th>
                <th>Complesse</th>
                <th>Totale</th>
              </tr>
              {this.state.reports.map( userDailyReport => {
                return <tr>
                  <td>{userDailyReport.year}</td>
                  <td>{moment().month( userDailyReport.month-1 ).format( 'MMMM' )}</td>
                  {userDailyReport.Author && <td>{userDailyReport.Author.name} {userDailyReport.Author.surname}</td>}
                  <td>{formatEur( userDailyReport.simpleTotal )}</td>
                  <td>{formatEur( userDailyReport.complexTotal )}</td>
                  <td>{formatEur( userDailyReport.total )}</td>
                </tr>
              })}
            </table>}
            </Row>
          </BoxContainer>
        </div>
      </Row>
  }

}

class Operations extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      OperationModal: false,
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
      reports: false
    };

  }

  componentDidMount(){
    fetch( 'api' , 'MarketingReports' , 'select' , { filters: { modelName: 'Operations' } } ).then( msg => {
      this.setState({reports: msg.body});
    });
  }

  render(){

    const columns = { 
      a1: 'C.O.' ,
      a2: 'Tipo' ,
      a3: 'Operazione' ,
      az: 'Punto vendita',
      a4name: 'C.Fiscale' ,
      a5: 'Pagato' ,
      actions: 'Azioni'
  };

    const row = Operation => {
        return <tr>
          <td>{Operation.code}</td>
          <td>{Operation.OperationType ? Operation.OperationType.type : '-'}</td>
          <td>{Operation.OperationType ? Operation.OperationType.name : '-'}</td>
          <td>{Operation.SellingPoint ? Operation.SellingPoint.name : '-' }</td>
          <td>{Operation.vat}</td>
          {can('Operations.showValueEur') && 
            <td>{Operation.valueImported ? formatEur( Operation.valueImported ): 'No' }</td>}

          <td>
            <div class="btn-group">
                {can('Operations.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({OperationModal: Operation.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Operazioni" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

        {this.state.importModal && <ImportModal onDone={se=>{ 
            window.location.reload();
            this.setState({importModal: false })}} />}

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Operations.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({OperationModal: true});
          }}>Nuovo operazione</button>}

          { can( 'Operations.import' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({importModal: true});
          }} style={{marginLeft: '5px'}}>Importa file</button>}

          { can( 'OperationTypes.save' ) && 
            <Link style={{marginLeft: '10px'}} className="btn btn-primary" to="/operation_types">
              Gestisci Tipo operazioni</Link>}

      </div>

      <div className="row">

        <div className="col-md-6">
          <FormInputDate value={this.state.filterDateStart}
            direction={FormInputContainer.HORIZONTAL}
            label="Filtro data / Da"
            onChange={se=>{this.setState({filterDateStart: se})}} />
        </div>

        <div className="col-md-6">
          <FormInputDate value={this.state.filterDateEnd}
            direction={FormInputContainer.HORIZONTAL}
            label="Filtro data / A"
            onChange={se=>{this.setState({filterDateEnd: se})}} />
        </div>

        <div className="col-md-6">
          <FormInputSelect value={this.state.filterOperationTypeFilter}
            direction={FormInputContainer.HORIZONTAL}
            label="Tipo operazione"
            values={{
            '0' : 'Tutte',
            'Semplice' : 'Semplice',
            'Complessa' : 'Complessa',
            }}
            onChange={se=>this.setState({
              filterOperationType: false,
              filterOperationTypeId: 0,
              filterOperationTypeFilter: se})} />
        </div>

        <div className="col-md-6">
          <OperationTypeSelect 
            filterType={this.state.filterOperationTypeFilter}
            direction={FormInputContainer.HORIZONTAL}
            isMulti
            value={this.state.filterOperationTypes}
            onChange={se=>this.setState({filterOperationTypes: se})} />
        </div>

        <div className="col-sm-6" key="lead-table-3">
            { can( 'Operations.viewAll' ) && <UserSelect label="Caricato da" 
                all={true}
                value={this.state.filterAuthor}
                direction={FormInputContainer.HORIZONTAL}
  onChange={se=>this.setState({filterAuthor: se,filterAuthorId: se ? se.id : null})} /> }
        </div>

        <div className="col-sm-6 m-b-xs" key="lead-table-3">
            { can( 'Operations.showValueEur' ) && <FormInputSelect label="Pagato" 
                values={{
                  'n': 'NON Pagato',
                  'y': 'Pagato',
                  'a': 'Tutti'
                }}
                value={this.state.filterValue}
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>this.setState({filterValue: se})} /> }
        </div>
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="Operations" 
                        filters={{ 
                          filterDateStart: this.state.filterDateStart,
                          filterDateEnd: this.state.filterDateEnd,
                          AuthorId: this.state.filterAuthorId,
                          OperationTypes: this.state.filterOperationTypes,
                          OperationTypesType: this.state.filterOperationTypeFilter,
                          value: this.state.filterValue }}
                        row={row}
                        columns={columns}/>
                  </div>
              </div>
            </div>
          </div> }
        </div>

      </div>

      {can('Operations.report') && <OperationsReport filterAuthor={this.state.filterAuthor} />}

      {this.state.OperationModal && <NewOperationModal onDone={res=>{
        this.setState({OperationModal: false});
      }} id={this.state.OperationModal === true ? false: this.state.OperationModal} />}

      


    </div>];
  }
}



class NewOperationModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'Operations', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'Operations', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>
    
            <FormInputSelect value={this.state.operationType}
                direction={FormInputContainer.HORIZONTAL}
                label="Tipo operazione"
                values={{
                'Semplice' : 'Semplice',
                'Complessa' : 'Complessa',
                }}
                onChange={se=>this.setState({operationType: se})} />

            <OperationTypeSelect 
              filterType={this.state.operationType}
              direction={FormInputContainer.HORIZONTAL}
              value={this.state.OperationType}
              onChange={se=>this.setState({OperationTypeId: se?se.id:0,OperationType: se})} />

{/*this.state.operationType == 'Semplice' && <FormInputSelect value={this.state.operationSimple}
                direction={FormInputContainer.HORIZONTAL}
                label="Operazione semplice"
                values={{
                  'Allaccio gas': 'Allaccio gas',
                  'Allaccio e attivazione ': 'Allaccio e attivazione ',
                  'Attivazione rid ': 'Attivazione rid ',
                  'Attivazione vas': 'Attivazione vas',
                  'Autolettura ': 'Autolettura ',
                  'Cambio uso': 'Cambio uso',
                  'Cessazione con chiusura contatore ': 'Cessazione con chiusura contatore ',
                  'Copia documenti ': 'Copia documenti ',
                  'Dimostrato pagamento ': 'Dimostrato pagamento ',
                  'Errata operatività ': 'Errata operatività ',
                  'Estratto conto ': 'Estratto conto ',
                  'Fornitura straordinaria ': 'Fornitura straordinaria ',
                  'Informativa': 'Informativa',
                  'Modifica anagrafica ': 'Modifica anagrafica ',
                  'Modifica indirizzo fatturazione': 'Modifica indirizzo fatturazione',
                  'Modifica indirizzo fornitura ': 'Modifica indirizzo fornitura ',
                  'Modifica iva ': 'Modifica iva ',
                  'Modifica tensione/potenza ': 'Modifica tensione/potenza ',
                  'Modifica stato residenza ': 'Modifica stato residenza ',
                  'Piano di dietro ': 'Piano di dietro ',
                  'Cambio prodotto ': 'Cambio prodotto ',
                  'Rettifica fatturazione ': 'Rettifica fatturazione ',
                  'Revoca rid': 'Revoca rid',
                  'Revoca vas ': 'Revoca vas ',
                  'spostamento contatore ': 'spostamento contatore ',
                  'Spostamento impianto': 'Spostamento impianto',
                  'Verifica contatore/tensione ': 'Verifica contatore/tensione ',
                  'Voltura con accollo': 'Voltura con accollo'
                }}
                onChange={se=>this.setState({operationSimple: se})} />}

{this.state.operationType == 'Complessa' && <FormInputSelect value={this.state.operationComplex}
                direction={FormInputContainer.HORIZONTAL}
                label="Operazione complessa"
                values={{
                  'Voltura senza accollo': 'Voltura senza accollo',
                  'Prima attivazione': 'Prima attivazione',
                  'Subentro': 'Subentro'
                }}
              onChange={se=>this.setState({operationComplex: se})} />*/}

            <FormInputText label="Codice operazione" value={this.state.code}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="code"
                onChange={se=>this.setState({code: se.target.value})} />

            {/*<FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />

            <FormInputText label="Cognome" value={this.state.surname}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="surname"
              onChange={se=>this.setState({surname: se.target.value})} />*/}

            <FormInputText label="Codice fiscale / Piva" value={this.state.vat}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="vat"
                onChange={se=>this.setState({vat: se.target.value})} />

            {/*<FormInputText label="Localita" value={this.state.locality}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="locality"
            onChange={se=>this.setState({locality: se.target.value})} />*/}

            {/*<FormInputSelect value={this.state.eleGas}
                direction={FormInputContainer.HORIZONTAL}
                label="EleGas"
                values={{
                'Elettrico' : 'Elettrico',
                'Gas' : 'Gas',
                }}
              onChange={se=>this.setState({eleGas: se})} />*/}

            {/*<FormInputSelect value={this.state.type}
                direction={FormInputContainer.HORIZONTAL}
                label="Tipo cliente"
                values={{
                'Consumer residenziale' : 'Consumer residenziale',
                'Consumer business' : 'Consumer business',
                }}
              onChange={se=>this.setState({type: se})} />*/}

            {/*<FormInputText label="KW" value={this.state.kw}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="kw"
                onChange={se=>this.setState({kw: se.target.value})} />

            <FormInputSelect value={this.state.outcome}
                direction={FormInputContainer.HORIZONTAL}
                label="Esito"
                values={{
                'Inviata' : 'Inviata',
                'Fatto' : 'Fatto',
                'Chiusa' : 'Chiusa',
                'Annullata' : 'Annullata',
                'Sospesa' : 'Sospesa',
                }}
              onChange={se=>this.setState({outcome: se})} />*/}

            { can( 'Operations.showValueEur' ) && <FormInputRadio label="Pagato" 
              checked={this.state.valueImported}
              direction={FormInputContainer.HORIZONTAL}
              onClick={se=>{this.setState({valueImported:
                this.state.valueImported ? false : true })}} /> }

            <SellingPointSelect value={this.state.SellingPoint}
              direction={FormInputContainer.HORIZONTAL}
              onChange={se=>this.setState({SellingPointId: se.id,SellingPoint: se})} />

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva operazione"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class OperationSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Operazione" {...this.props} model="Operations" 
      formatRow={row => { return row.name; }} filters={{}}
       />;
  }
}

export { Operations , OperationSelect, NewOperationModal };