import React from 'react';
import moment from 'moment';

import {
  Loader, FormInputDate, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, FormInputSelect, Row, FormInputRange
} from './Common.js';

import {EleGasSelect, FileUploadInput, formatDateTime} from './AppCommon.js';

import {ContractStateSelect, ContractStateViewer} from './ContractStates';

import {
    AbstractTableViewer, can, AbstractApiFormFooter, apiFormInit, apiFormSave, ChannelDescription,
    ContractTypesSelect, ApiMultiSelect, ContractStatesSelect, Breadcumbs, LocalCache, ChannelSelect, formatEur
} from './AppCommon';
import { ImportConfigurationSelect } from './Contracts';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";
import {showExportModal} from "./Exports";

class ChannelVerificationDetail extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true
        };

        this.reload();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.ContractId != this.props.ContractId ){
            this.reload();
        }
    }

    reload(){

        this.setState({
            loading: true
        });

        fetch( 'api' , 'ChannelVerification' , 'detail', this.props.ContractId ).then( msg => {
            this.setState({
                data: msg.body,
                loading: false
            })
        })
    }

    render() {
        return <Modal onClose={this.props.onClose}>
            {this.state.loading && <Loader/> }
            {!this.state.loading && <React.Fragment>
              <h4>Dettagli pagamenti contratto</h4>
              <table className={"table table-striped"}>
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Tipo</th>
                    <th>EUR</th>
                    <th>Kw</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.data.map( row => {
                    let type = row.paymentType;
                    if( type == 'recurring' )
                      type = 'ricorrente';
                    return <tr>
                      <td>{moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{type}</td>
                      <td>
                        {formatEur(row.value)}
                      </td>
                      <td>{row.kw}</td>
                    </tr>;
                  })}
                </tbody>
              </table>

            </React.Fragment>}
        </Modal>;
    }
}

class ChannelVerificationImports_NotFound extends React.Component {

  constructor(props) {
    super(props);

    this.onApply = this.onApply.bind(this);

    this.state = {
      saving: false,
      saved: false,
    }
  }

  onApply( destContractId ){

    this.setState({ saving: true });

    const payload = {
      ChannelVerificationImportId: this.props.ChannelVerificationImportId,
      originalUniqueUuid: this.props.uniqueUuid,
      destContractId: destContractId,
    };

    fetch('api', 'ChannelVerificationImports', 'reimport' , payload ).then( msg => {
      this.setState({saved: msg.body, saving: false});
    });

  }

  render(){

    return <div className={"row"}>

      <div className={"col-4"}>
        <b>{this.props.uniqueUuid}</b>
      </div>

      <div className={"col-8"}>



        {this.state.saving && <div className={"row"}>
          <div className={"col-12"}>Salvataggio..</div>
        </div>}

        {!this.state.saving && <>

          {this.state.saved && <div className={"row"}>
            <div className={"col-12"}>Salvato</div>
          </div>}

          {!this.state.saved &&
          <div className={"row"}>
            <div className={"col-12"}>
              Alternative:
            </div>
            {this.props.alternatives.map( alternative => {
              return <div className={"col-12"}>
                {alternative.uniqueUuid} <button className={"btn btn-xs btn-primary"} onClick={se=>{
                se.preventDefault();
                this.onApply( alternative.id );
              } }>Applica</button>
              </div>
            })}
          </div>}

        </>}



      </div>

    </div>;
  }
}

class ChannelVerificationImportResultsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      results: false
    };

  }

  loadNextPage( page = 1 ){

    fetch( 'api' , 'ChannelVerificationImports' , 'result' , this.props.id , page ).then( data => {

      let current = this.state.results;
      
      let notFoundKeys = Object.keys( data.body.notFoundByContractUuid );

      if( !current )
        current = data.body;
      else {
        notFoundKeys.map( key => {
          current.notFoundByContractUuid[key] = data.body.notFoundByContractUuid[key];
        });

      }

      if( notFoundKeys.length > 0 )
        this.loadNextPage( page + 1 );

      this.setState({
        loading: false,
        results: current,
      });
    });
  }

  componentDidMount() {
    this.loadNextPage();
  }

  render(){

    if( this.state.loading )
      return <Modal><Loader /></Modal>;

    if( !this.state.results.complete )
      return <Modal  onClose={this.props.onDone}>
        <p>Import in fase di elaborazione...</p>
      </Modal>;

    return <Modal onClose={this.props.onDone}>

      <p>Contratti importati: {this.state.results.itemDone}</p>
      <p>Errori: {this.state.results.itemCount - this.state.results.itemDone}</p>

      {Object.keys(this.state.results.notFoundByContractUuid).length > 0 && <>
        <h4>Contratti non trovati</h4>

        {Object.keys(this.state.results.notFoundByContractUuid).map( notFoundUuid => {
          return <ChannelVerificationImports_NotFound {...this.state.results.notFoundByContractUuid[notFoundUuid]} />
        })}
      </>}

      {/*this.state.results.infos.map( info => {
        return <p>{info}</p>;
      })*/}

    </Modal>;
  }

}

class ImportModal extends React.Component {

    constructor(props){
      super(props);
  
      this.state = {
          configId: false,
          phase: 'form' // form | loading | results
      };
  
      this.onSubmit = this.onSubmit.bind(this);
    }
  
    onSubmit(se){
      se.preventDefault();


      this.setState({ phase: 'results' });

      fetch( 'api' , 'ChannelVerification' , 'import' , this.state ).then( data => {
      });

    }
  
    render(){
      return <Modal onClose={this.props.onDone} >
        {this.state.phase == 'form' && <form onSubmit={this.onSubmit}>
        
          <label>File da importare</label>
  
          <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
            filesId={this.state.filesId} />

          <FormInputDate value={this.state.referenceDate}
                         label="Data di riferimento file"
                         direction={FormInputContainer.HORIZONTAL}
                         onChange={se=>this.setState({referenceDate: se})} />

          <FormButtons saveLabel="Carica file"  direction={FormInputContainer.HORIZONTAL}
            onSave={this.onSubmit} 
            onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
        </form>}
  
        {this.state.phase == 'results' && <React.Fragment>
          <h4>Importazione avviata: controlla l'avanzamento da Contratti -> Importazioni verifica canali</h4>
          {/*
          <p>Contratti importati: {this.state.results.contractImported}</p>
          <p>Errori: {this.state.results.errors}</p>

          {Object.keys(this.state.results.notFoundByContractUuid).length > 0 && <>
            <h4>Contratti non trovati</h4>

            {Object.keys(this.state.results.notFoundByContractUuid).map( notFoundUuid => {
              return <ChannelVerificationImports_NotFound {...this.state.results.notFoundByContractUuid[notFoundUuid]} />
            })}
          </>}
  
          {this.state.results.infos.map( info => {
            return <p>{info}</p>;
          })}
  */}
          <button className="btn btn-primary" onClick={se=>{this.props.onDone()}}>
            Chiudi</button>
  
  
        </React.Fragment> }
      </Modal>;
    }
}
class ChannelVerificationRecurringTotals extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterDateStart: moment().subtract(1,'month').startOf('month'),
      filterDateEnd: moment().subtract(1,'month').endOf('month'),
      filterPaymentType: '',
    };

    this.reloadTotal();

  }

  getFilters( from ){

    if( !from ) from = this.state;

    return {
      channels: from.filterChannels,
      dateStart: from.filterDateStart,
      dateEnd: from.filterDateEnd,
      agents: from.filterAgents,
      eleGas: from.filterEleGas,
      paymentType: from.filterPaymentType,
      kw: from.filterKw,
      kwMeterType: from.filterKwMeterType
    }
  }

  reloadTotal(){
    this.setState({ total: false });

    fetch( 'api' , 'ChannelVerification' , 'recurringTotalsSum' , { filters: this.getFilters() } ).then( msg => {
      this.setState({ ...msg.body });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( JSON.stringify( this.getFilters( this.state ) ) !== JSON.stringify( this.getFilters( prevState ) ) ){
      this.reloadTotal();
    }
  }

  render(){

    const columns = {
      createdAt: 'Data' ,
      uniqueUuid: 'Contratto',
      kw: 'Kw',
      amount: 'Eur',
      Agent: 'Caricato Da',
    };

    if( ! can('ChannelVerification.recurringTotalsEur' ) )
      delete columns.amount;

    const row = row => {
      return <tr>
        <td>{formatDateTime( row.createdAt )}</td>
        <td>{row.uniqueUuid}</td>
        <td>{row.kw}</td>
        { can('ChannelVerification.recurringTotalsEur' ) && <td>{formatEur(row.value)}</td> }
        <td>{formatLeadName(row.ContractsExploded.Agent)}</td>
      </tr>;
    };

    return <React.Fragment>
      <Breadcumbs title="Calcolo totale ricorrenti" items={{'Home': '/' , 'Verifica commissioni canale': '/channel_verification'}} />

      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          <div className="col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-md-4">
                    <ChannelSelect value={this.state.filterChannels}
                                   label="Canale"
                                   validation={this.state.validation}
                                   direction={FormInputContainer.HORIZONTAL}
                                   isMulti
                                   onChange={se=>this.setState({filterChannels: se,})} />
                  </div>

                  <div className={"col-md-4"}>
                    <FormInputDate value={this.state.filterDateStart}
                                   label="Da"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({filterDateStart: se})}/>
                  </div>

                  <div className={"col-md-4"}>
                    <FormInputDate value={this.state.filterDateEnd}
                                   label="A"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({filterDateEnd: se})}/>
                  </div>

                  { can( 'ChannelVerification.recurringTotalsAll' ) && <div className={"col-md-4"}>
                    <UserSelect value={this.state.filterAgents}
                                isMulti
                                label="Caricato da"
                                direction={FormInputContainer.HORIZONTAL}
                                onChange={se => this.setState({filterAgents: se})}/>
                  </div> }

                  <div className={"col-md-4"}>
                    <EleGasSelect value={this.state.filterEleGas}
                                  onChange={se => this.setState({filterEleGas: se})} />
                  </div>

                  <div className={"col-md-4"}>
                    <FormInputSelect value={this.state.filterPaymentType}
                                     label="Tipologia"
                                     values={{
                                       '': 'Tutti',
                                       'recurring': 'Ricorrenti',
                                       'wallet': 'Portafoglio',
                                     }}
                                     direction={FormInputContainer.HORIZONTAL}
                                     onChange={se => this.setState({filterPaymentType: se})}/>
                  </div>

                  <div className="col-xl-4 col-lg-4">
                    <FormInputRange label="Kw" value={this.state.filterKw}
                                    direction={FormInputContainer.HORIZONTAL}
                                    name="filterKw"
                                    onChange={se => {
                                      this.setState({filterKw: se})
                                    }}/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={"row"}>

        { can('ChannelVerification.recurringTotalsEur' ) &&
          <div className={"col-md-6 col-lg-4"}>
            <div className="widget style1 navy-bg">
              <div className="row">
                <div className="col-md-12 text-right">
                  <span> Totale eur </span>
                  <h2 className="font-bold">
                    {this.state.amountEur ? formatEur( this.state.amountEur ) : '-'}
                  </h2>
                </div>
              </div>
            </div>
          </div> }

          <div className={"col-md-6 col-lg-4"}>
            <div className="widget style1 navy-bg">
              <div className="row">
                <div className="col-md-12 text-right">
                  <span> Totale KW </span>
                  <h2 className="font-bold">
                    {this.state.amountKw ? Math.round( this.state.amountKw ).toLocaleString() : '-'}
                  </h2>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> :
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="ChannelVerification"
                                       method={"recurringTotals"} infoMethod={"recurringTotalsInfo"}
                                       filters={ this.getFilters() }
                                       row={row}
                                       columns={columns}/>

                  { can( 'ChannelVerification.recurringTotalsReport' ) && <>
                    <h4>Verifica contratti mancanti</h4>

                    <ApiMultiSelect label="File da confrontare"  direction={FormInputContainer.HORIZONTAL}
                                    model="ChannelVerification"  modelAction="recurringVerificationReport_availableImports"
                                    filters={ this.getFilters() }
                                    isMulti
                                    onChange={ se => { this.setState({imports: se })}}
                                    value={this.state.imports}
                                    formatRow={row => { return row.filename; }} {...this.props} />

                    <AbstractTableViewer model="ChannelVerification"
                                         method={"recurringVerificationReport"} infoMethod="recurringVerificationReport_info"
                                         filters={{ ...this.getFilters(), imports: this.state.imports }}
                                         row={row => {
                                           return <tr>
                                             <td>{row.uniqueUuid}</td>
                                           </tr>;
                                         }}
                                         columns={{
                                           uniqueUuid: 'Contratto'
                                         }}/>
                  </>}

                </div>
              </div> }
          </div>
        </div>


      </div>

    </React.Fragment>;
  }

}

class ChannelVerificationRecurring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment().endOf('year'),
    };

  }

  render(){

    const columns = {
      ContractId: 'ID Contratto' ,
      lastRecurring: 'Ultimo ricorrente'
    };

    const row = row => {
      return <tr>
        <td>{row.uniqueUuid}</td>
        <td>{row.lastRecurring?moment(row.lastRecurring).format('D MMMM YY HH:mm'): '-'}</td>
      </tr>;
    };

    return <React.Fragment>
      <Breadcumbs title="Verifica Ricorrenti" items={{'Home': '/' , 'Verifica commissioni canale': '/channel_verification'}} />

      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          <div className="col-sm-12">
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-md-4">
                    <ChannelSelect value={this.state.filterChannels}
                                   label="Canale"
                                   validation={this.state.validation}
                                   direction={FormInputContainer.HORIZONTAL}
                                   isMulti
                                   onChange={se=>this.setState({filterChannels: se,})} />

                  </div>

                  <div className="col-md-2">
                    <FormInputDate value={this.state.filterDateStart}
                                   label="Data inizio"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setState({filterDateStart: se})} />
                  </div>

                  <div className="col-md-2">
                    <FormInputDate value={this.state.filterDateEnd}
                                   label="Data fine"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setState({filterDateEnd: se})} />
                  </div>

                  <div className={"col-md-4"}>
                    <UserSelect value={this.state.filterAgents}
                                isMulti
                                label="Caricato da"
                                direction={FormInputContainer.HORIZONTAL}
                                onChange={se => this.setState({filterAgents: se})}/>
                  </div>

                  <div className={"col-md-4"}>
                    <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterStateType"
                                     values={{
                                       '': 'Tutti',
                                       'positive': 'Pagabile',
                                       'no': 'Neutro',
                                       'negative': 'Non Pagabile',
                                     }}
                                     onChange={se => {
                                       this.setState({filterStateType: se})
                                     }}/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>


        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> :
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="ChannelVerification"
                     method={"recurring"} infoMethod={"recurringInfo"}
                     filters={{
                       channels: this.state.filterChannels,
                       agents: this.state.filterAgents,
                       dateStart: this.state.filterDateStart,
                       dateEnd: this.state.filterDateEnd,
                       stateType: this.state.filterStateType,
                     }}
                     row={row}
                     columns={columns}/>
                </div>
              </div> }
          </div>
        </div>


      </div>

    </React.Fragment>;
  }

}

class ChannelVerification extends React.Component {

    constructor(props){
      super(props);

      let q = this.getQuerySearch( "contract" );

      this.state = {
        filterDateStart: moment().startOf( 'year' ),
        filterDateEnd: moment().endOf( 'year' ),
        filteType: 'all',
        detailModal: false,
        filterStateDateType: 'created',
        filterUniqueUuid: q ? q : '',
      };
  
    }

    getQuerySearch( name ){
      try {
        const re = new RegExp( name+"\=([^&]+)" );
        const match = re.exec( window.location.search );

        if( match[1].length > 0 ){
          return match[1];
        }


      } catch (e) { }

      return false;
    }

    componentDidMount() {
      if( this.getQuerySearch( "contract" ) ){
        this.setState({filterUniqueUuid: this.getQuerySearch( "contract" )});
      }
    }

    getFilters(){
      return {
        uniqueUuid: this.state.filterUniqueUuid,
        filterType: this.state.filterType,
        filterChannel: this.state.filterChannel,
        filterStateRidWeb: this.state.filterStateRidWeb,
        filterStateType: this.state.filterStateType,
        filterStateDateType: this.state.filterStateDateType,
        filterDateStart: this.state.filterDateStart,
        filterDateEnd: this.state.filterDateEnd,};
    }

  render(){

      const columns = { 
          ContractId: 'ID Contratto' , 
          createdAt: 'Data contratto',
          marketingAt: 'Data Ins. Sistema',
          ContractState: 'Stato',
          Value: 'Totale import',
          ContractCreatedAt: 'Data import',
      };
      
      const row = notFound => {
        const mDate = moment(notFound.ContractsHistories_lastMarketing_date);

        return <tr>
          <td>{notFound.Contract.uniqueUuid}</td>
          <td>{moment(notFound.ContractCreatedAt).format('D MMMM YY HH:mm')}</td>
          <td>{mDate.isValid() ? mDate.format('D MMMM YY HH:mm') : '-'}</td>
          <td><ContractStateViewer contract={notFound.Contract} /></td>
            <td><a href="#" onClick={se=>{
                se.preventDefault();

                this.setState({detailModal: notFound.Contract.id})
            }}>{notFound.total ? notFound.total : '-' }</a></td>
          <td>{notFound.ImportCreatedAt ? moment(notFound.ImportCreatedAt).format('D MMMM YY HH:mm') : '-'}</td>
        </tr>;
      };

      return <React.Fragment>
          <Breadcumbs title="Verifica commissioni canale" items={{'Home': '/'}} />

          {this.state.detailModal && <ChannelVerificationDetail
                onClose={S=>{this.setState({detailModal: false})}}
              ContractId={this.state.detailModal}/>}

          {this.state.importModal && <ImportModal onDone={se=>{ 
            window.location.reload();
            this.setState({importModal: false })}} />}

          <div className="wrapper wrapper-content  animated fadeInRight">

            <div className="row" style={{marginBottom: '15px'}}>
              <div className="col-sm-12">
                { can( 'ChannelVerification.import' ) && <button className="btn btn-primary" onClick={se=>{
                    se.preventDefault();
                    this.setState({importModal: true});
                }} style={{marginLeft: '10px'}}>Importa file</button>}

                { can( 'ChannelVerification.recurring' ) && <a className={"btn btn-primary ml-2"} href={"/channel_verification_recurring"}>Verifica ricorrenti</a> }

                { can( 'ChannelVerification.recurringTotals' ) && <a className={"btn btn-primary ml-2"} href={"/channel_verification_recurring_totals"}>Calcolo totale ricorrenti</a> }

                {can('ChannelVerification.export') && <button className="btn btn-primary" onClick={se => {
                  se.preventDefault();
                  showExportModal( 'ChannelVerification' , this.getFilters() );
                }} style={{marginLeft: '10px'}}>Esporta Excel</button>}

              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <div className="row">
                      {!this.getQuerySearch( "contract" ) && <div className="col-lg-3">
                        <FormInputText label="ID Contratto" value={this.state.filterUniqueUuid}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>this.setState({filterUniqueUuid: se.target.value})} />
                      </div>}

                        <div className="col-lg-2">
                            <FormInputSelect label="Tipo date" value={this.state.filterStateDateType}
                             direction={FormInputContainer.HORIZONTAL}
                             name="filterStateType"
                             values={{
                                 'state': 'Inserito a sistema',
                                 'created': 'Creazione',
                             }}
                             onChange={se=>{this.setState({filterStateDateType: se})}} />
                        </div>

                        <div className="col-lg-2">
                            <FormInputDate value={this.state.filterDateStart}
                               key="sdate"
                               label="Data inizio"
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se=>this.setState({filterDateStart: se})} />
                        </div>

                        <div className="col-lg-2">
                            <FormInputDate value={this.state.filterDateEnd}
                               key="edate"
                               label="Data fine"
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se=>this.setState({filterDateEnd: se})} />
                        </div>

                      <div className="col-lg-3">
                        <FormInputSelect values={{
                            'all' : 'Tutti',
                            'null' : 'Non pagati',
                            'notNull' : 'Pagati',
                          }} label="Filtro per tipo"
                          value={this.state.filterType}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>{this.setState({filterType: se})}} />
                      </div>

                        <div className="col-lg-2">
                            <ChannelSelect value={this.state.filterChannel}
                               direction={FormInputContainer.HORIZONTAL}
                               all={true}
                               isMulti
                               onChange={se=>this.setState({filterChannel: se})} />
                        </div>

                      <div className="col-lg-4">
                        <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                           direction={FormInputContainer.HORIZONTAL}
                           name="filterStateType"
                           values={{
                             '': 'Tutti',
                             'positive': 'Pagabile',
                             'no': 'Neutro',
                             'negative': 'Non pagabile',
                           }}
                           onChange={se=>{this.setState({filterStateType: se})}} />
                      </div>

                        <div className="col-lg-4">
                            <FormInputSelect label="Rid / Web" value={this.state.filterStateRidWeb}
                             direction={FormInputContainer.HORIZONTAL}
                             name="filterStateRidWeb"
                             values={{
                                 '': 'Tutti',
                                 'rid': 'Solo RID',
                                 'web': 'Solo Web',
                             }}
                             onChange={se=>{this.setState({filterStateRidWeb: se})}} />
                        </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="row">

              <div className="col-md-12 col-lg-12">
                { this.state.loading ? <Loader /> : 
                    <div className="ibox">
                        <div className="ibox-content">
                          <AbstractTableViewer model="ChannelVerification" 
                              filters={this.getFilters()}
                              row={row}
                              columns={columns}/>
                        </div>
                    </div> }
              </div>
            </div>

                    
          </div>

      </React.Fragment>;
    }
}

export { ChannelVerification, ChannelVerificationImportResultsModal, ChannelVerificationRecurring, ChannelVerificationRecurringTotals };