import React, {useEffect, useState} from 'react';
import './App.css';
import moment from 'moment';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { BrowserRouter as Router, Route, Link } from "react-router-dom";

import {
  Loader, fetch, FormDashline, FormInputText, FormButtons,
  FormInputContainer, Modal, FormInputSelect, FormInputPassword, FormInputDate,
  FormInputTime, fetchHook, FormInputColorSimple, SocketIoDebugger
} from './Common.js';

import  { can, apiFormInit, apiFormSave, AbstractApiFormFooter,
  LocalCache, ScrollPagination } from './AppCommon';

import { Products, ProductCategories } from './Products.js';

import {Users, Agents, UsersLogs} from './Users.js';
import { Calendar } from './Calendar';
import { LeadsTable, LeadsPage } from './Leads';
import { Calls } from './Calls';
import { Appointments } from './Appointments';
import { Contracts, ContractsImports, ContractReportUsers,
  ChannelVerificationImports, ContractReportSellingPoints,
  ReportStates, ContractReportMonthly, BusinessReport } from './Contracts';
import { Folders } from './Folders';
import { Roles } from './Roles';
import { Chat } from './Messages';
import { SellingPoints } from './SellingPoints';
import { Operations } from './Operations';
import { ContractStates } from './ContractStates';
import {
  ChannelVerification,
  ChannelVerificationRecurring,
  ChannelVerificationRecurringTotals
} from './ChannelVerification';
import { ChannelCodes } from './ChannelCodes';
import {
  MarketingPlans, MarketingReport, MarketingReportsPlanPage,
  AgentReport, MarketingReport_ContractInspector, UsersPaymentReport, MarketingPlansToPayReport
} from './MarketingPlans';
import { SettingsPages } from './Settings';
import {UserDailyReports, UserDailyReportsDays} from './UserDailyReports';
import { OperationTypes } from './OperationsTypes';
import { LeadInterests } from './LeadInterests';
import { ContractVoidCauses } from './ContractVoidCauses';
import { ReportProducts } from './ReportProducts';
import { ReportObjectives } from './ReportObjectives';
import {ReportAppointments} from "./ReportAppointments";
import {ReportProduction} from "./ReportProduction";
import {ReportLead} from "./ReportLead";
import {Ddt, DdtDetailView, DdtMissingContracts} from "./Ddt";
import ReportStatesNulled from "./ReportStatesNulled";
import {NotificationTypes} from "./NotificationTypes";
import {DdtCategories} from "./DdtCategories";
import {DdtRangeView} from "./DdtRangeView";
import {MarketingReportsEditor} from './MarketingReportsEditor';
import {UserManagers} from "./UserManagers";
import {EnelxSells} from "./EnelxSells";
import {EnelxSellsStatesReport} from "./EnelxSellsStatesReport";
import {EnelxSellsStates} from "./EnelxSellsStates";
import {AutoContractRoles} from "./AutoContractRoles";
import {CustomPages, CustomPagesMenuResolver, CustomPagesRouter} from "./CustomPages";

import {EvaluationForms} from "./EvaluationForms";
import {EvaluationFormAreas} from "./EvaluationFormAreas";
import {TelephonesWhitelist} from "./TelephonesWhitelist";
import {EvaluationFormStates} from "./EvaluationFormStates";
import {ExportModal, ExportsStatus} from "./Exports";
import {Pedonabilities} from "./Pedonabilities";
import {TechAssistance} from "./TechAssistance";
import {TechAssistanceStates} from "./TechAssistanceStates";
import {TechAssistanceAreas} from "./TechAssistanceAreas";
import {Practices} from "./Practices";
import {Shifts, ShiftsAndLoginsReport, ShiftsReport} from "./Shifts";
import {EnelxSellInstallations} from "./EnelxSellInstallations";
import {MarketingReportJobs} from "./MarketingReportJobs";
import {QuotationProducts} from "./QuotationProducts";
import {Quotations, QuotationScreen} from "./Quotations";
import {EnelxSellsSubStates} from "./EnelxSellsSubStates";
import {Tickets} from "./Tickets";
import {ActiveUsers} from "./ActiveUsers";
import ContractReportMonthly2 from "./ContractReportMonthly";
import BackgroundTasksSummary from "./BackgroundTasks";

class VersionInfo extends React.Component {
  constructor(props){
    super(props);
    this.state={};
  }

  componentDidMount(){
    let sa = require( 'superagent' );
    sa.get( '/version.json' ).then( msg => {
      this.setState(msg.body);
    })
  }

  render(){
    if( !this.state.version )
      return null;

    return `Versione ${this.state.version}.${this.state.minVersion} (${moment(this.state.buildTimestamp).format('DD.MM.YY - HH:mm:ss')})`;
  }
}

class PostItBox extends React.Component {
  constructor(props){
    super(props);

    this.state={
      tasks:[],
      newModal: false
    };
  }

  reloadData(){
    fetch('api','Tasks','select',{filters:{type:'postit',done: false}}).then( data => {
      this.setState({tasks: data.body});
      this.installOnSaveHook();
    });
  }

  componentDidMount(){
    this.reloadData();
  }

  installOnSaveHook(){

    if( this.onSaveHook )
        this.onSaveHook.abort();

    this.onSaveHook = fetchHook( 'Tasks', 'onSave' );

    this.onSaveHook.then( data => {
      this.reloadData( true );
    }).catch( () => {
    });
  }

  componentWillUnmount(){
    if( this.onSaveHook )
        this.onSaveHook.abort();

    this.onSaveHook = false;
  }

  onTrash( se, item ){
    se.preventDefault();

    fetch( 'api' , 'Tasks' , 'save' , { id: item.id, done: true } ).then( data => {
      // auto reload from hook!
    });
  }

  render(){
    return <div className="ibox">

      {this.state.newModal && 
        <NewMemoModal type="postit" onDone={se=>{this.setState({newModal: false})}} />}

      <div class="ibox-title">
        <h5>Appunti</h5>
        <div class="ibox-tools">
            <a data-toggle="modal" class="btn btn-primary btn-xs"
            onClick={se=>{se.preventDefault();this.setState({newModal:true})}} href="#modal-form">Nuovo Appunto</a>
        </div>
      </div>
      <div className="ibox-content" style={{backgroundColor: '#f3f3f4'}}>
        <ul className="notes">
          {(this.state.tasks && this.state.tasks.length < 1) && <p className="text-center"
            style={{fontStyle: 'italic', color:'lightgray',padding: '20px',margin: '3em 0 3em 0'}}>
              Non ci sono appunti</p>}

          {this.state.tasks && this.state.tasks.map( task => {
            return <li>
                <div style={{backgroundColor: task.color ? task.color : '#ffc' }}>
                    <small>{moment(task.createdAt).format('HH:mm:ss DD-MM-YYYY')}</small>
                    <h4>{task.title}</h4>
                    <p>{task.body}</p>
                    <a href="#" onClick={se=>{se.preventDefault();this.onTrash(se, task)}}><i class="fa fa-trash-o "></i></a>
                </div>
            </li>
          })}
        </ul>
      </div>
    </div>
  }
}

class ToDoItem extends React.Component {

  constructor(props){
    super(props);

    this.state = { item: this.props.item };

    this.onClickItem = this.onClickItem.bind(this);
  }


  onClickItem( se, item ){
    se.preventDefault();

    fetch( 'api' , 'Tasks' , 'save' , { ...item, done: item.done?false:true } ).then( data => {
      fetch( 'api' , 'Tasks' , 'get' , this.state.item.id ).then( data => {
        this.setState({item: data.body});
      });
    });
  }

  render(){
    const item = this.state.item;

    return <li onClick={se=>{this.onClickItem(se, item)}}>
      <a onClick={se=>{this.onClickItem(se, item)}} href="#" class="check-link">
        <i className={"fa "+(item.done ? 'fa-check-square' : 'fa-square-o')}></i> </a>
      <span className={"m-l-xs "+(item.done ? 'todo-completed' : '')}>{item.title}</span>
      <span className="pull-right small">Creato il {moment(item.createdAt).format( 'DD MMM YYYY HH:mm')}</span>
    </li>
  }
}

class ToDoList extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      list: []
    };

    this.reloadData();
  }

  reloadData(){

    const ops = {
      filters: {done: false, type: 'memo'},
      pagination: { limit: 10 }
    };

    fetch( 'api' , 'Tasks' , 'select' , ops ).then( data => {
      this.setState({list: data.body});
    });
  }

  render(){

    if( this.state.list.length < 1 ){
      return <p className="text-center" 
        style={{fontStyle: 'italic', color:'lightgray',padding: '20px',margin: '3em 0 3em 0'}}>Non ci sono promemoria da completare</p>
    }

    return <ul className="todo-list m-t small-list">
      {this.state.list && this.state.list.map( item => {
        return <ToDoItem item={item} />;
      })}
      
    </ul>;
  }
}
class Dashboard extends React.Component {

  constructor(props){
    super(props);

    this.state = { 
      notifications: [],
      viewll: false,
      newMemoModal: false
    };


  }
  
  reloadData(){
    fetch( 'api' , 'Notifications' , 'unread' ).then( data => {
      this.setState({unread: data.body});
    });

    fetch( 'api' , 'Notifications' , 'select' , { pagination: { limit: 5 } } ).then( data => {
      this.setState({notifications: data.body});
    });
  }

  componentDidMount(){
    this.reloadData();
  }

  render(){
    return <React.Fragment>
        <div className="page-wrapper ">
          <div className="row  border-bottom white-bg dashboard-header">
            <div className="col-md-6">
              <h2>Benvenuto {LocalCache.get('loginData').User.name}</h2>
              <large>Le tue ultime notifiche.<a href="#" onClick={se=>{se.preventDefault();this.setState({viewll: true})}}>
                  <strong> Vedi Tutte</strong></a> </large>

              <ul className="list-group clear-list m-t">
                {this.state.notifications.map( n => {
                  return <li class="list-group-item" style={ n.read ? {} : { fontWeight: 'bold'}}>
                      <span class="float-right" style={ n.read ? {} : { fontWeight: 'bold'}}>
                          {moment(n.createdAt).format('DD/MM/YY')}
                      </span>
                    {n.content}
                  </li>;
                })}
              
              </ul>

              {this.state.viewll && <NotificationsModal onDone={se=>{this.setState({viewll: false})}} /> }
              {this.state.newMemoModal && <NewMemoModal onDone={s=>{window.location.reload();}} />}
              {this.state.memoModal && <MemoModal onDone={s=>{this.setState({memoModal: false})}} />}

            </div>
              <div className="col-md-6">
                <div className="ibox">
                  <h2>Promemoria</h2>
                  <large>Aggiungi e smarca tutte le tue cose da fare</large>
                  <span className="pull-right"><a href="#" onClick={se=>{se.preventDefault();this.setState({newMemoModal: true})}}>Aggiungi</a></span>
                    <ToDoList />
                  <span className="pull-right">
                    <a href="#" 
                      onClick={se=>{se.preventDefault();this.setState({memoModal: true})}}>Vedi tutti</a>
                  </span>
                </div>
              </div>
            </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              <div className="col-sm-12">
                <PostItBox />
              </div>
            </div>
          </div>

          <div className="wrapper wrapper-content">
            <div className="row">
              
              <div className="col-lg-12">
                <div className="ibox">
                  <div className="ibox-title" ><h5>Calendario</h5></div>
                  <div className="ibox-content">
                    <Calendar />
                  </div>
                </div>
                
              </div>
            </div>

          </div>
          
        </div>    
    </React.Fragment>;
  }
}


class Login extends React.Component {
  constructor(props){
    super(props);

    const cacheSep = LocalCache.get( 'loginSEP' );

    this.state = {
      sep: (cacheSep?cacheSep.id:false) || 'no',
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    /* eslint-disable no-undef */
    jQuery('body').addClass('gray-bg');
    /* eslint-enable no-undef */

  }

  componentWillUnmount(){
    /* eslint-disable no-undef */
    jQuery('body').removeClass('gray-bg');
    /* eslint-enable no-undef */
  }

  onSubmit(se){
    se.preventDefault();

    this.setState({error: false, loading: true});

    fetch( 'api' ,'login' , this.state ).then( data => {

      this.setState({loading: false});

      const token = data.body.token;
      if( token ){
        LocalCache.set( 'loginToken' , token );
        LocalCache.set( 'loginData' , data.body );

        window.location.reload();
      }

      if( data.body.error ){
        this.setState({error: data.body.error });
      }

    });
  }

  render(){
    return <div className="middle-box text-center loginscreen animated fadeInDown">
        <div>
            <div>

                <h1 className="LoginH1">easylife CRM</h1>

            </div>
            <h3>Benvenuto su easylife CRM</h3>
            <form className="m-t" role="form" action="index.html" onSubmit={this.onSubmit}>
              <FormInputText value={this.state.username} 
                onChange={se=>{this.setState({username: se.target.value})}} 
                onInput={se=>{this.setState({username: se.target.value})}}
                direction={FormInputContainer.HORIZONTAL}
                label="Username" />

              <FormInputPassword value={this.state.password} 
                onChange={se=>{this.setState({password: se.target.value})}}
                 onInput={se=>{this.setState({password: se.target.value})}}
                 direction={FormInputContainer.HORIZONTAL}
                label="Password" />

              {/*<FormInputSelect value={this.state.sep}
                 values={this.state.availableSeps}
                 onChange={se=>{this.setState({sep: se})}}
                 direction={FormInputContainer.HORIZONTAL}
                 label="SEP" />*/}

              {this.state.error && <div className="alert alert-danger" role="alert">{this.state.error}</div>}

                <button onClick={this.onSubmit} type="submit" className="btn btn-primary block full-width m-b">
                  {this.state.loading ? <i className="fa fa-spinner fa-spin"></i> : 'Login'}
                </button>

            </form>
            <p className="m-t"> <small>easylife CRM &copy; 2020</small> </p>
        </div>
    </div>;
  }
}

function SepSelectionModal( props ){

  const [ seps , setSeps ] = useState( [] );

  const [ sep , setSep ] = useState( 'no' );

  useEffect( () => {
    fetch( 'api' ,'availableSeps' ).then( data => {
      setSeps( data.body.seps );
    });
  } , [] );

  const onSubmit = e => {

    LocalCache.set( 'loginSEP' , sep );

    const payload = {
      token: LocalCache.get( 'loginToken' ),
      SellingPointId: sep.id
    };

    fetch( 'api' ,'updateSep' , payload ).then( data => {
    });

    props.onDone();
  }

  return <Modal onClose={props.onDone}>
    <h2>Seleziona SEP</h2>

    <FormInputSelect value={sep.id}
                     values={seps}
                     onChange={se=>{setSep( { id: se , name: seps[se] } )}}
                     direction={FormInputContainer.HORIZONTAL}
                     label="SEP" />

    <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                 onSave={onSubmit}
                 onCancel={se=>{se.preventDefault();if( props.onDone ) props.onDone(); }} />

  </Modal>
}

class NotificationsModal extends React.Component {
  render(){
    return <Modal onClose={this.props.onDone}>
      <h2>Storico notifiche</h2>

      <ul className="list-group clear-list m-t">
        <ScrollPagination model="Notifications" limit="50" row={ n => {
          return <li class="list-group-item" style={{width: '100%'}}>
              <span class="float-right">
                  {moment(n.createdAt).format('DD/MM/YY HH:mm')}
              </span>
            {n.content}
          </li>
        }} />
      </ul>
    </Modal>
  }
}


class NewMemoModal extends React.Component {

  constructor(props){
      super(props);
  
      this.state = {
        type: this.props.type || 'memo',
        title: ''
      };

      this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
      apiFormInit( 'Tasks', this ); 
    }

    onSubmit(se){
      se.preventDefault();
      apiFormSave( 'Tasks', this).then( () => { window.location.reload(); });
    }
  
    render(){
  
      return <Modal onClose={this.props.onDone}>
        <form onSubmit={this.onSubmit}>
  
          <FormInputText label="Titolo" value={this.state.title}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="title"
              onChange={se=>this.setState({title: se.target.value})} />
  
          {this.state.type == 'postit' && <FormInputText label="Corpo" value={this.state.body}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="body"
              onChange={se=>this.setState({body: se.target.value})} /> }

          {this.state.type == 'postit' && <FormInputColorSimple label="Colore" value={this.state.color}
               direction={FormInputContainer.HORIZONTAL}
               validation={this.state.validation} name="color"
               onChange={se=>this.setState({color: se.target.value})} /> }
          <FormDashline />

          <AbstractApiFormFooter {...this.state} />
  
          <FormButtons saveLabel="Salva promemoria"  direction={FormInputContainer.HORIZONTAL} 
            saving={this.state.apiFormSaving}
            onSave={this.onSubmit} 
            onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

        </form>
      </Modal>;
    }

}

class MemoModal extends React.Component {
  render(){
    return <Modal onClose={this.props.onDone}>
      <h2>Storico promemoria</h2>

      <ul className="todo-list m-t small-list">
        <ScrollPagination model="Tasks" filters={{type:'memo'}} limit="50" row={ n => {
          return <ToDoItem item={n} />
        }} />
      </ul>
    </Modal>
  }
}

class NotificationsIcon extends React.Component {

  constructor(props){
    super(props);

    this.state={
      notifications: [],
      open: false,
      viewll: false
    };

    this.reloadData();

    this.open = this.open.bind(this);
  }

  componentDidMount(){
    this.reloadData();

    this.timer = setInterval( ()=> { this.reloadData(); } , 60000 );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( prevState.unread !== this.state.unread ){
      fetch( 'api' , 'Notifications' , 'select' , { pagination: { limit: 8 } } ).then( data => {
        this.setState({notifications: data.body});
      });
    }
  }

  componentWillUnmount(){
    clearInterval( this.timer );
  }

  reloadData() {
    fetch('api', 'Notifications', 'unread').then(data => {
      this.setState({unread: data.body});
    });
  }

  open(se){
    se.preventDefault(); 
    this.setState({open: this.state.open ? false : true });

    fetch( 'api' , 'Notifications' , 'markRead' ).end();
    setTimeout( () => {
      this.reloadData();
    } , 2000 );
  }

  render(){
    return <React.Fragment>
      <a href="#" onClick={this.open}>
        <i className="fa fa-bell"></i>
        {this.state.unread > 0 && <span className="label label-primary">{this.state.unread}</span>}
      </a>

      {this.state.viewll && <NotificationsModal onDone={se=>{this.setState({viewll: false})}} /> }

      {this.state.open && <ul className="dropdown-menu dropdown-messages" style={{right: '100px', display: 'block', color: '#676a6c'}}>
        {this.state.notifications.length < 1 && 
          <p className="text-center">Non ci sono notifiche</p> }
          
        {this.state.notifications.map( notification => {
          return <React.Fragment>
              <li>
                <div className="dropdown-messages-box">
                    <div className="media-body" style={{}}>
                        <small className="pull-right">{moment(notification.createdAt).fromNow()}</small>
                        <span style={ notification.read ? {} : { fontWeight: 'bold'}}>{notification.content}</span> <br />
                        <small className="text-muted">{moment(notification.createdAt).format('dddd, MMMM D YYYY, h:mm:ss a')}</small>
                    </div>
                </div>
            </li>
            <li class="divider" style={{margin: '10px',height: '1px', backgroundColor: '#efefef'}}></li>
          </React.Fragment>
        })}

        <li>
          <div className="row">
            <div className="col-sm-6">
              <a href="#" className="text-left" onClick={se=>{se.preventDefault(); this.setState({viewll: true,open: false})}}>Vedi tutte</a>
            </div>
            <div className="col-sm-6">
              <a href="#" className="text-right" onClick={se=>{se.preventDefault(); this.setState({open: false})}}>Chiudi</a>
            </div>
          </div>
          
        </li>
      </ul>}

    </React.Fragment>;
  }
}

class App extends React.Component {

  constructor(props){
    super(props);

    this.toggleNavigation = this.toggleNavigation.bind(this);

    this.state = {};

    this.onChangeSep = this.onChangeSep.bind(this);

  }

  checkLoginToken(){
    fetch( 'api' , 'Notifications' , 'select' ).then( data => {
      if( data.body['error'] ){
        //LocalCache.clear();
        localStorage.clear();
        window.location.reload();
      }
    });

    fetch( 'api' , 'Users' , 'userInfo' ).then( msg => {
      LocalCache.set( 'loginData' , {User: msg.body });
    });

  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    let errorPayload = {
      UserId: 0,
      error: JSON.stringify({
        error, errorInfo
      })
    };

    try {
      errorPayload.UserId = LocalCache.get('loginData').User.id;
    } catch ( e) {}

    fetch( 'api' , 'ClientErrors' , 'save' , errorPayload ).then( data => {
    } );
  }

  componentDidMount(){

    window.onerror = function( msg, url, lineNo, columnNo, error){

      let errorPayload = {
        UserId: 0,
        error: JSON.stringify({
          msg, url, lineNo, columnNo, error
        })
      };

      try {
        errorPayload.UserId = LocalCache.get('loginData').User.id;
      } catch ( e) {}

      fetch( 'api' , 'ClientErrors' , 'save' , errorPayload ).then( data => {
      } );

    }

    LogRocket.init('z8lulo/desiedcrm');
    setupLogRocketReact(LogRocket);

    moment().locale('it');


    this.checkLoginToken();

    setInterval( ()=>{
      this.checkLoginToken();

      if( !LocalCache.get( 'loginToken') ){

        LogRocket.identify( `${LocalCache.get('loginData').User.id}` , {
          name: LocalCache.get('loginData').User.username,
        
          // Add your own custom user variables here, ie:
          userId: LocalCache.get('loginData').User.id
        });

      }

    } , 1000 * 60 );

    if( LocalCache.get('loginData') && LocalCache.get('loginData').User && can('Users.requestSellingPoint' ) ){
      if( !LocalCache.get( 'loginSEP' ) && LocalCache.get('loginData').User.Role.machineName != 'admin' )
        this.setState({ sepModal: true });
    }

  }

  toggleNavigation( se ){
    se.preventDefault();

    /* eslint-disable no-undef */
    if( jQuery('body').hasClass('mini-navbar') ){
      jQuery('body').removeClass('mini-navbar');
    } else {
      jQuery('body').addClass('mini-navbar');
    }
    /* eslint-enable no-undef */
  }

  onChangeSep( se ){
    se.preventDefault();
    this.setState({
      sepModal: true
    });
  }
  render(){

    try {
      if( !LocalCache.get('loginData').User.Role.name )
        LocalCache.clear( 'loginToken' );
    } catch ( e ){
      LocalCache.clear( 'loginToken' );
    }

    if( !LocalCache.get( 'loginToken') )
      return <Login />;
    
    let sellingPointName = false;

    try {
      sellingPointName = LocalCache.get('loginSEP').name
    } catch (e) {     }

    return <Router><div id="wrapper">

    <nav className="navbar-default navbar-static-side" role="navigation">
        <div className="sidebar-collapse">
            <ul className="nav metismenu" id="side-menu">
                <li className="nav-header">
                    <div className="dropdown profile-element">
                      <div className="logo-aside">
                        <img  alt="image" className="" src="/img/crm_logo_white.png"/>
                      </div>
                        <a href="#">
                            <span className="block m-t-xs font-bold text-cx">{LocalCache.get('loginData').User.name} {LocalCache.get('loginData').User.surname}</span>
                            <span className="text-muted text-xs block text-cx">{LocalCache.get('loginData').User.Role.name}</span>

                            <span className="text-muted text-xs block text-cx mt-2" onClick={this.onChangeSep}>
                              {sellingPointName ? sellingPointName : "Imposta SEP"}
                            </span>
                        </a>

                    </div>
                    <div className="logo-element">
                        Easylife CRM
                    </div>
                </li>
                <li>
                  <Link to="/"><i className="fa fa-fw fa-th-large"></i> <span className="nav-label">Home</span></Link>
                </li>
                { can( 'Users.adminPage' ) && <li>
                    <Link to="/users_list"><i className="fa fa-fw fa-star"></i> <span className="nav-label">Utenti</span></Link>
                    <ul className="nav nav-second-level collapse">
                      { can( 'Users.adminPage' ) && <li><Link to="/users_list">Utenti</Link></li> }
                      { can( 'UserManagers.select' ) && <li><Link to="/user_managers">Responsabili</Link></li> }
                      { can( 'AutoContractRoles.select' ) && <li><Link to="/auto_contract_roles">Ruoli contratti</Link></li> }
                      { can( 'Users.activeUsers' ) && <li><Link to="/active_users">Utenti attivi</Link></li> }
                      { can( 'Users.usersLogs' ) && <li><Link to="/users_logs">Storico accessi</Link></li> }
                      <CustomPagesMenuResolver machineName={"Users"} />
                    </ul>
                </li> }
                { (can( 'Leads.select' )&&can('Leads.viewLeadPage')) &&  <li>
                    <Link to="/leads_list"><i className="fa fa-fw fa-user-circle"></i> <span className="nav-label">Lead</span></Link>
                </li> }
                { /*can( 'Calls.select' ) && <li>
                    <Link to="/calls_list"><i className="fa fa-fw fa-phone"></i> <span className="nav-label">Chiamate</span></Link>
                  </li>*/ }
                { can( 'Appointments.select' ) && <li>
                    <Link to="/appointments_list"><i className="fa fa-fw fa-calendar"></i> <span className="nav-label">Appuntamenti</span></Link>
                </li> }

                { can( 'Contracts.select' ) && <li>
                    <Link to="/contracts_list"><i className="fa fa-fw fa-handshake-o"></i> <span className="nav-label">Contratti</span></Link>
                    <ul className="nav nav-second-level collapse">
                      <li><Link to="/contracts_list">Tutti i contratti</Link></li>
                      { can( 'ChannelCodes.save' ) && <li><Link to="/channel_codes">Codici canale</Link></li> }
                      { can( 'Contracts.import' ) && <li><Link to="/contracts_imports">Stato importazioni</Link></li> }
                      { can( 'Ddt.select' ) && <li><Link to="/ddt">Ddt</Link></li> }
                      { can( 'TelephonesWhitelist.select' ) && <li><Link to="/telephones_whitelist">Numeri TP2</Link></li> }
                      <CustomPagesMenuResolver machineName={"Contracts"} />
                    </ul>
                </li> }

              { (
                can( 'ChannelVerification.select' ) ||
                can( 'ChannelVerification.import' ) ||
                can( 'ChannelVerification.recurring' ) ||
                can( 'ChannelVerification.recurringTotals' )
              ) && <li>
                <Link to="/contracts_list"><i className="fa fa-fw fa-exclamation"></i> <span className="nav-label">Verifica canali</span></Link>
                <ul className="nav nav-second-level collapse">
                  { can( 'ChannelVerification.select' ) && <li><Link to="/channel_verification">Verifica commissioni canale</Link></li> }
                  { can( 'ChannelVerification.import' ) && <li><Link to="/channel_verification_imports">Importazioni</Link></li> }
                  { can( 'ChannelVerification.recurring' ) && <li><Link to="/channel_verification_recurring">Verifica ricorrenti</Link></li> }
                  { can( 'ChannelVerification.recurringTotals' ) && <li><Link to="/channel_verification_recurring_totals">Calcolo totale ricorrenti</Link></li> }
                </ul>
              </li> }

              { can( 'EnelxSells.select') && <li>
                <Link to="/enelx"><i className="fa fa-fw fa-handshake-o"></i> <span className="nav-label">Efficienza Energetica</span></Link>
                <ul className="nav nav-second-level collapse">
                  <li><Link to="/enelx">Tutte le vendite</Link></li>
                  { can( 'EnelxSellsStates.save' ) && <li><Link to="/enelx_states">Stati</Link></li> }
                  { can( 'EnelxSellsSubStates.save' ) && <li><Link to="/enelx_sub_states">Sotto stati</Link></li> }
                  { can( 'EnelxSellInstallations.select' ) && <li><Link to="/enelx_installations">Installazioni</Link></li> }
                </ul>
              </li>}

              { can( 'Quotations.select') && <li>
                <Link to="/quotations"><i className="fa fa-fw fa-briefcase"></i> <span className="nav-label">Preventivi</span></Link>
                <ul className="nav nav-second-level collapse">
                  <li><Link to="/quotations">Tutti i preventivi</Link></li>
                  { can( 'QuotationProducts.save' ) && <li><Link to="/quotation_products">Prodotti</Link></li> }
                </ul>
              </li>}

              { can( 'Practices.select' ) && <li>
                <Link to="/practices_list"><i className="fa fa-fw fa-folder-o"></i> <span className="nav-label">Pratiche</span></Link>
              </li> }

              { can( 'Shifts.select' ) && <li>
                <Link to="/shifts"><i className="fa fa-fw fa-clock-o"></i> <span className="nav-label">Turni</span></Link>
                <ul className="nav nav-second-level collapse">
                <li><Link to="/shifts">Tutti i Turni</Link></li>
                  { can( 'Shifts.showReport' ) && <li><Link to="/shifts_report">Report Turni</Link></li>}
                  { can( 'Shifts.showLoginsReport' ) && <li><Link to="/shifts_logins_report">Report Accessi / Turni</Link></li>}
                </ul>
              </li> }

                { can( 'Operations.select' ) && <li>
                    <Link to="/operations"><i className="fa fa-fw fa-bolt"></i> <span className="nav-label">Operazioni</span></Link>
                </li> }
                { can( 'EvaluationForms.select' ) && <li>
                  <Link to="/evaluation_forms"><i className="fa fa-fw fa-address-card"></i> <span className="nav-label">Schede valutative</span></Link>
                  <ul className="nav nav-second-level collapse">
                    { can( 'EvaluationForms.select' ) && <li><Link to="/evaluation_forms">Tutte le schede</Link></li>}
                    { can( 'EvaluationFormAreas.select' ) && <li><Link to="/evaluation_form_areas">Impostazioni aree</Link></li>}
                    { can( 'EvaluationFormStates.select' ) && <li><Link to="/evaluation_form_states">Impostazioni stati</Link></li>}
                  </ul>
                </li> }

              { can( 'TechAssistance.select' ) && <li>
                <Link to="/tech_assistance"><i className="fa fa-fw fa-address-card"></i> <span className="nav-label">Assistenza Tecnica</span></Link>
                <ul className="nav nav-second-level collapse">
                  { can( 'TechAssistance.select' ) && <li><Link to="/tech_assistance">Tutte le schede</Link></li>}
                  { can( 'TechAssistanceAreas.select' ) && <li><Link to="/tech_assistance_areas">Impostazioni aree</Link></li>}
                  { can( 'TechAssistanceStates.select' ) && <li><Link to="/tech_assistance_states">Impostazioni stati</Link></li>}
                </ul>
              </li> }
              
                { can( 'Products.viewMenuItem' ) && <li>
                    <Link to="/products_list"><i className="fa fa-fw fa-cube"></i> <span className="nav-label">Prodotti</span></Link>
                </li> }
                { can( 'Users.agent_show' ) && <li>
                    <Link to="/agents_list"><i className="fa fa-fw fa-suitcase"></i> <span className="nav-label">Agenti</span></Link>
                </li> }
                { can( 'SellingPoints.select' ) && <li>
                    <Link to="/selling_points"><i className="fa fa-fw fa-building"></i> <span className="nav-label">Punti vendita</span></Link>
                </li> }


              { can( 'Pedonability.select' ) && <li>
                <Link to="/pedonabilities"><i className="fa fa-fw fa-address-card"></i> <span className="nav-label">Pedonabilità</span></Link>
              </li> }

                { (can( 'ContractStates.select' ) && can( 'ContractStates.adminPage' )) && <li>
                    <Link to="/contract_types"><i className="fa fa-fw fa-random"></i> <span className="nav-label">Stati contratto</span></Link>
                </li> }

                { can( 'UserDailyReports.select' ) && <li>
                  <Link to="/user_daily_reports"> <i className="fa fa-fw fa-calendar-o"></i> <span className="nav-label">Resoconto Giornaliero</span></Link>
                  <ul className="nav nav-second-level collapse">

                    { can( 'UserDailyReports.select' ) &&
                      <li><Link to="/user_daily_reports">Vedi tutti</Link></li>}

                    { can( 'UserDailyReports.report' ) &&
                      <li><Link to="/user_daily_reports_days">Giornaliero</Link></li>}
                  </ul>
                </li> }

                <li>
                <Link to="/"><i className="fa fa-fw fa-bar-chart"></i> <span className="nav-label">Report</span></Link>
                  <ul className="nav nav-second-level collapse">

                    { can( 'MarketingReports.agentReport' ) &&
                      <li><Link to="/marketing_plans_agent_report">Report agente</Link></li>}
                    { can( 'reportObjectives.select' ) &&
                      <li><Link to="/report_objectives">Report obbiettivi</Link></li>}


                    { can( 'Appointments.report' ) && <li><Link to="/appointments_report">Report appuntamenti</Link></li> }
                    { can( 'Leads.report' ) && <li><Link to="/leads_report">Report lead</Link></li> }

                    { can( 'Contracts.reportMonthContract' ) && <li><Link to="/contracts_report_monthly">Report mensile</Link></li> }
                    { can( 'Contracts.reportStates' ) && <li><Link to="/contracts_report_states">Report stati</Link></li> }
                    { can( 'Contracts.reportStatesNulled' ) && <li><Link to="/contracts_report_states_nulled">Report stati annullati</Link></li> }

                    { can( 'Contracts.reportBusiness' ) && <li><Link to="/business_report">Report produzione reale</Link></li> }

                    { can( 'EnelxSells.reportStates') && <li><Link to="/enelx_states_report">Report stati EnelX</Link></li> }
                    <CustomPagesMenuResolver machineName={"Reports"} />

                  </ul>
                </li>

                { can('CustomPages.select' ) && <li>
                  <Link to="/"><i className="fa fa-fw fa-bar-chart"></i> <span className="nav-label">Report BI</span></Link>
                    <ul className="nav nav-second-level collapse">
                      <CustomPagesMenuResolver machineName={"Report BI"} />
                    </ul>
                </li>}
                
                { (can( 'MarketingPlans.generalPlansPage' ) || can( 'MarketingReports.viewUsersReports' ) || can( 'MarketingReports.viewFixedReports' ) || can( 'MarketingReports.agentReport' ) ) && <li>
                    <Link to="/marketing_plans"><i className="fa fa-fw fa-sitemap"></i> <span className="nav-label">Piano compensi</span></Link>
                    <ul className="nav nav-second-level collapse">

                      { can( 'MarketingPlans.generalPlansPage' ) &&
                        <li><Link to="/marketing_plans">Editor piano compensi</Link></li>}

                      { can( 'MarketingPlans.generalPlansPage' ) &&
                        <li><Link to="/marketing_plans_jobs">Stato calcolo</Link></li>}

                      { ( can( 'MarketingReports.viewUsersReports' ) || can( 'MarketingReports.viewFixedReports' ) ) && 
                        <li><Link to="/marketing_plans_report">Vedi Report</Link></li>}

                      { ( can( 'MarketingReportsEditor.select' ) ) &&
                      <li><Link to="/marketing_reports_editor">Modifica Report</Link></li>}

                      { ( can( 'MarketingReports.viewUsersPaymentReport' ) ) &&
                      <li><Link to="/user_payment_report">Report Pagamenti</Link></li>}

                      { ( can( 'MarketingReports.viewUsersReports' ) ) &&
                      <li><Link to="/marketing_report_to_pay">Report da pagare</Link></li>}

                        
                    </ul>
                </li> }

                { /*<li>
                    <a href="piano-compensi.html"><i class="fa fa-sitemap"></i> <span class="nav-label">Piani Compensi</span></a>
                </li> 
                <li>
                    <a href="reports.html"><i class="fa fa-area-chart"></i> <span class="nav-label">Reports</span></a>
                </li> */}
                { can( 'Folders.select' ) && <li>
                    <Link to="/folders"><i className="fa fa-fw fa-picture-o"></i> <span className="nav-label">Documenti</span></Link>
                </li> }

                { can( 'Tickets.select' ) && <li>
                  <Link to="/tickets"><i className="fa fa-fw fa-address-card"></i> <span className="nav-label">Ticket</span></Link>
                </li> }

                { (can( 'Settings.select' ) ) && <li>
                    <Link to="/settings"><i className="fa fa-fw fa-gear"></i> <span className="nav-label">Impostazioni</span></Link>
                    <ul className="nav nav-second-level collapse">
                      <li><Link to="/settings">Generali</Link></li>
                      { can( 'LeadInterests.save' ) && <li><Link to="/settings_lead_interests">Interessi lead</Link></li> }
                      { can( 'ContractVoidCauses.save' ) && <li><Link to="/settings_lead_void">Sotto stati contratti</Link></li> }
                      { can( 'NotificationTypes.save' ) && <li><Link to="/notification_types">Notifiche</Link></li> }
                      { can( 'CustomPages.save' ) && <li><Link to="/custom_pages">Pagine personalizzate</Link></li> }
                    </ul>
                </li> }

            </ul>

        </div>
    </nav>
    
    <div id="page-wrapper" className="gray-bg dashbard-1">

        <div className="row border-bottom">
          <nav className="navbar navbar-static-top" role="navigation" style={{marginBottom: 0}}>
          <div className="navbar-header">
              <a className="navbar-minimalize minimalize-styl-2 btn btn-primary " href="#" onClick={this.toggleNavigation} >
                <i className="fa fa-bars"></i> </a>
          </div>
                  <ul className="nav navbar-top-links navbar-right">
                      <li style={{padding: "20px"}}>
                          <span className="m-r-sm text-muted welcome-message">Benvenuti su easylife CRM</span>
                      </li>
                      <li>
                        <NotificationsIcon />
                      </li>
                      <li>
                          <a onClick={se => {
                            se.preventDefault(); 

                            LocalCache.clear('loginToken');
                            window.location.reload();
                          }}>
                              <i className="fa fa-sign-out"></i> Log out
                          </a>
                      </li>
                  </ul>

          </nav>
        </div>


        <Route exact path='/' component={Dashboard} />
        <Route path='/settings' component={SettingsPages} />
        <Route path='/settings_lead_interests' component={LeadInterests} />
        <Route path='/settings_lead_void' component={ContractVoidCauses} />
        <Route path='/notification_types' component={NotificationTypes} />

        <Route path='/leads_list' component={LeadsPage} />
        <Route path='/calls_list' component={Calls} />
        <Route path='/agents_list' component={Agents} />
        <Route path='/products_list' component={Products} />
        <Route path='/products_categories_list' component={ProductCategories} />
        <Route path='/users_list' component={() => { return <React.Fragment>
          <Users /><Roles />
        </React.Fragment>}} />
        <Route path='/user_managers' component={UserManagers} />
        <Route path='/auto_contract_roles' component={AutoContractRoles} />
        <Route path='/appointments_list' component={Appointments} />
        <Route path='/contracts_list' component={Contracts} />
        <Route path='/contracts_imports' component={ContractsImports} />
        <Route path='/channel_verification_imports' component={ChannelVerificationImports} />
        <Route path='/folders' component={Folders} />
        <Route path='/selling_points' component={SellingPoints} />
        <Route path='/operations' component={Operations} />
        <Route path='/operation_types' component={OperationTypes} />
        <Route path='/contract_types' component={ContractStates} />
        <Route path='/channel_codes' component={ChannelCodes} />
        <Route path='/channel_verification' component={ChannelVerification} />
        <Route path='/channel_verification_recurring' component={ChannelVerificationRecurring} />
        <Route path='/channel_verification_recurring_totals' component={ChannelVerificationRecurringTotals} />
        <Route path='/tickets' component={Tickets} />
        <Route path='/marketing_plans_report' component={MarketingReportsPlanPage} />
        <Route path='/marketing_plans_agent_report' component={AgentReport} />
        <Route path='/marketing_plans_report_contract' component={MarketingReport_ContractInspector} />
        <Route path='/marketing_reports_editor' component={MarketingReportsEditor} />
        <Route path='/user_daily_reports' component={UserDailyReports} />
        <Route path='/user_daily_reports_days' component={UserDailyReportsDays} />
        <Route path='/contracts_report_users' component={ContractReportUsers} />
        <Route path='/contracts_report_selling_points' component={ContractReportSellingPoints} />
        <Route path='/contracts_report_products' component={ReportProducts} />
        <Route path='/report_objectives' component={ReportObjectives} />
        <Route path='/contracts_report_monthly' component={ContractReportMonthly} />
        <Route path='/contracts_report_monthly2' component={ContractReportMonthly2} />
        <Route path='/contracts_report_states' component={ReportStates} />
        <Route path='/contracts_report_states_nulled' component={ReportStatesNulled} />
        <Route path='/custom_pages' component={CustomPages} />
        <Route path='/telephones_whitelist' component={TelephonesWhitelist} />
        <Route path='/pedonabilities' component={Pedonabilities} />


      <Route path='/tech_assistance' component={TechAssistance} />
      <Route path='/tech_assistance_areas' component={TechAssistanceAreas} />
      <Route path='/tech_assistance_states' component={TechAssistanceStates} />

      <Route path='/practices_list' component={Practices} />
      <Route path='/shifts' component={Shifts} />
      <Route path='/shifts_report' component={ShiftsReport} />
      <Route path='/shifts_logins_report' component={ShiftsAndLoginsReport} />

      <Route path='/active_users' component={ActiveUsers} />
      <Route path='/users_logs' component={UsersLogs} />

      <Route path='/quotations' component={Quotations} />
      <Route path='/quotation' component={QuotationScreen} />
      <Route path='/quotation_products' component={QuotationProducts} />

      <Route path='/business_report' component={ReportProduction} />
        <Route path='/appointments_report' component={ReportAppointments} />
        <Route path='/leads_report' component={ReportLead} />
        <Route path='/enelx' component={EnelxSells} />
        <Route path='/enelx_installations' component={EnelxSellInstallations} />
        <Route path='/enelx_states' component={EnelxSellsStates} />
        <Route path='/enelx_sub_states' component={EnelxSellsSubStates} />
        <Route path='/enelx_states_report' component={EnelxSellsStatesReport} />
        <Route path='/evaluation_forms' component={EvaluationForms} />
        <Route path='/evaluation_form_areas' component={EvaluationFormAreas} />
        <Route path='/evaluation_form_states' component={EvaluationFormStates} />
        <Route path='/ddt' component={Ddt} />
        <Route path='/ddt_detail_view/:ddtId' component={ (props) => {
                  return <DdtDetailView id={props.match.params.ddtId} />
        }} />
        <Route path='/ddt_categories' component={DdtCategories} />
        <Route path='/ddt_range_view' component={DdtRangeView} />
        <Route path='/ddt_missing' component={DdtMissingContracts} />
        <Route path='/user_payment_report' component={UsersPaymentReport} />
        <Route path='/marketing_report_to_pay' component={MarketingPlansToPayReport} />
        <Route path='/marketing_plans' component ={ props => <MarketingPlans editor={true} />} />
        <Route path='/marketing_plans_jobs' component={MarketingReportJobs} />
        <Route path='/marketing_report/:reportId' component={ (props) => {
          return <MarketingReport id={props.match.params.reportId} />
        }} />

        <CustomPagesRouter />

      {this.state.sepModal && <SepSelectionModal onDone={()=>{this.setState({sepModal: false})}} />}

      <BackgroundTasksSummary />

      <ExportModal />
        <ExportsStatus />
          
        <div className="footer">
                <div className="float-right">
                  <VersionInfo /> <strong>easylife CRM</strong>.
                </div>
                <div>
                    <strong>Copyright</strong> easylife CRM &copy; 2020
                </div>
            </div>

      </div>
      

    </div>

    </Router>
    ;
  }
}

export default App;

export {};