import React, {useEffect, useState} from 'react';
import './App.css';

import { Loader , fetch, FormDashline,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, FormInputSelect } from './Common.js';

import AsyncSelect from 'react-select/async';

import {
  ApiSelect,
  AbstractTableViewer,
  can,
  AbstractApiFormFooter,
  apiFormInit,
  apiFormSave,
  ApiMultiSelect,
  AbstractEditorModal,
  ConfirmableButton
} from './AppCommon';

class Roles extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      roleModal: false
    };

  }

  onDelete( role ) {
    fetch('api', 'Roles', 'delete', role.id).then(res => {

    });
  }

  render(){

    const columns = { 
      name: 'Nome' , 
      count: 'Utenti assegnati' ,
      actions: '',
  };


    const row = role => {
        return <tr>
          <td>{role.name}</td>
          <td>{role.Users.length}</td>
          <td>
            <div class="btn-group">
                <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({roleModal: role.id});
                }}>Vedi</button>
              <button className="btn-white btn btn-xs" onClick={se => {
                se.preventDefault();
                this.setState({cloneRoleModal: role.id});
              }}>Clona
              </button>
              {role.Users.length === 0 && <ConfirmableButton className="btn btn-xs btn-danger"
                                 onClick={se=>{
                                   se.preventDefault();
                                   this.onDelete(role)
                                 }}>Elimina</ConfirmableButton>}
            </div>
          </td>
        </tr>
      };

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Roles.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({roleModal: true});
          }}>Nuovo ruolo</button>}
      </div>

      {this.state.roleModal && can( 'Roles.save' ) && <RoleModal onDone={res=>{
        this.setState({roleModal: false});
        //window.location.reload();
      }} id={this.state.roleModal === true ? false: this.state.roleModal} />}

      {this.state.cloneRoleModal && can( 'Roles.save' ) && <CloneRoleModal onDone={res=>{
        this.setState({cloneRoleModal: false});
        //window.location.reload();
      }} id={this.state.cloneRoleModal === true ? false: this.state.cloneRoleModal} />}

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="Roles" 
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}

function CloneRoleModal( props ){
  const [ formData, setFormData ] = useState({ });

  return <AbstractEditorModal
    saveMethod="clone" modalTitle={"Clona ruolo"}
    formData={formData} onFormDataChange={setFormData}
    name="ruolo" model="Roles" {...props} >

    <h4>Nome originale ruolo da clonare: {formData.name}</h4>

    <FormDashline />

    <FormInputText label="Nome" value={formData.newName}
                   direction={FormInputContainer.HORIZONTAL}
                   validation={formData.validation} name="newName"
                   onChange={se=>setFormData({...formData, newName: se.target.value})} />

  </AbstractEditorModal>
}

function RoleModal( props ){

  const [ formData, setFormData ] = useState({ Permissions: [] });

  const [ permissionsList, setPermissionsList ] = useState( [] );
  const [ permissions, setPermissions ] = useState( [] );

  const [ filter, setFilter ] = useState( '' );

  useEffect( () => {
    fetch( 'api' , 'Roles' , 'permissionsList' ).then( data => {
      setPermissionsList( Object.fromEntries( data.body.map( p => {
        return [ p, p ];
      } ) ) );
    });
  } , [] );

  useEffect( () => {
    setPermissions([]);
    fetch( 'api' , 'Roles' , 'permissionsOf' , { model: filter } ).then( data => {
      setPermissions( data.body.length ? data.body : [] );
    } );
  } , [ filter ] );

  const switchPermission = permission => {
    if( hasPermission( permission ) ){
      setFormData({ ...formData, Permissions: formData.Permissions.filter( perm => {
          return perm.id != permission.id;
        })})
    } else {
      let ps = formData.Permissions;
      ps.push( permission );

      setFormData({ ...formData, Permissions: ps});
    }

  }

  const hasPermission = permission => {
    return formData.Permissions.filter( perm => {
      return perm.id == permission.id;
    }).length > 0;
  }

  const alterPayLoad = payload => {
    payload.Permissions = payload.Permissions.map( p => {
      return { id: p.id }
    });

    return payload;
  };

  return <AbstractEditorModal
    alterPayLoad={alterPayLoad}
    formData={formData} onFormDataChange={setFormData}
    name="ruolo" model="Roles" {...props} >

    <FormInputText label="Nome" value={formData.name}
                   direction={FormInputContainer.HORIZONTAL}
                   validation={formData.validation} name="name"
                   onChange={se=>setFormData({...formData, name: se.target.value})} />

    <FormDashline />

    <FormInputSelect values={permissionsList}
                     value={filter}
                     label="Contesto"
                     direction={FormInputContainer.HORIZONTAL}
                     onChange={setFilter} />

    {!permissions.length && <Loader />}

    {permissions.length && <>{permissions.map( permission => {
      return <FormInputRadio checked={hasPermission(permission)}
                             direction={FormInputContainer.HORIZONTAL}
                             label={permission.name} onClick={se=>{ switchPermission( permission )}} />
    })} </> }

  </AbstractEditorModal>
}

class RoleSelect extends React.Component {
  render(){
    return <ApiMultiSelect model="Roles" formatRow={row => {
      return `${row.name}`;
    }} label="Ruolo" {...this.props} />;
  }
}

export { Roles , RoleSelect, RoleModal };