import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputTextArea, FormInputSelect
} from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
  apiFormInit , apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {formatLeadName} from "./Leads";
import {Link, Route} from "react-router-dom";
import {Ddt} from "./Ddt";

class CustomPages extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      sellingPointModal: false,
      reports: false
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = {
      name: 'Titolo' ,
      menu: 'Menu',
      author: 'Autore',
      update: 'Ultima modifica',
      order: 'Ordine',
      actions: 'Azioni'
    };

    const row = page => {
      return <tr>
        <td>{page.title}</td>
        <td>{page.menuTitle}</td>
        <td>{formatLeadName( page.Author )}</td>
        <td>{moment(page.createdAt).format('D MMMM YY HH:mm')}</td>
        <td>{page.order}</td>
        <td>
          <div class="btn-group">
            {can('CustomPages.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: page.id});
            }}>Vedi</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Pagine personalizzate" items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          { can( 'CustomPages.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({sellingPointModal: true});
          }}>Nuova pagina</button>}
        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="CustomPages"
                                         filters={{ }}
                                         row={row}
                                         columns={columns}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

        {this.state.sellingPointModal && <NewCustomPagesModal onDone={res=>{
          this.setState({sellingPointModal: false});
        }} id={this.state.sellingPointModal === true ? false: this.state.sellingPointModal} />}

      </div>];
  }
}



class NewCustomPagesModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'CustomPages', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'CustomPages', this);
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <FormInputText label="Titolo" value={this.state.title}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="title"
                       onChange={se=>this.setState({title: se.target.value})} />

        <FormInputText label="Slug" value={this.state.slug}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="slug"
                       onChange={se=>this.setState({slug: se.target.value})} />

        <FormInputText label="Voce di menu" value={this.state.menuTitle}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="menuTitle"
                       onChange={se=>this.setState({menuTitle: se.target.value})} />

        <FormInputText label="Ordine" value={this.state.order}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="order"
                       onChange={se=>this.setState({order: se.target.value})} />

        <FormInputSelect values={{
          '': 'Nessuna',
          'Users': 'Utenti',
          'Reports': 'Report',
          'Contracts': 'Contratti',
          'Report BI': 'Report BI',
        }} label="Posizione nel menu" value={this.state.menuPosition}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="menuPosition"
                         onChange={se=>this.setState({menuPosition: se})} />

        <FormDashline />

        <FormInputTextArea label="Contenuto HTML" value={this.state.content}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="content"
                       onChange={se=>this.setState({content: se.target.value})} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva pagina"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class CustomPagesLoader {
  static init( o ){
    if( CustomPagesLoader.menu )
      return o.setState( { menu: CustomPagesLoader.menu } );

    if( !CustomPagesLoader.listener )
      CustomPagesLoader.listener = [];
    CustomPagesLoader.listener.push( o );

    if( CustomPagesLoader.loadingMenu ){
      return;
    }

    CustomPagesLoader.loadingMenu = true;

    fetch( 'api' , 'CustomPages' , 'select' , { pagination: { limit: 250 } } ).then( msg => {
      CustomPagesLoader.menu = msg.body;
      CustomPagesLoader.listener.map( list => {
        list.setState({ menu: CustomPagesLoader.menu });
      })
    });
  }
}

class CustomPageRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.contentRef = React.createRef();
  }

  componentDidMount() {
    CustomPagesLoader.init( this );
    this.updateRefsContent();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.updateRefsContent();
  }

  getPage(){
    let page = this.state.menu.filter( page => { return page.id == this.props.id } );

    if( !page.length )
      return null;

    return page[0];
  }

  updateRefsContent(){
    if( !this.contentRef.current || !this.state.menu )
      return;

    const page = this.getPage();
    if( !page )
      return;

    this.contentRef.current.innerHTML = page.content;
  }

  render() {
    if( !this.state.menu )
      return null;

    const page = this.getPage();
    if( !page )
      return null;

    return [ <Breadcumbs title={page.title} items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">
        <div className={"row"}>
          <div className={"col-md-12"} ref={this.contentRef}>
            content
          </div>
        </div>
      </div> ];
  }

}

class CustomPagesRouter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};

    this.pageRefsById = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( this.state.menu && !prevState.menu ){
      this.state.menu.map( page => {
        this.pageRefsById[page.id] = { ref: React.createRef() , page: page };
      });

      setInterval( () => {

      } , 1500 );

      this.setState({ ref: true });

    }

    //this.updateRefsContent();
  }

  updateRefsContent(){
    Object.keys( this.pageRefsById ).map( id => {
      const pageRef = this.pageRefsById[id];

      if( !pageRef.ref.current )
        return;

      pageRef.ref.current.innerHTML = pageRef.page.content;
    });
  }

  componentDidMount() {
    CustomPagesLoader.init( this );
  }

  render(){

    if( !this.state.menu )
      return null;

    return <> {this.state.menu.map( page => {

      if( !this.pageRefsById[page.id] )
        return null;

      return <Route path={'/'+page.slug} component={ (props) => {
        /*return [ <Breadcumbs title={page.title} items={{'Home': '/'}} />,
          <div className="wrapper wrapper-content  animated fadeInRight">
            <div className={"row"}>
              <div className={"col-dm-12"} ref={this.pageRefsById[page.id].ref}>
                content
              </div>
            </div>
          </div> ]*/

        return <CustomPageRender id={page.id} />
        } } />
    })}</>;
  }
}

class CustomPagesMenuResolver extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    CustomPagesLoader.init( this );
  }

  render(){
    if( !this.state.menu )
      return null;

    return <> {this.state.menu.sort( (a,b) => {
      if( a.order < b.order ) return -1;
      if( a.order > b.order ) return 1;
      return 0;
    }).map( page => {
      if( page.menuPosition != this.props.machineName )
        return null;
      return can( 'CustomPages.page_' + page.id ) ? <li><Link to={'/' + page.slug}>{page.title}</Link></li> : null;
    })}</>;
  }
}

export { CustomPages , NewCustomPagesModal , CustomPagesMenuResolver, CustomPagesRouter };