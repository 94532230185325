import React from "react";
import moment from "moment";
import {BoxContainer, fetch, FormInputYear, Loader, Row} from "./Common";
import {Breadcumbs, can } from "./AppCommon";
import {Link} from "react-router-dom";
import {ResponsiveBar} from "@nivo/bar";

class ReportProductionGraph extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      values: {}
    };

  }


  componentDidMount() {
    ReportProductionGraph.instance = this;
  }

  static toggleValues( values , name, color ){
    if( !ReportProductionGraph.instance )
      return;

    let state = ReportProductionGraph.instance.state;

    if( state.values[name] )
      delete state.values[name]
    else
      state.values[name] = { name: name , values: values, color: color };

    ReportProductionGraph.instance.setState( state );

  }

  render(){

    if( Object.keys( this.state.values ).length < 1 ){
      return <p className={"text-info"} style={{height:'200px', width: '100%', padding: '60px'}}>
        Utilizzare il pulsante <i className={"fa fa-bar-chart"}></i>
        per aggiungere elementi sul grafico</p>
    }

    let barCharData = []; // [{pv: 'a', 1: 10, 2: 20},{pv: 'b', 1: 15, 2: 25}];
    let barChartKeys = [];

    Object.keys( this.state.values ).map( k => {
      if( barChartKeys.indexOf( this.state.values[k].name ) < 0 )
        barChartKeys.push( this.state.values[k].name );
    });

    [...new Array(12).keys()].map( m => {
      let v = {
        pv: moment().month( m ) .format( 'MMM' ),
      };

      Object.keys( this.state.values ).map( k => {
        v[this.state.values[k].name] = this.state.values[k].values[m];
        v[this.state.values[k].name +'-col'] = this.state.values[k].color;
      });

      barCharData.push( v );
    });

    return <div className={"col-md-12"} style={{height: '400px'}}>
      <ResponsiveBar
        data={barCharData}
        keys={barChartKeys}
        indexBy="pv"
        groupMode={"grouped"}
        enableLabel={false}
        margin={{ top: 80, right: 20, bottom: 180, left: 40 }}
        padding={0.3}
        colors={ (id,data)=> {
          return id.data[id.id+'-col']; } }
        legends={[]}
        animate={true}
        axisBottom={{
          tickRotation: -45
        }}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>;
  }
}

class ReportProductionRowTotal extends React.Component {
  render() {

    let total = 0;
    let positive = 0;
    let idle = 0;
    let negative = 0;

    if( this.props.total ){
      total = this.props.total.total ? this.props.total.total : 0;
      positive = this.props.total.positive ? this.props.total.positive : 0;
      idle = this.props.total.idle ? this.props.total.idle : 0;
      negative = this.props.total.negative ? this.props.total.negative : 0;
    }

    return <React.Fragment>

      <Row>
        <div className={"col-sm-6 text-left"} style={{textSize:'6pt',color:'green'}}>
          {positive}</div>
        <div className={"col-sm-6 text-center"} style={{textSize:'6pt',color:'orange'}}>
          {idle}</div>
        <div className={"col-sm-12 text-center"} style={{textSize:'8pt'}}>
          <b>{total}</b></div>

      </Row>

      <Row>
        <div className={"col-sm-12 text-center"} style={{textSize:'6pt',color:'red'}}>
          {negative}</div>
      </Row>

    </React.Fragment>;
  }
}

class GraphColorPicker extends React.Component {

  constructor(props) {
    super(props);
  }

  render(){

    const colors = [
      '#962727',
      '#3a9627',
      '#272d96',
      '#968527',
      '#752796',
      '#4f4f4f'
    ];

    return <span>
      <div style={{
        backgroundColor: 'white',
        position: 'absolute',
        marginTop: '-70px',
        border: '1px solid #c1c1c1',
        width: '247px',
        height: '44px',
        borderRadius: '3px',
        zIndex: 6000
      }}>
        <div>
          {colors.map( color => {
            return <div style={{
              minHeight: '30px',
              margin: '5px',
              width: '30px',
              float: 'left',
              borderRadius: '3px',
              backgroundColor: color
            }} onClick={ se => {
              this.props.onPick( color );
            }}></div>
          })}
        </div>
      </div>
    </span>;
  }

}

class ReportProductionRows extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      openedDetails: [],
      onBarChart: [],
      selectingColor: false,
    };

    this.updateData();

  }

  toggleDetail( rowId ){

    let openedDetails = this.state.openedDetails;

    if( openedDetails.indexOf( rowId ) != -1 ){
      openedDetails.splice( openedDetails.indexOf( rowId ) , 1 );
    } else {
      openedDetails.push( rowId );
    }

    this.setState({
      openedDetails: openedDetails
    });


  }

  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( prevProps.year != this.props.year ){
      this.updateData();
    }
  }

  updateData(){
    this.setState({data: false});

    if( this.req )
      this.req.abort();

    this.req = fetch( 'api' , 'ReportProduction' , 'select' , {
      type: this.props.type,
      year: this.props.year,
      channel: this.props.channel,
      contractType: this.props.contractType,
      productCategory: this.props.productCategory
    } );

    this.req.then( msg => {
      this.setState({data: msg.body});
    }).catch(e=>{});
  }

  render(){
    if( !this.state.data )
      return <Loader />;

    const depth = this.props.depth ? this.props.depth : 0;

    let twelve = [...new Array(12).keys()];

    let total = {
      year: {
        total: 0,
        positive: 0,
        idle: 0,
        negative: 0
      }
    };

    return <React.Fragment>
      {this.state.data.map( row => {

        const myName = this.props.parentName ? this.props.parentName + ' - ' + row.name : row.name;

        let yearTotal = {
          total: 0,
          positive: 0,
          idle: 0,
          negative: 0
        };

        const opened = this.state.openedDetails.indexOf( row.name ) != -1;

        let detailType = false;
        let filters = {
        channel: this.props.channel,
        contractType: this.props.contractType,
        productCategory: this.props.productCategory
      };

        let color = 'black';

        if( this.props.type == 'channel' ){
        detailType = 'contractType';
        filters.channel = row.ChannelId;
      }

        if( this.props.type == 'contractType' ){
        color = '#791010';
        detailType = 'productCategory';
        filters.contractType = row.ContractTypeId;
      }

        if( this.props.type == 'productCategory' ){
        color = '#796210';
        detailType = 'contractOrigin';
        filters.productCategory = row.Products_Categories_Ids;
      }

        if( this.props.type == 'contractOrigin' ){
        color = '#107915';
      }

        let barValues = twelve.map( k => {
        return row[k+1] ? row[k+1].total : 0
      });

        const isOnBarChart = this.state.onBarChart.indexOf( myName ) >= 0;

        const barCharBgColor = isOnBarChart ? { backgroundColor:
          ReportProductionGraph.instance.state.values[myName].color } : {};

        return <React.Fragment>
        <tr onClick={se=>{  }} style={{color: color}}>
        <td style={{paddingLeft: (15+(depth*20)) + 'px'}} width={"350px"}>
        {row.name}
        <button className={"btn btn-small"} onClick={se=>{
          se.preventDefault();
          this.toggleDetail(row.name )
        }} style={{float: 'right'}}>
        <i className={"fa fa-plus"}></i></button>

        <button className={"btn " + ( isOnBarChart ? 'btn-primary' : '') } onClick={se=>{
          se.preventDefault();

          let obc = this.state.onBarChart;
          if( isOnBarChart ) {
            obc.splice(this.state.onBarChart.indexOf(myName), 1);
            this.setState({ onBarChar: obc } );
            ReportProductionGraph.toggleValues( barValues , myName );
          } else {
            this.setState({ selectingColor: myName });
          }

        }} style={{float: 'right', ...barCharBgColor}}>

        <i className={"fa fa-bar-chart"}></i></button>

          { this.state.selectingColor == myName && <GraphColorPicker onPick={ color => {
            let obc = this.state.onBarChart;
            obc.push( this.state.selectingColor );
            ReportProductionGraph.toggleValues( barValues , this.state.selectingColor, color );
            this.setState({ onBarChar: obc, selectingColor: false });
          }} />}

        </td>

        {twelve.map( k => {
          yearTotal.total += row[k+1] ? row[k+1].total : 0;
          yearTotal.positive += row[k+1] ? row[k+1].positive : 0;
          yearTotal.idle += row[k+1] ? row[k+1].idle : 0;
          yearTotal.negative += row[k+1] ? row[k+1].negative : 0;

          total.year.total += row[k+1] ? row[k+1].total : 0;
          total.year.positive += row[k+1] ? row[k+1].positive : 0;
          total.year.idle += row[k+1] ? row[k+1].idle : 0;
          total.year.negative += row[k+1] ? row[k+1].negative : 0;

          if( !total[k] )
            total[k] = {
              total: 0,
              positive: 0,
              idle: 0,
              negative: 0
            };

          total[k].total += row[k+1] ? row[k+1].total : 0;
          total[k].positive += row[k+1] ? row[k+1].positive : 0;
          total[k].idle += row[k+1] ? row[k+1].idle : 0;
          total[k].negative += row[k+1] ? row[k+1].negative : 0;

          return <td><ReportProductionRowTotal total={row[k+1]} /></td>;
        })}

        <td><ReportProductionRowTotal total={yearTotal} /></td>
        </tr>

        {(opened && detailType) && <ReportProductionRows
          {...filters}
          depth={depth+1}
          parentName={myName}
          year={this.props.year} type={detailType} />}
        </React.Fragment>;
      })}

      {depth == 0 && <tr>
        <td>Totale</td>
        {twelve.map( m => {
          return <td><ReportProductionRowTotal total={total[m]} /></td>
        })}
        <td>
          <ReportProductionRowTotal total={total.year} />
        </td>
      </tr>}
    </React.Fragment>;

  }
}

class ReportProduction extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterYear: moment().year() +'',
    };
  }

  componentDidMount(){
    this.updateData();

    fetch( 'api' , 'ReportProduction' , 'updated' ).then( msg => {
      this.setState({
        updatedAt: msg.body
      })
    });
  }

  updateData(){
    /*this.setState({data: false});
    if( this.req )
      this.req.abort();

    this.req = fetch( 'api' , 'ReportProduction' , 'select' , this.state );
    this.req.then( msg => {
      this.setState({data: msg.body});
    }).catch(e=>{});*/
  }

  render(){


    return <React.Fragment>
      <Breadcumbs title="Report produzione reale" items={{'Home': '/'}} />
      <div className="wrapper wrapper-content  animated fadeInRight">

        <Row>
          <div className={"col-md-12 btn-group"} style={{marginBottom: '20px'}}>

            {  can( 'Contracts.reportBusiness' )
            && <Link className={"btn btn-primary"} to="/business_report">Reale</Link> }

            {  can( 'Products.reportBusiness' ) &&
            <Link className={"btn btn-default"} to="/contracts_report_products">Prodotti</Link> }

            {  can( 'Users.reportBusiness' ) &&
            <Link className={"btn btn-default"} to="/contracts_report_users">Utenti</Link>}

            {  can( 'SellingPoints.reportBusiness' ) &&
            <Link className={"btn btn-default"} to="/contracts_report_selling_points">Punti vendita</Link>}

          </div>
        </Row>

        <BoxContainer>

          <Row>
            {this.state.updatedAt && <div className={"col-md-12"}>
              <div className="alert alert-primary" role="alert">
                Dati aggiornati al {moment(this.state.updatedAt).format( 'HH:mm DD MMMM YYYY' )}
              </div>
            </div> }
          </Row>

          {true && <Row>
            <div className="col-lg-2">
              <FormInputYear value={this.state.filterYear}
                             onChange={se=>this.setState({filterYear: se})} />
            </div>

          </Row>}

          <Row>
            <ReportProductionGraph />
          </Row>

          <div className="row">

            <React.Fragment>

              <table className="table table-hover table-mail table-altered-td">
                <thead>
                <tr>
                  <th></th>

                  <th>Gen</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>Mag</th>
                  <th>Giu</th>
                  <th>Lug</th>
                  <th>Ago</th>
                  <th>Set</th>
                  <th>Ott</th>
                  <th>Nov</th>
                  <th>Dic</th>

                  <th>{this.state.filterYear}</th>
                </tr>
                </thead>
                <tbody>
                  <ReportProductionRows type="channel" year={this.state.filterYear} />
                </tbody>
              </table>

            </React.Fragment>
          </div>
        </BoxContainer>
      </div>


    </React.Fragment>;
  }
}

export { ReportProduction };
