import React from 'react';
import moment from 'moment';

import {
  Loader, fetch, FormDashline, FormInputText, FormButtons, Row, FormInputRadio,
  FormInputContainer, Modal, FormInputPassword, FormInputTextArea, FormInputArray, FormInputDate
} from './Common.js';


import  { RoleSelect,  apiFormInit, apiFormSave, can, Breadcumbs,
  AbstractTableViewer, AbstractApiFormFooter, ApiMultiSelect } from './AppCommon';
import { MarketingPlans, MarketingReportPlanFiltersSelect } from './MarketingPlans.js';
import AsyncSelect from "react-select/async/dist/react-select.esm";

class TagsSelect extends React.Component {

  constructor(props){
    super(props);

    this.loadOptions = this.loadOptions.bind(this);
    this.onChange = this.onChange.bind( this );

    this.state = {
      values: []
    };

  }

  componentDidMount(){
  }

  componentDidUpdate( prevProps ){
    if( JSON.stringify( prevProps['value'] ) !== JSON.stringify( this.props['value'] ) ){
    }
  }

  loadOptions( input ){
    return new Promise( success => {

      if( this.loadOptionsTimeout )
        clearTimeout( this.loadOptionsTimeout );

      this.loadOptionsTimeout = setTimeout( () => {

        let filters = {};

        if( typeof( input ) == 'string' ){
          input = input.toLowerCase().trim();
          if( input.length > 0 ){
            filters = { filters: { text: input } };
          }
        }

        fetch( 'api' , 'Tags' , 'tags' , this.props.entityName , filters ).then( msg => {
          if( msg.body.length < 1 && input.length > 0 && this.props.canCreate ){
            success([{
              value: -1, label: `Crea nuovo tag "${input}"`, input: input
            }]);
          }

          if( !msg.body.map )
            return success( [] );

          success( msg.body.map( v => {
            return { value: v.id, label: v.name , object: v };
          }) );
        });

      } , 750 );

    });
  }

  async createValue( text ){
    return new Promise( success => {
      fetch( 'api' , 'Tags' , 'save' , { name: text , EntityName: this.props.entityName } ).then( msg => {
        success( msg.body.object );
      });
    });
  }

  async onChangeAsync( se ){
    let v = [];

    if( !se )
      return v;

    if( se.map ){
      for (let i = 0; i < se.length; i++) {
        if( se[i].value < 0 ){
          v.push( await this.createValue( se[i].input ) )
        } else
          v.push( se[i].object );
      }

      if( this.props.isMulti===false && v.length > 1 ){
        v = v.slice( v.length-1 , v.length );
      }
    }

    return v;
  }

  onChange( se ) {
    this.onChangeAsync(se).then( ret => {
      this.props.onChange( ret );
    });
  }

  render(){

    const sprops = {
      value: '',
      label: 'Tag',
      values: false,
      onChange: () => {},
      ...this.props
    };

    let values = [];

    try {
      values = this.props.value.map(v => {
        return {value: v.id, label: v.name, object: v};
      })
    } catch ( e ){}

    return <FormInputContainer isMulti {...sprops}>
      <AsyncSelect {...sprops} loadOptions={this.loadOptions}
                   onChange={ this.onChange }
                   defaultOptions
                   isMulti
                   cacheOptions={false}
                   isClearable
                   key={JSON.stringify(this.props.filters)}
                   value={values} />
    </FormInputContainer>;
  }
}

export { TagsSelect };