import React from 'react';
import './App.css';
import moment from 'moment';

import { fetch, FormDashline,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputDate, 
  FormInputTime, FormInputRadio } from './Common.js';

import { UsersMultiSelect } from './Users';

import  { apiFormInit , apiFormSave, can, LocalCache } from './AppCommon';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import {RoleSelect} from "./Roles";

class CalendarNewEventModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
            startDate: this.props.startDate || moment(),
            endDate: this.props.endDate || moment(),
            important: false,
            type: 'calendar'
        };

        this.onSubmit = this.onSubmit.bind(this);
    }
        
    componentDidMount(){
        apiFormInit( 'Tasks', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'Tasks', this ).then( d => {
            window.location.reload();
        });
      }

    render(){

        return <Modal>
            <form onSubmit={this.onSubmit}>

            <FormInputText label="Titolo" value={this.state.title}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="title"
                onChange={se=>this.setState({title: se.target.value})} />

            <FormInputRadio label="Importante" checked={this.state.important}
                direction={FormInputContainer.HORIZONTAL}
                onClick={se=>{this.setState({important:
                 this.state.important ? false : true })}} />

            <FormInputDate label="Data inizio" value={this.state.startDate}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="startDate"
                onChange={se=>this.setState({startDate: se})} />

            <FormInputTime label="Ora inizio" value={this.state.startDate}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="startDate"
                onChange={se=>this.setState({startDate: se})} />

            <FormInputDate label="Data fine" value={this.state.endDate}
                validation={this.state.validation} name="endDate"
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>this.setState({endDate: se})} />

            <FormInputTime label="Ora fine" value={this.state.endDate}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="endDate"
                onChange={se=>this.setState({endDate: se})} />            

            <FormDashline />

            { can( 'Tasks.assignOtherUsers' ) && <UsersMultiSelect value={this.state.Users}
                label="Utenti coinvolti" isMulti
                direction={FormInputContainer.HORIZONTAL}
                onChange={se=>this.setState({Users: se})}/> }

            { can( 'Tasks.assignOtherUsers' ) && <RoleSelect value={this.state.Roles}
               label="Ruoli coinvolti" isMulti
               direction={FormInputContainer.HORIZONTAL}
               onChange={se=>this.setState({Roles: se})}/> }

            <FormButtons saveLabel="Salva Evento"  direction={FormInputContainer.HORIZONTAL} 
                saveEnable={this.state.AuthorUserId == LocalCache.get('loginData').User.id || !this.state.id}
                onSave={this.onSubmit} 
                saving={this.state.apiFormSaving}
                onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
            </form>
        </Modal>;
    }
       
}

class Calendar extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            events: [],
            newEventModal: false
        };

        this.reloadData();

        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.fetchEvents = this.fetchEvents.bind(this);
    }

    reloadData(){

    }

    render(){
        return <div>

            <FullCalendar defaultView="dayGridMonth" 
                locale='it'
                plugins={[ dayGridPlugin, interactionPlugin ]}
                dateClick={this.handleDateClick}
                eventClick={this.handleEventClick}
                events={this.fetchEvents} />

            {this.state.newEventModal !== false && 
                <CalendarNewEventModal id={this.state.newEventModal} 
                    startDate={this.state.newEventModalStartDate}
                    endDate={this.state.newEventModalEndDate}
                    onDone={se=>{this.reloadData();this.setState({newEventModal: false})}} />}
        </div>
    }

    handleDateClick(arg) { 
        console.log(arg);
        let now = moment();

        let start = moment(arg.dateStr);
        start.set( 'hour' , now.get('hour') );

        let end = moment(arg.dateStr);
        end.set( 'hour' , now.get('hour') );
        end.add( 1, 'hour');

        this.setState({newEventModal: -1, 
            newEventModalStartDate: start, 
            newEventModalEndDate: end});
    }

    fetchEvents( fetchInfo , onDone ){
        console.log( fetchInfo );

        let payload = {
            filters: {
                type: 'calendar',
                startAtFrom: fetchInfo.startStr,
                startAtTo: fetchInfo.endStr,
            },
        };

        fetch( 'api' , 'Tasks' , 'select' , payload ).then( data => {

            if( !data.body.map )
                return;

            let events = data.body.map( event => {

                let colorData = {};

                if( event.AuthorUserId != LocalCache.get('loginData').User.id ){
                    colorData = {
                        backgroundColor: '#3fc9e8',
                        borderColor: '#1fb0d1',
                    }
                }

                return {
                    id: event.id,
                    title: event.title,
                    start: event.startDate,
                    end: event.endDate,
                    ...colorData
                }
            });

            this.setState({events: events});

            onDone( events );
        });
    }

    handleEventClick(arg) { 
        console.log(arg);
        this.setState({newEventModal: arg.event.id});
    }
    
}

export { Calendar };