import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, Row,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer, FormInputDate } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter , 
    apiFormInit , apiFormSave, formatEur, BrandSelect, 
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {UserSelect} from "./Users";
import {formatLeadName} from "./Leads";

const UserDailyReports_Fields = {
  swa_ele_res: 'SWA E/RES',
  swa_gas_res: 'SWA G/RES',
  swa_ele_bus: 'SWA E/BUS',
  swa_gas_bus: 'SWA G/BUS',
  swa_luce_corp: 'SWA E/CORP',
  swa_gas_corp: 'SWA G/CORP',
  swa_pratiche: 'PRATICHE',
  ass_h24: 'PROTEZIONE 360 PLUS',
  prot_360_gas: 'PROTEZIONE 360 GAS',
  prot_360_luce: 'PROTEZIONE 360 LUCE',
  /*prot_plus_swa: 'PROTEZIONE 360 PLUS SWA',
  enelx_per_te: 'PROTEZIONE 360 ELE/GAS',*/
  checkup_caldaia: 'CALD.',
  lead_foto: 'LEAD FOTOV',
  lead_caldaia: 'LEAD CALD.',
  lead_clima: 'LEAD CLIMA',
  lead_colonnina: 'LEAD COLONNINA',
  pos: 'POS',
  b_web: 'B. WEB',
  rid: 'RID',
  fidelity: 'FIDEL.',
  //melita: 'MELITA',
  fibra: 'Fibra',
  enelx_flex_sicura: 'ENEL FLEX SICURA',
  scegli_oggi_relax: 'SCEGLI OGGI RELAX',
};

class UserDailyReports extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      userDailyReportModal: false,
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
    };

    this.updateReport();
  }

  updateReport(){
    this.setState({reports: false});

  }

  componentDidUpdate( prevPros , prevState ){
    if( moment(prevState.filterDateStart).format() != moment(this.state.filterDateStart).format() || 
      moment(prevState.filterDateEnd).format() != moment(this.state.filterDateEnd).format() ){
      this.updateReport();
    }
  }

  render(){

    const columns = { 
      name: 'Utente', 
      ...UserDailyReports_Fields,
      actions: 'Azioni'
  };

    const row = userDailyReport => {
        return <tr>
          <td>{userDailyReport.User.name} {userDailyReport.User.surname}</td>
          {Object.keys(UserDailyReports_Fields).map( k => {
            return <td>{userDailyReport[k]}</td>
          })}
          <td>
            <div class="btn-group">
                {can('UserDailyReports.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({userDailyReportModal: userDailyReport.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Resoconto giornaliero" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'UserDailyReports.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({userDailyReportModal: true});
          }}>Nuovo resoconto giornaliero</button>}
      </div>

      <Row>
        <div className="col-md-4">
          <FormInputDate value={this.state.filterDateStart}
            direction={FormInputContainer.HORIZONTAL}
            label="Filtro data / Da"
            onChange={se=>{this.setState({filterDateStart: se})}} />
        </div>

        <div className="col-md-4">
          <FormInputDate value={this.state.filterDateEnd}
            direction={FormInputContainer.HORIZONTAL}
            label="Filtro data / A"
            onChange={se=>{this.setState({filterDateEnd: se})}} />
        </div>

        <div className="col-md-4">
          <UserSelect value={this.state.filterUsers}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Utenti" isMulti
                      onChange={se=>this.setState({ filterUsers: se })} />
        </div>

      </Row>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                  <div className="ibox-content"  style={{overflowX: 'scroll'}}>
                    <AbstractTableViewer model="UserDailyReports" 
                        filters={{
                          filterUsers: this.state.filterUsers,
                          filterDateStart: this.state.filterDateStart,
                          filterDateEnd: this.state.filterDateEnd}}
                        row={row}
                        columns={columns}/>
                  </div>
              </div>
            </div>
          </div> }
        </div>
      </div>

      {this.state.userDailyReportModal && <NewUserDailyReportModal onDone={res=>{
        this.setState({userDailyReportModal: false});
      }} id={this.state.userDailyReportModal === true ? false: this.state.userDailyReportModal} />}

    </div>];
  }
}

class UserDailyReportsDays extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      userDailyReportModal: false,
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
      reports: false
    };

    this.updateReport();
    this.onExport = this.onExport.bind( this );
  }

  onExport( se ){
    se.preventDefault();

    fetch( 'api' , 'UserDailyReports' , 'totalsExport' , this.state ).then( msg => {
      let exp = msg.body;
      window.location = `${window.location.protocol}//${window.location.hostname}/api/Files/download/${exp.file.id}/${exp.file.filename}`;
    });

  }

  updateReport(){
    this.setState({reports: false});

    fetch( 'api' , 'UserDailyReports' , 'totals' , this.state ).then( msg => {
      this.setState({reports: msg.body.rows, totals: msg.body.totals});
    });

  }

  componentDidUpdate( prevPros , prevState ){
    if( moment(prevState.filterDateStart).format() != moment(this.state.filterDateStart).format() ||
      moment(prevState.filterDateEnd).format() != moment(this.state.filterDateEnd).format() ||
      prevState.filterUsers != this.state.filterUsers ){
      this.updateReport();
    }
  }

  render(){
    return [ <Breadcumbs title="Resoconto giornaliero" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <Row>
        <div className={"col-md-4"}>
          <button className={"btn btn-primary"} onClick={this.onExport}>Esporta</button>
        </div>
      </Row>

      <Row>
        <div className="col-md-4">
          <FormInputDate value={this.state.filterDateStart}
                         direction={FormInputContainer.HORIZONTAL}
                         label="Filtro data / Da"
                         onChange={se=>{this.setState({filterDateStart: se})}} />
        </div>

        <div className="col-md-4">
          <FormInputDate value={this.state.filterDateEnd}
                         direction={FormInputContainer.HORIZONTAL}
                         label="Filtro data / A"
                         onChange={se=>{this.setState({filterDateEnd: se})}} />
        </div>

        <div className="col-md-4">
          <UserSelect value={this.state.filterUsers}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Utenti" isMulti
                      onChange={se=>this.setState({ filterUsers: se })} />
        </div>

      </Row>

      {can('UserDailyReports.report') && <Row>
        <div className="col-md-12 col-lg-12">
          <div className={"ibox"}>
            <div className={"ibox-content"} style={{overflowX: 'scroll'}}>

            {!this.state.reports && <Loader />}
            {this.state.reports && <table className="table table-striped table-hover">
              <tr>
                <th>Utente</th>
                {Object.keys(UserDailyReports_Fields).map( k => {
                  return <th>{UserDailyReports_Fields[k]}</th>
                })}

              </tr>
              {this.state.reports.map( userDailyReport => {
                return <tr>
                  <td>{formatLeadName(userDailyReport)}</td>

                  {Object.keys(UserDailyReports_Fields).map( k => {
                    return <th>{userDailyReport[k]}</th>
                  })}

                </tr>
              })}
              <tr style={{fontWeight: 'bold'}}>
                <td></td>
                {Object.keys(UserDailyReports_Fields).map( k => {
                  return <th>{this.state.totals[k]}</th>
                })}
              </tr>
            </table>}

            </div>
          </div>
        </div>
      </Row>}

      {this.state.userDailyReportModal && <NewUserDailyReportModal onDone={res=>{
        this.setState({userDailyReportModal: false});
      }} id={this.state.userDailyReportModal === true ? false: this.state.userDailyReportModal} />}




    </div>];
  }
}

class NewUserDailyReportModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          termDate: moment(),
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'UserDailyReports', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'UserDailyReports', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>

            <FormInputDate label="Data registrazione" value={this.state.termDate}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="termDate"
              onChange={se=>{this.setState({termDate: se})}} />
    
            <FormInputText label="SWA Ele Residenziale" value={this.state.swa_ele_res}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="swa_ele_res"
                onChange={se=>this.setState({swa_ele_res: se.target.value})} />

            <FormInputText label="SWA Gas Residenziale" value={this.state.swa_gas_res}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="swa_gas_res"
                onChange={se=>this.setState({swa_gas_res: se.target.value})} />

            <FormInputText label="SWA Ele Business" value={this.state.swa_ele_bus}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="swa_ele_bus"
                onChange={se=>this.setState({swa_ele_bus: se.target.value})} />

            <FormInputText label="SWA Gas Business" value={this.state.swa_gas_bus}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="swa_gas_bus"
                onChange={se=>this.setState({swa_gas_bus: se.target.value})} />

            <FormInputText label="SWA Ele Corporate" value={this.state.swa_luce_corp}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="swa_luce_corp"
                           onChange={se=>this.setState({swa_luce_corp: se.target.value})} />

            <FormInputText label="SWA Gas Corporate" value={this.state.swa_gas_corp}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="swa_gas_corp"
                           onChange={se=>this.setState({swa_gas_corp: se.target.value})} />

            <FormInputText label="SWA con pratiche" value={this.state.swa_pratiche}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="swa_pratiche"
                onChange={se=>this.setState({swa_pratiche: se.target.value})} />

            <FormInputText label="PROTEZIONE 360 PLUS" value={this.state.ass_h24}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="ass_h24"
                onChange={se=>this.setState({ass_h24: se.target.value})} />

            <FormInputText label="Checkup Caldaia" value={this.state.checkup_caldaia}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="checkup_caldaia"
                onChange={se=>this.setState({checkup_caldaia: se.target.value})} />


            <FormInputText label="PROTEZIONE 360 GAS" value={this.state.prot_360_gas}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="prot_360_gas"
                           onChange={se=>this.setState({prot_360_gas: se.target.value})} />

            <FormInputText label="PROTEZIONE 360 LUCE" value={this.state.prot_360_luce}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="prot_360_luce"
                           onChange={se=>this.setState({prot_360_luce: se.target.value})} />

            <FormInputText label="ENEL FLEX SICURA" value={this.state.enelx_flex_sicura}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="enelx_flex_sicura"
                           onChange={se=>this.setState({enelx_flex_sicura: se.target.value})} />

            <FormInputText label="SCEGLI OGGI RELAX" value={this.state.scegli_oggi_relax}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="scegli_oggi_relax"
                           onChange={se=>this.setState({scegli_oggi_relax: se.target.value})} />

            <FormInputText label="Fibra" value={this.state.fibra}
               direction={FormInputContainer.HORIZONTAL}
               validation={this.state.validation} name="fibra"
               onChange={se=>this.setState({fibra: se.target.value})} />

            <FormInputText label="LEAD Fotovoltaico" value={this.state.lead_foto}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="lead_foto"
                           onChange={se=>this.setState({lead_foto: se.target.value})} />

            <FormInputText label="LEAD Caldaia" value={this.state.lead_caldaia}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="lead_caldaia"
                           onChange={se=>this.setState({lead_caldaia: se.target.value})} />

            <FormInputText label="LEAD Clima" value={this.state.lead_clima}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="lead_clima"
                           onChange={se=>this.setState({lead_clima: se.target.value})} />

            <FormInputText label="LEAD Colonnina" value={this.state.lead_colonnina}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="lead_colonnina"
                           onChange={se=>this.setState({lead_colonnina: se.target.value})} />

            <FormInputText label="POS" value={this.state.pos}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="pos"
                           onChange={se=>this.setState({pos: se.target.value})} />

            <FormInputText label="Bolletta web" value={this.state.b_web}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="b_web"
                           onChange={se=>this.setState({b_web: se.target.value})} />

            <FormInputText label="Rid" value={this.state.rid}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="rid"
                           onChange={se=>this.setState({rid: se.target.value})} />

            <FormInputText label="Fidelizzazione" value={this.state.fidelity}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="fidelity"
                           onChange={se=>this.setState({fidelity: se.target.value})} />

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva report giornaliero"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class UserDailyReportSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Report giornaliero" {...this.props} model="UserDailyReports" 
      formatRow={row => { return row.name; }} filters={{}}
       />;
  }
}

export { UserDailyReports , UserDailyReportsDays , UserDailyReportSelect, NewUserDailyReportModal };