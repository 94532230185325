import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect, formatMultilineText,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, LocalCache
} from './AppCommon';
import {FormInputTextArea} from "./Common";
import {RoleSelect} from "./AppCommon";
import {formatLeadName} from "./Leads";

function getFirstRevision( note , allNotes ){
  let currentRevision = note;
  while( currentRevision.ParentRevisionId ){
    var found = allNotes.filter( note => {
      return note.id == currentRevision.ParentRevisionId;
    });

    if( !found.length )
      break;

    currentRevision = found[0];
  }

  return currentRevision;
}

function getLastRevision( note , allNotes ){
  let currentRevision = note;
  while( currentRevision.NextRevisionId ){
    var found = allNotes.filter( note => {
      return note.id == currentRevision.NextRevisionId;
    });

    if( !found.length )
      break;

    currentRevision = found[0];
  }

  return currentRevision;
}

class NoteViewer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      currentRevisionId: getLastRevision( props.note , this.props.notes ).id, // last
    }

    this.onPrev = this.onPrev.bind(this);
    this.onNext = this.onNext.bind(this);
  }

  onPrev( se ){
    se.preventDefault();

    let current = this.getCurrentRevision();
    if( current.ParentRevisionId )
      this.setState({ currentRevisionId: current.ParentRevisionId });
  }

  onNext( se ){
    se.preventDefault();

    let current = this.getCurrentRevision();
    if( current.NextRevisionId )
      this.setState({ currentRevisionId: current.NextRevisionId });
  }

  getCurrentRevision(){
    const note = this.props.note;
    let currentRevision = note;

    while( currentRevision.id != this.state.currentRevisionId ){
      var found = this.props.notes.filter( note => {
        return note.id == currentRevision.ParentRevisionId;
      });

      if( !found.length )
        break;

      currentRevision = found[0];
    }

    return currentRevision;
  }

  render(){
    if( !this.props.note || !this.props.notes )
      return <p>Missing data</p>;

    const note = this.props.note;

    let firstRevision = getFirstRevision( note , this.props.notes );
    let currentRevision = this.getCurrentRevision();

    let lastValidRevision = currentRevision;

    while( lastValidRevision.NextRevisionId ){
      var found = this.props.notes.filter( note => {
        return note.id == lastValidRevision.NextRevisionId;
      });

      if( !found.length )
        break;

      lastValidRevision = found[0];
    }

    return <div id={"notes_"+note.id}>
      <p>{currentRevision.body ? formatMultilineText(currentRevision.body) : '-'}</p>
      <div className={"row"}>
        <div className={"col-1"}>
          {currentRevision.NextRevisionId &&
            <a className={"btn btn-xs btn-primary"} href={"#"} onClick={this.onNext}>
              <i className={"fa fa-chevron-left"} /></a> }
        </div>
        <div className={"col-10"}>
          <p className={"text-right small"}>
            {currentRevision.id == firstRevision.id ? <>
              {formatLeadName(note.CreatedByUser)} - {moment(note.createdAt).format('DD/MM/YY HH:mm')}
            </> : <>
              Modificato da {formatLeadName(currentRevision.CreatedByUser)} - {moment(currentRevision.createdAt).format('DD/MM/YY HH:mm')}
            </>}


            {(can(this.props.name + '.notesEdit') && note.id && currentRevision.id == lastValidRevision.id) && <>
              &nbsp;
              <a href={"#"} onClick={se => {
                se.preventDefault();
                this.props.onEdit(lastValidRevision);
              }}>Modifica</a>

            </>}
          </p>
        </div>
        <div className={"col-1"}>
          {currentRevision.ParentRevisionId &&
            <a className={"btn btn-xs btn-primary"} href={"#"} onClick={this.onPrev}>
              <i className={"fa fa-chevron-right"} /></a>}
        </div>
      </div>

      <FormDashline/>
    </div>;
  }

}
class NotesEditor extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
    this.onEditConfirm = this.onEditConfirm.bind(this);
    this.onEdit = this.onEdit.bind(this);
  }

  onEditConfirm( se ){
    se.preventDefault();

    let alteredNote = this.props.notes.filter( note => {
      return note.id == this.state.editNoteId;
    })[0];

    let newNotes = this.props.notes.map( note => {
      if( note.id != this.state.editNoteId )
        return note;

      return {
        ...note,
        NextRevisionId: -1
      };
      /*
      return {
        EntityId: note.EntityId,
        EntityName: this.props.name,
        body: this.state.editNoteBody,
        createdAt: moment(),
        CreatedByUser: LocalCache.get('loginData').User,
        ParentRevisionId: this.state.editNoteId,
        NextRevisionId: -1,
      }*/
    });

    newNotes.push( {
      EntityId: alteredNote.EntityId,
      EntityName: this.props.name,
      body: this.state.editNoteBody,
      createdAt: moment(),
      CreatedByUser: LocalCache.get('loginData').User,
      ParentRevisionId: this.state.editNoteId,
      id: -1,
    });

    this.setState({
      editNoteId: false
    });

    if( this.props.onChange )
      this.props.onChange( newNotes );
  }

  onEdit( note ){
    this.setState({
      editNoteId: note.id,
      editNoteBody: note.body
    });
  }

  onSubmit( se ){
    se.preventDefault();

    if( !this.props.onChange || !this.state.body )
      return;

    if( this.state.body.trim().length < 1 )
      return;

    let notes = this.props.notes;

    notes.push( {
      body: this.state.body,
      createdAt: moment(),
      CreatedByUser: LocalCache.get('loginData').User,
    });

    this.setState({
      body: ''
    });

    if( this.props.id ){

      this.setState({ saving: true });

      let payload = notes.map( note => {
        return {
          ...note,
          EntityId: this.props.id,
          EntityName: this.props.name
        }
      });

      fetch( 'api' , 'Notes' , 'save' , payload ).then( msg => {
        this.setState({ saving: false });
        this.props.onChange( msg.body );
      });
    }

    this.props.onChange( notes );
  }

  render() {

    if( !this.props.notes )
      return null;

    const title = this.props.title ? this.props.title : 'Note';

    return <>

      <h2 className={"mb-4"}>{title}</h2>

      {this.props.notes.filter( note => {
        return !note.NextRevisionId;
      }).sort( (a,b) => {
        return moment( getFirstRevision( a , this.props.notes ).createdAt ).unix() -
          moment( getFirstRevision( b , this.props.notes ).createdAt ).unix();
      }).map( (note) => {

        if( this.state.editNoteId == note.id && note.id ){
          return <>
            <FormInputTextArea label="Modifica nota:" value={this.state.editNoteBody}
                               direction={FormInputContainer.HORIZONTAL} name="body"
                               onChange={se=>this.setState({editNoteBody: se.target.value})} />
            <button className={"btn btn-xs btn-primary text-right"} onClick={this.onEditConfirm}>Conferma</button>
            <FormDashline/>
          </>
        } else {
          return <NoteViewer note={note} notes={this.props.notes} name={this.props.name} onEdit={this.onEdit} />
        }

      })}

      {!this.props.notes.length && <p style={{textAlign:'center',fontStyle:'italic'}}>Nessuna nota inserita</p>}

      {can( this.props.name +'.notesSave') && <form onSubmit={this.onSubmit}>

        <FormInputTextArea label="Inserisci nuova nota:" value={this.state.body}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="body"
                           onChange={se=>this.setState({body: se.target.value})} />

        <FormButtons saveLabel="Salva nota" cancelLabel={false}  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                      />

        {this.state.saving && <>
          <span>
            <i className={"fa fa-spinner fa-pulse"}></i> Salvataggio...
          </span>
        </>}

      </form> }

    </>;
  }
}

class NotesModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      loading: true,

    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    this.reloadData();
  }

  reloadData( silent = false ){
    if( !silent )
      this.setState({loading: true});

    fetch( 'api' , 'Notes' , 'notes' , this.props.id, this.props.name ).then( msg => {
      this.setState({
        loading: false,
        rows: msg.body,
      })
    });

  }

  onSubmit( notes ){

    this.setState({
      loading: true,
    });

    notes = notes.map( note => {
      return {
        ...note,
        EntityName: this.props.name,
        EntityId: this.props.id,
      };
    } );

    fetch( 'api' , 'Notes' , 'save' , notes ).then( msg => {
      this.reloadData();
    });
  }

  render(){

    if( this.state.loading )
      return <Modal><Loader></Loader></Modal>;

    return <Modal onClose={this.props.onDone}>

      <NotesEditor notes={this.state.rows} title={this.props.title} onChange={ notes => {
        this.onSubmit( notes );
      }} name={this.props.name} />

      {/*
      {this.props.title && <h2 className={"mb-4"}>{this.props.title}</h2>}

      {this.state.rows.map( note => {
        return <>
          <p>{note.body ? formatMultilineText( note.body ) : '-'}</p>
          <p className={"text-right small"}>
            {formatLeadName(note.CreatedByUser)} - {moment(note.createdAt).format('DD/MM/YY HH:mm')}</p>
          <FormDashline />
        </>
      })}

      {!this.state.rows.length && <p style={{textAlign:'center',fontStyle:'italic'}}>Nessuna nota inserita</p>}

      <form onSubmit={this.onSubmit}>

        <FormInputTextArea label="Inserisci nuova nota:" value={this.state.body}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="body"
                       onChange={se=>this.setState({body: se.target.value})} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva nota"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saveEnable={can(this.props.name+'.notesSave')}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form> */}
    </Modal>;
  }

}

function NotePlainViewer( props ){
  if( !props.notes )
    return null;

  let notes = '-';

  try {
    notes = props.notes.filter( note => {
      return !note.NextRevisionId;
    }).map( note => {
      return `${note.body}`;
    }).join( ' / ' );
  } catch ( e ){}

  return <span>{notes}</span>;
}

export { NotesModal , NotesEditor, NotePlainViewer };
