import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, FormInputCurrency,
    FormInputText, FormButtons, FormInputContainer ,
    Modal, FormInputRadio, BoxContainer } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
    apiFormInit , apiFormSave, formatEur, BrandSelect,
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {FormInputTextArea} from "./Common";
import {RoleSelect} from "./AppCommon";

class NotificationTypes extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            notificationTypeModal: false,
            reports: false
        };

    }

    componentDidMount(){
    }

    render(){

        const columns = {
            name: 'Nome' ,
            roles: 'Ruoli assegnati',
            actions: 'Azioni'
        };

        const row = notificationType => {
            return <tr>
                <td>{notificationType.name}</td>
                <td>{notificationType.Roles.map( r => {
                    return r.name;
                }).join(', ')}</td>
                <td>
                    <div class="btn-group">
                        {can('NotificationTypes.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                            se.preventDefault();
                            this.setState({notificationTypeModal: notificationType.id});
                        }}>Vedi</button> }
                    </div>
                </td>
            </tr>
        };

        return [ <Breadcumbs title="Notifiche di sistema" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

            {/* ############# NON HA MOLTO SENSO FAR CREARE ALL'UTENTE LE NOTIFICHE  */}
            {/* ############# DOVREBBE ESSERE CREATE DA MIGRAZIONE  */}
            {/* ############# VEDI migrations/20201008115753-create-notification-types.js  */}

            {/*<div className="row" style={{marginBottom: '15px'}}>
                { can( 'NotificationTypes.save' ) && <button className="btn btn-primary" onClick={se=>{
                    se.preventDefault();
                    this.setState({notificationTypeModal: true});
                }}>Nuova notifica</button>}
            </div>*/}

            <div className="row">

                <div className="col-md-12 col-lg-12">
                    { this.state.loading ? <Loader /> : <div className="row">
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className="ibox-content">
                                    <AbstractTableViewer model="NotificationTypes"
                                                         filters={{ }}
                                                         row={row}
                                                         columns={columns}/>
                                </div>
                            </div>
                        </div>
                    </div> }
                </div>

            </div>

            {this.state.notificationTypeModal && <NewNotificationTypeModal onDone={res=>{
                this.setState({notificationTypeModal: false});
            }} id={this.state.notificationTypeModal === true ? false: this.state.notificationTypeModal} />}




        </div>];
    }
}



class NewNotificationTypeModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            active: 1,
            name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
        apiFormInit( 'NotificationTypes', this );
    }

    onSubmit(se){
        se.preventDefault();
        apiFormSave( 'NotificationTypes', this);
    }

    render(){

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <FormInputText label="Nome" value={this.state.name}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="name"
                               onChange={se=>this.setState({name: se.target.value})} />

                {/*<FormInputText label="Nome macchina" value={this.state.machine_name}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="machine_name"
                               onChange={se=>this.setState({machine_name: se.target.value})} />

                <FormInputText label="Categoria" value={this.state.category}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="category"
                               onChange={se=>this.setState({category: se.target.value})} />*/}

                <FormInputTextArea label="Corpo" value={this.state.body}
                                   direction={FormInputContainer.HORIZONTAL}
                                   validation={this.state.validation} name="body"
                                   onChange={se=>this.setState({body: se.target.value})} />

                <RoleSelect
                    value={this.state.Roles} isMulti
                    label={"Ruoli abilitati alla ricezione"}
                    direction={FormInputContainer.HORIZONTAL}
                    validation={this.state.validation} name="Roles"
                    onChange={se=>{this.setState({Roles: se})}} />

                <FormDashline />

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva notifica"  direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

            </form>
        </Modal>;
    }

}

class NotificationTypeSelect extends React.Component {
    render(){
        return <ApiMultiSelect label="Notifica" {...this.props} model="NotificationTypes"
                               formatRow={row => { return row.name; }} filters={{}}
        />;
    }
}

export { NotificationTypes , NotificationTypeSelect, NewNotificationTypeModal };