import React from 'react';
import moment from 'moment';

import './MarketingReport.css';

import { Link } from "react-router-dom";

import {
    can, AbstractTableViewer, Breadcumbs, ApiMultiSelect, apiFormInit, ChannelDescription,
    apiFormSave, AbstractApiFormFooter, formatEur, ContractTypesSelect, LocalCache, EleGasSelect
} from './AppCommon';

import {
    Loader, Modal, FormInputText, FormDashline, FormInputContainer,
    FormButtons, FormInputMonth, FormInputYear, fetch, Row, BoxContainer,
    FormInputSelect, FormInputRadio, FormInputTextArea, queryToObject
} from './Common.js';

import { formatLeadName } from './Leads';
import Select from 'react-select';
import { ContractTypeRoleSelect , NewContractModal } from './Contracts';
import { ContractStateSelect, ContractStateViewer } from './ContractStates';
import { ProductSelect, SubProductSelect } from './Products';
import { UsersMultiSelect, UserSelect } from './Users';
import {TagsSelect} from "./Tags";


class TreeViewItem extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            open: true
        };
    }

    render(){

        const style = {
            position: 'relative',
            padding: '10px',
            paddingLeft: '20px'
        };


        return <div style={style}>
            <i className={this.state.open?"fa fa-square-o":"fa fa-square-o"}
                style={{float: 'left'}}></i>
            <div>{this.props.children}</div>
        </div>;
    }
}

class TreeView extends React.Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){
        return <div style={{
                position: 'relative'
            }}>

            {this.props.children}

        </div>;
    }
}

class MarketingPlanFilterBreadcumbs extends React.Component {

    constructor(props){
        super(props);

        this.state = props.filter;
    }

    breadcumbs( from ){
        if( !from )
            return null;

        return <React.Fragment>
            { from.Parent ? <React.Fragment>
                {this.breadcumbs( from.Parent )} / </React.Fragment>
            : null } <span class="label">{from.name}</span>
        </React.Fragment>
    }

    render(){
        return this.breadcumbs(this.state);
    }
}

class MarketingPlanEditorItem extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            filterData: JSON.stringify( {} ),
        };

        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);

    }

    componentDidUpdate(prevProps, prevState){
        if( prevProps.id !== this.props.id )
            this.reloadData();
    }

    componentDidMount(){
        this.reloadData();
    }

    reloadData(){
        if( !this.props.id )
            return;

        this.setState({loading: true});

        fetch( 'api' , 'MarketingReportPlanFilters' , 'get' , this.props.id ).then( msg => {
            this.setState({
                loading: false,
                ...msg.body,
                filterData: msg.body ? msg.body.filterData || '{}' : '{}'
            });
        });
    }

    breadcumbs( from ){
        if( !from )
            return null;

        return <React.Fragment>
            { from.Parent ? <React.Fragment>
                {this.breadcumbs( from.Parent )} / </React.Fragment>
            : null } <span class="label">{from.name}</span>
        </React.Fragment>
    }

    setFilterData( name , value ){

        let v = {};

        try {
            v = JSON.parse( this.state.filterData );
            if( !v )
                v = {};
        } catch( e ){}

        v[name] = value;
        this.setState({filterData: JSON.stringify( v )});

    }

    getFilterData( name ){
        try {
            let v = JSON.parse( this.state.filterData );
            return v[name];
        } catch( e ){}

        return undefined;
    }

    onSave( se ){
        se.preventDefault();

        this.setState({loading: true});

        fetch( 'api' , 'MarketingReportPlanFilters' , 'save' , this.state ).then( msg => {
            this.reloadData();

            if( this.props.onSave )
                this.props.onSave();
        });
    }

    onDelete(){

        fetch( 'api' , 'MarketingReportPlanFilters' , 'delete' , this.state.id ).then( msg => {
            this.setState({
                id: this.state.ParentId
            } , () => {
                this.reloadData();
            });


            if( this.props.onDelete )
                this.props.onDelete();
        });

    }

    render(){

        if( this.state.loading )
            return <Loader />;

        if( !this.props.id ){
            return <BoxContainer title="Impostazioni filtro">
                <p style={{margin: '4em',color: 'rgb(172, 172, 172)', textAlign: 'center'}} >
                    Seleziona un filro per impostarne le regole</p>
            </BoxContainer>;
        }

        let typeOptions = this.state.ParentId ? {
            'qtyRanges': 'Fasce di volumi',
            'kwRanges': 'Fasce KW Singolo contratto',
            'kwRangesTotal': 'Fasce KW Totali nel report',
            'contractType' : 'Tipo contratto',
            'productMargin' : 'Marginalità prodotto',
            'productBusinessType' : 'Settore di business prodotto',
            'assign' : 'Ricavo contratto',
            'gasEle'  :'Gas / Elettrico / Fibra',
            'product'  :'Prodotto o accessorio',
            'multiProduct': 'Multi prodotto',
            'userType'  :'Ruolo compensi',
            'rid' : 'Controllo RID',
            'webPayment' : 'Controllo Bolletta Web',
            'fromTablet' : 'Controllo contratto da tablet',
            'leadOrigin' : 'Origine lead',
            'kwMeterType': 'Tipologia contatore',
            'enelxBusinessType': 'Tipo business ENELX',
            'productTags': 'Tag prodotto',
        } : {
            'plan': 'Piano compensi',
        };

        return <BoxContainer title="Impostazioni filtro">

            <div style={{margin: '10px', marginBottom: '20px'}}>
                {this.breadcumbs(this.state)}
            </div>

            <FormInputText value={this.state.name} onChange={se=>{
                this.setState({name: se.target.value});
            }} direction={FormInputContainer.HORIZONTAL} label="Nome" />

            <FormInputSelect label="Strategia Filtro" value={this.state.type}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="type"
                values={typeOptions}
                onChange={se=>{this.setState({type: se})}} />

            {this.state.type == 'plan' && <React.Fragment>
                <FormInputSelect label="Tipo piano" value={this.getFilterData('type')}
                    direction={FormInputContainer.HORIZONTAL}
                    values={{
                        'fixed': 'Azienda',
                        'perAgent': 'Per agente',
                    }}
                    onChange={se=>this.setFilterData('type', se)} />

            </React.Fragment>}

            {this.state.type == 'qtyRanges' && <React.Fragment>

                <FormInputRadio label="Si annulla in caso di superamento volume" 
                    checked={this.getFilterData( 'void_if_exceed' )}
                    direction={FormInputContainer.HORIZONTAL}
                    onClick={se=>{this.setFilterData( 'void_if_exceed' ,
                        this.getFilterData( 'void_if_exceed' ) ? false : true )}} />

                <FormInputText label="Volume minimo" value={this.getFilterData('minValue')}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setFilterData('minValue', se.target.value)} />

                <FormInputText label="Volume massimo" value={this.getFilterData('maxValue')}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setFilterData('maxValue', se.target.value)} />

                
            </React.Fragment>}

            {this.state.type == 'gasEle' && <React.Fragment>

                <EleGasSelect value={this.getFilterData('type')}
                              onChange={se=>this.setFilterData('type', se)} />


            </React.Fragment>}

            {(this.state.type == 'enelxBusinessType') && <React.Fragment>
                <FormInputSelect values={{
                    'B2B': 'B2B',
                    'B2C': 'B2C',
                    'Microbusiness': 'Microbusiness',
                }}
                                 label="Tipologia business"
                                 value={this.getFilterData('enelxBusinessType')}
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se=>this.setFilterData('enelxBusinessType', se)} />
            </React.Fragment>}

            {(this.state.type == 'productTags') && <React.Fragment>
                <TagsSelect
                  direction={FormInputContainer.HORIZONTAL}
                  onChange={tags=>{ this.setFilterData('tags', tags) }}
                  value={this.getFilterData('tags')} canCreate={false}
                  entityName={"Products"} entityId={this.state.id} />
            </React.Fragment>}

            {(this.state.type == 'kwMeterType') && <React.Fragment>
                <FormInputSelect values={{
                    'Small': 'Small',
                    'Medium': 'Medium',
                    'Micro': 'Micro',
                }}
                                 label="Tipologia contatore"
                                 value={this.getFilterData('kwMeterType')}
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se=>this.setFilterData('kwMeterType', se)} />
            </React.Fragment>}

            {(this.state.type == 'kwRanges'||this.state.type == 'kwRangesTotal') && <React.Fragment>

                <FormInputText label="Kw minimo" value={this.getFilterData('minValue')}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setFilterData('minValue', se.target.value)} />

                <FormInputText label="Kw massimo" value={this.getFilterData('maxValue')}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setFilterData('maxValue', se.target.value)} />

                    {this.state.type == 'kwRangesTotal' && <React.Fragment>
                        <FormInputRadio label="Si annulla in caso di superamento volume" 
                            checked={this.getFilterData( 'void_if_exceed' )}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>{this.setFilterData( 'void_if_exceed' ,
                                this.getFilterData( 'void_if_exceed' ) ? false : true )}} />
                    </React.Fragment>}

            </React.Fragment>}

            {(this.state.type == 'leadOrigin') && <React.Fragment>

                <FormInputSelect label={"Origine lead"}
                                 values={{
                                     'Call center': 'Call center',
                                     'Contratto diretto': 'Contratto diretto',
                                     'Inserimento diretto': 'Inserimento diretto',
                                 }}
                                 value={this.getFilterData('origin')}
                               direction={FormInputContainer.HORIZONTAL}
                               onChange={se=>this.setFilterData('origin', se)} />

            </React.Fragment>}

            {this.state.type == 'rid' && <React.Fragment>
                <FormInputRadio label="RID Abilitato" 
                    checked={this.getFilterData( 'enabled' )}
                    direction={FormInputContainer.HORIZONTAL}
                    onClick={se=>{this.setFilterData( 'enabled' ,
                        this.getFilterData( 'enabled' ) ? false : true )}} />
            </React.Fragment>}

            {this.state.type == 'webPayment' && <React.Fragment>
                <FormInputRadio label="Bolletta web abilitata" 
                    checked={this.getFilterData( 'enabled' )}
                    direction={FormInputContainer.HORIZONTAL}
                    onClick={se=>{this.setFilterData( 'enabled' ,
                        this.getFilterData( 'enabled' ) ? false : true )}} />
            </React.Fragment>}

            {this.state.type == 'fromTablet' && <React.Fragment>
                <FormInputRadio label="Contratto da tablet"
                                checked={this.getFilterData( 'enabled' )}
                                direction={FormInputContainer.HORIZONTAL}
                                onClick={se=>{this.setFilterData( 'enabled' ,
                                  this.getFilterData( 'enabled' ) ? false : true )}} />
            </React.Fragment>}

            {this.state.type == 'multiProduct' && <React.Fragment>
                <ProductSelect onChange={se=>{
                    this.setFilterData('products', se)
                }} direction={FormInputContainer.HORIZONTAL} isMulti
                value={this.getFilterData('products')} />
        
            </React.Fragment>}

            {(this.state.type == 'product') && <React.Fragment>
                <ProductSelect value={this.getFilterData('product')}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={
                        se=>this.setFilterData('product', se)} />

                {this.getFilterData('product') && <SubProductSelect
                    value={this.getFilterData('subproduct')}
                    label="Accessori / Sotto prodotti"
                    validation={this.state.validation} name="SubProducts"
                    direction={FormInputContainer.HORIZONTAL} isMulti
                    ProductId={this.getFilterData('product').id}
                    onChange={se=>this.setFilterData('subproduct', se)} /> }
            </React.Fragment>}

            {(this.state.type == 'userType') && <React.Fragment>
                <ContractTypeRoleSelect value={this.getFilterData('roles')||[this.getFilterData('role')]}
                    direction={FormInputContainer.HORIZONTAL}
                    isMulti
                    onChange={
                        se=>this.setFilterData('roles', se)} />
            </React.Fragment>}

            {this.state.type == 'contractType' && <React.Fragment>
                <ContractTypesSelect label="Modulistica contratto abilitato" value={this.getFilterData('ContractType')}
                    validation={this.state.validation} name="ContractType"
                    filters={{productId: this.state.ProductId}}
                    direction={FormInputContainer.HORIZONTAL}
                    isMulti
                onChange={se=>this.setFilterData( 'ContractType' , se )} /> 
            </React.Fragment>}

            {this.state.type == 'productMargin' && <React.Fragment>
                <FormInputSelect values={{
                    'low': 'Bassa',
                    'high': 'Alta',
                    'none': 'Unica',
                    }} value={this.getFilterData( 'marginType' )}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Filtro marginalità prodotto"
                    onChange={se=>{this.setFilterData( 'marginType', se )}} />
            </React.Fragment>}

            {this.state.type == 'productBusinessType' && <React.Fragment>
                <FormInputSelect values={{
                    'residential': 'Residenziale',
                    'business': 'Business',
                }} value={this.getFilterData( 'productBusinessType' )}
                                 direction={FormInputContainer.HORIZONTAL}
                                 label="Filtro settore di business prodotto"
                                 onChange={se=>{this.setFilterData( 'productBusinessType', se )}} />
            </React.Fragment>}

            {this.state.type == 'assign' && <React.Fragment>

                <FormInputText label="Descrizione riga" value={this.getFilterData('description')}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setFilterData('description', se.target.value)} />

                <FormInputSelect label="Calcolo valore commerciale" value={this.getFilterData('type')}
                    direction={FormInputContainer.HORIZONTAL}
                    values={{
                        'fixed': 'Fisso',
                        'perContract': 'Per singolo contratto',
                        'product': 'In base a valore prodotto',
                        'kwPerYear': 'Consumo Kwh Annuale',
                        'enelxValue': 'Valore scheda EnelX',
                        'recurring': 'Pagamento ricorrente'
                    }}
                    onChange={se=>this.setFilterData('type', se)} />

                { this.getFilterData('type') == 'recurring' && <React.Fragment>
                    <FormInputText label="Importo su valore ricorrente" value={this.getFilterData('recurringPercentage')}
                                   placeholder={'Esempio: "2%" o direttamente "2" per importo fisso '}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setFilterData('recurringPercentage', se.target.value)} />

                    <FormInputText label="Importo su MWh annui" value={this.getFilterData('recurringMw')}
                                   placeholder={'Esempio: "5" per indicare 5€ (Per cui MW / 1000 / 12 * 5€)'}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setFilterData('recurringMw', se.target.value)} />
                </React.Fragment>}

                { this.getFilterData('type') == 'enelxValue' && <React.Fragment>
                    <FormInputText label="Percentuale su prezzo indicato (da 1 a 100)" value={this.getFilterData('enelxValuePercentage')}
                                   placeholder={'Esempio: "2%" o direttamente "2" '}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setFilterData('enelxValuePercentage', se.target.value)} />
                </React.Fragment>}

                { this.getFilterData('type') == 'kwPerYear' && <React.Fragment>
                    <FormInputText label="Fattore di divisione" value={this.getFilterData('kwPerYearFactor')}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setFilterData('kwPerYearFactor', se.target.value)} />
                </React.Fragment>}

                { (this.getFilterData('type') != 'product' && this.getFilterData('type') != 'enelxValue' && this.getFilterData('type') != 'recurring' ) &&
                    <FormInputText label="Valore EURO" value={this.getFilterData('valueEur')}
                        direction={FormInputContainer.HORIZONTAL}
                        onChange={se=>this.setFilterData('valueEur', se.target.value)} /> }

            </React.Fragment>}


            <AbstractApiFormFooter {...this.state} />

            <Row>
                <div className="col-sm-6">
                    <button className="btn btn-primary" onClick={this.onSave}>Salva</button>
                </div>

                <div className="col-sm-6 text-right">

                    <button className="btn btn-default" onClick={se=>{
                        if( this.props.onCopy ) this.props.onCopy( this.state.id );
                    }}>Copia</button>

                    <button className="btn btn-default" onClick={se=>{
                        if( this.props.onCut ) this.props.onCut( this.state.id );
                    }}>Taglia</button>

                    {this.props.cutName && <button className="btn btn-default" onClick={se=>{
                        if( this.props.onPaste ) this.props.onPaste( this.state.id );
                    }}>Incolla {this.props.cutName} dentro</button>}

                    <button className="btn btn-danger" onClick={this.onDelete}>Elimina</button>
                </div>
            </Row>

        </BoxContainer>;
    }
}

class MarketingPlanEditor extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            currentFilter: false,
            clipboardId: false,
            clipboardName: false,
        };

        this.onAddFilter = this.onAddFilter.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onCut = this.onCut.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onCopyPlan = this.onCopyPlan.bind(this);
        this.onPaste = this.onPaste.bind(this);
    }

    componentDidMount(){
        this.reloadData();
    }

    componentDidUpdate( prevProps , prevState ){
    }

    reloadData( soft = false ){
        if( !soft )
            this.setState({loading: true});

        fetch( 'api' , 'MarketingReportPlanFilters' , 'selectPlans' , { users: this.state.filterUsers } ).then( msg => {
            this.setState({
                loading: false,
                filters: msg.body
            });
        });

        if( this.state.currentPlanId ){
            fetch( 'api' , 'MarketingReportPlanFilters' , 'fetchPlan' , this.state.currentPlanId ).then( msg => {
                this.setState({
                    loading: false,
                    currentPlan: msg.body,
                });
            });
        }

    }

    onCopy( id ){
        this.setState({clipboardId: id, clipboardOp: 'copy'});
        if( id === this.state.currentFilterId )
            this.setState({clipboardName: this.state.currentFilter.name});
    }

    onCut( id ){
        this.setState({clipboardId: id, clipboardOp: 'cut'});
        if( id === this.state.currentFilterId )
            this.setState({clipboardName: this.state.currentFilter.name});
    }

    onPaste( id ){
        this.setState({
            clipboardName: false,
            clipboardId: false});

        if( !this.state.clipboardId )
            return;

        if( this.state.clipboardOp == 'cut' ){
            fetch( 'api' , 'MarketingReportPlanFilters' , 'save' , {
                id: this.state.clipboardId,
                ParentId: id
            } ).then( msg => {

                this.setState({
                    currentFilterId: msg.body.object.id,
                });

                this.reloadData();
            });
        }

        if( this.state.clipboardOp == 'copy' ){
            fetch( 'api' , 'MarketingReportPlanFilters' , 'copy' , {
                id: this.state.clipboardId,
                ParentId: id
            } ).then( msg => {

                this.setState({
                    currentFilterId: msg.body.id,
                });

                this.reloadData();
            });
        }

    }

    onCopyPlan( id ){
        fetch( 'api' , 'MarketingReportPlanFilters' , 'copy' , {
            id: id,
            ParentId: null
        } ).then( msg => {

            this.setState({
                currentFilterId: msg.body.id,
            });

            this.reloadData();
        });
    }

    onAddFilter( parentFilter ){

        fetch( 'api' , 'MarketingReportPlanFilters' , 'save' , {
            name: 'Senza nome',
            type: parentFilter ? 'qtyRanges' : 'plan',
            ParentId: parentFilter ? parentFilter.id : null
        } ).then( msg => {

            this.setState({
                currentFilterId: msg.body.object.id,
            });
            
            this.reloadData();
        });
    }

    onFilterClick( filter ){
        this.setState({
            currentFilter: filter,
            currentFilterId: filter.id,
        });
    }

    renderFilter( filter , depth = 1 ){

        let type = 'Errore';

        let filterData = {};
        try {
            filterData = JSON.parse( filter.filterData );
        } catch ( e ){}

        if( filter.type === 'qtyRanges' ){ 
            type = `Volumi`;
            try {
                type += ` : ${filterData.minValue} / ${filterData.maxValue}`;
            } catch ( e ){}
        }

        if( filter.type === 'kwRanges' ){ 
            type = 'Fasce KW'
            try {
                type += ` : ${filterData.minValue} / ${filterData.maxValue}`;
            } catch ( e ){}
        };

        if( filter.type === 'leadOrigin' ){
            type = 'Origine lead'
            try {
                type += ` : ${filterData.origin}`;
            } catch ( e ){}
        };

        if( filter.type === 'kwRangesTotal' ){ 
            type = 'Fasce KW Totali'
            try {
                type += ` : ${filterData.minValue} / ${filterData.maxValue}`;
            } catch ( e ){}
        };

        if( filter.type === 'kwMeterType' ){
            type = 'Tipologia contatore'
            try {
                type += ` : ${filterData.kwMeterType}`;
            } catch ( e ){}
        };

        if( filter.type === 'enelxBusinessType' ){
            type = 'Tipologia business ENELX'
            try {
                type += ` : ${filterData.enelxBusinessType}`;
            } catch ( e ){}
        };

        if( filter.type === 'productTags' ){
            type = 'Tag prodotto'
            try {
                type += ` : ${'-'}`;
            } catch ( e ){}
        };

        if( filter.type === 'contractType' ){ 
            type = 'Tipo contratto';
            try {
                type += ` : ${filterData.ContractType.map( t => { return t.name; }).join(', ')}`;
            } catch ( e ){}
        }; 

        if( filter.type === 'plan' ){ 
            type = 'Piano compensi';
            try {
                type += `${filterData.type == 'fixed' ? ' : Azienda' : ''}`;
                type += `${filterData.type == 'perAgent' ? ' : Agente' : ''}`;
            } catch ( e ){}
        }

        if( filter.type === 'assign' ){ 
            type = 'Ricavo contratto';
            try {
                type += `${filterData.type == 'fixed' ? ` : Fisso / ${formatEur( filterData.valueEur )}` : ''}`;
                type += `${filterData.type == 'perContract' ? ` : Per singolo contratto / ${formatEur( filterData.valueEur )}` : ''}`;
                type += `${filterData.type == 'product' ? ` : Valore prodotto / ${formatEur( filterData.valueEur )}` : ''}`;
                type += `${filterData.type == 'kwPerYear' ? ` : KW ANNO / ${filterData.kwPerYearFactor} * ${formatEur( filterData.valueEur )}` : ''}`;
                type += `${filterData.type == 'recurring' ? ` : Ricorrente / ${filterData.recurringPercentage}` : ''}`;
            } catch ( e ){}
        }

        if( filter.type === 'rid' ){
            type = 'RID';
            try {
                type += `${filterData.enabled ? ' : Abilitato' : ' : Disabilitato'}`;
            } catch ( e ){}
        }

        if( filter.type === 'multiProduct' ){
            type = 'Multiprodotto';
        }

        if( filter.type === 'webPayment' ){
            type = 'Bolletta web';
            try {
                type += `${filterData.enabled ? ' : Abilitata' : ' : Disabilitata'}`;
            } catch ( e ){}
        }

        if( filter.type === 'fromTablet' ){
            type = 'Contratto da tablet';
            try {
                type += `${filterData.enabled ? ' : Abilitato' : ' : Disabilitato'}`;
            } catch ( e ){}
        }

        if( filter.type === 'productMargin' ) type = 'Marginalità prodotto';
        if( filter.type === 'productBusinessType' ) type = 'Settore di business prodotto';
        if( filter.type === 'gasEle' ) type = 'Gas / Elettrico / Fibra';
        if( filter.type === 'product' ) type = 'Prodotto o accessorio';
        if( filter.type === 'userType' ) type = 'Ruolo compensi';

        return <div   className="MarketingReportFilter">
                
                <div className="MarketingReportFilterL"><div /></div>

                <div className={"MarketingReportFilterDescription "+(this.state.currentFilterId==filter.id?'selected':'')} 
                    onClick={se=>{this.onFilterClick(filter)}}>

                    <span className="fdesc0" >{filter.name}</span> <br />
                    <span className="fdesc1">
                        [{type}]
                    </span>
                </div>
                
                <div className="MarketingReportFilterChildren " style={{paddingLeft: '20px'}} >
                {filter.Children && 
                    filter.Children.map( child => { return this.renderFilter( child , depth +1 ); })}
                </div>
                { (filter.type!='assign') && <div style={{paddingLeft: '20px'}} >

                    <div className="MarketingReportFilterL"><div /></div>

                    <button className="btn btn-xs btn-default" 
                            onClick={se=>{se.preventDefault();this.onAddFilter( filter )}}>Aggiungi</button>
                </div> }
        </div>;
    }

    setCurrentPlanId( id ){

        fetch( 'api' , 'MarketingReportPlanFilters' , 'fetchPlan' , id ).then( msg => {
            this.setState({
                loading: false,
                currentPlanId: id,
                currentPlan: msg.body,
            });
        });

        this.setState({
            loading: true
        });
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        let plans = [];
        let selectedValue = false;

        this.state.filters.map( filter => {
            if( this.state.currentPlanId == filter.id )
                selectedValue ={ value: filter.id , label: filter.name };
            plans.push( { value: filter.id , label: filter.name } );
            return filter;
        });

        return <Row>
            <div className="col-md-6 col-lg-6">
                <BoxContainer title="Piani compensi">
                    <Row>
                        <div className={"col-md-8"}>
                            <UserSelect value={this.state.filterUsers}
                                        direction={FormInputContainer.HORIZONTAL}
                                        label="Filtra per utente assegnato" isMulti
                                        onChange={se=>{
                                            this.setState({ filterUsers: se } , () => {
                                                this.reloadData()
                                            })
                                        } } />
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-8">
                            <Select options={plans}
                                onChange={se=>{this.setCurrentPlanId(se.value)}}
                                direction={FormInputContainer.HORIZONTAL}
                                value={selectedValue} />
                        </div>
                        <div className="col-md-4">
                            <button className="btn btn-xs btn-primary" 
                                onClick={se=>{se.preventDefault();this.onAddFilter( )}}>Aggiungi Piano</button>
                            {this.state.currentPlan &&
                                <button className="btn btn-xs btn-primary"
                                        onClick={se => {
                                            se.preventDefault();
                                            this.onCopyPlan( this.state.currentPlan.id )
                                        }}>Duplica Piano</button>
                            }
                        </div>
                    </Row>
                    
                    {this.state.currentPlan && this.renderFilter( this.state.currentPlan )}

                </BoxContainer>
            </div>
            <div className="col-md-6 col-lg-6">
                <MarketingPlanEditorItem id={this.state.currentFilterId} 
                    cutName={this.state.currentFilterId != this.state.clipboardId ? 
                        this.state.clipboardName : false }
                    onCut={this.onCut} onPaste={this.onPaste}
                    onCopy={this.onCopy} onPaste={this.onPaste}
                    onSave={se=>{this.reloadData( true );}}
                    onDelete={se=>{this.reloadData( true );this.setState({currentFilterId: false})}} />
            </div>
        </Row>;
    }
}

class MarketingPlansToPayReport extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            filterMonth: moment().subtract(1,'month').month() + '',
            filterYear: moment().year() + '',
            filterPayedAt: 'n',

            updatingRows: [],
        };
    }

    /*componentDidUpdate(prevProps, prevState, snapshot) {
        if( JSON.stringify( prevState.filterUsers ) != JSON.stringify( this.state.filterUsers ) )
            this.reloadData();
        if( prevState.filterMonth != this.state.filterMonth || prevState.filterYear != this.state.filterYear )
            this.reloadData();
    }

    reloadData(){
        fetch( 'api' , 'MarketingReports' , 'toPayReport' , this.state ).then( msg => {
            this.setState( msg.body );
        } );
    }*/

    payRow( se , contract ){
        se.preventDefault();

        this.setState( {
            updatingRows: this.state.updatingRows.concat( [ contract.id ] )
        });

        fetch( 'api' , 'MarketingReports' , 'payRow' , this.props.row.id , contract.Contract.id ).then( msg => {
            this.updateData();
        });
    }

    render(){

        const row = contract => {
            return <tr>
                <td>
                    <a href={"/marketing_report/"+contract.MarketingReport.id}>
                        {contract.MarketingReport.month+1}/{contract.MarketingReport.year}</a>
                </td>
                <td>{contract.Contract.uniqueUuid}</td>
                <td>{contract.Contract.Lead ?
                  <Link to={'/leads_list/detail/'+contract.Contract.Lead.id} >
                      <span>{formatLeadName( contract.Contract.Lead )}</span>
                  </Link> : '-'}</td>
                <td>{ contract.ContractsExploded.ContractsHistories_state_30 ?
                  moment(contract.ContractsExploded.ContractsHistories_state_30).format('DD/MM/YY')
                  : '-'
                }<MarketingPlanContractKwhInfo contract={contract.Contract} KwhRefundContracts={contract.KwhRefundContracts} /></td>
                <td><ContractStateViewer contract={contract.Contract} /></td>
                <td>{formatEur(contract.valueEur)}</td>
                {this.state.updatingRows.indexOf( contract.id ) != -1 ?
                  <td><button onClick={se=>{se.preventDefault()}} class="btn btn-xs btn-default"><i className="fa fa-spin fa-circle-o-notch"></i></button> </td>
                  :
                  <td>{contract.payedAt ?
                    <button onClick={se=>{this.payRow(se,contract)}} class="btn btn-xs btn-primary">Si</button> :
                    <button onClick={se=>{this.payRow(se,contract)}} class="btn btn-xs btn-danger">No</button> }</td>
                }
            </tr>
        };


        const columns = {
            report: 'Report',
            uniqueUuid: 'Codice' ,
            lead: 'Cliente',
            state_30: 'Ins. a sistema',
            state: 'Stato',
            value: 'Valore',
            payed: 'Pagato',
        };

        return<>
            <Breadcumbs title="Oggetti da pagare" items={{'Home': '/'}} />
            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">
                    <div className={"col-4"}>
                        <UserSelect value={this.state.filterUsers}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="Utente"
                                    onChange={se=>this.setState({ filterUsers: se })} />
                    </div>

                    <div className="col-lg-2">
                        <FormInputMonth value={this.state.filterMonth}
                                        label={"A partire da / mese"}
                                        onChange={se=>{this.setState({filterMonth: se});}} />
                    </div>

                    <div className="col-lg-2">
                        <FormInputYear value={this.state.filterYear}
                                       onChange={se=>this.setState({filterYear: se})} />
                    </div>


                    <div className="col-lg-2">
                        <FormInputSelect value={this.state.filterPayedAt}
                                         direction={FormInputContainer.HORIZONTAL}
                                         label="Stato pagamento"
                                         values={{
                                             '' : 'Tutti',
                                             'y': 'Solo pagati',
                                             'n': 'Non pagati',
                                         }}
                                         onChange={se=>this.setState({filterPayedAt: se})} />
                    </div>

                </div>

                <BoxContainer>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <AbstractTableViewer model="MarketingReports"
                                 method="toPayReport"
                                 infoMethod={"toPayReportInfo"}
                                 filters={{
                                     user: this.state.filterUsers,
                                     month: this.state.filterMonth,
                                     year: this.state.filterYear,
                                     payedAt: this.state.filterPayedAt,
                                 }}
                                 row={row}
                                 columns={columns}/>
                        </div>
                    </div>
                </BoxContainer>
            </div>
        </>;
    }
}

class MarketingPlans extends React.Component {
    
    constructor(props){
        super(props);

        this.state = {
            planModal: false,
            loading: true,
            plan: { id: 0 },
            rangeModal: false,
            type: this.props.type || 'fixed',
            editor: this.props.editor ? true: false
        };

    }

    componentDidMount(){
    }
    
    
    render(){
    
        let breadcumbs = true;

        if( typeof( this.props.breadcumbs ) != 'undefined' )
            breadcumbs = this.props.breadcumbs;
    
        return <React.Fragment>

            { breadcumbs  && 
                <Breadcumbs title="Piano compensi" items={{'Home': '/'}} /> }

            <div className="wrapper wrapper-content animated fadeInRight">
                <div className="row">

                    {this.state.editor && <div className="col-md-12">
                        <MarketingPlanEditor /> 
                    </div>}
                
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <BoxContainer title="Errori elaborazione report">
                        <div className="row">
                            <div className="col-md-12">
                            <AbstractTableViewer model="MarketingReportFilterErrors" 
                                row={ row => {
                                    try {
                                        return <tr>
                                            <td>{moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{row.Plan.name}</td>
                                            <td>{row.Filter.name}</td>
                                            <td>{row.Contract.uniqueUuid}</td>
                                            <td>{row.MarketingReport.id}</td>
                                        </tr>;
                                    } catch( e ){}

                                    return <tr>
                                        <td></td><td></td><td></td>
                                        <td></td><td></td>
                                    </tr>
                                }} columns={{
                                    a:'Data',
                                    b:'Piano',
                                    c:'Filtro',
                                    d:'Contratto',
                                    e:'Report'
                                }} />
                                
                            </div>
                        </div>
                        </BoxContainer>
                    </div>
                    
                </div>

            </div>

        </React.Fragment>;
      }
}

class MarketingReportsSelect extends React.Component {
    render(){
        return <ApiMultiSelect label="Report" {...this.props}
                               model="MarketingReports"
                               formatRow={row => { return `${row.MarketingReportPlanFilter.name} ${row.month+1}/${row.year}`; }}
        />;
    }
}

class MarketingReportPlanFiltersSelect extends React.Component {
    render(){
        return <ApiMultiSelect label="Piano compensi" {...this.props}
           model="MarketingReportPlanFilters" modelAction={"selectPlans"}
           formatRow={row => { return row.name; }}
        />;
    }
}

class MarketingReport_ContractInspector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            rowDetailOpened: false,
            updatingRows: [],
            ...queryToObject()
        };
    }

    componentDidMount() {
        fetch( 'api' , 'MarketingReports' , 'contractInfo' , this.state.contractId ).then( msg => {
            this.setState({
                ...msg.body,
                loading: false,
            })
        });
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        return <React.Fragment>
            <Breadcumbs title={'Ispeziona report contratto ' + this.state.contract.uniqueUuid} items={{'Home': '/'}} />
            <Row>
                <div className={"col-xl-8 offset-xl-2 col-md-12 pt-4"}>


                    {this.state.reports.map( report => {
                        const reportDateInfo = moment([report.year,report.month]).format("MMMM YYYY");
                        return <BoxContainer title={report.MarketingReportPlanFilter.name + " - " + reportDateInfo} tools={<>
                            <Link className={"btn btn-xs btn-primary"} to={"/marketing_report/"+report.id}>Vedi report</Link>
                        </>}>
                            <table className={"table table-striped"}>
                                <thead>
                                <tr>
                                    <th width="10%">Codice</th>
                                    <th width="40%">Cliente</th>
                                    <th width="20%">Stato</th>
                                    <th width="5%">Valore</th>
                                    <th width="5%">Pagato</th>
                                </tr>
                                </thead>
                                <tbody>
                                {report.rowContracts.map( rowContract => {
                                    try {
                                        return <tr>
                                            <td>{rowContract.Contract.uniqueUuid}</td>
                                            <td>{rowContract.Contract.Lead ?
                                              <Link to={'/leads_list/detail/'+rowContract.Contract.Lead.id} >
                                                  <span>{formatLeadName( rowContract.Contract.Lead )}</span>
                                              </Link> : '-'}</td>
                                            <td><ContractStateViewer contract={rowContract.Contract} /></td>
                                            <td>{rowContract.Contract.ContractState.isClosingState == 'positive' ?
                                              formatEur(rowContract.valueEur) : '-'}</td>
                                            <td>{rowContract.payedAt ? formatEur(rowContract.valueEur) : 'No'}</td>
                                        </tr>;
                                    } catch( e ){}

                                    return <tr>
                                        <td>-</td><td>-</td><td>-</td>
                                        <td>-</td><td>-</td>
                                    </tr>;
                                })}
                                </tbody>
                            </table>

                        </BoxContainer>
                    })}


                </div>
            </Row>

        </React.Fragment>
    }
}

class MarketingReport extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            loading: true,
            rowDetailOpened: false,
            updatingRows: [],
        }

        this.onAddRow = this.onAddRow.bind(this);
        this.onSave = this.onSave.bind(this);

        this.onResetReport = this.onResetReport.bind(this);
        this.payAllPositive = this.payAllPositive.bind(this);
    }

    componentDidMount(){
        this.updateData();
    }

    updateData(){
        fetch( 'api' , 'MarketingReports' , 'get' , this.props.id ).then( msg => {
            this.setState({
                ...msg.body,
                loading: false,
                updatingRows: []
            })
        });
    }

    onSave( se ){
        se.preventDefault();

        this.setState({loading: true});

        fetch( 'api' , 'MarketingReports' , 'save' , this.state ).then( msg => {
            this.setState({
                ...msg.body.object,
                loading: false
            })
        });
    }

    onAddRow(se){
        se.preventDefault();

        this.setState( s => {
            let o = s.MarketingReportRows;
            o.push({
                description: '-',
                MarketingReportId: this.state.id,
                valueEur: 0
            });

            return { MarketingReportRows: o };
        })
    }

    onResetReport( se ){
        fetch( 'api' , 'MarketingReports' , 'resetReport' , this.state.id ).then( m => {
            window.location.reload();
        });
    }

    payAllPositive(se){
        se.preventDefault();

        this.setState({
            loading: true,
            rowDetailOpened: false,
        });

        fetch( 'api' , 'MarketingReports' , 'payAllPositive' , this.state.id ).then( msg => {
            this.updateData();
        });
    }

    payRow(se, row){
        se.preventDefault();

        this.setState( {
            updatingRows: this.state.updatingRows.concat( [ row.id ] )
        });

        fetch( 'api' , 'MarketingReports' , 'payRow' , row.id , -3 ).then( msg => {
            this.updateData();
        });
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        let uniqueIds = [];

        this.state.MarketingReportContracts.map( c => {
            if( uniqueIds.indexOf( c.ContractId ) === -1 )
                uniqueIds.push( c.ContractId );
        });

        let numTotalContracts = uniqueIds.length;

        return <React.Fragment>
            <Breadcumbs title="Report" items={{'Home': '/'}} />

            { this.state.MarketingReportPlanFilter && 
                <h2>Piano compensi "{this.state.MarketingReportPlanFilter.name}"</h2>}

            {this.state.ExtraModelName == 'Users' && 
                <h2>Piano compensi agente "{this.state.User.name} {this.state.User.surname}"</h2>}

            <Row>
                <div className="col-md-8">

                    {this.state.regenerationRequired && <React.Fragment>
                        <div class="alert alert-warning" role="alert">
                            <i className="fa fa-exclamation"></i> Il seguente report è in fase di ricalcolo
                        </div>
                    </React.Fragment>}

                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>Contenuto report</h5>
                        </div>

                        <div className="ibox-content" style={{fontWeight: 'bold'}}>
                            <Row>
                                <div className="col-lg-7">
                                    Descrizione
                                </div>
                                <div className="col-lg-3">
                                    EUR
                                </div>
                                <div className="col-lg-2"></div>
                            </Row>
                        </div>

                        {this.state.MarketingReportRows.map( (row,rowIndex) => {

                            let numTotalContractsRow = 0;

                            try {
                                numTotalContractsRow = JSON.parse( row.jsonContractIds ).length;
                            } catch ( e ){}

                            if( row.ModelName ){
                                // this row is read-only

                                if(row.valueEur == 0 )
                                    return null;

                                return <div className="ibox-content">
                                    <Row className="">
                                        <div className="col-lg-7 text-left">
                                            <h4>{row.description}</h4><br />
                                            <span><span className="label">{numTotalContractsRow}</span> Contratti</span>
                                        </div>
                                        <div className="col-lg-3 text-left">
                                            {formatEur( row.valueEur )}
                                        </div>
                                        <div className="col-lg-2 text-left">
                                            
                                        </div>
                                        <div className="col-md-12 text-right">
                                            <span className="text-muted small">Inserito il {moment(row.createdAt).format('MMMM D, YYYY')}</span>
                                            {row.AuthorUserId && <span className="text-muted small">
                                                da {}</span>} &nbsp;
                                            <button className="btn btn-xs" onClick={se=>{
                                                this.setState({rowDetailOpened: row.id})
                                            }}><i className="fa fa-info"></i></button>
                                        </div>
                                    </Row>

                                    { this.state.rowDetailOpened == row.id && 
                                        <MarketingReportRowInspector row={row} onUpdate={se=>{this.updateData()}} /> }

                                </div>;
                            }

                            return <div className="ibox-content">
                                <Row className=""> 
                                    <div className="col-lg-6 text-left">
                                        <FormInputText value={row.description} onChange={se=>{
                                            let h = this.state.MarketingReportRows;
                                            h[rowIndex].description = se.target.value;
                                            this.setState({MarketingReportRows: h});
                                        }} direction={FormInputContainer.HORIZONTAL}  />
                                    </div>
                                    <div className="col-lg-3 text-left">
                                        <FormInputText value={row.valueEur} onChange={se=>{
                                            let h = this.state.MarketingReportRows;
                                            h[rowIndex].valueEur = se.target.value;
                                            this.setState({MarketingReportRows: h});
                                        }} direction={FormInputContainer.HORIZONTAL} />
                                    </div>
                                    <div className="col-lg-2 text-left">
                                        <button className="btn btn-default" onClick={se=>{
                                           let h = this.state.MarketingReportRows;
                                           h.splice(rowIndex,1)
                                           this.setState({MarketingReportRows: h});
                                        }}><i className="fa fa-trash"></i></button>
                                    </div>

                                    <div className="col-lg-1">
                                    {this.state.updatingRows.indexOf( row.id ) != -1 ?
                                      <button onClick={se=>{se.preventDefault()}} class="btn btn-xs btn-default">
                                          <i className="fa fa-spin fa-circle-o-notch"></i>
                                      </button>
                                      :
                                      <React.Fragment>
                                          {row.payedAt ?
                                            <button onClick={se=>{this.payRow(se,row)}} class="btn btn-xs btn-primary">Si</button> :
                                            <button onClick={se=>{this.payRow(se,row)}} class="btn btn-xs btn-danger">No</button> }
                                      </React.Fragment>
                                    }

                                    </div>
                                    
                                    <div className="col-md-12 text-right">
                                            <span className="text-muted small">Inserito il {moment(row.createdAt).format('MMMM D, YYYY')}</span>
                                            {row.Author && <span className="text-muted small">
                                                da {row.Author.name} {row.Author.surname}</span>}
                                        </div>
                                </Row>
                            </div>;
                        })}

                        <div className="ibox-content text-right">

                            <button className="btn btn-default" onClick={this.onAddRow} 
                                style={{marginRight: '10px'}}>Aggiungi riga</button>

                            <button className="btn btn-primary" onClick={this.onSave}>Salva</button>
                        </div>

                    </div>
                    
                </div>
                <div className="col-md-4">

                    <BoxContainer title="Informazioni">

                        <Row>
                            <div className="col-sm-12">
                                <span>Periodo</span>
                                <h2>{moment().month(this.state.month).format('MMMM')} {this.state.year}</h2>
                            </div>
                        </Row>

                        <hr />

                        <Row>
                            <div className="col-sm-12">
                                <span>Totale</span>
                                <h2 class="font-bold">{formatEur( this.state.totalEur )}</h2>
                            </div>
                        </Row>

                        <hr />

                        <Row>
                            <div className="col-sm-6">
                                <span>Pagato</span>
                                <h3 class="">{formatEur( this.state.payed )}</h3>
                            </div>
                            <div className="col-sm-6">
                                <span>Restanti</span>
                                <h3 class="">{formatEur( this.state.totalEur - this.state.payed )}</h3>
                            </div>
                        </Row>

                        <hr />

                        <Row>
                            <div className="col-sm-12">
                                
                                <span className="label">{numTotalContracts}</span> Contratti elaborati
                                <hr />
                                <button className="btn btn-xs mr-2" onClick={this.onResetReport}>Ricalcola report</button>
                                <button className="btn btn-xs btn-primary" onClick={this.payAllPositive}>Paga tutti i positivi</button>
                            </div>
                        </Row>

                    </BoxContainer>

                    <BoxContainer title="Storico pagamenti">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th># Contratti</th>
                                    <th>Totale</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.payedHistory.map( ph => {
                                    return <tr>
                                        <td>{moment( ph.date ).format( 'DD/MM/YY' )}</td>
                                        <td>{ph.count}</td>
                                        <td>{formatEur( ph.valueEur ) }</td>
                                    </tr>;
                                })}
                                
                            </tbody>
                        </table>
                    </BoxContainer>
                </div>
            </Row>
        </React.Fragment>;
    }
}

class MarketingReportRowInspector extends React.Component {

    constructor( props ){
        super(props);

        this.state = {
            loading: true,
            contractModal: false,
            updatingRows: [],
        };

        this.payRow = this.payRow.bind(this);
        this.showContract = this.showContract.bind(this);
        this.payRowAll = this.payRowAll.bind(this);
    }

    componentDidMount( ){

        if( this.props.row.ModelName == 'MarketingReportPlanFilter' ){
            fetch( 'api' , 'MarketingReportPlanFilters' , 'get' , this.props.row.ModelId ).then( msg => {
                this.setState({ filter: msg.body , loading: false });
            });
        }

        this.updateData();

    }

    updateData(){
        fetch( 'api' , 'MarketingReports' , 'rowContracts' , this.props.row.id ).then( msg => {
            this.setState({ 
                contracts: msg.body , 
                updatingRows: [],
                loading: false });
        });

        if( typeof( this.props.onUpdate ) == 'function' ){
            this.props.onUpdate();
        }
    }

    payRow( se , contract ){
        se.preventDefault();

        this.setState( {
            updatingRows: this.state.updatingRows.concat( [ contract.id ] )
        });

        fetch( 'api' , 'MarketingReports' , 'payRow' , this.props.row.id , contract.Contract.id ).then( msg => {
            this.updateData();
        });
    }

    payRowAll( se, code ){
        se.preventDefault();

        fetch( 'api' , 'MarketingReports' , 'payRow' , this.props.row.id , code ).then( msg => {
            this.updateData();
        });
    }

    showContract( se , contract ){
        se.preventDefault();
        this.setState({contractModal: contract.id});
    }

    render(){
        
        if( this.state.loading )
            return <Loader />;

        return <React.Fragment>

            {this.state.contractModal && <NewContractModal onDone={res=>{
                    this.setState({contractModal: false});
                }} id={this.state.contractModal === true ? false: this.state.contractModal} />}

            { this.state.filter && <React.Fragment>
                <h4>Filtro applicato</h4>
                <MarketingPlanFilterBreadcumbs filter={this.state.filter} />
            </React.Fragment> }

            { this.state.contracts && <React.Fragment>
                <h4>Contratti</h4>

                <p className="text-right">
                    Segna tutti come: 
                    <button type="button" onClick={se=>{this.payRowAll(se,-1)}} 
                        class="btn btn-xs btn-primary ">Pagati</button>
                    <button type="button" onClick={se=>{this.payRowAll(se,-2)}} 
                        class="btn btn-xs btn-danger ">NON pagati</button>
                </p>

                <table class="table">
                    <thead>
                        <tr>
                            <th>Codice</th>
                            {/*<th>Canale  </th>*/}
                            <th>Cliente</th>
                            <th>Stipula</th>
                            <th>Ins. a sistema</th>
                            <th>Stato</th>
                            <th>Valore</th>
                            <th>Pagato</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.state.contracts.map( contract => {

                        let dat_stip = '-';

                        try {
                            dat_stip = moment(JSON.parse(contract.Contract.extraJsonData).dat_stip_contratto)
                                .format('DD/MM/YY');
                        } catch( e ){}

                        let alertChannelVerification = false;
                        try {

                            if( contract.ChannelVerificationImportRows.length > 0 ){
                                alertChannelVerification = contract.ChannelVerificationImportRows[0].uniqueUuid;
                            }

                        } catch( e ){}

                        return <tr>
                            <td><a href="#" onClick={se=>{this.showContract(se,contract.Contract)}}>{contract.Contract.uniqueUuid}</a></td>
                            {/*<td><ChannelDescription channel={contract.Channel} /></td>*/}
                            <td>{contract.Contract.Lead ? 
                                <Link to={'/leads_list/detail/'+contract.Contract.Lead.id} >
                                    <span>{formatLeadName( contract.Contract.Lead )}</span>
                                </Link> : '-'}</td>

                            <td>{dat_stip}</td>
                            <td>{ contract.ContractsExploded.ContractsHistories_state_30 ?
                                moment(contract.ContractsExploded.ContractsHistories_state_30).format('DD/MM/YY')
                                : '-'
                            }<MarketingPlanContractKwhInfo contract={contract.Contract} KwhRefundContracts={contract.KwhRefundContracts} /></td>
                            <td><ContractStateViewer contract={contract.Contract} /></td>
                            <td>
                                {alertChannelVerification && <React.Fragment>
                                    <a target="_blank" className={" btn btn-xs btn-danger"} href={"/channel_verification?contract=" + alertChannelVerification}>
                                        <i className={"fa fa-exclamation-triangle"}></i>
                                    </a>
                                </React.Fragment>}
                                {formatEur(contract.valueEur)}</td>
                            {this.state.updatingRows.indexOf( contract.id ) != -1 ?
                                <td><button onClick={se=>{se.preventDefault()}} class="btn btn-xs btn-default"><i className="fa fa-spin fa-circle-o-notch"></i></button> </td>
                                : 
                                <td>{contract.payedAt ? 
                                    <button onClick={se=>{this.payRow(se,contract)}} class="btn btn-xs btn-primary">Si</button> : 
                                    <button onClick={se=>{this.payRow(se,contract)}} class="btn btn-xs btn-danger">No</button> }</td>
                            }
                            
                        </tr>
                    })}
                    </tbody>
                </table>
                
            </React.Fragment> }

        </React.Fragment>
    }
}

function MarketingPlanContractKwhInfo( props ){
    if( !props.contract )
        return null;

    let kwhPerYear = null;

    try {
        let extraData = JSON.parse( props.contract.extraJsonData );
        kwhPerYear = extraData.ele_kwh_annuo;
    } catch ( e ){
        console.error( e );
        return null;
    }

    if( !kwhPerYear )
        return null;

    let currentValue = null;

    const refund = props.KwhRefundContracts;
    if( refund ){
        refund.sort( (a,b) => {
            return a.id - b.id;
        })
          .filter( r => r.ChannelVerificationImportRow )
          .map( r => {
            currentValue = r.ChannelVerificationImportRow.kw;
        });

        if( currentValue )
            currentValue = ' > ' + currentValue;
    }
    return <span className="label label-default">Kwh: {kwhPerYear} {currentValue}</span>
}

class MarketingReportsPlanPage extends React.Component {

    constructor( props ){
        super(props);

        this.state = {
            filterMonth: (moment().month()+1) +'',
            filterYear: moment().year() +'',
            ...queryToObject(),
        };

        if( typeof this.state.filterUsers == 'string' )
            this.state.filterUsers = JSON.parse( decodeURIComponent( this.state.filterUsers ) );

        this.infoRequest = false;
    }

    componentDidUpdate( prevProps ,prevState ){
        if( JSON.stringify( this.getFilters( prevState ) ) != 
            JSON.stringify( this.getFilters( this.state ) ) ){

            this.setState({infos: false});

            if( this.infoRequest && this.infoRequest.abort )
                this.infoRequest.abort();

            this.infoRequest = fetch( 'api' , 'MarketingReports' , 'reportInfo' , { filters: this.getFilters( this.state ) } ).then( msg => {
                this.setState({infos: msg.body});
            });

        }

        if( prevState.filterType == 'perAgent' && this.state.filterType == 'fixed' )
            this.setState({filterUsers: []});
    }

    getFilters( from ){
        return {
            name: from.filterName,
            month: from.filterMonth - 1,
            year: from.filterYear,
            type: from.filterType,
            users: from.filterUsers,
        };
    }
    render(){

        const canFixed = can('MarketingReports.viewFixedReports');
        const canUser = can('MarketingReports.viewUsersReports');
    
        const columns = { 
            name: 'Nome piano' , 
            selles: 'Mese rif.' ,
            marketingValueEur: 'Totale',
            actions: 'Azioni'
        };
      
          const row = report => {
            return <tr>
                    <td>{report.MarketingReportPlanFilter ? 
                        report.MarketingReportPlanFilter.name : '-'}</td>
                    <td>{moment().month(report.month).format('MMMM')} {report.year}</td>
                    <td className="text-right">{formatEur( report.totalEur )}</td>
                    <td className="text-right"><a href={"/marketing_report/"+report.id} className="btn btn-default btn-xs">Apri</a></td>
                </tr>
            };
      
          return <React.Fragment>
              
              <Breadcumbs title="Piano compensi" items={{'Home': '/'}} />

              <div className="wrapper wrapper-content  animated fadeInRight">
              
                <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">
                        <div className="row">
        
                            <div className="col-lg-4">
                                <FormInputText value={this.state.filterName}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="Filtra per nome"
                                    onChange={se=>{this.setState({filterName: se.target.value})}} />
                            </div>
        
                            <div className="col-lg-2">
                                <FormInputSelect value={this.state.filterMonth}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="Mese riferimento"
                                    values={{
                                        '1': 'Gennaio',
                                        '2': 'Febbraio',
                                        '3': 'Marzo',
                                        '4': 'Aprile',
                                        '5': 'Maggio',
                                        '6': 'Giugno',
                                        '7': 'Luglio',
                                        '8': 'Agosto',
                                        '9': 'Settembre',
                                        '10': 'Ottobre',
                                        '11': 'Novembre',
                                        '12': 'Dicembre',
                                    }}
                                    onChange={se=>this.setState({filterMonth: se})} />
                            </div>
        
                            <div className="col-lg-2">
                                <FormInputYear value={this.state.filterYear}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="Anno riferimento"
                                    onChange={se=>this.setState({filterYear: se})} />
                            </div>

                            {(canFixed && canUser) && <div className="col-lg-2">
                                <FormInputSelect value={this.state.filterType}
                                    direction={FormInputContainer.HORIZONTAL}
                                    label="Tipo report"
                                    values={{
                                        'fixed' : 'Azienda',
                                        'perAgent' : 'Utenti',
                                    }}
                                    onChange={se=>this.setState({filterType: se})} />
                            </div>}

                            { (this.state.filterType == 'perAgent' && can('MarketingReports.viewOtherUsersReports') ) && <div className="col-lg-6">
                                <UsersMultiSelect value={this.state.filterUsers}
                                    label="Utenti" isMulti
                                    direction={FormInputContainer.HORIZONTAL}
                                    onChange={se=>this.setState({filterUsers: se})} />
                            </div> }
        
                        </div>
                        </div>
                    </div>
                </div>
                </div>

                { !this.state.infos ? <div className="col-sm-12">
                    <i className="fa fa-spinner fa-pulse"></i> Caricamento totali
                </div> : <div>
                    <div className="col-sm-12">
                    <ul class="list-group clear-list m-t">
                            <li class="list-group-item fist-item">
                                <span class="pull-right">
                                    &nbsp;
                                </span>
                                <span class="label label-success">
                                    {this.state.infos.total}</span> 
                                &nbsp; Contratti totali
                            </li>
                        </ul>
                        
                    </div>
                </div>}
        
                { this.state.loading ? <Loader /> : <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">
                        <AbstractTableViewer model="MarketingReports" 
                            filters={this.getFilters(this.state)}
                            row={row}
                            columns={columns}/>
                        </div>
                    </div>
                </div>
                </div> }
        
        
            </div>
          </React.Fragment>;
        }
}

class AgentReportRow extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            alteredRows: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( !prevState.editRowConfirm && this.state.editRowConfirm ){
            const payload = {
                MarketingReportRowContractId: this.state.editRow,
                valueEur: this.state.editRowValue,
                op: 'editPrice'
            };

            fetch( 'api' , 'MarketingReports' , 'alterRow' , payload ).then( msg => {
                if( msg.body.result ){
                    let alteredRows = this.state.alteredRows;
                    alteredRows.push( msg.body.row );
                    this.setState({
                        alteredRows: alteredRows,
                        MarketingReportRowContractId: false,
                        valueEur: false,
                        op: false,
                    } );
                }
            })
        }
    }

    render() {
        const props = this.props;
        const row = props.row;

        const validRows = row.MarketingReportRowContracts.filter(contractRow => {
            return contractRow.Contract;
        });

        if (!validRows.length)
            return null;

        const total = validRows.filter(contractRow => {
            return contractRow.Contract.ContractState.isClosingState == 'positive';
        }).reduce((acc, curr) => {
            return acc + curr.valueEur;
        }, 0);

        const totalPayed = validRows.reduce((acc, curr) => {
            return curr.payedAt ? acc + curr.valueEur : acc;
        }, 0);

        return <BoxContainer title={row.description}>
            <Row>
                <div className="col-lg-12">
                    <table className="table">
                        <thead>
                        <tr>
                            <th width="10%">Codice</th>
                            <th width="40%">Cliente</th>
                            <th width="10%">Stipula</th>
                            <th width="20%">Stato</th>
                            <th width="5%">Valore</th>
                            <th width="5%">Pagato</th>
                            <th width="10%"></th>
                        </tr>
                        </thead>
                        <tbody>
                        {validRows.map(contractRow => {

                            let altered = this.state.alteredRows.filter( row => {
                                return row.id == contractRow.id;
                            });

                            if( altered.length )
                                contractRow = altered[0];

                            let dat_stip = '-';

                            try {
                                dat_stip = moment(JSON.parse(contractRow.Contract.extraJsonData).dat_stip_contratto)
                                  .format('DD/MM/YY');
                            } catch (e) {
                            }

                            try {
                                return <tr>
                                    <td>{contractRow.Contract.uniqueUuid}</td>
                                    <td>{contractRow.Contract.Lead ?
                                      <Link to={'/leads_list/detail/' + contractRow.Contract.Lead.id}>
                                          <span>{formatLeadName(contractRow.Contract.Lead)}</span>
                                      </Link> : '-'}</td>
                                    <td>{dat_stip}</td>
                                    <td><ContractStateViewer contract={contractRow.Contract}/></td>
                                    <td>
                                        {this.state.editRow == contractRow.id ?
                                          <>
                                              <input className={"form-control"} value={this.state.editRowValue}
                                                     onChange={se=>{this.setState({editRowValue: se.target.value})}}
                                                     style={{fontSize: '8pt', display: 'inline', width: '100px'}} />
                                              <button className={"btn btn-default btn-xs"} onClick={se => {
                                                  se.preventDefault();
                                                  this.setState({editRowConfirm: true})
                                              }}>Salva</button>
                                          </> :
                                          <>{contractRow.Contract.ContractState.isClosingState == 'positive' ?
                                            formatEur(contractRow.valueEur) : '-'}</> }
                                    </td>
                                    <td>{contractRow.payedAt ? formatEur(contractRow.valueEur) : 'No'}</td>
                                    <td>{can('MarketingReports.alterRows') && <>
                                        <a className={"btn btn-default btn-xs"} onClick={se => {
                                            se.preventDefault();
                                            this.setState({editRowValue: contractRow.valueEur, editRow: contractRow.id})
                                        }}><i className={"fa fa-pencil"}></i> </a>
                                        <a className={"btn btn-danger btn-xs"} onClick={se => {
                                            se.preventDefault();
                                            this.setState({deleteRow: contractRow.id})
                                        }}><i style={{color:'white'}} className={"fa fa-times"}></i> </a>
                                    </>}</td>
                                </tr>;
                            } catch (e) {
                            }

                            return <tr>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>;
                        })}

                        </tbody>
                        <tfoot>
                        <tr>
                            <th></th>
                            <th>Totale</th>
                            <th></th>
                            <th></th>
                            <th>{formatEur(total)}</th>
                            <th>{formatEur(totalPayed)}</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </Row>
        </BoxContainer>;
    }
}

class UsersPaymentReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            filterMonth: moment().subtract(1,'month').month() + '',
            filterYear: moment().subtract(1,'month').year() + '',

        };

        this.reloadData();

        this.onExport = this.onExport.bind( this );
    }

    onExport( se ){
        se.preventDefault();

        let payload = { ...this.state , export: 1 };

        fetch( 'api' , 'MarketingReports' , 'usersPaymentReport' , payload ).then( msg => {
            window.location = `${window.location.protocol}//${window.location.hostname}/api/Files/download/${msg.body.id}/${msg.body.filename}`
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.state.filterMonth != prevState.filterMonth || this.state.filterYear != prevState.filterYear )
            this.reloadData();
    }

    reloadData(){

        this.setState({loading: true});

        fetch( 'api' , 'MarketingReports' , 'usersPaymentReport' , this.state ).then( msg => {
            this.setState({
                data: msg.body,
                loading: false,
            })
        });
    }

    render(){
        return <React.Fragment>
            <Breadcumbs title="Report pagamenti utenti" items={{'Home': '/'}} />

            <div className="wrapper wrapper-content  animated fadeInRight">

                <div className={"row"}>
                    <div className={"col-12"}>
                        <a href={"#"} className={"btn btn-primary"} onClick={this.onExport}>Esporta CSV</a>
                    </div>
                </div>

                <BoxContainer title={"Filtri"}>
                    <div className={"row"}>
                        <div className={"col-lg-3"}>
                            <FormInputMonth value={this.state.filterMonth}
                                            onChange={se => this.setState({filterMonth: se})}/>
                        </div>

                        <div className="col-lg-2">
                            <FormInputYear value={this.state.filterYear}
                                           onChange={se => this.setState({filterYear: se})}/>
                        </div>
                    </div>
                </BoxContainer>

                <BoxContainer>
                    {this.state.loading && <Loader />}
                    {!this.state.loading && <React.Fragment>
                        <table className={"table table-striped"}>
                            <thead>
                            <tr>
                                <th>Utente</th>
                                <th>Pagato</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                                {this.state.data.map( row => {
                                    return <tr>
                                        <td>{formatLeadName( row )}</td>
                                        <td>{formatEur( row.totalEur ? row.totalEur : 0 )}</td>
                                        <td>

                                        </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </React.Fragment>}
                </BoxContainer>
            </div>
        </React.Fragment>;
    }

}

class AgentReport extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            filterMonth: (moment().month()) + '',
            filterYear: moment().year() + '',
            filterUsers: [],
            contractPayedModal: false
        };

        this.updateData();
    }

    updateData(){
        this.setState({loading: true});

        const payload = {
            filterMonth: this.state.filterMonth,
            filterYear: this.state.filterYear,
            filterUsers: this.state.filterUsers,
        };

        fetch( 'api' , 'MarketingReports' , 'agentReport' , payload ).then( msg => {
            this.setState({loading: false, data: msg.body});
        });
    }

    componentDidUpdate(prevProps,prevState){
        if( prevState.filterMonth != this.state.filterMonth ||
            prevState.filterUsers != this.state.filterUsers ||
          prevState.filterYear != this.state.filterYear ){
            this.updateData();
        }
      }

    render(){

        const totalPayed = this.state.data ? this.state.data.rows.reduce( (acc,cur) => {

            if( !cur.ModelName ){
                // Riga inserita manualmente

                return acc + cur.valueEur;
            }

            return acc + cur.MarketingReportRowContracts.filter( contractRow => {
                return contractRow.Contract && contractRow.payedAt;
            } ).reduce( (acc,curr) => {
                return acc + curr.valueEur;
            } , 0 );
        }, 0) : 0;

        return <React.Fragment>
            <Breadcumbs title="Report agente" items={{'Home': '/'}} />

            <div className="wrapper wrapper-content  animated fadeInRight">

                {this.state.data && 
                    <div className="row">
                        <div className="col-lg-12">
                            <BoxContainer>
                                <Row>
                                    <div className="col-lg-2">
                                        <FormInputMonth value={this.state.filterMonth}
                                            onChange={se=>{this.setState({filterMonth: se});}} />
                                    </div>
                                    <div className="col-lg-2">
                                        <FormInputYear value={this.state.filterYear}
                                            onChange={se=>{this.setState({filterYear: se});}} />
                                    </div>
                                    { can('MarketingReports.agentReportAll') && <div className="col-lg-4">
                                        <UserSelect value={this.state.filterUsers} 
                                            direction={FormInputContainer.HORIZONTAL}
                                            label="Utenti provvigionati"
                                            onChange={se=>this.setState({
                                                filterUsers: se})} />
                                    </div>}

                                    <div className="col-lg-2">
                                        <a href={`${window.location.protocol}//${window.location.hostname}/api/MarketingReports/agentReportExport?uid=${this.state.filterUsers ? this.state.filterUsers.id : 0}&month=${this.state.filterMonth}&year=${this.state.filterYear}&logintoken=${LocalCache.get( 'loginToken')}`} className="btn btn-primary" target="_blank" >
                                            Scarica PDF</a>

                                        {can( 'MarketingReports.downloadXlsx') && <a href={`${window.location.protocol}//${window.location.hostname}/api/MarketingReports/agentReportExport?uid=${this.state.filterUsers ? this.state.filterUsers.id : 0}&month=${this.state.filterMonth}&year=${this.state.filterYear}&logintoken=${LocalCache.get( 'loginToken')}&format=xlsx`} className="btn btn-primary" target="_blank" >
                                            Scarica XLSX</a> }
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className="col-sm-12">
                            {this.state.loading && <Loader />}
                            {!this.state.loading && <React.Fragment>

                                {this.state.contractPayedModal && <Modal onClose={se=>{this.setState({contractPayedModal: false})}}>
                                    <h4>Contratti pagati in {this.state.contractPayedModal.date}</h4>

                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Data Stipula</th>
                                                <th>Codice</th>
                                                <th>Valore</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.contractPayedModal.contracts.map( contract => {
                                                return <tr>
                                                    <td>{formatLeadName( contract.Lead )}</td>
                                                    <td>{moment(contract.createdAt).format('DD/MM/YY')}</td>
                                                    <td>{contract.uniqueUuid}</td>
                                                    <td>{formatEur( contract.valueEur )}</td>
                                                </tr>;
                                            })}

                                            {this.state.contractPayedModal.manuals.map( manual_row => {

                                                return <tr>
                                                    <td>{manual_row.description}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>{formatEur( manual_row.valueEur )}</td>
                                                </tr>;
                                            })}
                                        </tbody>
                                    </table>
                                </Modal>}

                                {this.state.data && this.state.data.test && <Row>
                                    <div className={"col-md-12"}>
                                        {this.state.data.test.missing && <>
                                            <BoxContainer title={"Verifica pagamento contratti"} style={{border: '2px dashed orange'}}>
                                                <table className="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th>Contratto</th>
                                                        <th>Data Ins. sistema</th>
                                                        <th>Stato</th>
                                                        <th>Non presente in</th>
                                                        <th></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.data.test.missing.map( missing => {
                                                        return <tr>
                                                            <td>{missing.contract.uniqueUuid}</td>
                                                            <td>{ missing.contract.ContractsHistories_state_30 ?
                                                              moment(missing.contract.ContractsHistories_state_30).format('YYYY-MM-DD') :
                                                              '-'
                                                            }</td>
                                                            <td><ContractStateViewer contract={missing.contract} /></td>
                                                            <td>{missing.side == 'ContractsExploded' ? 'CONTRATTI' : 'REPORT'}</td>
                                                            <td></td>
                                                        </tr>;
                                                    })}
                                                    </tbody>
                                                </table>
                                            </BoxContainer>
                                        </>}
                                    </div>
                                </Row>}

                                <Row>

                                    {/*<div className="col-lg-3">
                                        <div class="widget style1 navy-bg">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <i class="fa fa-eur fa-5x"></i>
                                                </div>
                                                <div class="col-md-8 text-right">
                                                    <span> Totale </span>
                                                    <h2 class="font-bold">{formatEur(total)}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>*/}

                                    <div className="col-lg-6">
                                        <BoxContainer title="Storico pagamenti">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Data</th>
                                                        <th># Contratti</th>
                                                        <th>Totale</th>
                                                    </tr>
                                                </thead>
                                                { this.state.data.payedHistory && <tbody>
                                                    {this.state.data.payedHistory.map( ph => {
                                                        return <tr>
                                                            <td>
                                                                <a onClick={se=>{this.setState({contractPayedModal: ph})}}>
                                                                <i className="fa fa-eye"></i>&nbsp;
                                                                {moment( ph.date ).format( 'DD/MM/YY' )}
                                                                </a></td>
                                                            <td>{ph.count}</td>
                                                            <td>{formatEur( ph.valueEur ) }</td>
                                                        </tr>;
                                                    })}
                                                    
                                                </tbody> }
                                            </table>
                                        </BoxContainer> 
                                    </div>

                                    <div className="col-lg-3">
                                        <div className="widget style1 navy-bg">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <i className="fa fa-eur fa-5x"></i>
                                                </div>
                                                <div className="col-md-8 text-right">
                                                    <span> Totale pagato </span>
                                                    <h2 className="font-bold">{formatEur(totalPayed)}</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12">

                                        {this.state.data.rows.map( row => {
                                            return <AgentReportRow row={row} />;
                                        })}

                                        {this.state.data.others.length > 0 && <React.Fragment>
                                            <BoxContainer title={"Altro"}>
                                                <div className="col-lg-12">
                                                    <table className="table">
                                                        <thead>
                                                        <tr>
                                                            <th width="60%">Descrizione</th>
                                                            <th width="20%">Valore</th>
                                                            <th width="20%">Pagato</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.data.others.map( other => {
                                                                return <tr>
                                                                    <td>{other.description}</td>
                                                                    <td>{formatEur( other.valueEur )}</td>
                                                                    <td>{other.payedAt ? formatEur(other.valueEur) : 'No'}</td>
                                                                </tr>;
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </BoxContainer>
                                        </React.Fragment>}
                                    </div>

                                </Row>
                            
                            </React.Fragment>}
                        </div>
                    </div>
                }

            </div>
        </React.Fragment>;
        
    }
}

export { MarketingPlans, MarketingReport , MarketingReportsPlanPage, AgentReport, MarketingReportsSelect,
     MarketingReportPlanFiltersSelect, MarketingPlanFilterBreadcumbs, MarketingReport_ContractInspector,
    UsersPaymentReport, MarketingPlansToPayReport };