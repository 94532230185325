import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputSelect, FormInputDate
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, formatDateTime, FileUploadInput, formatDate
} from './AppCommon';
import {formatLeadName} from "./Leads";
import {ContractStateSelect, ContractStateViewer} from "./ContractStates";
import {AdvancedProductSelect, ProductSelect} from "./Products";
import {NotesEditor} from "./Notes";
import {EnelxSellsStatesSelect} from "./EnelxSellsStates";
import {UserSelect} from "./Users";
import {TagsSelect} from "./Tags";
import {EnelxInstallerSelect, EnelxSellModal} from "./EnelxSells";
import {showExportModal} from "./Exports";
import {EnelxSellsSubStatesSelect} from "./EnelxSellsSubStates";

class EnelxSellInstallationsStatesSelect extends React.Component {
  render(){
    return <React.Fragment>

    </React.Fragment>;
  }
}

class EnelxSellInstallations extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      installerModal: false,
      reports: false,

      filterLeadName: '',
      filterResourceUsers: [],
      filterEnelxInstaller: [],
      filterInChargeUsers: [],

      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
    };

  }

  componentDidMount(){
  }

  getFilters(){
    return {
      contractStates: this.state.filterContractStates,
      EnelxSellsState: this.state.filterEnelxSellsState,
      EnelxSellsSubStates: this.state.filterEnelxSellsSubStates,
      leadName: this.state.filterLeadName.length >= 3 ? this.state.filterLeadName : null ,
      ResourceUsers: this.state.filterResourceUsers.map( u => { return u.id }),
      ResourceUserTags: this.state.filterResourceTags,
      InChargeUsers: this.state.filterInChargeUsers.map( u => { return u.id }),
      dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
      dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm'),
      EnelxInstaller: this.state.filterEnelxInstaller.map( u => { return u.id }),
      paymentType: this.state.filterPaymentType,
      products: this.state.filterProducts,
      tags: this.state.filterTags,
      origin: this.state.filterOrigin,
      telephone: this.state.filterTelephone,
      leadCity: this.state.filterLeadCity,
      update: this.state.update,
      businessType: this.state.filterBusinessType,
      filterContract: this.state.filterContract,
      cacheIds: this.state.cacheIds,
    };
  }

  render(){

    const columns = {
      state: 'Stato',
      name: 'Nome' ,
      contract: 'Prodotto' ,
      /*onSiteInspectionAt: 'Sopralluogo',
      installationAt: 'Installazione',
      merce: 'Merce',*/
      leadCity: 'Citta',
      orCodes: 'OR',
      systemCloseAt: {
        sortBy: 'systemCloseAt',
        label: 'Chiusura al sistema',
      },
      installationDate: {
        sortBy: 'installationDate',
        label: 'Data installaz.',
      } ,
      actions: 'Azioni'
    };

    const row = installation => {

      let products = '-';

      try {
        products = installation.Products.map( p => {
          return p.name;
        }).join(', ');
      } catch ( e ){}

      return <tr>
        <td>
          { installation.ContractState ?
            <ContractStateViewer contract={{
                state: installation.ContractState.name,
                ContractState: {
                  type: installation.ContractState.type
                }
            }} />
            : '-' }
        </td>
        <td>{ installation.Lead ? formatLeadName( installation.Lead ) : '-'}</td>
        <td style={{fontSize:'8pt'}}>{products}</td>
        <td>{installation.Lead? installation.Lead.city : ''}</td>
        {/*<td>
          { installation.EnelxSell ? installation.EnelxSell.onSiteInspectionAt ?
            moment(installation.EnelxSell.onSiteInspectionAt).format('DD/MM/YYYY')
          : '-' : '-'}
        </td>
        <td></td>
        <td></td>*/}
        <td>{installation.EnelxSell ?
          installation.EnelxSell.Contracts.map( c => {
            return c.uniqueUuid
          }).join( ' / ')
          : ''}</td>
        <td>{formatDate( installation.systemCloseAt )}</td>
        <td>{formatDate( installation.installationDate )}</td>
        <td>
          <div class="btn-group">
            {can('EnelxSellInstallations.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({installerModal: installation.id});
            }}>Vedi</button> }
            {installation.EnelxSell && <button className="btn-white btn btn-xs" onClick={se => {
              se.preventDefault();
              this.setState({enelxSellModal: installation.EnelxSell.id});
            }}>Vendita</button>}
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Installazioni" items={{'Home': '/', 'Enelx': '/enelx'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className={"row mb-4"}>
          <div className={"col-12"}>
            <div className="btn-group">
              {can('EnelxSellInstallations.save') && <button className="btn-primary btn" onClick={se => {
                se.preventDefault();
                this.setState({installerModal: true});
              }}>Nuova installazione</button>}

              {can('EnelxSellInstallations.export') && <button className="btn btn-primary" onClick={se => {
                se.preventDefault();
                showExportModal( 'EnelxSellInstallations' , this.getFilters() );
              }} style={{marginLeft: '0px'}}>{'Esporta Excel'}</button>}
            </div>
          </div>
        </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className="row">

                <div className={"col-4"}>
                  <ContractStateSelect value={this.state.filterContractStates}
                                       parentEntityType={"enelxSellInstallation"}
                                       direction={FormInputContainer.HORIZONTAL}
                                       label={"Filtro stato installazione"}

                                       isMulti={true}
                                       onChange={se => {
                                         this.setState({
                                           filterContractStates: se,
                                         });
                                       }}/>

                </div>

                <div className={"col-4"}>
                  <EnelxSellsStatesSelect
                    label={"Filtro stato vendita"}
                    isMulti
                    all={true}
                    direction={FormInputContainer.HORIZONTAL}
                    value={this.state.filterEnelxSellsState}
                    onChange={se=>this.setState({filterEnelxSellsState: se})} />
                </div>

                <div className={"col-4"}>
                  <EnelxSellsSubStatesSelect
                    label={"Filtro sottostato vendita"}
                    isMulti
                    all={true}
                    direction={FormInputContainer.HORIZONTAL}
                    value={this.state.filterEnelxSellsSubStates}
                    onChange={se=>this.setState({filterEnelxSellsSubStates: se})} />
                </div>

                <div className={"col-4"}>
                  <EnelxInstallerSelect value={this.state.filterEnelxInstaller}
                                        onChange={se => {
                                          this.setState({filterEnelxInstaller: se ? se : []})
                                        }} label={"Installatore"} isMulti canCreate={false} />
                </div>

                <div className={"col-4"}>
                  <FormInputSelect value={this.state.filterOrigin}
                                   direction={FormInputContainer.HORIZONTAL}
                                   label="Origine"
                                   values={{
                                     'all': 'Tutte',
                                     'Risorsa': 'Risorsa',
                                     'Social': 'Social',
                                     'Altro': 'Altro',
                                   }}
                                   onChange={se => this.setState({filterOrigin: se})}/>
                </div>

                <div className={"col-4"}>
                  <FormInputSelect value={this.state.filterBusinessType}
                                   direction={FormInputContainer.HORIZONTAL}
                                   label="Settore business"
                                   values={{
                                     '': 'Tutti',
                                     'B2C': 'B2C',
                                     'B2B': 'B2B',
                                     'Microbusiness': 'Microbusiness',
                                     'Rivenerg' : 'Rivenerg'
                                   }}
                                   onChange={se => this.setState({filterBusinessType: se})}/>
                </div>

                <div className={"col-4"}>
                  <UserSelect value={this.state.filterResourceUsers}
                              onChange={se => {
                                this.setState({filterResourceUsers: se ? se : []})
                              }} label={"Risorsa lead"} isMulti />
                </div>

                <div className={"col-4"}>
                  {can('Users.tags') &&
                  <TagsSelect value={this.state.filterResourceTags}
                              entityName={"Users"} label={"Tag Risorsa lead"}
                              direction={FormInputContainer.HORIZONTAL}
                              onChange={se => this.setState({
                                filterResourceTags: se ? se : []
                              })}/>
                  }
                </div>

                <div className={"col-4"}>
                  <UserSelect value={this.state.filterInChargeUsers}
                              onChange={se => {
                                this.setState({filterInChargeUsers: se ? se : []})
                              }} label={"Assegnatario"} isMulti />
                </div>

                <div className={"col-4"}>
                  <FormInputText label={"Nome lead"}
                                 direction={FormInputContainer.HORIZONTAL}
                                 value={this.state.filterLeadName}
                                 onChange={se=>{this.setState({filterLeadName: se.target.value})}} />
                </div>

                <div className="col-lg-2">
                  <FormInputDate value={this.state.filterDateStart}
                                 key="sdate"
                                 label="Data inizio"
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => this.setState({filterDateStart: se})}/>
                </div>

                <div className="col-lg-2">
                  <FormInputDate value={this.state.filterDateEnd}
                                 key="edate"
                                 label="Data fine"
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => this.setState({filterDateEnd: se})}/>
                </div>

                <div className={"col-4"}>
                  <FormInputSelect value={this.state.filterPaymentType}
                                   label="Tipologia pagamento"
                                   values={{
                                     '0': 'Tutti' ,
                                     'Non definito': 'Non definito' ,
                                     'Bonifico': 'Bonifico' ,
                                     'Finanziaria': 'Finanziaria'
                                   }}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>{this.setState({filterPaymentType: se})}} />
                </div>

                <div className={"col-4"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterTelephone: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Telefono lead"
                    value={this.state.filterTelephone}/>
                </div>

                <div className={"col-4"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterLeadCity: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Città lead"
                    value={this.state.filterLeadCity}/>
                </div>

                <div className={"col-4"}>
                  {can('Contracts.filterProducts') &&
                  <ProductSelect value={this.state.filterProducts}
                                 direction={FormInputContainer.HORIZONTAL}
                                 hideCategories={true}
                                 isMulti
                                 all={true}
                                 onChange={se => this.setState({
                                   filterProducts: se ? se : []
                                 })}/>
                  }
                </div>

                <div className={"col-4"}>
                  {can('Products.tags') &&
                  <TagsSelect value={this.state.filterTags}
                              entityName={"Products"}
                              direction={FormInputContainer.HORIZONTAL}
                              onChange={se => this.setState({
                                filterTags: se ? se : []
                              })}/>
                  }
                </div>

                <div className={"col-4"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterContract: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Codice contratto"
                    value={this.state.filterContract}/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="EnelxSellInstallations"
                                       filters={this.getFilters()}
                                       defaultSort={[]}
                                       row={row}
                                       columns={columns}/>
                </div>
              </div>
            </div>
          </div> }
        </div>

      </div>

      {this.state.installerModal && <EnelxSellInstallerModal onDone={res=>{
        this.setState({installerModal: false});
      }} id={this.state.installerModal === true ? false: this.state.installerModal} />}

      {this.state.enelxSellModal && <EnelxSellModal onDone={res=>{
        this.setState({enelxSellModal: false});
      }} id={this.state.enelxSellModal === true ? false: this.state.enelxSellModal} />}

    </div>];
  }
}



class EnelxSellInstallerModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      active: 1,
      name: '',
      Products: []
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'EnelxSellInstallations', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'EnelxSellInstallations', this);
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <div className={"row"}>
          <div className={"col-12"}>
            <ContractStateSelect value={this.state.ContractState}
             parentEntityType={"enelxSellInstallation"}
             requiredField={true}
             direction={FormInputContainer.HORIZONTAL}
             onChange={se => {
               this.setState({
                 ContractState: se,
                 ContractStateId: se ? se.id : null,
                 state: se ? se.name : null
               })
             }}/>
          </div>
        </div>

        <FormDashline />

        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Nome" value={this.state.name}
                             requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="name"
                             onChange={se => this.setState({name: se.target.value})}/>
            </div>
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Cognome" value={this.state.surname}
                             direction={FormInputContainer.HORIZONTAL}
                             requiredField={true}
                             validation={this.state.validation} name="surname"
                             onChange={se => this.setState({surname: se.target.value})}/>
            </div>
            <div className="col-sm-12">
              <FormInputText upperCase={true} label="Ragione sociale" value={this.state.businessName}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="businessName"
                             onChange={se => this.setState({businessName: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Indirizzo" value={this.state.address}
                             validation={this.state.validation} name="address"
                             requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({address: se.target.value})}/>
            </div>
            <div className="col-sm-3">
              <FormInputText upperCase={true} label="Civico" value={this.state.civicNumber}
                             validation={this.state.validation} name="civicNumber"
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({civicNumber: se.target.value})}/>
            </div>
            <div className="col-sm-3">
              <FormInputText upperCase={true} label="CAP" value={this.state.cap}
                             validation={this.state.validation} name="cap" requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({cap: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <FormInputText upperCase={true} label="Codice Fiscale / P.IVA" value={this.state.fiscalCode}
                             validation={this.state.validation} name="fiscalCode" requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({fiscalCode: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Provincia" value={this.state.province}
                             validation={this.state.validation} name="province"
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({province: se.target.value})}/>
            </div>
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Città" value={this.state.city}
                             direction={FormInputContainer.HORIZONTAL}
                             requiredField={true}
                             validation={this.state.validation} name="city"
                             onChange={se => this.setState({city: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Telefono" value={this.state.telephone}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="telephone"
                             requiredField={true}
                             onChange={se => this.setState({telephone: se.target.value})}/>
            </div>
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Telefono Secondario" value={this.state.telephoneSec}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="telephoneSec"
                             onChange={se => this.setState({telephoneSec: se.target.value})}/>
            </div>
          </div>

          <FormInputText label="Email (obbligatorio)" value={this.state.email}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="email"
                         onChange={se => this.setState({email: se.target.value})}/>

          <FormInputRadio label="Chiusura al sistema"
                          checked={this.state.systemCloseAt}
                          direction={FormInputContainer.HORIZONTAL}
                          onClick={se=>{this.setState({
                            systemCloseAt: this.state.systemCloseAt ? null : moment()
                          })}} />

          { this.state.systemCloseAt && <FormInputDate value={this.state.systemCloseAt}
                         direction={FormInputContainer.HORIZONTAL}
                         label="Chiusura al sistema"
                         validation={this.state.validation} name="systemCloseAt"
                         onChange={se=>{this.setState({systemCloseAt: se})}} /> }

          <FormInputRadio label="Data installazione"
                          checked={this.state.installationDate}
                          direction={FormInputContainer.HORIZONTAL}
                          onClick={se=>{this.setState({
                            installationDate: this.state.installationDate ? null : moment()
                          })}} />

          { this.state.installationDate && <FormInputDate value={this.state.installationDate}
                         direction={FormInputContainer.HORIZONTAL}
                         label="Data installazione"
                         validation={this.state.validation} name="installationDate"
                         onChange={se=>{this.setState({installationDate: se})}} /> }

        </React.Fragment>

        <FormDashline />

        <div style={{border: '3px dashed #CCCCCC', padding: '20px'}}>
          <NotesEditor notes={this.state.Notes} onChange={notes => {
            this.setState({Notes: notes})
          }} name={"EnelxSellInstallations"} id={this.state.id} />
        </div>

        <FormDashline />

        <div className={"row"}>
          <div className={"col-12"}>
            <AdvancedProductSelect onChange={Products => {
              this.setState({Products: Products})
            }} throughModelName={"EnelxSellInstallationProducts"}
               values={this.state.Products}/>
          </div>
        </div>

        <FormDashline />

        <label>Allegati</label>
        <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
                         filesId={this.state.filesId} />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

export { EnelxSellInstallations };