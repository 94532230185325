import React from 'react';
import './App.css';
import moment from 'moment';
import './Ddt.css';

import {
    Loader, FormDashline, fetch,
    FormInputText, FormButtons, FormInputContainer,
    Modal, FormInputRadio, BoxContainer, queryToObject
} from './Common.js';

import {
    AbstractTableViewer, can, AbstractApiFormFooter,
    apiFormInit, apiFormSave, formatEur, BrandSelect,
    ApiMultiSelect, ContractTypesSelect, Breadcumbs, ChannelSelect, ChannelDescription
} from './AppCommon';
import {UserSelect} from "./Users";
import {FormInputDate, FormInputSelect} from "./Common";
import {Link} from "react-router-dom";
import {SellingPointSelect} from "./SellingPoints";
import {DdtCategorySelect} from "./DdtCategories";
import {formatLeadName} from "./Leads";

class Ddt extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            ddtEditModal: false,
            reports: false,
        };

    }

    getFilters(){
        return {
            Users: this.state.filterUsers,
            code: this.state.filterCode,
            ddt: this.state.filterDdt,
            empty: this.state.filterEmpty,
            DdtCategoryId: this.state.filterDdtCategoryId,
        };
    }
    updateTotals(){
        fetch('api','Ddt','totals' , {filters: this.getFilters()} ).then( data => {
            this.setState({totals: data.body});
        });
    }

    componentDidMount(){
        this.updateTotals();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( JSON.stringify( this.state) != JSON.stringify( prevState ) ){
            this.updateTotals();
        }
    }

    render(){

        const columns = {
            codeStart: 'Inizio' ,
            codeEnd: 'Fine' ,
            ddt: 'DDT' ,
            category: 'Cat.' ,
            users: 'Utenti',
            warnig: '',
            actions: 'Azioni'
        };

        const row = ddt => {
            return <tr>
                <td>{ddt.codeStart}</td>
                <td>{ddt.codeEnd}</td>
                <td>{ddt.ddt}</td>
                <td>{ddt.DdtCategory ? ddt.DdtCategory.name : '-'}</td>
                <td>{ddt.DdtUsers.map( u => {
                    if( !u.User )
                        return '-';
                    return u.User.name + ' ' + u.User.surname;
                }).join(', ')}</td>
                <td>
                    {ddt.intersections.length > 0 && <span className={"badge badge-danger"}><i className={"fa fa-exclamation"}></i></span> }
                    {ddt.emptyCodeCount > 0 && <span className={"badge badge-warning"}>{ddt.emptyCodeCount} Codici non assegnati</span>}
                </td>

                <td>
                    <div class="btn-group">
                        {can('Ddt.save') && <a class="btn-white btn btn-xs" onClick={se=>{
                            se.preventDefault();
                            this.setState({ddtEditModal: ddt.id});
                        }}>Modifica</a> }

                        {can('Ddt.save') && <a class="btn-white btn btn-xs"
                                               href={"/ddt_detail_view/" + ddt.id}>Vedi</a> }
                    </div>
                </td>
            </tr>
        };



        return [ <Breadcumbs title="Ddt" items={{'Home': '/'}} />,
            <div className="wrapper wrapper-content  animated fadeInRight">

                <div className="row" style={{marginBottom: '15px'}}>
                    { can( 'Ddt.save' ) && <button className="btn btn-primary" onClick={se=>{
                        se.preventDefault();
                        this.setState({ddtEditModal: true});
                    }}>Nuovo Range Codici</button>}
                    { can( 'DdtCategories.save' ) && <Link style={{marginLeft: '10px'}}
                           className="btn btn-primary" to="/ddt_categories">Gestisci categorie</Link>}
                    { can( 'Ddt.save' ) && <Link style={{marginLeft: '10px'}}
                       className="btn btn-primary" to="/ddt_range_view">Visualizzazione range</Link>}
                    { can( 'DdtMissingContracts.save' ) && <Link style={{marginLeft: '10px'}}
                       className="btn btn-primary" to="/ddt_missing">Contratti non assegnati</Link>}
                </div>

                <div className={"row"}>
                    <div className={"col-md-6 col-lg-3"}>
                        <div className="widget style1 navy-bg">
                            <div className="row">
                                <div className="col-md-4">
                                    <i className="fa fa-exclamation fa-5x"></i>
                                </div>
                                <div className="col-md-8 text-right">
                                    <span> Totale codici non assegnati </span>
                                    <h2 className="font-bold">{this.state.totals}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row">

                    <div className="col-sm-12">
                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Range DDT</h5>
                            </div>
                            <div className="ibox-content">
                                <div className="row">

                                    <div className={"col-md-3"}>
                                        <UserSelect value={this.state.filterUsers}
                                            direction={FormInputContainer.HORIZONTAL}
                                            label="Utenti" isMulti
                                            onChange={se=>this.setState({ filterUsers: se })} />
                                    </div>

                                    <div className={"col-md-3"}>
                                        <FormInputText value={this.state.filterDdt}
                                            direction={FormInputContainer.HORIZONTAL}
                                            label="DDT"
                                            onChange={se=>this.setState({ filterDdt: se.target.value })} />
                                    </div>

                                    <div className={"col-md-3"}>
                                        <FormInputText value={this.state.filterCode}
                                             direction={FormInputContainer.HORIZONTAL}
                                             label="Codice contratto"
                                             onChange={se=>this.setState({ filterCode: se.target.value })} />
                                    </div>

                                    <div className={"col-md-3"}>
                                        <FormInputSelect values={{
                                            'all': 'Tutti',
                                            'empty': 'Codici non assegnati',
                                        }} label="Oggetto" value={this.state.filterEmpty}
                                         direction={FormInputContainer.HORIZONTAL}
                                         name="filterEmpty"
                                         onChange={se=>this.setState({filterEmpty: se})} />
                                    </div>

                                    <div className={"col-md-3"}>
                                        <DdtCategorySelect value={this.state.filterDdtCategory}
                                           direction={FormInputContainer.HORIZONTAL}
                                           onChange={se => this.setState({filterDdtCategory: se,filterDdtCategoryId: se?se.id:null})}/>
                                    </div>

                                </div>
                                
                                { this.state.loading ? <Loader /> :

                                    <AbstractTableViewer model="Ddt"
                                         filters={this.getFilters()}
                                         row={row}
                                         columns={columns}/>
                                }
                            </div>
                        </div>
                     </div>
                </div>

                {this.state.ddtEditModal && <NewDdtModal onDone={res=>{
                    this.setState({ddtEditModal: false});
                }} id={this.state.ddtEditModal === true ? false: this.state.ddtEditModal} />}



            </div>];
    }
}

class NewDdtModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            Users:[],
            intersections: [],
            errors: false,
            loading: props.id > 0 ? true : false,
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
        apiFormInit( 'Ddt', this );
    }

    onSubmit(se){
        se.preventDefault();
        apiFormSave( 'Ddt', this);
    }


    render(){

        if( this.state.loading )
            return <Modal>
                <Loader />
            </Modal>;

        let ddtUsersErrors = false;

        try {
            ddtUsersErrors = this.state.DdtUsers.filter( u=>{return u.errorMessage}).length > 0;
        } catch ( e ){}

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <FormInputText label="Codice iniziale" value={this.state.codeStart}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="codeStart"
                               onChange={se=>this.setState({codeStart: se.target.value})} />

                <FormInputText label="Codice finale" value={this.state.codeEnd}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="codeEnd"
                               onChange={se=>this.setState({codeEnd: se.target.value})} />

                <FormInputText label="DDT" value={this.state.ddt}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="ddt"
                               onChange={se=>this.setState({ddt: se.target.value})} />

                <DdtCategorySelect value={this.state.DdtCategory}
                                direction={FormInputContainer.HORIZONTAL}
                                onChange={se => this.setState({DdtCategory: se,DdtCategoryId: se?se.id:null})}/>

                <FormDashline />

                {this.state.intersections.length > 0 &&
                    <div className="alert alert-danger" role="alert">
                        Questo range interseca con : {this.state.intersections.map( intersection => {
                            return intersection.ddt;
                        }).join( ', ')}
                    </div>
                }

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             saveEnable={!this.state.errors && !ddtUsersErrors }
                             onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

            </form>
        </Modal>;
    }

}

function DdtMissingContracts({}){

    const [ missingFilterDateStart , setMissingFilterDateStart ] = React.useState( moment().startOf('year') );
    const [ missingFilterDateEnd , setMissingFilterDateEnd ] = React.useState( moment().endOf('year') );
    const [ missingFilterChannel , setMissingFilterChannel ] = React.useState( null );
    const [ missingFilterUniqueUuid , setMissingFilterUniqueUuid ] = React.useState( null );

    const missingContractsColumns = {
        uniqueUuid: 'ID Contratto',
        createdAt: 'Data inserimento',
        channel: 'Canale'
    };

    const missingContractsRow = contract => {
        return <tr>
            <td>{contract.uniqueUuid}</td>
            <td>{moment(contract.createdAt).format('DD/MM/YY HH:mm')}</td>
            <td>{contract.ChannelName}</td>
        </tr>
    };

    return [ <Breadcumbs title="Ddt" items={{'Home': '/'}} />,
        <div className="wrapper wrapper-content  animated fadeInRight">

            <div className="row">

                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-title">
                            <h5>Contratti non compresi nei range</h5>
                        </div>
                        <div className="ibox-content">
                            <div className="row">

                                <div className="col-lg-2">
                                    <FormInputDate value={missingFilterDateStart}
                                                   key="sdate"
                                                   label="Data inizio"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => setMissingFilterDateStart(se)}/>
                                </div>

                                <div className="col-lg-2">
                                    <FormInputDate value={missingFilterDateEnd}
                                                   key="edate"
                                                   label="Data fine"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => setMissingFilterDateEnd(se)}/>
                                </div>

                                <div className="col-lg-2">
                                    <ChannelSelect value={missingFilterChannel}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   all={true}
                                                   additionalValues={[{id: -1, Brand: {name: ''}, name: 'Senza canale'}]}
                                                   onChange={se => setMissingFilterChannel(se)}/>
                                </div>

                                <div className="col-lg-2">
                                    <FormInputText label="ID Contratto" value={missingFilterUniqueUuid}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => setMissingFilterUniqueUuid(se.target.value)}/>
                                </div>

                            </div>

                            <div className={"row"}>
                                <div className="col-md-12 col-lg-12">
                                      <AbstractTableViewer model="DdtMissingContracts"
                                                           filters={{
                                                               channel: missingFilterChannel,
                                                               dateStart: missingFilterDateStart,
                                                               dateEnd: missingFilterDateEnd,
                                                               uniqueUuid: missingFilterUniqueUuid,
                                                           }}
                                                           row={missingContractsRow}
                                                           columns={missingContractsColumns}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


        </div>
    ];
}

class DdtDetailModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            Users:[],
            intersections: [],
        };

        if( this.props.rangeStart )
            this.state.rangeStart = this.props.rangeStart;
        if( this.props.rangeEnd )
            this.state.rangeEnd = this.props.rangeEnd;

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
        apiFormInit( 'DdtUsers', this );

        if( this.props.rangeStart )
            this.setState({ rangeStart : this.props.rangeStart } );
        if( this.props.rangeEnd )
            this.setState({ rangeEnd : this.props.rangeEnd } );

    }

    onSubmit(se){
        se.preventDefault();

        apiFormSave( 'DdtUsers', this , {
            ...this.state,
            DdtId: this.props.DdtId,
        });
    }


    render(){

        if( this.state.loading )
            return <Modal>
                <Loader />
            </Modal>;

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <UserSelect value={this.state.User}
                            direction={FormInputContainer.HORIZONTAL}
                            label="Utente assegnato"
                            onChange={se=>this.setState({ User: se , UserId: se.id })} />

                <FormInputText label="Codice iniziale" value={this.state.rangeStart}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="rangeStart"
                               onChange={se=>this.setState({rangeStart: se.target.value})} />

                <FormInputText label="Codice finale" value={this.state.rangeEnd}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="rangeEnd"
                               onChange={se=>this.setState({rangeEnd: se.target.value})} />

                <FormDashline />

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             saveEnable={!this.state.errors }
                             onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

            </form>
        </Modal>;
    }

}

class DdtDetailView extends React.Component {

    constructor(props){
        super( props );

        this.state = {
            loading: true,
        };

        this.updateData();
    }

    onDelete( id ){
        fetch( 'api' , 'DdtUsers' , 'delete' , id ).then( msg => {

        });
    }

    updateData(){
        if( !this.props.id )
            return;

        fetch( 'api' , 'Ddt' , 'get' , this.props.id ).then( msg => {
            this.setState({
                loading: false,
                ...msg.body,
            });
        });
    }

    alterRows( rows ){
        return rows;
    }

    render(){

        if( this.state.loading )
            return <Loader />;

        const missingColumns = {
            count: 'Totale #',
            from: 'Da',
            to: 'A',
            actions: ''
        }
        
        const missingRow = row => {

            let count = parseInt( row.end ) - parseInt( row.start ) + 1;

            return <tr>
                <td>{count}</td>
                <td>{row.start}</td>
                <td>{row.end}</td>
                <td>
                    <button className={"btn btn-xs byn-default"}
                        onClick={se=>{
                            se.preventDefault();
                            this.setState({
                                ddtUsersModal: true,
                                ddtUsersModalDdtId: this.state.id,
                                ddtUsersModalStartAt: row.start,
                                ddtUsersModalEndAt: row.end,
                            });
                        }}>
                        Usa</button>
                </td>
            </tr>
        };
        
        const columns = {
            info: '',
            count: 'Totale #',
            id: 'Utente',
            from: 'Da',
            to: 'A',
            actions: ''
        }

        const row = entry => {

            let info = null;

            let count = parseInt( entry.rangeEnd ) - parseInt( entry.rangeStart ) + 1;

            if( parseInt( entry.rangeStart ) > parseInt( entry.rangeEnd ) )
                info = <span className={"label label-danger"}>Range non valido</span>;

            return <tr>
                <td>{info}</td>
                <td>{count}</td>
                <td>{formatLeadName( entry.User )}</td>
                <td>{entry.rangeStart}</td>
                <td>{entry.rangeEnd}</td>
                <td>
                    <div className="btn-group">
                        {can('DdtUsers.save') && <button className="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({ddtUsersModal: entry.id, ddtUsersModalDdtId: this.state.id });
                        }}>Vedi</button>}

                        {can('DdtUsers.delete') && <button className="btn-white btn btn-xs" onClick={se=>{
                            se.preventDefault();
                            this.onDelete( entry.id );
                        }}>Elimina</button>}
                    </div></td>
            </tr>
        };

        return [ <Breadcumbs title={`Dettaglio DDT ${this.state.ddt} (${this.state.codeStart} > ${this.state.codeEnd})`}
                             items={{'Home': '/' , 'Ddt': '/ddt'}} />,
            <div className="wrapper wrapper-content  animated fadeInRight">

                {this.state.ddtUsersModal && <DdtDetailModal onDone={res=>{
                    this.setState({
                        ddtUsersModal: false,
                        ddtUsersModalStartAt: null,
                        ddtUsersModalEndAt: null,
                    });
                }} id={this.state.ddtUsersModal === true ? false: this.state.ddtUsersModal}
                 DdtId={this.state.ddtUsersModalDdtId}
                 rangeStart={this.state.ddtUsersModalStartAt}
                 rangeEnd={this.state.ddtUsersModalEndAt}
                />}

                <div className="row" style={{marginBottom: '15px'}}>
                    { can( 'DdtUsers.save' ) && <button className="btn btn-primary" onClick={se=>{
                        se.preventDefault();
                        this.setState({ ddtUsersModal: true, ddtUsersModalDdtId: this.state.id });
                    }}>Nuovo Range</button>}
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="ibox">
                            <div className={'ibox-title'}>
                                <h5>Range</h5>
                            </div>
                            <div className="ibox-content">
                                <AbstractTableViewer model="DdtUsers"
                                                     alterRows={this.alterRows}
                                                     filters={{ DdtId: this.props.id }}
                                                     row={row}
                                                     columns={columns}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="ibox">
                            <div className={'ibox-title'}>
                                <h5>Range mancanti</h5>
                            </div>

                            <div className="ibox-content">
                                <AbstractTableViewer model="Ddt"
                                                     method={"missingRanges"}
                                                     infoMethod={"missingRangesInfo"}
                                                     alterRows={this.alterRows}
                                                     filters={{ DdtId: this.props.id }}
                                                     row={missingRow}
                                                     columns={missingColumns}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        ];
    }
}

export { Ddt , NewDdtModal , DdtDetailView, DdtMissingContracts };