import React, {useEffect, useState} from 'react';
import './App.css';
import moment from 'moment';
import Dropzone from 'react-dropzone'
import accounting from 'accounting';
import md5 from 'md5';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';

import { Loader , Utils, Route, Link, fetch, FormDashline, fetchHook, FormInputRadio,
  FormInputText, FormButtons, FormInputContainer , BoxContainer, SweetAlert, FormInputCurrency,
  ScrollBasedList, Modal, FormInputSelect, FormInputPassword, FormInputDate, FormInputTime } from './Common.js';
import { isNumber } from 'util';


class FileViewer extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        loading: true,
        info: false,
        safeDelete: false,
      };
  
      this.reloadData();

      this.onChangeInfo = this.onChangeInfo.bind( this );
      this.onSafeDelete = this.onSafeDelete.bind( this );
      this.onRealDelete = this.onRealDelete.bind( this );
    }

    reloadData(){
      this.setState({loading: true});

      if( this.props['id'] > 0 ){
        fetch( 'api' , 'Files' , 'get' , this.props.id ).then( data => {
          this.setState({
            loading: false,
            ...data.body
          });
        });
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if( this.props.id != prevProps.id ){
        this.reloadData()
      }
    }

  onRealDelete( se ){
    se.preventDefault();

    if( this.props.onDelete )
      this.props.onDelete();
  }

  onSafeDelete( se ) {
    se.preventDefault();

    this.setState({
      safeDelete: !this.state.safeDelete
    });
  }

  onChangeInfo( se ){
    se.preventDefault();

    this.setState({
      info: !this.state.info
    });
  }

  render(){

    const downloadFilename = encodeURIComponent( this.state.filename );

    const link = `${window.location.protocol}//${window.location.hostname}/api/Files/download/${this.props.id}/${downloadFilename}`;
    const thumbnailLink = `${window.location.protocol}//${window.location.hostname}/api/Files/thumbnail/${this.props.id}/${this.state.sysFilename}.png`;

    if( this.state.loading )
      return <div className="FileUploadInput_Preview"> <Loader /> </div>;

    const bgImage = `url( ${thumbnailLink} )`;

    return <a href={link}>
      <div className="FileUploadInput_Preview" style={{backgroundImage: bgImage}}>
        {/*<i className={"fa fa-file"} ></i>*/}
      </div>
      <div className="FileUploadInput_PreviewFooter">
        <div className="row">
          <div className="col-sm-12 mb-2">
            <i>{this.state.filename}</i>
            {this.state.info && <p>
              Data di inserimento: {formatDateTime(this.state.createdAt)} <br/>
              Tipo MIME: {this.state.mimeType} <br/>
              Filename interno: {this.state.sysFilename} <br/>
            </p>}
          </div>

          {this.state.safeDelete && <>
            <div className="col-sm-6">
              <a href="#" onClick={this.onRealDelete}>Conferma</a>
            </div>

            <div className="col-sm-6 text-right">
              <a href="#" onClick={this.onSafeDelete}>Annulla</a>
            </div>
          </>}

          {!this.state.safeDelete && <>
            <div className="col-sm-6">
              <a href="#" onClick={this.onChangeInfo}>Info</a>
            </div>

            <div className="col-sm-6 text-right">
              <a href="#" onClick={this.onSafeDelete}>Elimina</a>
            </div>
          </>}

        </div>
      </div>
    </a>;

      const aStyle = {
        color: 'black',
        textDecoration: 'underline',
        cursor: 'pointer'
      };
  
      return <a style={aStyle} href={link}><i class="fa fa-file"></i> {this.state.filename}</a>;
    }
  }
  
  class ChannelDescription extends React.Component {
  
    constructor(props){
      super(props);
      this.state={
        channel: typeof( this.props.channel ) == 'object' ? this.props.channel : false
      };
  
    }
  
    componentDidUpdate( prevProps , prevState ){
      
      if( JSON.stringify( prevProps ) == JSON.stringify( this.props ) )
        return;
    
      if( typeof( this.props.channel ) == 'number' ){
        fetch( 'sqlcommon' , 'channels' , 'get' , this.props.channel ).then( data => {
          this.setState({channel: data.body });
        });
      }
  
      if( typeof( this.props.channelId ) == 'number' ){
        fetch( 'sqlcommon' , 'channels' , 'get' , this.props.channelId ).then( data => {
          this.setState({channel: data.body });
        });
      }
  
    }
  
    render(){
      if( !this.state )
        return '-';
  
      if( !this.state.channel )
        return '-';
  
      return `${this.state.channel.Brand ? this.state.channel.Brand.name : 'No brand'} - ${this.state.channel.name}`;
    }
  }
  
  class FileUploadInput extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        files: [],
        filesId: this.props.filesId ? this.props.filesId : [],
        uploading: false,
        uploadProgress: 0,
        successfullUploaded: false
      };
  
      this.onFilesAdded = this.onFilesAdded.bind(this);
      this.sendRequest = this.sendRequest.bind(this);
      /*this.uploadFiles = this.uploadFiles.bind(this);
      this.sendRequest = this.sendRequest.bind(this);
      this.renderActions = this.renderActions.bind(this);*/
  
    }
  
    onFilesAdded(files) {
      this.setState(prevState => ({
        files: prevState.files.concat(files)
      }));
  
      files.map( file => {
        this.sendRequest(file)
      });    
    }
  
    componentDidUpdate( prevProps, prevState ){
      if( JSON.stringify( prevState.filesId ) !== JSON.stringify( this.state.filesId ) 
        && this.props.onChange )
        this.props.onChange( this.state.filesId );
  
      if( JSON.stringify( prevProps.filesId ) !== JSON.stringify( this.props.filesId ) )
        this.setState({filesId: this.props.filesId });
    }
  
    sendRequest(file) {

      this.setState({
        uploading: true,
        uploadProgress: 0
      });
      
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
    
        const formData = new FormData();
        formData.append("file", file, file.name);
            
        req.upload.onprogress = (event) => {
          this.setState({uploadProgress: Math.ceil( (event.loaded / event.total) * 100 )});
        };

        req.onload = () => {
          if( req.responseText > 0 ){
            this.setState(prevState => {
              return { uploading: false, filesId: prevState.filesId.concat( [ req.responseText ] ) };
            });
          }
        };

        req.open("POST", `${window.location.protocol}//${window.location.hostname}/api/Files/upload`);

        req.send(formData);
  
      });
    }  

    onDelete( file ){
      this.setState({
        filesId: this.state.filesId.filter( fid => { return fid !== file })
      });
    }

    onInfo( file ){
      if( file == this.state.infoOn ){
        this.setState({ infoOn: 0 });
        return;
      }

      this.setState({
        infoOn: file
      });
    }
  
    render(){

      const sprops = {
        showFiles: true,
        ...this.props
      };

      return <div>
  
        <div className="row FileUploadInput">

          {sprops.showFiles && this.state.filesId.map( file => {

            return <React.Fragment>
              <div className="col-sm-6 FileUploadInput">
                <FileViewer id={file} onDelete={()=>{
                  this.onDelete(file)
                }} />
              </div>
            </React.Fragment>;
          })}
        </div>

        {this.state.uploading && <div className="row"> 
          <div className="col-sm-12">
            <i className="fa fa-spin fa-spinner"></i> Caricamento file...
          </div>
          <div className="col-sm-12">
            <div className="progress">
              <div className="progress-bar" role="progressbar" 
                aria-valuenow={this.state.uploadProgress} aria-valuemin="0" 
                aria-valuemax="100" style={{width: this.state.uploadProgress + '%'}}>
              </div>
            </div>
          </div>
        </div>}
        
        { !this.state.uploading && <Dropzone onDrop={this.onFilesAdded}>
          {({getRootProps, getInputProps}) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <p style={{
                  padding: '30px',
                  border: '1px dashed #dadada'
                }}>Trascina un file in questo riquadro oppure clicca qui per caricare un file</p>
              </div>
            </section>
          )}
        </Dropzone> }
      </div>
    }
  }

  
class ApiMultiSelect extends React.Component {

  constructor(props){
      super(props);

      this.loadOptions = this.loadOptions.bind(this);
      this.formatOnChangeData = this.formatOnChangeData.bind(this);
      this.onChange = this.onChange.bind( this );

      this.state = {
          values: []
      };
 
      this.cachedIds = {};

      this.resolveValue();
  }

  componentDidMount(){
      this.cachedIds = {};
  }

  resolveValue(){

      if( !this.props.value )
          return;

      let values = this.props.value;
      if( !values.map ){
        // Non è una multiselect
        values = [ values ];
      }

      Promise.all( values.map( value => {

          if( !value )
              return undefined;

          /*if( this.props.additionalValues ){
            if( this.props.additionalValues[value.id] )
              return { value: value.id, label: this.props.formatRow( this.props.additionalValues[value.id] ) };
          }*/

          if( this.cachedIds[value.id] )
              return { value: value.id, label: this.props.formatRow( this.cachedIds[value.id] ) };

          return new Promise( valueResolve => {
            fetch( 'api' , this.props.model , 'get' , value.id ).then( data => {
              if( !data.body ) return;
                valueResolve( { value: data.body.id, label: this.props.formatRow( data.body ) } );
            });
          });

      })).then( values => {

        if( !this.props.value )
          return this.setState({values: values[0]});

        if( !this.props.value.map )
          return this.setState({values: values[0]});

          this.setState({values: values});
      })
  }

  componentDidUpdate( prevProps ){
    if( JSON.stringify( prevProps['value'] ) !== JSON.stringify( this.props['value'] ) ){
        this.resolveValue();
        if( prevProps['value'] && !this.props.value )
          this.setState({ values: undefined });
    }
  }

  cachedFetch( ){

    let argArray = Array.from( arguments );

    if( !this.props.cachedFetch ){
      return fetch.apply( this , argArray );
    }

    let filters = {};
    if( argArray[argArray.length-1] && argArray[argArray.length-1].filters )
      filters = argArray[argArray.length-1].filters;

    let filtersKeys = Object.keys( filters );
    if( filtersKeys.length > 1 ) // se ci sono filtro non uso la cache
      return fetch.apply( this , argArray );

    let cacheKey = 'ApiMultiSelect_' + md5( JSON.stringify( argArray ) );

    const cachedResult = LocalCache.get( cacheKey );

    if( cachedResult ){
      return {
        then: function( cb ){ cb( { body: cachedResult} ); return this; } ,
        catch: function(){},
        abort: function(){}
      };
    }

    let retObj = {
      then: function( cb ){
        this.cb = cb;
        return this;
      },
      catch: function ( cb ){
        return this;
      },
      abort: function ( cb ){
        return this;
      },
    };

    fetch.apply( this , argArray ).then( msg => {
      LocalCache.set( cacheKey , msg.body , 60 * 0.5 );
      retObj.cb( msg );
    } );

    return retObj;
  }

  loadOptions( input ){
      return new Promise( success => {

        if( this.loadOptionsTimeout )
          clearTimeout( this.loadOptionsTimeout );

        this.loadOptionsTimeout = setTimeout( () => {

          let filters = this.props.filters || {};

          filters = { ...filters };

          if( input && input.length > 0 ){
            filters = { text: input , ...filters };
          }

          filters = { filters: filters };

          if( this.loadOptionsRequest )
            this.loadOptionsRequest.abort();

          this.loadOptionsRequest = this.cachedFetch( 'api' , this.props.model , this.props.modelAction || "select" , filters );
          this.loadOptionsRequest.then( data => {

            let options = [];

            if( !data.body )
              data.body = [];

            data.body = data.body.concat( this.props.additionalValues || [] );

            options = options.concat( data.body.map( row =>{
              let r = { value: row.id, label: this.props.formatRow( row ) }
              this.cachedIds[row.id] = row;
              return r;
            } ) );

            if( this.props.canCreate && input && input.length > 0 ){
              let foundExact = options.filter( o => {
                return o.label.toLowerCase() == input.toLowerCase();
              }).length > 0;
              if( !foundExact )
                options.push({ value: -1 , label: `Crea nuovo "${input}"` , input: input });
            }

            success( options );
          }).catch( () => {
          });

        } , 750 );

      });
  }

  async formatOnChangeData( se ){
    if( !se )
      return undefined;

    if( se.map )
      return se.map( value => {
          return this.cachedIds[value.value];
      });

    if( this.props.canCreate && se.value == -1 ) {

      let newObject = this.props.canCreate( se.input );

      let saveres = await fetch('api', this.props.model, 'save', newObject );

      return saveres.body.object;
    }

    return this.cachedIds[se.value];
  }

  onChange( se ) {
    if (this.props.onChange) {
      this.formatOnChangeData(se).then( r => {
        this.props.onChange( r );
      });

    }

  }

  render(){

      const sprops = { 
          value: '',
          label: 'Etichetta',
          values: false,
          onChange: () => {},
          ...this.props 
      };

      return <FormInputContainer isMulti {...sprops}>
          <AsyncSelect {...sprops} loadOptions={this.loadOptions} 
              onChange={ this.onChange }
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: 5000,
                })
              }}
              defaultOptions
              cacheOptions={false}
              isClearable
              key={JSON.stringify(this.props.filters)}
              value={this.state.values} />
      </FormInputContainer>;
  }
}
  
  class ApiSelect extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        rows: {}
      };
  
      let filters = this.props.filters || null;

      fetch( 'api', this.props.table , this.props.method || 'select' , filters ).then( data => {
        let rows = {};
  
        if( this.props.all ){
          rows[0] = 'Tutti';
        }
  
        const format = this.props.format ? this.props.format : row => { return row.id; }

        data.body.map( row => {
          rows[row.id] = format( row );
        });
    
        this.setState({rows: rows});
      });
    }

    render(){
      const sprops = {
        label: 'Etichetta',
        onChange: () => {},
        direction: FormInputContainer.HORIZONTAL,
        ...this.props
      };
  
      return <FormInputSelect values={this.state.rows} label={sprops.label} 
        value={sprops.value}
        direction={sprops.direction}
        onChange={sprops.onChange} />
    }
  }

  class RoleSelect extends React.Component {
    render(){
      return <ApiMultiSelect label="Ruolo" {...this.props}
        model="Roles" formatRow={row => { return row.name; }} />;
    }
  }
  
  class ContractTypesSelect extends React.Component {
    render(){
      return <React.Fragment>
        <ApiMultiSelect 
          model="ContractTypes" 
          formatRow={row => {
              return `${row.name}`;
          }} 
          label="Tipo contratto" 
          direction={FormInputContainer.HORIZONTAL} 
          {...this.props} 
        />
      </React.Fragment>;
    }
  }

  class AgentSelect extends React.Component {
    render(){
      return <React.Fragment>
        <ApiMultiSelect 
          model="Users" 
          formatRow={row => {
              return `${row.name} ${row.surname} ${row.city?'['+row.city+']':''} ${row.note?'('+row.note+')':''}`;
          }} 
          filters={{role: 'agent'}}
          label="Agente" 
          direction={FormInputContainer.HORIZONTAL} 
          {...this.props} 
        />
      </React.Fragment>;
    }
  }

  class AgentAppointmentsSummary extends React.Component {
    constructor(props){
      super(props);

      this.state = { 
        appointments: []
      };
    }

    componentDidUpdate( prevProps ){
      if( this.props.agent != prevProps.agent ){
        const filters = {
          AgentId: this.props.agent ? this.props.agent.id : false, 
          termDateStart: moment().subtract(6,'hours')
        };

        fetch( 'api' , 'Appointments' , 'select' , { pagination:{ limit: 15 }, filters: filters } ).then( data => {
          this.setState({appointments: data.body});
        });
      }
    }

    render(){
      if( !this.props.agent )
        return <div style={{padding: '20px', fontStyle: 'italic',color: '#c1c1c1'}}>
          Nessun agente selezionato</div>;

      if( this.state.appointments.length < 1 )
        return <div style={{padding: '20px', fontStyle: 'italic',color: '#c1c1c1'}}>
          Nessun appuntamento per {this.props.agent.name} {this.props.agent.surname}</div>;

      return <React.Fragment>
        <p>Appuntamenti per {this.props.agent.name} {this.props.agent.surname}:</p>
        <ul style={{padding: 0}}>{this.state.appointments.map( row => {
          return <li style={{listStyle: 'none outside none', borderBottom: '1px solid #c1c1c1', paddingBottom: '5px', marginBottom: '5px'}}>
            <span>{row.Children.length > 0 ? row.Children[0].Lead.city : ''}</span> 
            <small className="text-muted pull-right">{moment(row.minCreatedAt).format('DD MMMM - HH:mm')}</small>
            <div style={{clear:'both'}} />
          </li>;
        })}</ul>
      </React.Fragment>
    }
  }

  class ProductSelect extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        products: {}
      };
  
      fetch( 'sqlcommon', 'products' , 'select' ).then( data => {
        let products = {};
  
        if( this.props.all ){
          products[0] = 'Tutti';
        }
  
        data.body.map( product => {
          products[product.id] = `${product.name}`;
        });
    
        this.setState({products: products});
      });
    }
  
    render(){
      const sprops = {
        label: 'Prodotto',
        onChange: () => {},
        direction: FormInputContainer.HORIZONTAL,
        ...this.props
      };
  
      return <FormInputSelect values={this.state.products} label={sprops.label} 
        value={sprops.value}
        direction={sprops.direction}
        onChange={sprops.onChange} />
    }
  }

  class BrandSelect extends React.Component {
    render(){
      return <ApiMultiSelect label="Brand" {...this.props} model="Brands" 
        formatRow={row => { return row.name; }} />;
    }
  }

  
  class ChannelSelect extends React.Component {
    render(){
      return <React.Fragment>
        <ApiMultiSelect 
          model="Channels" 
          formatRow={row => {
              return `${row.Brand.name} ${row.name}`;
          }} 
          label="Canale" 
          direction={FormInputContainer.HORIZONTAL} 
          {...this.props} 
        />
      </React.Fragment>;
    }
  }
  
  class AppointmentStateSelect extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        states: {}
      };
  
      let states = {};
  
      if(this.props.all)
        states[0] = 'Tutti';
  
      states = {
        ...states,
        'Effettuato': 'Effettuato',
        'Assegnato': 'Assegnato',
      };
  
      this.state.states = states;
      
    }
  
    render(){
      const sprops = {
        label: 'Stato appuntamento',
        onChange: () => {},
        direction: FormInputContainer.HORIZONTAL,
        ...this.props
      };
  
      return <FormInputSelect values={this.state.states} label={sprops.label} 
        value={sprops.value}
        direction={sprops.direction}
        onChange={sprops.onChange} />
    }
  }

  class AppointmentOriginSelect extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        states: {}
      };
  
      let states = {};
  
      if(this.props.all)
        states[0] = 'Tutti';
  
      states = {
        ...states,
        'leads': 'Lead',
        'callcenter': 'Call center',
      };
  
      this.state.states = states;
      
    }
  
    render(){
      const sprops = {
        label: 'Origine appuntamento',
        onChange: () => {},
        direction: FormInputContainer.HORIZONTAL,
        ...this.props
      };
  
      return <FormInputSelect values={this.state.states} label={sprops.label} 
        value={sprops.value}
        direction={sprops.direction}
        onChange={sprops.onChange} />
    }
  }

  function EleGasSelect( props ){
    return <FormInputSelect value={props.value}
      label="Ele / Gas / Fibra"
      values={{
        '': 'Tutti',
        'ele': 'Elettrica',
        'gas': 'Gas',
        'fibra': 'Fibra',
      }}
      direction={FormInputContainer.HORIZONTAL}
      onChange={se => props.onChange( se )}/>
  }

  class Breadcumbs extends React.Component {
    render(){
      const sprops = {
        items: [],
        title: 'Home',
        ...this.props
      };
  
      return <div className="row wrapper border-bottom white-bg page-heading">
          <div className="col-lg-10">
              <h2>{sprops.title}</h2>
              <ol className="breadcrumb">
                {Object.keys( sprops.items ).map( itemKey => {
                  return <li className="breadcrumb-item">
                      <a href={sprops.items[itemKey]}>{itemKey}</a>
                  </li>
                })}
                  <li className="breadcrumb-item">
                      <a>{sprops.title}</a>
                  </li>
              </ol>
          </div>
      </div>;
    }
  }

  class Pagination extends React.Component {
    constructor(props){
      super(props);
  
      this.state = {
        page: props.page ? props.page : 1,
        limit: props.limit ? props.limit : 1,
        count: 0,
        pages: 1,
        loading: true,
      };
  
      let selectOptions = this.props.filters || null;
  
      if( selectOptions ) selectOptions = { filters: selectOptions };
      
      this.infoRequest = fetch( 'api', props.table , this.props.method || 'info' , selectOptions );
      this.infoRequest.then( rows => {
        this.infoRequest = false;
        this.setState({
          loading: false,
          count: rows.body ? rows.body.count : 0,
          pages: rows.body ? Math.ceil( rows.body.count / this.state.limit ) : 0
        });
      });
    }
  
    componentDidUpdate(prevProps, prevState){
      if( prevState.page !== this.state.page && this.props.onChange )
        this.props.onChange( this.state );
  
      if( JSON.stringify( this.props.selectOptions ) != JSON.stringify( prevProps.selectOptions ) ){
  
        let selectOptions = this.props.selectOptions ? this.props.selectOptions : undefined;
        this.setState({loading: true});
      
        if( this.infoRequest )
          this.infoRequest.abort();
          
        this.infoRequest = fetch( 'api', this.props.table , this.props.method || 'info' , selectOptions );
        this.infoRequest.then( rows => {
          this.infoRequest = false;
          
          let pages = Math.ceil( rows.body.count / this.state.limit );
            
          this.setState({
            loading: false,
            count: rows.body.count,
            pages: pages,
            page: 1,
          });
  
        }).catch(e => {});
        
      }
  
    }
  
    render(){

      if( this.state.loading ){
        return <React.Fragment>
          <i className="fa fa-spinner fa-pulse"></i> Caricamento totali...
        </React.Fragment>
      }
      let pages = [];

      let elementCount = this.state.limit > this.state.count ? this.state.count : this.state.limit;
      if( this.state.page == this.state.pages )
        elementCount = this.state.count - ((this.state.pages-1) * this.state.limit );
  
      for( let p = this.state.page -2; p < this.state.page; p++ )
        if( p > 0 )
          pages.push( p );
  
      pages.push( this.state.page );
  
      for( let p = this.state.page +1; p < this.state.page +3; p++ )
          if( p <= this.state.pages )
            pages.push( p );
  
      return <React.Fragment>

        {this.state.pages > 1 && <ul class="pagination float-right">

        { this.state.page > 1 && <li class="footable-page-arrow disabled">
          <a data-page="first" href="#"onClick={se=>{se.preventDefault();
              this.setState({page: 1})}}>«</a>
        </li> }

        { this.state.page > 1 && <li class="footable-page-arrow">
          <a data-page="prev" href="#" onClick={se=>{se.preventDefault();
              this.setState({page: this.state.page-1})}}>‹</a>
        </li> }
  
        {pages.map( page => {
          return <li class={"footable-page " + (this.state.page == page ? 'active' :'')} style={{zIndex:0}}>
            <a href="#" onClick={se=>{se.preventDefault();
              this.setState({page: page})}}>{page}</a>
          </li>;
        })}
  
        { this.state.page < this.state.pages && <li class="footable-page-arrow">
          <a data-page="next" href="#" onClick={se=>{se.preventDefault();
              this.setState({page: this.state.page+1})}}>›</a>
        </li> }

        { this.state.page < this.state.pages && <li class="footable-page-arrow">
          <a data-page="last" href="#" onClick={se=>{se.preventDefault();
              this.setState({page: this.state.pages})}}>»</a>
        </li>}

      </ul>}

      {!this.props.hideTotals && <span className="pull-left">
        {elementCount} elementi su {this.state.count} totali</span>}
      </React.Fragment>;
    }
  }

  
const LocalCache = {

  name: function( name ){
    return '_00001_' + name;
  },

  set: function( name , value, timeoutInSeconds = false ){
      localStorage.setItem( LocalCache.name( name ) , JSON.stringify( value ) );
      if( timeoutInSeconds )
          localStorage.setItem( 'timeout_' + LocalCache.name( name ) , (new Date()).getTime() + (timeoutInSeconds*1000) );
  },

  get: function( name ){
      let savedValue = localStorage.getItem( LocalCache.name( name ) );
      if( savedValue ){
          try {
              savedValue = JSON.parse( savedValue );
          } catch( e ){ savedValue = false }
      }

      const timeout = localStorage.getItem( 'timeout_' + LocalCache.name( name ) );
      if( timeout ){
          if( (new Date()).getTime() - timeout > 0 )
              savedValue = false;
      }

      return savedValue;
  },

  clear: function( name ){
      localStorage.removeItem( LocalCache.name( name ) );
      localStorage.removeItem( 'timeout_' + LocalCache.name( name ) );
  },

  groupSet: function( groupName , value ){
      return LocalCache.set( 'cacheGroups_' + groupName , value );
  },

  groupGet: function( groupName ){
      let g = LocalCache.get( 'cacheGroups_' + groupName );
      if( typeof( g ) !== 'object' || g === null )
          return [];
      return g;
  },

  groupAppendName: function( groupName , name ){
      if( typeof( name ) !== 'string' || typeof( groupName ) !== 'string' ) 
          return;

      let g = LocalCache.groupGet( groupName );
      if( g.indexOf( name ) === -1 )
          g.push( name );

      this.groupSet( groupName , g );
  },

  groupClear: function( groupName ){
      let g = LocalCache.groupGet( groupName );
      g.map( item => {
          LocalCache.clear( item );
      });

      LocalCache.groupSet( groupName , [] );
  }
}

function DataTablePaginator({ pagination, onChange }){
  return <></>;
}

function DataTable({ pFilters }){

  const [ loading, setLoading ] = useState( true );
  const [ dataRows, setDataRows ] = useState( [] );
  const [ filters, setFilters ] = useState( pFilters || {} );
  const [ pagination , setPagination ] = useState({
    page: 1,
    limit: 25,
    sort: [],
  });

  useEffect( () => {
    setFilters( pFilters );
  } , [ pFilters ])

  const reloadData = (filters, pagination) => {

  };

  useEffect( () => {
    reloadData( filters, pagination );
  }, [] );

  useEffect( () => {
    reloadData( filters, pagination );
  }, [filters, pagination] );

  return <div className="row">
    <div className="col-md-12">
      <DataTablePaginator pagination={pagination} onChange={setPagination} />
    </div>
    <div className="col-md-12">
      <table className="table table-striped table-hover" style={{width: '100%'}}>
        <thead>
        <tr>
        </tr>
        </thead>
      </table>
    </div>
    <div className="col-md-12">
      <DataTablePaginator pagination={pagination} onChange={setPagination} />
    </div>

  </div>;
}

class AbstractTableViewer extends React.Component {

  constructor(props){
      super(props);
  
      this.state = {
        loading: true,
        rows: [],
        filters: this.props.filters || {},
        pagination: {
          page: 1,
          limit: this.props.limit || 25,
          sort: props.defaultSort || [],
        },
      };

      this.updateSortingStatus();

      this.reloadData();

      this.onSaveHook = false;

      this.dataLoadRequest = false;

      this.onSortClick = this.onSortClick.bind( this );
  }

  updateSortingStatus(){

    Object.keys( this.props.columns ).map( columnKey => {
      let info = this.getColumnInfo( columnKey );
      if( info.sortBy ){}
    });

  }

  reloadData( silent ){

      this.setState({
        loading: silent ? false : true,
      });    

      const payload = {
        pagination: this.state.pagination,
        filters: this.state.filters,
      };

      if( this.dataLoadRequest && this.dataLoadRequest.abort ){
        this.dataLoadRequest.abort();
        this.dataLoadRequest = false;
      }

      this.dataLoadRequest = fetch( 'api', this.props.model , this.props.method || 'select' , payload );
      this.dataLoadRequest.then( msg => {
        this.dataLoadRequest = false;

        let rows = msg.body;

        if( typeof( this.props.alterRows ) == 'function' )
            rows = this.props.alterRows( rows );

        this.setState({rows, loading: false});
        this.installOnSaveHook();
      }).catch( e => {});
  
  }

  componentDidUpdate(prevProps, prevState){
      let reload = false;
      
      if( JSON.stringify( this.state.pagination ) != JSON.stringify( prevState.pagination ) )
        reload = true;

      if( JSON.stringify( prevProps['filters'] ) != JSON.stringify( this.props['filters'] ) ){
        this.setState({filters: this.props['filters'] , pagination: { ...this.state.pagination, page: 1 } });
      }

      if( JSON.stringify( prevState['filters'] ) != JSON.stringify( this.state['filters'] ) ){
        reload = true;
        this.setState({ pagination: { ...this.state.pagination, page: 1 } });
      }
  
      if( reload )
        this.reloadData();
  }    

  installOnSaveHook(){

    if( this.onSaveHook )
        this.onSaveHook.abort();

    this.onSaveHook = fetchHook( this.props.model, 'onSave' );

    let timeout = 1000 * 60 * 2;

    if( this.onSaveHookTimeout )
      clearTimeout( this.onSaveHookTimeout );

    this.onSaveHookTimeout = setTimeout( () => {
      this.installOnSaveHook();
    } , timeout + 500 );

    this.onSaveHook.timeout({
      response: timeout,  // Wait 5 seconds for the server to start sending,
      deadline: timeout, // but allow 1 minute for the file to finish loading.
    }).then( data => {
      this.reloadData( true );
    }).catch( () => {

    });
  }

  componentWillUnmount(){
    if( this.onSaveHookTimeout )
      clearTimeout( this.onSaveHookTimeout );

    if( this.onSaveHook )
        this.onSaveHook.abort();

    this.onSaveHook = false;
  }

  getColumnInfo( column ){
    let info = {
      name: column,
      label: '-',
      sortBy: false
    };

    if( typeof( this.props.columns[column] ) == 'string' )
      info.label = this.props.columns[column];

    if( typeof( this.props.columns[column] ) == 'object' ){
      info = { ...info , ...this.props.columns[column] };
    }

    return info;
  }

  onSortClick( columnKey ){
    let info = this.getColumnInfo( columnKey );

    let paginationSort = this.state.pagination.sort;

    let sortFound = paginationSort.filter( sort => {
      return sort.length == 2 && sort[0] == info.sortBy;
    });

    if( sortFound.length < 1 )
      paginationSort.push( [ info.sortBy , 'ASC' ] );
    else {
      if( sortFound[0][1] == 'ASC' ){
        paginationSort = paginationSort.map( sort => {
          if( sort.length == 2 && sort[0] == info.sortBy )
            return [ info.sortBy , 'DESC' ];
          return sort;
        });
      }

      if( sortFound[0][1] == 'DESC' ){
        paginationSort = paginationSort.filter( sort => {
          if( sort.length == 2 && sort[0] == info.sortBy )
            return false;
          return true;
        });
      }
    }

    this.setState({pagination: { ...this.state.pagination, sort: paginationSort }} , () => {
      this.reloadData();
    });
  }

  render(){

      if( this.state.loading )
          return <Loader />;

      const row = this.props.row ? this.props.row : rowData => {
          return <tr key={rowData.id}>{Object.keys(this.props.columns).map( column => {
              return <td>{rowData[column]}</td>;
          })}</tr>;
      };

      return <table className="table table-striped table-hover" style={{width: '100%'}}>
          <thead>
          <tr>
              <td key={'td'+Math.random()} colSpan="12" className="footable-visible">
                  <Pagination onChange={p=>{this.setState({pagination: {...this.state.pagination,...p}})}}
                    hideTotals={false}
                    filters={this.props.filters}
                    limit={this.state.pagination.limit} page={this.state.pagination.page}
                    method={this.props.infoMethod}
                    table={this.props.model} />
              </td>
          </tr>
          </thead>
          <tbody>
          <tr>
              {Object.keys(this.props.columns).map( column => {

                let sortStatus = 'fa-sort';
                let info = this.getColumnInfo(column);

                try {
                  let sortFound = this.state.pagination.sort.filter(sort => {
                    return sort.length == 2 && sort[0] == info.sortBy;
                  });

                  if (sortFound.length > 0)
                    sortStatus = sortFound[0][1] === 'ASC' ? 'fa-sort-asc' : 'fa-sort-desc';
                } catch ( e ){}

                return <td key={'td'+Math.random()}>
                  <strong>{info.label}&nbsp;</strong>
                  {info.sortBy && <i className={"fa " + sortStatus} onClick={se=>{
                    this.onSortClick( column );
                  }}></i>}
                </td>;
              })}
          </tr>
          {this.state.rows.map( row )}
          </tbody>
          <tfoot>
          <tr>
              <td colSpan={Object.keys(this.props.columns).length} className="footable-visible">
                  <Pagination onChange={p=>{this.setState({pagination: {...this.state.pagination,...p}})}}
                      filters={this.props.filters}
                      method={this.props.infoMethod}
                      limit={this.state.pagination.limit} page={this.state.pagination.page} 
                      table={this.props.model} />
              </td>
          </tr>
          </tfoot>
      </table>;
  }
}

function can( permMachineName ){
  try {

    if( LocalCache.get('loginData').User.Role.machineName == 'admin' )
      return true;
      
    let found = false;
    LocalCache.get('loginData').User.Role.Permissions.map( perm => {
      if( perm.machineName === permMachineName)
        found = true;
    });

    return found;
  } catch(e){}

  return false;
}

/**
 * @deprecated use AbstractEditorModal
 * @param model
 * @param component
 */
function apiFormInit( model, component ){

  component.setState({
    loading: true,
  });

  if( component.props.id > 0 ){
    fetch( 'api' , model , 'get' , component.props.id ).then( data => {
      component.setState( { ...data.body , loading: false } );
    });
  } else {
    fetch( 'api' , model , 'defaults' ).then( data => {
      component.setState( { ...data.body , loading: false } );
    });
  }
}

/**
 * @deprecated use AbstractEditorModal
 * @param model
 * @param component
 * @param payload
 * @returns {Promise<unknown>}
 */
function apiFormSave( model, component , payload = false ){

  if( component.state.apiFormSaving )
    return;

  component.setState({apiFormSaving: true});
  setTimeout( () => { component.setState({apiFormSaving: false}); } , 20000 );

  const _payload = payload ? payload : component.state;

  return new Promise( resolve => {
    fetch( 'api' , model , 'save' , _payload ).then( res => {
      component.setState({apiFormSaving: false});
      if( res.body.status ){
        if( component.props.onDone )
          component.props.onDone( res );
      } else {
        component.setState({validation: res.body});
      }

      resolve( res.body );
    });
  });
}

/**
 *
 * @param {string} name String for title label and save button label
 * @param {string} model model machine name. Ex: "Users"
 * @param {number} id Entity id to edit, null for new entities
 * @param {function} onDone Callback called when closing the form
 * @param {string} saveMethod api method to call on save (default: 'save')
 * @param {object} formData
 * @param {function} onFormDataChange
 * @param {function} alterPayLoad Useful for alter the payload before sending to the server
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function AbstractEditorModal({ name, model, id, onDone, formData, modalTitle,
                               onFormDataChange, alterPayLoad, children, saveMethod }){

  const [ loading, setLoading ] = useState( true );
  const [ saving, setSaving ] = useState( false );

  const onSubmit = se => {
    se.preventDefault();

    if( loading || saving )
      return;

    setSaving( true );

    setTimeout( () => {
      setSaving( false );
    } , 20000 );

    const _payload = alterPayLoad ? alterPayLoad( formData ) : formData;

    const method = saveMethod ? saveMethod : 'save';

    fetch( 'api' , model , method , _payload ).then( res => {
      setSaving( false );

      if( res.body.status ){
        if( onDone )
          onDone( res );
      } else {
        onFormDataChange( {...formData, validation: res.body} );
      }

    });

  };

  const _onDone = se => {
    se.preventDefault();
    if( onDone ) onDone();
  }

  useEffect( () => {
    if( id > 0 ){
      fetch( 'api' , model , 'get' , id ).then( data => {
        onFormDataChange( {...formData, ...data.body} );
        setLoading( false );
      });
    } else
      setLoading( false );
  } , [] );

  return <Modal onClose={onDone}>
    <form onSubmit={onSubmit}>
      <h2>{modalTitle ? modalTitle : ( id?'Modifica '+name:'Inserisci '+name )} </h2>

      {loading && <Loader />}

      {!loading && <>
        {children}
      </>}

      <FormDashline />

      <AbstractApiFormFooter {...formData} />

      <FormButtons saveLabel={"Salva " + name}  direction={FormInputContainer.HORIZONTAL}
                   onSave={onSubmit}
                   saving={saving}
                   onCancel={_onDone} />

    </form>
  </Modal>
}

class AbstractApiFormFooter extends React.Component {
  render(){

    if( !this.props.validation  )
      return null;

    let msg = 'Errore nella compilazione dati';

    if( this.props.validation.message )
      msg = this.props.validation.message;

    return <div className="alert alert-danger" role="alert">
      {msg}
    </div>;
  }

}

class ScrollPagination extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      page: 0,
      rows: [],
      loadingPage: false,
      reachEnd: false,
    };

    this.loadNextPage = this.loadNextPage.bind(this);
    this.checkNextItems = this.checkNextItems.bind(this);

    this.placeholderRef = React.createRef();

    this.loadNextPage();
  }

  componentDidMount(){
    this.timerHandle = setInterval( this.checkNextItems , 250 );
  }

  componentWillUnmount(){
    clearInterval( this.timerHandle );
  }

  loadNextPage(){
    if( this.state.loadingPage || this.state.reachEnd )
      return;

    this.setState({loadingPage: true});

    let nextPage = this.state.page + 1;
    const limit = this.props.limit || 250;

    fetch( 'api' , this.props.model , 'select' , { 
      pagination: { page: nextPage, limit: parseInt( limit ) },
      filters: this.props.filters || {} }).then( data => {

      this.setState({ 
        rows: this.state.rows.concat( data.body ),
        page: nextPage,
        loadingPage: false,
        reachEnd: data.body.length < limit
      });

    });
  }

  checkNextItems(){

    if( !this.placeholderRef ) return;
    if( !this.placeholderRef.current ) return;

    const rect = this.placeholderRef.current.getBoundingClientRect();

    const wHeight = window.innerHeight * 1.5;
    
    if( rect.top < wHeight && !this.state.loadingPage && !this.state.reachEnd ){
      this.loadNextPage();
    }
    
  }

  render(){

    const sprops = {
      row: () => { return <p>Item</p>; },
      ...this.props
    };

    return <React.Fragment>
      {this.state.rows.map( sprops.row )}
      <div ref={this.placeholderRef} />
      {!this.state.reachEnd && this.state.loadingPage && <Loader />}
    </React.Fragment>
  }
}

function formatEur( value ){
  return accounting.formatMoney(value, "€ ", 2, ".", ",");
}

function formatDateTime( d ){
  let m = moment( d );

  if( m.isValid() )
    return m.format('DD/MM/YY HH:mm');
  return '-';
}

function formatDate( d ){
  let m = moment( d );

  if( m.isValid() )
    return m.format('DD/MM/YY');
  return '-';
}

class FormAutoInput extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      schema: false
    };

  }

  componentDidMount(){
    this.describe();
  }

  describe( ){
    if( !FormAutoInput.schemas )
      FormAutoInput.schemas = {};

    if( !FormAutoInput.schemas[this.props.model] ){
      fetch( 'api' , this.props.model , 'describe' ).then( msg => {
        FormAutoInput.schemas[this.props.model] = msg.body;
        this.describe();
      });

      return;
    }

    this.setState({schema: FormAutoInput.schemas[this.props.model]});
  }

  render(){
    try {

      if( !this.state.schema )
        return null;

      let field = this.state.schema.model.fields.filter( f => {
        return f.name == this.props.field;
      })[0];

      field = {
        type: 'text',
        label: this.props.field,
        required: false,
        ...field
      };

      if( field.type == 'text' ){
        return <FormInputText label={field.label}
          direction={FormInputContainer.HORIZONTAL}
          requiredField={field.required}
          value={this.props.state[this.props.field]}
          validation={this.props.state.validation}
          name={this.props.field}  
          {...this.props} />
      }

      if( field.type == 'currency' ){
        return <FormInputCurrency label={field.label}
          direction={FormInputContainer.HORIZONTAL}
          requiredField={field.required}
          value={this.props.state[this.props.field]}
          validation={this.props.state.validation}
          name={this.props.field}  
          {...this.props} />
      }

      if( field.type == 'boolean' ){
        return <div onClick={se=>{this.props.onChange(!this.props.state[field.name])}} >
            <FormInputRadio label={field.label} checked={this.props.state[field.name]}
                direction={FormInputContainer.HORIZONTAL}
                onClick={se=>{this.props.onChange(!this.props.state[field.name])}}
            />
        </div>
      }

      return JSON.stringify( field ); 

    } catch( e ){
      console.error( e );
      return <FormInputText />;
    }
  }
}

class ConfirmableButton extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      open: false
    };

    this.confirmRef = React.createRef();

    this.onClick = this.onClick.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onConfirm(se){
    this.props.onClick( se );
    this.setState({open: false});
  }

  onClick(se){
    se.preventDefault();

    if( this.state.open ){
      return this.setState({open: false});
    }

    if( typeof( this.props.confirm ) != 'undefined' ){
      if( this.props.confirm == false )
        return this.props.onClick( se );
    }

    this.setState({open: true});
  }

  render(){

    const sprops = {
      confirmLabel: 'Sei sicuro?',
      ...this.props
    };

    return <React.Fragment>

      {/*this.state.open ? 
        <div className="AppCommon_ConfirmableButton_Dialog">
          <div className="AppCommon_ConfirmableButton_DialogContent" ref={this.confirmRef}>
            {sprops.confirmLabel}

            <button className="btn btn-primary btn-circle" onClick={this.onConfirm}>
              <i className="fa fa-check"></i>
            </button>

          </div>
        </div>
      : '' */}

      { this.state.open ? 

        <button {...sprops} onClick={this.onConfirm}>
          {sprops.confirmLabel} <i className="fa fa-check"></i>
        </button> 
        
        : <button {...sprops} onClick={this.onClick}>
          {sprops.children}
        </button>
      }

    </React.Fragment>
  }
}

function formatMultilineText( text ){
  if( !text ) return null;
  if( !text.split ) return null;

  let paragraphs = text.split( "\n" );

  return paragraphs.map( paragraph => {
    return <p>{paragraph}</p>;
  });
}

export { Breadcumbs , ChannelDescription , ChannelSelect , formatEur, FormAutoInput,
  AgentSelect, FileViewer , FileUploadInput , Pagination , BrandSelect, can, formatMultilineText,
  apiFormInit, apiFormSave, AbstractApiFormFooter, ApiSelect, ApiMultiSelect, ConfirmableButton,
  AgentAppointmentsSummary, AppointmentOriginSelect, ScrollPagination, ContractTypesSelect, formatDate,
  AppointmentStateSelect, ProductSelect , LocalCache, RoleSelect, AbstractTableViewer, formatDateTime,
  EleGasSelect, AbstractEditorModal, DataTable };
