import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter , 
    apiFormInit , apiFormSave, formatEur, BrandSelect, 
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';

class LeadInterests extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      leadInterestModal: false,
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = { 
      name: 'Nome' , 
      actions: 'Azioni'
  };

    const row = leadInterest => {
        return <tr>
          <td>{leadInterest.name}</td>
          <td>
            <div class="btn-group">
                {can('LeadInterests.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({leadInterestModal: leadInterest.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Interessi Lead" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'LeadInterests.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({leadInterestModal: true});
          }}>Nuovo interesse</button>}
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="LeadInterests" 
                        filters={{ }}
                        row={row}
                        columns={columns}/>
                  </div>
              </div>
            </div>
          </div> }
        </div>

      </div>

      {this.state.leadInterestModal && <NewLeadInterestModal onDone={res=>{
        this.setState({leadInterestModal: false});
      }} id={this.state.leadInterestModal === true ? false: this.state.leadInterestModal} />}

      


    </div>];
  }
}



class NewLeadInterestModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'LeadInterests', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'LeadInterests', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />
    
            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva interesse"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class LeadInterestSelect extends React.Component {
  render(){
    return <ApiMultiSelect label={this.props.label || "Interessi lead"} {...this.props} model="LeadInterests"
        isMulti={this.props.isMulti===false ? false : true}
      formatRow={row => { return row.name; }} filters={{}}
       />;
  }
}

class LeadInterestsPlainViewer extends React.Component {
  render(){
    try {

      const max = 3;

      let names = [];

      if( this.props.lead.Interests && this.props.lead.Interests.length ){
        names = this.props.lead.Interests.map( i => {
          return i.name.trim();
        });
      } else {
        if( this.props.lead.flat_Interests_names ){
          names = this.props.lead.flat_Interests_names
            .slice(1,this.props.lead.flat_Interests_names.length-1)
            .split( "--" );
        }
      }

      if( !names.length )
        return '-';

      if( names.length > max ){
        names.push( `+${ names.length - max}` );
      }

      return names.map( name => {
        return <span className="label label-default" style={{marginLeft: '5px'}}>{name}</span>;
      });

    } catch( e ){
      return '-';
    }
  }
}

export { LeadInterests , LeadInterestsPlainViewer , LeadInterestSelect, NewLeadInterestModal };