import React from 'react';
import './App.css';
import moment from 'moment';
import './Products.css';

import {
  Loader, FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, FormInputSelect, FormInputDate, FormInputTime, BoxContainer, queryToObject
} from './Common.js';

import {
  AbstractTableViewer,
  can,
  AbstractApiFormFooter,
  apiFormInit,
  apiFormSave,
  ApiMultiSelect,
  AgentSelect,
  AgentAppointmentsSummary, FileUploadInput, Breadcumbs, LocalCache
} from './AppCommon';
import {AdvancedProductSelect, ProductSelect} from "./Products";
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";
import {NotePlainViewer, NotesEditor, NotesModal} from "./Notes";
import {NewContractModal} from './Contracts';
import {TagsSelect} from "./Tags";
import {Link} from "react-router-dom";
import {EnelxSellsStatesSelect, EnelxSellsStateViewer} from "./EnelxSellsStates";
import {showExportModal} from "./Exports";
import {EnelxSellsSubStatesSelect, EnelxSellsSubStateViewer} from "./EnelxSellsSubStates";
import {LeadInterestSelect} from "./LeadInterests";
import {SellingPointSelect} from "./SellingPoints";

const originTypes = {
  'Risorsa': 'Risorsa',
  'Social': 'Social',
  'Enel': 'Enel',
  'Passaparola': 'Passaparola',
  'Cover giornale 2022': 'Cover giornale 2022',
  'Social 2.0': 'Social 2.0',
  'Lemonfour': 'Lemonfour',
  'Altro': 'Altro',
};

class EnelxSellKoReasonSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Motivazione KO"  direction={FormInputContainer.HORIZONTAL}
                           canCreate={ inputText => { return { name: inputText } } }
                           model="EnelxSellKoReasons" formatRow={row => { return row.name; }} {...this.props} />;
  }
}

class EnelxInstallerSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Ruolo"  direction={FormInputContainer.HORIZONTAL}
               canCreate={ inputText => { return { name: inputText } } }
               model="EnelxInstallers" formatRow={row => { return row.name; }} {...this.props} />;
  }
}

class EnelxSellAppointment extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      EnelxSellId: this.props.EnelxSellId,
      LeadId: this.props.LeadId,
      termDate: moment().format(),
      origin: 'Enel X'
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    if( this.props.id ){
      this.setState({loading: true});

      fetch( 'api' , 'EnelxSells' , 'loadAppointment' , this.props.id ).then( msg => {
        this.setState({
          loading: false,
          ...msg.body,
        });
      });

    }
  }

  onSubmit(se){
    this.setState({saving: true});

    fetch( 'api' , 'EnelxSells' , 'saveAppointment' , this.state ).then( msg => {
      this.setState({
        saving: false
      });
      this.props.onDone();
    });
  }

  render(){

    if( this.state.loading ){
      return <Modal>
        <Loader />
      </Modal>
    }

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <h2>{this.state.id ? 'Visualizza ' : 'Nuovo ' } appuntamento</h2>

        <FormInputDate value={this.state.termDate}
                       direction={FormInputContainer.HORIZONTAL}
                       label="Data appuntamento"
                       validation={this.state.validation} name="termDate"
                       onChange={se=>{this.setState({termDate: se})}} />

        <FormInputTime value={this.state.termDate}
                       direction={FormInputContainer.HORIZONTAL}
                       label="Orario appuntamento"
                       validation={this.state.validation} name="termDate"
                       onChange={se=>{this.setState({termDate: se})}} />

        { true && <React.Fragment>
          <AgentSelect value={this.state.Agent}
                       validation={this.state.validation} name="AgentId"
                       onChange={se=>this.setState({Agent: se, AgentId: se ? se.id : null})} />

          <AgentAppointmentsSummary agent={this.state.Agent} />

        </React.Fragment>}

        <FormDashline />

        <FormInputSelect values={{
          'Programmato': 'Programmato',
          'Confermato': 'Confermato',
          'Chiuso': 'Chiuso',
          'Disdetto': 'Disdetto',
          'Ko': 'Ko',
          'Riprogrammato': 'Riprogrammato',
          'Trattativa': 'Trattativa'
        }} label="Esito" value={this.state.outcome}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="outcome"
                         onChange={se=>this.setState({outcome: se})} />

        <FormInputText label="Note" value={this.state.note}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="note"
                       onChange={se=>this.setState({note: se.target.value})} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva Appuntamento"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={false}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}


class EnelxSellContract extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      Products: [],

    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    this.setState({loading: true});

    fetch( 'api' , 'EnelxSells' , 'get' , this.props.id ).then( msg => {
      this.setState({
        loading: false,
        Products: msg.body.Products,
        EnelxSell: msg.body,
      });
    });
  }

  onSubmit(se){
    se.preventDefault();

    this.setState({saving: true});

    fetch( 'api' , 'EnelxSells' , 'saveContract' , this.state ).then( msg => {
      if( msg.body.status )
        this.props.onDone();
      else {
        this.setState({
          error: msg.body.message,
          saving: false,
        })
      }
    });
  }

  render(){

    if( this.state.loading ){
      return <Modal>
        <Loader />
      </Modal>
    }

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <FormInputText upperCase={true} label="Codice Univoco" value={this.state.uniqueUuid}
                       direction={FormInputContainer.HORIZONTAL}
                       requiredField={true}
                       validation={this.state.validation} name="uniqueUuid"
                       onChange={se => this.setState({uniqueUuid: se.target.value})}/>


        <AdvancedProductSelect onChange={Products => {
          this.setState({Products: Products})
        }} throughModelName={"EnelxSellProducts"} values={this.state.Products}/>

        <AbstractApiFormFooter {...this.state} />

        {this.state.error && <div className="alert alert-danger" role="alert">
          {this.state.error}
        </div>}

        <FormButtons saveLabel="Salva Contratto"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={false}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}


class EnelxSellHistory extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      loading: true
    };

  }

  componentDidMount(){
    this.setState({loading: true});

    fetch( 'api' , 'EnelxSells' , 'fetchHistory' , this.props.id ).then( msg => {
      this.setState({
        loading: false,
        data: msg.body,
      });
    });
  }

  render(){

    if( this.state.loading ){
      return <Modal>
        <Loader />
      </Modal>
    }

    return <Modal onClose={this.props.onDone}>
      {this.state.data.map( row => {
        return <div className={"row"}>
          <div className={"col-6"}>
            <h4>{row.EnelxSellsState ? <EnelxSellsStateViewer entity={row.EnelxSellsState} /> : '-'}</h4>
          </div>
          <div className={"col-6 text-right"}>
            {formatLeadName(row.User)}
          </div>
          <div className={"col-12 text-small text-right"}>
            {moment(row.createdAt).format( 'DD/MM/YY HH:mm' )}
          </div>
          <div className={"col-12"}>
            <FormDashline />
          </div>

        </div>;
      })}
    </Modal>;
  }

}

class EnelxSellModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      Products: [],
      LeadId: props.LeadId,
      workingStatus: 'Attesa contatto'
    };

    this.onSubmit = this.onSubmit.bind(this);

    this.loadLead();

  }

  componentDidMount(){
    apiFormInit( 'EnelxSells', this );

    if( this.props.id < 1 ){
      fetch( 'api' , 'EnelxSellsStates' , 'default' ).then( msg => {
        if( msg.body && msg.body.id ){
          this.setState({
            EnelxSellsState: msg.body,
            EnelxSellsStateId: msg.body.id
          });
        }
      });

      try {
        let sellingPoint = LocalCache.get('loginSEP');
        if( sellingPoint ){
          this.setState({
            SellingPoint: sellingPoint,
            SellingPointId: sellingPoint.id
          });
        }
      } catch (e) {     }
    }

  }


  onSubmit(se){
    se.preventDefault();

    let payload = { ...this.state };

    apiFormSave( 'EnelxSells', this , payload );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (this.state.LeadId != prevState.LeadId) {
      this.setState({loadingLeadData: true});
      this.loadLead();
    }
  }

  loadLead() {

    if (!this.state.LeadId) {
      return;
    }

    fetch('api', 'Leads', 'fastGet', this.state.LeadId).then(msg => {
      let o = {};

      ['name', 'surname' ,'telephone','city'].map(key => {
        o[key] = msg.body[key];
      });

      this.setState({
        Lead: msg.body,
        name: o.name,
        surname: o.surname,
        telephone: o.telephone,
        city: o.city,
      });
    });
  }


  render(){

    const origin = (this.state.OriginTags && this.state.OriginTags.length > 0 && this.state.OriginTags[0].name) ?
      this.state.OriginTags[0].name : '';

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        {/*<div className={"row"}>
           <div className={"col-12"}>

            <FormInputSelect values={{
              'working': 'In lavorazione',
              'ok': 'Chiuso OK',
              'ko': 'Ko',
            }} label="Stato completamento"
               direction={FormInputContainer.HORIZONTAL}
               value={this.state.complete_status}
               onChange={se=>this.setState({complete_status: se})}
               name="complete_status" />

          </div>


        </div>

        <FormDashline />*/ }

        <div className={"row"}>
          <div className={"col-12"}>
            <EnelxSellsStatesSelect value={this.state.EnelxSellsState}
              onChange={se=>this.setState({EnelxSellsState: se, EnelxSellsStateId: se.id})} />
          </div>

          {can('EnelxSellsSubStates.select' ) && <div className={"col-12"}>
            <EnelxSellsSubStatesSelect value={this.state.EnelxSellsSubState}
                                       filters={{
                                         FromStateId: this.state.EnelxSellsState ? this.state.EnelxSellsState.id : 0
                                       }}
                                    onChange={se=>this.setState({EnelxSellsSubState: se, EnelxSellsSubStateId: se.id})} />
          </div> }

          { (this.state.workingStatus == 'KO') && <div className={"col-12"}>
            <EnelxSellKoReasonSelect value={this.state.EnelxSellKoReason}
                                  onChange={se => {
                                    this.setState({EnelxSellKoReason: se, EnelxSellKoReasonId: se ? se.id : null})
                                  }} label={"Motivazione KO"}/>

            <FormInputText value={this.state.ko_reason}
                           label="Note aggiuntive su KO"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se=>this.setState({ko_reason: se.target.value})} />
          </div> }

          <div className={"col-12"}>

            {can('EnelxSells.seeAll') && <React.Fragment>

              <TagsSelect
                direction={FormInputContainer.HORIZONTAL}
                onChange={tags=>{
                  if( tags.length && tags[0].name != 'Risorsa' )
                    this.setState({ResourceUser: null,ResourceUserId: null })
                  this.setState({OriginTags: tags})
                }}
                label="Origine" isMulti={false}
                value={this.state.OriginTags} canCreate={can('EnelxSells.createOriginTags' )}
                entityName={"EnelxSellOrigins"} entityId={this.state.id} />

              {(origin == 'Risorsa') &&
                <UserSelect value={this.state.ResourceUser}
                     onChange={se => {
                       this.setState({ResourceUser: se, ResourceUserId: se ? se.id : null})
                     }} label={"Risorsa lead"}/> }
            </React.Fragment>}

            {(!can('EnelxSells.seeAll')) && <React.Fragment>
              <h4>Origine:</h4>
              <p>{origin}</p>


              {origin == 'Risorsa' && <>
                <h4>Risorsa:</h4>
                <p>{formatLeadName(this.state.ResourceUser)}</p>
              </> }
            </React.Fragment>}


            <UserSelect value={this.state.InChargeUser}
                        onChange={se => {
                          this.setState({InChargeUser: se, InChargeUserId: se.id})
                        }} label={"Assegnatario vendita"}/>

            <EnelxInstallerSelect value={this.state.EnelxInstaller}
                        onChange={se => {
                          this.setState({EnelxInstaller: se, EnelxInstallerId: se ? se.id : null})
                        }} label={"Installatore"}/>

            <SellingPointSelect value={this.state.SellingPoint}
                         direction={FormInputContainer.HORIZONTAL}
                        onChange={se => {
                          this.setState({SellingPoint: se, SellingPointId: se ? se.id : null})
                        }} label={"Punto vendita"}/>
          </div>

          <div className={"col-12"}>
            <FormInputRadio label="Contatto effettuato" checked={this.state.firstContactAt}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>{this.setState({firstContactAt: this.state.firstContactAt ? null : moment() })}} />

            {this.state.firstContactAt && <FormInputDate value={this.state.firstContactAt}
                                                             label="Data contatto"
                                                             direction={FormInputContainer.HORIZONTAL}
                                                             onChange={se=>this.setState({firstContactAt: se})} />}
          </div>

          <div className={"col-12"}>
            <FormInputRadio label="Sopralluogo effettuato" checked={this.state.onSiteInspectionAt}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>{this.setState({onSiteInspectionAt: this.state.onSiteInspectionAt ? null : moment() })}} />

            {this.state.onSiteInspectionAt && <FormInputDate value={this.state.onSiteInspectionAt}
                                                             label="Data sopralluogo"
                                                             direction={FormInputContainer.HORIZONTAL}
                                                             onChange={se=>this.setState({onSiteInspectionAt: se})} />}
          </div>

          <div className={"col-12"}>

            <FormInputSelect value={this.state.paymentType}
                             label="Tipologia pagamento"
                             values={{
                               'Non definito': 'Non definito' ,
                               'Bonifico': 'Bonifico' ,
                               'Finanziaria': 'Finanziaria'
                             }}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se=>{this.setState({paymentType: se})}} />

            <FormInputRadio label="Pagamento effettuato" checked={this.state.paymentDoneAt}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>{this.setState({paymentDoneAt: this.state.paymentDoneAt ? null : moment() })}} />

            {this.state.paymentDoneAt && <FormInputDate value={this.state.paymentDoneAt}
                                                             label="Data pagamento"
                                                             direction={FormInputContainer.HORIZONTAL}
                                                             onChange={se=>this.setState({paymentDoneAt: se})} />}
          </div>

          <div className={"col-12"}>
            <EnelxSellsBusinessTypeSelect
              value={this.state.businessType}
              onChange={se=>{
                this.setState({businessType: se})
              }} />
          </div>

          <div className={"col-12"}>

{/*            <FormInputText value={this.state.leadInterest}
                           label="Interesse Lead"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se=>this.setState({leadInterest: se.target.value})} />*/}

            <LeadInterestSelect value={this.state.Interests}
                                label="Interesse Lead"
                                isMulti={false}
                                onChange={se=>{this.setState({Interests: se, leadInterest: se ? se.name : null})}}
                                direction={FormInputContainer.HORIZONTAL} />
          </div>

        </div>

        <FormDashline />

        <React.Fragment>
          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Nome" value={this.state.name}
                             requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="name"
                             onChange={se => this.setState({name: se.target.value})}/>
            </div>
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Cognome" value={this.state.surname}
                             direction={FormInputContainer.HORIZONTAL}
                             requiredField={true}
                             validation={this.state.validation} name="surname"
                             onChange={se => this.setState({surname: se.target.value})}/>
            </div>
            <div className="col-sm-12">
              <FormInputText upperCase={true} label="Ragione sociale" value={this.state.businessName}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="businessName"
                             onChange={se => this.setState({businessName: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Indirizzo" value={this.state.address}
                             validation={this.state.validation} name="address"
                             requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({address: se.target.value})}/>
            </div>
            <div className="col-sm-3">
              <FormInputText upperCase={true} label="Civico" value={this.state.civicNumber}
                             validation={this.state.validation} name="civicNumber"
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({civicNumber: se.target.value})}/>
            </div>
            <div className="col-sm-3">
              <FormInputText upperCase={true} label="CAP" value={this.state.cap}
                             validation={this.state.validation} name="cap"
                             direction={FormInputContainer.HORIZONTAL} requiredField={true}
                             onChange={se => this.setState({cap: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <FormInputText upperCase={true} label="Codice Fiscale / P.IVA" value={this.state.fiscalCode}
                             validation={this.state.validation} name="fiscalCode" requiredField={true}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({fiscalCode: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Provincia" value={this.state.province}
                             validation={this.state.validation} name="province"
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se => this.setState({province: se.target.value})}/>
            </div>
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Città" value={this.state.city}
                             direction={FormInputContainer.HORIZONTAL}
                             requiredField={true}
                             validation={this.state.validation} name="city"
                             onChange={se => this.setState({city: se.target.value})}/>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Telefono" value={this.state.telephone}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="telephone"
                             requiredField={true}
                             onChange={se => this.setState({telephone: se.target.value})}/>
            </div>
            <div className="col-sm-6">
              <FormInputText upperCase={true} label="Telefono Secondario" value={this.state.telephoneSec}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="telephoneSec"
                             onChange={se => this.setState({telephoneSec: se.target.value})}/>
            </div>
          </div>

          <FormInputText label="Email (obbligatorio)" value={this.state.email}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="email"
                         onChange={se => this.setState({email: se.target.value})}/>

          {/*<FormInputText upperCase={true} label="Note" value={this.state.note}
                         validation={this.state.validation} name="note"
                         direction={FormInputContainer.HORIZONTAL}
                         onChange={se => this.setState({note: se.target.value})}/>*/}

          <FormDashline/>

        </React.Fragment>

        <FormDashline />

        <div className={"row"}>
          <div className={"col-12"}>
            <AdvancedProductSelect onChange={Products => {
              this.setState({Products: Products})
            }} throughModelName={"EnelxSellProducts"}
                                   values={this.state.Products}/>
          </div>
        </div>

        <div className={"row"}>
          <div className="col-sm-6">
            <FormInputText label="Kw su fotovoltaico in trattativa" value={this.state.kw}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="kw"
                           onChange={se => this.setState({kw: se.target.value})}/>
          </div>
        </div>

        <div className={"row"}>
          <div className="col-sm-6">
            <FormInputText label="Prezzo" value={this.state.final_price}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="final_price"
                           onChange={se => this.setState({final_price: se.target.value})}/>
          </div>
        </div>

        <div className={"row"}>
          <div className="col-sm-6">
            <FormInputText label="Importo extra" value={this.state.final_price_extra}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="final_price_extra"
                           onChange={se => this.setState({final_price_extra: se.target.value})}/>
          </div>

          <div className="col-12">
            <TagsSelect
              direction={FormInputContainer.HORIZONTAL}
              onChange={tags=>{ this.setState({Tags: tags})}}
              value={this.state.Tags} canCreate={can('EnelxSells.tagsCreate')}
              entityName={"EnelxSells"} entityId={this.state.id} />
          </div>
        </div>

        <div style={{border: '3px dashed #CCCCCC', padding: '20px'}}>
          <NotesEditor notes={this.state.Notes} onChange={notes => {
            this.setState({Notes: notes})
          }} name={"EnelxSells"} id={this.state.id} />
        </div>

        <FormDashline />

        <label>Allegati</label>
        <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
                         filesId={this.state.filesId} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}


function EnelxSellsBusinessTypeSelect( props ){

  let values = {
    'B2C': 'B2C',
    'B2B': 'B2B',
    'Microbusiness': 'Microbusiness',
    'Rivenerg' : 'Rivenerg'
  };

  if( props.all )
    values['0'] = 'Tutti';

  return <FormInputSelect
    values={values}
    label="Settore di business"
    direction={FormInputContainer.HORIZONTAL}
    name="businessType"
    {...props}
  />;
}

/*
function EnelxSellWorkingStatusSelect( props ){

  let values = [
    'Da affidare ad agente',
    'Trattativa',
    'Attesa contatto',
    'Da inserire a sistema',
    'Attesa appuntamento',
    'Attesa esito appuntamento',
    'Attesa sopralluogo installatore',
    'Attesa Pagamento',
    'Attesa merce',
    'attesa installazione',
    'Attesa pratiche',
    'Attesa connessione',
    'Attesa benestare',
    'Fatturato',
    'Chiuso OK',
    'KO',
  ];

  values = Object.fromEntries( values.map( v => {
    return [ v , v ]
  }));

  if( props.all )
    values['all'] = 'Tutti';

  return <FormInputSelect values={ values } label="Stato vendita"
    direction={FormInputContainer.HORIZONTAL}
    name="workingStatus" {...props} />;
}

function EnelxSellWorkingStatus( props ){
  const st = props.workingStatus;

  const colors = {
    'Da affidare ad agente': 'violet',
    'Trattativa': 'violet',
    'Attesa contatto': 'violet',
    'Da inserire a sistema': 'violet',
    'Attesa appuntamento': '#e7e708',
    'Attesa esito appuntamento': '#e7e708',
    'Attesa sopralluogo installatore': '#e7e708',
    'Attesa Pagamento': '#e7e708',
    'Attesa merce':'blue',
    'Attesa benestare':'blue',
    'Fatturato': 'blue',
    'Attesa pratiche':'blue',
    'Chiuso OK' : 'green',
    'KO': 'red'
  };

  let color = colors[st];
  let fgColor = color == '#e7e708' ? 'black' : 'white';

  return <span className="label label-warning"
               style={{backgroundColor: color, color: fgColor}}>{st}</span>;
}*/

function EnelxSellCompleteStatus( props ){
  const st = props.complete_status;

  if( st == 'ok' )
    return <span className="label label-primary">OK</span>;
  if( st == 'ko' )
    return <span className="label label-danger">KO</span>;
  return <span className="label label-warning">LAV</span>;
}

class EnelxSellsTableStandard extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render(){

    const row = (entity) => {

      const origin = (entity.OriginTags && entity.OriginTags.length > 0 && entity.OriginTags[0].name) ?
        entity.OriginTags[0].name : '';

      return <React.Fragment><tr style={{}}>
        <td><EnelxSellsStateViewer entity={entity.EnelxSellsState} /></td>
        <td><EnelxSellsSubStateViewer entity={entity.EnelxSellsSubState} /></td>
        <td>{moment(entity.createdAt).format('DD/MM/YY HH:mm')}</td>
        <td>{moment(entity.updatedAt).format('DD/MM/YY HH:mm')}</td>
        <td>{origin == 'Risorsa' ? entity.flat_ResourceUser_name : origin}</td>
        <td>{entity.flat_InChargeUser_name}</td>
        <td>{entity.flat_Lead_fullName}</td>
        <td style={{fontSize:'8pt'}}>{entity.flat_Products_names}</td>
        <td>{entity.EnelxInstaller?entity.EnelxInstaller.name:'-'}</td>
        {/*<td>

          {entity.EnelxSellAppointments.length > 0 && entity.EnelxSellAppointments.map( app => {

            let status = <span className={"label label-warning"}>...</span>;
            if( app.Appointment.outcome == 'Ko' )
              status = <span className={"label label-danger"}>KO</span>;
            if( app.Appointment.outcome == 'Chiuso' )
              status = <span className={"label label-primary"}>OK</span>;

            let alertIcon = false;

            if( app.Appointment.termDate ){
              if( moment().isAfter( moment( app.Appointment.termDate ) ) )
                alertIcon = true;
            }

            return <button onClick={ se => {
              se.preventDefault();
              this.props.onAppointment({ enelxSellsAppointmentModal: { id: app.id } });
            } } className={"btn btn-block btn-xs btn-default"}>
              {alertIcon && <i className={"fa fa-exclamation-circle"} style={{color: 'red'}}></i>}
              &nbsp; {formatLeadName(app.Appointment.Agent)} &nbsp;
              {moment(app.Appointment.termDate).format('D/M HH:mm')}
              &nbsp; {status}
            </button>;
          })}

          {entity.EnelxSellAppointments.length < 1 && <span>Nessun appuntamento</span>}

          {can('EnelxSells.save') && <button class="btn-white btn btn-xs" onClick={se=>{
            se.preventDefault();
            this.props.onAppointment({enelxSellsAppointmentModal: {
                LeadId: entity.LeadId,
                EnelxSellId: entity.id
              }});
          }}><i className={"fa fa-plus"}></i> Nuovo</button> }
        </td>*/}
        <td>{entity.Contracts.map( contract => {
          return <div className={"btn-group"}>
            <button className="btn-white btn btn-xs" onClick={se => {
              se.preventDefault();
              this.setState({contractModal: contract});
            }}>{contract.uniqueUuid}</button>
            <button className={"btn btn-xs btn-danger"}
                    onClick={se => {
                      se.preventDefault();
                      this.props.onDetach(entity.id, contract.id)
                    }}>
              <i className={"fa fa-times"}></i>
            </button>
          </div>;
        })}
          <button class="btn-white btn btn-xs" onClick={se=>{
            se.preventDefault();
            this.setState({enelxSellsContractModal: entity});
          }}>Nuovo contratto</button>
        </td>
        <td>
          <div className="btn-group">

            { entity.flat_Lead_telephone &&
              <a className={"btn btn-white btn-xs"} href={"tel:" + entity.flat_Lead_telephone}>
                <i className={"fa fa-phone"}></i>
              </a> }

            {can('EnelxSells.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onView( entity );
            }}>Vedi</button>}

            {can('EnelxSells.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onNote( entity );
            }}>Note</button>}

            {can('EnelxSells.select') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onHistory( entity );
            }}>Storico</button>}
          </div>
        </td>
      </tr>
      </React.Fragment>
    };

    const columns = {
      workingStatus: 'Stato',
      workingSubStatus: 'Sotto stato',
      creaetdAt: {
        label: 'Creato il',
        sortBy: 'createdAt'
      },
      updatedAt: {
        sortBy: 'updatedAt',
        label: 'Agg. il'
      },
      resource: 'Lead',
      inCharge: 'Vendita',
      name: 'Cliente',
      products: 'Prodotti',
      appointments: 'Installatore',
      contract: 'Contratto',
      actions: '',
    };

    return <>
      {this.state.enelxSellsContractModal && <EnelxSellContract onDone={res=>{
        this.setState({enelxSellsContractModal: false});
      }} id={this.state.enelxSellsContractModal ? this.state.enelxSellsContractModal.id : false} />}

      {this.state.contractModal && <NewContractModal onDone={res=>{
        this.setState({contractModal: false});
      }} id={this.state.contractModal ? this.state.contractModal.id : false} />}
      
      <AbstractTableViewer model="EnelxSells"
                                filters={ this.props.filters }
                                row={row}
                                defaultSort={[['createdAt','DESC']]}
                                columns={columns}/>
      </>;
  }
}

class EnelxSellsTableLead extends React.Component {

  constructor(props) {
    super(props);

    this.state = {};
  }

  render(){

    const row = (entity) => {

      let products = '-';

      try {
        products = entity.Products.map( p => {
          return p.name;
        }).join(', ');
      } catch ( e ){}

      const origin = (entity.OriginTags && entity.OriginTags.length > 0 && entity.OriginTags[0].name) ?
        entity.OriginTags[0].name : '';

      return <React.Fragment><tr style={{}}>
        <td><EnelxSellsStateViewer entity={entity.EnelxSellsState} /></td>
        <td>{moment(entity.createdAt).format('DD/MM/YY HH:mm')}</td>
        <td>{origin === 'Risorsa' ? entity.flat_ResourceUser_name : origin}</td>
        <td>{entity.flat_Lead_fullName}</td>
        <td>{entity.flat_Lead_city} ({entity.flat_Lead_province})</td>
        <td>{entity.flat_Lead_telephone}</td>
        <td style={{fontSize:'8pt'}}>{entity.flat_Products_names}</td>
        <td>{entity.Contracts.map(contract => {
          return <div className={"btn-group"}>
            <button className="btn-white btn btn-xs" onClick={se => {
              se.preventDefault();
              this.setState({contractModal: contract});
            }}>{contract.uniqueUuid}</button>
            <button className={"btn btn-xs btn-danger"}
                    onClick={se => {
                      se.preventDefault();
                      this.props.onDetach(entity.id, contract.id)
                    }}>
              <i className={"fa fa-times"}></i>
            </button>
          </div>;
        })}
          <button className="btn-white btn btn-xs" onClick={se => {
            se.preventDefault();
            this.setState({enelxSellsContractModal: entity});
          }}>Nuovo contratto
          </button>
        </td>
        <td>
          {entity.businessType}
        </td>
        <td>{entity.leadInterest}</td>
        <td>
          <div className="btn-group">
            {can('EnelxSells.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onView( entity );
            }}>Vedi</button>}

            {can('EnelxSells.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onNote( entity );
            }}>Note</button>}

            {can('EnelxSells.select') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onHistory( entity );
            }}>Storico</button>}
          </div>
        </td>
      </tr>
      </React.Fragment>
    };

    const columns = {
      workingStatus: 'Stato',
      creaetdAt: 'Creato il',
      leadRes: 'Lead',
      resource: 'Nome',
      name: 'Città',
      tel: 'Telefono',
      products: 'Prodotti',
      contract: '',
      businessType: 'Bus',
      intereset: 'Interesse',
      actions: '',
    };

    return <>
      {this.state.enelxSellsContractModal && <EnelxSellContract onDone={res=>{
        this.setState({enelxSellsContractModal: false});
      }} id={this.state.enelxSellsContractModal ? this.state.enelxSellsContractModal.id : false} />}

      {this.state.contractModal && <NewContractModal onDone={res=>{
        this.setState({contractModal: false});
      }} id={this.state.contractModal ? this.state.contractModal.id : false} />}

      <AbstractTableViewer model="EnelxSells"
                           filters={ this.props.filters }
                           row={row}
                           columns={columns}/>
    </>;
  }
}

class EnelxSellsTableNotes extends React.Component {
  render(){

    const row = (entity) => {

      const origin = (entity.OriginTags && entity.OriginTags.length > 0 && entity.OriginTags[0].name) ?
        entity.OriginTags[0].name : '';

      return <React.Fragment><tr style={{}}>
        <td><EnelxSellsStateViewer entity={entity.EnelxSellsState} /></td>
        <td>{origin === 'Risorsa' ? entity.flat_ResourceUser_name : origin}</td>
        <td>{formatLeadName( entity.InChargeUser )}</td>
        <td>{formatLeadName( entity.Lead )}</td>
        <td><NotePlainViewer notes={entity.Notes} /></td>
        <td>
          <div className="btn-group">
            {can('EnelxSells.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onView( entity );
            }}>Vedi</button>}

            {can('EnelxSells.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onNote( entity );
            }}>Note</button>}

            {can('EnelxSellWorkingStatusHistories.select') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.props.onHistory( entity );
            }}>Storico</button>}
          </div>
        </td>
      </tr>
      </React.Fragment>
    };

    const columns = {
      workingStatus: 'Stato',
      resource: 'Risorsa',
      inCharge: 'Assegnatario',
      name: 'Lead',
      notes: 'Note',
      actions: '',
    };

    return <AbstractTableViewer model="EnelxSells"
      filters={ this.props.filters }
      row={row}
      columns={columns}/>
  }
}

class EnelxSells extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterWorkingStatus: 'all',
      filterResourceUsers: [],
      filterInChargeUsers: [],
      filterEnelxInstaller: [],
      filterLeadName: '',
      filterOrigin: 'all',
      filterTags: [],
      filterTagsEnelxSells: [],
      filterBusinessType: '',
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
      update: 0,
      ...(queryToObject())
    };

    this.onTableUpdate = this.onTableUpdate.bind( this );
    this.onDetach = this.onDetach.bind( this );
  }

  onDetach( EnelxSellsId, ContractId ){
    fetch( 'api' , 'EnelxSells' , 'detachContract' , EnelxSellsId, ContractId ).then( msg => {
      this.onTableUpdate();
    });
  }

  onTableUpdate( se ){
    if( se && se.preventDefault )
      se.preventDefault();

    this.setState({update: this.state.update+1});
  }

  getFilters(){
    return {
      EnelxSellsState: this.state.filterEnelxSellsState,
      EnelxSellsSubStates: this.state.filterEnelxSellsSubStates,
      leadName: this.state.filterLeadName.length >= 3 ? this.state.filterLeadName : null ,
      ResourceUsers: this.state.filterResourceUsers.map( u => { return u.id }),
      ResourceUserTags: this.state.filterResourceTags,
      InChargeUsers: this.state.filterInChargeUsers.map( u => { return u.id }),
      dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
      dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm'),
      EnelxInstaller: this.state.filterEnelxInstaller.map( u => { return u.id }),
      paymentType: this.state.filterPaymentType,
      products: this.state.filterProducts,
      tags: this.state.filterTags,
      tagsEnelxSells: this.state.filterTagsEnelxSells,
      origin: this.state.filterOrigin,
      telephone: this.state.filterTelephone,
      leadCity: this.state.filterLeadCity,
      leadCap: this.state.filterLeadCap,
      update: this.state.update,
      businessType: this.state.filterBusinessType,
      filterContract: this.state.filterContract,
      cacheIds: this.state.cacheIds,
    };
  }

  render(){

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
        <div className={"col-12"}>
          { can( 'EnelxSells.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({enelxSellsModal: true});
          }}>Nuova vendita</button>}

          {can('EnelxSells.export') && <button className="btn btn-primary" onClick={se => {
            se.preventDefault();
            showExportModal( 'EnelxSells' , this.getFilters() );
          }} style={{marginLeft: '10px'}}>Esporta Excel</button>}

          { can( 'EnelxSellsStates.save' ) && <Link to={"/enelx_states"} className={"btn btn-primary ml-2"}>Gestisci stati</Link>}

        </div>
      </div>

      {this.state.enelxSellsModal && <EnelxSellModal onDone={res=>{
        this.setState({enelxSellsModal: false});
        //this.onTableUpdate();
      }} id={this.state.enelxSellsModal === true ? false: this.state.enelxSellsModal} />}

      {this.state.enelxSellsAppointmentModal && <EnelxSellAppointment onDone={res=>{
        this.setState({enelxSellsAppointmentModal: false});
        //this.onTableUpdate();
      }} LeadId={this.state.enelxSellsAppointmentModal.LeadId}
          id={this.state.enelxSellsAppointmentModal.id}
         EnelxSellId={this.state.enelxSellsAppointmentModal ? this.state.enelxSellsAppointmentModal.EnelxSellId : false} />}

      {this.state.enelxSellsNotes && <NotesModal title={"Note / " + formatLeadName( this.state.enelxSellsNotes.Lead)} onDone={res=>{
        this.setState({enelxSellsNotes: false});
        //this.onTableUpdate();
      }} name={"EnelxSells"} id={this.state.enelxSellsNotes ? this.state.enelxSellsNotes.id : false} />}

      {this.state.enelxSellsHistoryModal && <EnelxSellHistory onDone={res=>{
        this.setState({enelxSellsHistoryModal: false});
      }} id={this.state.enelxSellsHistoryModal ? this.state.enelxSellsHistoryModal : false} />}


      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className="row">

                <div className={"col-4"}>
                  <EnelxSellsStatesSelect
                    label={"Filtro stato vendita"}
                    isMulti
                    all={true}
                    direction={FormInputContainer.HORIZONTAL}
                    value={this.state.filterEnelxSellsState}
                    onChange={se=>this.setState({filterEnelxSellsState: se})} />
                </div>

                <div className={"col-4"}>
                  <EnelxSellsSubStatesSelect
                    label={"Filtro sottostato vendita"}
                    isMulti
                    all={true}
                    direction={FormInputContainer.HORIZONTAL}
                    value={this.state.filterEnelxSellsSubStates}
                    onChange={se=>this.setState({filterEnelxSellsSubStates: se})} />
                </div>

                <div className={"col-4"}>
                  <EnelxInstallerSelect value={this.state.filterEnelxInstaller}
                                        onChange={se => {
                                          this.setState({filterEnelxInstaller: se ? se : []})
                                        }} label={"Installatore"} isMulti canCreate={false} />
                </div>

                <div className={"col-4"}>
                  <TagsSelect value={this.state.filterOrigin}
                              entityName={"EnelxSellOrigins"}
                              label="Origine" all={true}
                              direction={FormInputContainer.HORIZONTAL}
                              onChange={se => this.setState({
                                filterOrigin: se ? se : []
                              })}/>
                </div>

                <div className={"col-4"}>
                  <FormInputSelect value={this.state.filterBusinessType}
                                   direction={FormInputContainer.HORIZONTAL}
                                   label="Settore business"
                                   values={{
                                     '': 'Tutti',
                                     'B2C': 'B2C',
                                     'B2B': 'B2B',
                                     'Microbusiness': 'Microbusiness',
                                     'Rivenerg' : 'Rivenerg'
                                   }}
                                   onChange={se => this.setState({filterBusinessType: se})}/>
                </div>

                <div className={"col-4"}>
                  <UserSelect value={this.state.filterResourceUsers}
                      onChange={se => {
                        this.setState({filterResourceUsers: se ? se : []})
                      }} label={"Risorsa lead"} isMulti />
                </div>

                <div className={"col-4"}>
                  {can('Users.tags') &&
                  <TagsSelect value={this.state.filterResourceTags}
                              entityName={"Users"} label={"Tag Risorsa lead"}
                              direction={FormInputContainer.HORIZONTAL}
                              onChange={se => this.setState({
                                filterResourceTags: se ? se : []
                              })}/>
                  }
                </div>

                <div className={"col-4"}>
                  <UserSelect value={this.state.filterInChargeUsers}
                              onChange={se => {
                                this.setState({filterInChargeUsers: se ? se : []})
                              }} label={"Assegnatario"} isMulti />
                </div>

                <div className={"col-4"}>
                  <FormInputText label={"Nome lead"}
                     direction={FormInputContainer.HORIZONTAL}
                     value={this.state.filterLeadName}
                     onChange={se=>{this.setState({filterLeadName: se.target.value})}} />
                </div>

                <div className="col-lg-2">
                  <FormInputDate value={this.state.filterDateStart}
                                 key="sdate"
                                 label="Data inizio"
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => this.setState({filterDateStart: se})}/>
                </div>

                <div className="col-lg-2">
                  <FormInputDate value={this.state.filterDateEnd}
                                 key="edate"
                                 label="Data fine"
                                 direction={FormInputContainer.HORIZONTAL}
                                 onChange={se => this.setState({filterDateEnd: se})}/>
                </div>

                <div className={"col-4"}>
                  <FormInputSelect value={this.state.filterPaymentType}
                                   label="Tipologia pagamento"
                                   values={{
                                     '0': 'Tutti' ,
                                     'Non definito': 'Non definito' ,
                                     'Bonifico': 'Bonifico' ,
                                     'Finanziaria': 'Finanziaria'
                                   }}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>{this.setState({filterPaymentType: se})}} />
                </div>

                <div className={"col-4"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterTelephone: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Telefono lead"
                    value={this.state.filterTelephone}/>
                </div>

                <div className={"col-4"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterLeadCity: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Città lead"
                    value={this.state.filterLeadCity}/>
                </div>

                <div className={"col-2"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterLeadCap: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="CAP lead"
                    value={this.state.filterLeadCap}/>
                </div>

                { can( 'EnelxSells.filterProducts' ) && <div className={"col-6"}>
                  {can('Contracts.filterProducts') &&
                    <ProductSelect value={this.state.filterProducts}
                                   direction={FormInputContainer.HORIZONTAL}
                                   hideCategories={true}
                                   isMulti
                                   all={true}
                                   onChange={se => this.setState({
                                     filterProducts: se ? se : []
                                   })}/>
                  }
                </div>}

                <div className={"col-4"}>
                  {can('Products.tags') &&
                  <TagsSelect value={this.state.filterTags}
                     entityName={"Products"}
                    label="Filtro tag prodotti"
                     direction={FormInputContainer.HORIZONTAL}
                     onChange={se => this.setState({
                       filterTags: se ? se : []
                     })}/>
                  }
                </div>

                <div className={"col-4"}>
                  {can('EnelxSells.tags') &&
                    <TagsSelect value={this.state.filterTagsEnelxSells}
                                entityName={"EnelxSells"}
                                label="Filtro tag vendite"
                                direction={FormInputContainer.HORIZONTAL}
                                onChange={se => this.setState({
                                  filterTagsEnelxSells: se ? se : []
                                })}/>
                  }
                </div>

                <div className={"col-4"}>
                  <FormInputText
                    onChange={se => {
                      this.setState({filterContract: se.target.value})
                    }}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Codice contratto"
                    value={this.state.filterContract}/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className={"row"}>
                <div className={"col-lg-3 col-md-4"}>
                  <FormInputSelect values={{
                    'standard': 'Visualizzazione standard',
                    'notes': 'Visualizzazione note',
                    'lead': 'Visualizzazione lead',
                  }} value={this.state.viewType} onChange={se=>{
                    this.setState({viewType: se})}}
                  direction={FormInputContainer.HORIZONTAL}/>
                </div>
                <div className={"col-12"}>
                  {this.state.viewType == 'standard' && <EnelxSellsTableStandard filters={ this.getFilters() }
                    onView={entity=>{ this.setState({enelxSellsModal: entity.id}); }}
                    onNote={entity=>{ this.setState({enelxSellsNotes: entity}); }}
                    onAppointment={ data => { this.setState( data ) }}
                    onDetach={this.onDetach}
                    onHistory={entity=>{ this.setState({enelxSellsHistoryModal: entity.id}); }}/>}
                  {this.state.viewType == 'notes' && <EnelxSellsTableNotes filters={ this.getFilters() }
                     onView={entity=>{ this.setState({enelxSellsModal: entity.id}); }}
                     onNote={entity=>{ this.setState({enelxSellsNotes: entity}); }}
                     onDetach={this.onDetach}
                     onHistory={entity=>{ this.setState({enelxSellsHistoryModal: entity.id}); }}/>}
                  {this.state.viewType == 'lead' && <EnelxSellsTableLead filters={ this.getFilters() }
                     onView={entity=>{ this.setState({enelxSellsModal: entity.id}); }}
                     onNote={entity=>{ this.setState({enelxSellsNotes: entity}); }}
                     onDetach={this.onDetach}
                     onHistory={entity=>{ this.setState({enelxSellsHistoryModal: entity.id}); }}/>}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}

function EnelxSellsReportStatesItem( props ){
  return <>
    <span className={"label label-danger mr-2"} style={{backgroundColor: props.color}}>{props.value ? props.value : 0}</span>{props.name}
    {props.eye && <a className="btn btn-xs pull-right" href={encodeURI(props.eye)}
       target="_blak"><i className="fa fa-eye"></i></a> }
  </>;
}

function EnelxSellsReportStatesBox( props ){
  return <BoxContainer title={props.label}>
    <div className={"row mb-3"}>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"violet"} name={"Da affidare ad agente"}
                                    value={props.data['Da affidare ad agente']} eye={"/enelx?filterWorkingStatus=Da affidare ad agente"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"violet"} name={"Trattativa"}
                                    value={props.data['Trattativa']} eye={"/enelx?filterWorkingStatus=Trattativa"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"violet"} name={"Attesa contatto"}
                                    value={props.data['Attesa contatto']} eye={"/enelx?filterWorkingStatus=Attesa Contatto"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"violet"} name={"Da inserire a sistema"}
                                    value={props.data['Da inserire a sistema']} eye={"/enelx?filterWorkingStatus=Da inserire a sistema"} />
      </div>
    </div>

    <div className={"row mb-3"}>

      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"#e7e708"} name={"Attesa appuntamento"}
                                    value={props.data['Attesa appuntamento']} eye={"/enelx?filterWorkingStatus=Attesa appuntamento"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"#e7e708"} name={"Attesa esito appuntamento"}
                                    value={props.data['Attesa esito appuntamento']} eye={"/enelx?filterWorkingStatus=Attesa esito appuntamento"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"#e7e708"} name={"Attesa sopralluogo installatore"}
                                    value={props.data['Attesa sopralluogo installatore']} eye={"/enelx?filterWorkingStatus=Attesa sopralluogo installatore"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"#e7e708"} name={"Attesa Pagamento"}
                                    value={props.data['Attesa Pagamento']} eye={"/enelx?filterWorkingStatus=Attesa Pagamento"} />
      </div>
    </div>

    <div className={"row mb-3"}>

      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"blue"} name={"Attesa merce"}
                                    value={props.data['Attesa merce']} eye={"/enelx?filterWorkingStatus=Attesa merce"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"blue"} name={"Attesa benestare"}
                                    value={props.data['Attesa benestare']} eye={"/enelx?filterWorkingStatus=Attesa benestare"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"blue"} name={"Fatturato"}
                                    value={props.data['Fatturato']} eye={"/enelx?filterWorkingStatus=Fatturato"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"blue"} name={"Attesa pratiche"}
                                    value={props.data['Attesa pratiche']} eye={"/enelx?filterWorkingStatus=Attesa pratiche"} />
      </div>
    </div>

    <div className={"row mb-3"}>

      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"green"} name={"Chiuso OK"}
                                    value={props.data['Chiuso OK']} eye={"/enelx?filterWorkingStatus=Chiuso OK"} />
      </div>
      <div className={"col-3"}>
        <EnelxSellsReportStatesItem color={"red"} name={"KO"}
                                    value={props.data['KO']} eye={"/enelx?filterWorkingStatus=KO"} />
      </div>

    </div>
  </BoxContainer>
}


export { EnelxSells , EnelxSellsBusinessTypeSelect, EnelxInstallerSelect, EnelxSellModal };