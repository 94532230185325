import React from "react";
import moment from "moment";
import {BoxContainer, fetch, FormInputContainer, FormInputSelect, FormInputYear, Loader, Row} from "./Common";
import {Breadcumbs, can, ChannelSelect, ContractTypesSelect} from "./AppCommon";
import {ProductSelect} from "./Products";
import {Link} from "react-router-dom";

function Bar( props ){

  const vwFull = Math.ceil( ( props.value / props.total ) * 100 );

  return <tr>
    <td style={{padding: '3px 12px 12px 12px'}}>
      <div style={{
        width: vwFull + 'vw',
        height: '18px',
        backgroundColor: '#1ab394',
        color: '#4a4a4a',
        borderRadius: '3px',
        textAlign: 'center',
        fontSize: '8pt',
      }}>
        {vwFull}%
      </div>
    </td>

    { [...Array(12).keys()].map( m => {
      return <td></td>
    })}
    <td></td>

  </tr>;
}

class ReportProductsDetail extends React.Component {
  constructor( props ){
    super( props );

    this.state = {
      loading: true
    };

    this.updateData();
  }

  updateData(){
    this.setState({
      loading: true
    });

    fetch( 'api' , 'ReportProducts' , 'detail' , {
      pid: this.props.productId,
      filterMonth: this.props.filterMonth,
      filterYear: this.props.filterYear,
    }  ).then( msg => {
      this.setState({
        loading: false,
        data: msg.body});
    });
  }

  componentDidMount() {
    this.updateData();
  }

  render(){

    if( this.state.loading ){
      return <tr>
        <td>
          <Loader />
        </td>
      </tr>;
    }

    return <React.Fragment>
      {Object.keys( this.state.data ).map( key => {
        return <tr>
          <td>{key}</td>

          { [...Array(12).keys()].map( m => {
            return <td>{this.state.data[key][`m${m+1}`] ? this.state.data[key][`m${m+1}`]: 0}</td>
          })}

        </tr>;
      })}
    </React.Fragment>;
  }
}

class ReportProducts extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterMonth: (moment().month()+1) +'',
      filterYear: moment().year() +'',
      filterChannel: 0,
      filterContractType: 0,
      openedDetails: [],
    };
  }

  toggleDetail( rowId ){

    let openedDetails = this.state.openedDetails;

    if( openedDetails.indexOf( rowId ) != -1 ){
      openedDetails.splice( openedDetails.indexOf( rowId ) , 1 );
    } else {
      openedDetails.push( rowId );
    }

    this.setState({
      openedDetails: openedDetails
    });


  }

  componentDidMount(){
    this.updateData();
  }

  updateData(){
    this.setState({data: false});
    if( this.req )
      this.req.abort();

    this.req = fetch( 'api' , 'ReportProducts' , 'select' , this.state );
    this.req.then( msg => {
      this.setState({data: msg.body});
    }).catch(e=>{});
  }

  componentDidUpdate(prevProps,prevState){
    if( prevState.filterMonth != this.state.filterMonth ||
      prevState.filterYear != this.state.filterYear ||
      prevState.filterChannel != this.state.filterChannel ||
      prevState.filterContractType != this.state.filterContractType ||
      prevState.filterProducts != this.state.filterProducts ||
      prevState.filterStateType != this.state.filterStateType ){
      this.updateData();
    }
  }

  render(){
    let barCharData = [];
    let barChartKeys = [];

    let yearTotal = 0;

    let twelve = [...new Array(12).keys()];

    if( this.state.data ){

      yearTotal = this.state.data.reduce( (acc,value) => {
        return acc + (twelve.reduce( (ack,k) => {
          return ack + parseInt( value['m'+k] );
        }));
      } , 0 );
    }

    const m1 = moment().month(this.state.filterMonth-1).year(this.state.filterYear)
      .format('MM/YYYY');

    const m2 = moment( moment().month(this.state.filterMonth-1).year(this.state.filterYear) )
      .subtract(1,'month').format('MM/YYYY');

    if( this.state.data ){
      barCharData = this.state.data.map( row => {
          return { "pv": row.name , [m1]: row.m1, [m2]: row.m2 , color: '#2f4050' };
        }
      );

      barChartKeys = [ m1 , m2 ];
    }

    return <React.Fragment>
      <Breadcumbs title="Report produzione prodotti" items={{'Home': '/'}} />
      <div className="wrapper wrapper-content  animated fadeInRight">

        <Row>
          <div className={"col-md-12 btn-group"} style={{marginBottom: '20px'}}>
            {  can( 'Contracts.reportBusiness' )
            && <Link className={"btn btn-default"} to="/business_report">Reale</Link> }

            {  can( 'Products.reportBusiness' ) &&
            <Link className={"btn btn-primary"} to="/contracts_report_products">Prodotti</Link> }

            {  can( 'Users.reportBusiness' ) &&
            <Link className={"btn btn-default"} to="/contracts_report_users">Utenti</Link>}

            {  can( 'SellingPoints.reportBusiness' ) &&
            <Link className={"btn btn-default"} to="/contracts_report_selling_points">Punti vendita</Link>}
          </div>
        </Row>

        <BoxContainer>

          <Row>
            <div className={"col-md-12"}>
              <div className="alert alert-primary" role="alert">
                Dati live
              </div>
            </div>
          </Row>

          {true && <Row>
            <div className="col-lg-2">
              <FormInputYear value={this.state.filterYear}
                             onChange={se=>this.setState({filterYear: se})} />
            </div>

            <div className="col-lg-2">
              <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                               direction={FormInputContainer.HORIZONTAL}
                               name="filterStateType"
                               values={{
                                 '': 'Tutti',
                                 'success': 'Positivo',
                                 'warning': 'Neutro',
                                 'error': 'Negativo',
                               }}
                               onChange={se=>{this.setState({filterStateType: se})}} />
            </div>

            <div className="col-lg-4">
              <ProductSelect onChange={se=>{
                this.setState({filterProducts: se});
              }} direction={FormInputContainer.HORIZONTAL}
                             hideCategories={true}
                             isMulti
                             value={this.state.filterProducts} />
            </div>

            <div className="col-lg-4">
              <ChannelSelect value={this.state.filterChannel}
                             onChange={se=>{this.setState({filterChannel: se})}}
                             isMulti />
            </div>

            <div className="col-lg-4">
              <ContractTypesSelect value={this.state.filterContractType}
                                   onChange={se=>{this.setState({filterContractType: se})}}
                                   isMulti />
            </div>

          </Row>}


          <div className="row">

            {!this.state.data && <Loader />}

            {this.state.data && <React.Fragment>

              <table className="table table-hover table-mail table-altered-td">
                <thead>
                <tr>
                  <th>Prodotto</th>

                  <th>Gen</th>
                  <th>Feb</th>
                  <th>Mar</th>
                  <th>Apr</th>
                  <th>Mag</th>
                  <th>Giu</th>
                  <th>Lug</th>
                  <th>Ago</th>
                  <th>Set</th>
                  <th>Ott</th>
                  <th>Nov</th>
                  <th>Dic</th>

                  <th>{this.state.filterYear}</th>
                </tr>
                </thead>
                <tbody>
                {this.state.data.map( row => {

                  const opened = this.state.openedDetails.indexOf( row.pId ) != -1;

                  return <React.Fragment>
                    <tr onClick={se=>{ this.toggleDetail(row.pId) }}>
                      <td>
                        {opened && <i className={"fa fa-minus"}></i>}
                        {!opened && <i className={"fa fa-plus"}></i>}
                        &nbsp; {row.name}
                      </td>

                      { [...Array(12).keys()].map( m => {
                        return <td>{row['m' + (m+1)]}</td>
                      })}

                      <td>{[...Array(12).keys()].reduce( (acc,m) => {
                        return acc + parseInt( row['m' + (m+1)] );
                      } , 0)}</td>
                    </tr>

                    <Bar total={yearTotal} value={twelve.reduce( (acc,m) => {
                      return acc + parseInt( row['m' + (m+1)] );
                    } , 0)} />

                    { opened &&
                      <ReportProductsDetail
                        productId={row.pId}
                        filterYear={this.state.filterYear}
                        filterMonth={this.state.filterMonth}  /> }

                  </React.Fragment>;
                })}
                </tbody>
                <tfoot>
                <tr>
                  <th>Totale</th>
                  { [...Array(12).keys()].map( m => {
                    return <th>{
                      this.state.data.reduce((acc,cur)=>{
                        return acc+parseInt(cur['m'+(m+1)]);
                      },0)
                    }</th>
                  })}
                  <th>{yearTotal}</th>
                </tr>
                </tfoot>
              </table>

            </React.Fragment>}
          </div>
        </BoxContainer>
      </div>


    </React.Fragment>;
  }
}

export { ReportProducts };
