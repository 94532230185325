import {Breadcumbs} from "./AppCommon";
import React from "react";
import moment from "moment";
import {
  BoxContainer,
  fetch,
  FormInputContainer,
  FormInputDate,
  FormInputSelect,
  FormInputYear,
  Loader,
  Row
} from "./Common";

import {ProductSelect} from "./Products";
import {ResponsivePie} from "@nivo/pie";
import {ResponsiveBar} from "@nivo/bar";

function ContractPie( props ){

  const defaultPieTemplate = {
    margin: { top: 40, right: 40, bottom: 80, left: 80 },
    innerRadius: 0.5,
    cornerRadius: 5,
    enableRadialLabels: false,
    //radialLabel: 'label',
    borderWidth: 1,
    padAngle: 2,
    colors: (id,data)=>{
      return id[`color`];
    },
  };

  let colors = [ '#1ab394' , '#FF9730' , '#e35f5f' , '#1ab322'
    ,'#9cb31a' , '#5f80e3'];

  let pieData = [];

  if( props.data ) {
    pieData = Object.keys(props.data.totalByOrigin).filter( key => {
      return key != 'total';
    }).map(key => {
      return {
        id: key,
        label: key,
        color: colors.pop(),
        value: props.data.totalByOrigin[key]
      }
    });
  }

  return <ResponsivePie
    {...defaultPieTemplate}
    data={pieData}
    height={400}
    defs={[
      {
        id: 'dots',
        type: 'patternDots',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        size: 4,
        padding: 1,
        stagger: true
      },
      {
        id: 'lines',
        type: 'patternLines',
        background: 'inherit',
        color: 'rgba(255, 255, 255, 0.3)',
        rotation: -45,
        lineWidth: 6,
        spacing: 10
      }
    ]}
    fill={[ {
      match: { id: 'warning' },
      id: 'lines'
    } ]}
    legends={[
      {
        anchor: 'top-left',
        direction: 'column',
        translateY: 56,
        itemWidth: 70,
        itemHeight: 18,
        itemTextColor: '#999',
        symbolSize: 18,
        symbolShape: 'circle',
        effects: [
          {
            on: 'hover',
            style: {
              itemTextColor: '#000'
            }
          }
        ]
      }
    ]}
  />;
}

class ReportLead extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterDateStart: moment().subtract( 1 , 'month' ),
      filterDateEnd: moment(),
      infoOpen: false,
    };
  }

  componentDidMount(){
    this.updateData();
  }

  updateData(){
    this.setState({data: false});
    fetch( 'api' , 'ReportLeads' , 'select' , this.state ).then( msg => {
      this.setState({data: msg.body});
    });
  }

  componentDidUpdate(prevProps,prevState){
    if( this.state.filterRole != prevState.filterRole ||
      this.state.filterDateStart != prevState.filterDateStart ||
      this.state.filterType != prevState.filterType ||
      this.state.filterDateEnd != prevState.filterDateEnd ){
      this.updateData();
    }
  }

  render(){

    const defaultPieTemplate = {
      margin: { top: 40, right: 40, bottom: 100, left: 40 },
      innerRadius: 0.5,
      cornerRadius: 5,
      enableRadialLabels: false,
      //radialLabel: 'label',
      borderWidth: 1,
      padAngle: 2,
      colors: (id,data)=>{
        return id[`color`];
      },
    };

    let colors = [ '#1ab394' , '#FF9730' , '#e35f5f' ,
      '#1ab322' ,'#9cb31a' , '#5f80e3'];

    let pieData = [];

    let pieDataTotal = 0;

    let validKeys = [];

    if( this.state.data ) {

      validKeys = Object.keys(this.state.data.totalByOrigin).filter( key => {
        return key != 'total';
      });

      pieData = validKeys.map(key => {
        pieDataTotal += this.state.data.totalByOrigin[key].total;
        return {
          id: key,
          label: key,
          color: colors.pop(),
          value: this.state.data.totalByOrigin[key].total
        }
      });
    }

    let barCharData = [];
    let barChartKeys = [ 'Lead' , 'Contratti'];

    if( this.state.data ){
      barCharData = validKeys.map( key => {
        return {
          pv: key,
          "Lead": this.state.data.totalByOrigin[key].total,
          "Contratti": this.state.data.totalByOrigin[key].contractsTotal
        }
      });
    }

    return <React.Fragment>
      <Breadcumbs title="Report Lead" items={{'Home': '/'}} />
      <div className="wrapper wrapper-content  animated fadeInRight">
        <BoxContainer>

          <Row>

            <div className="col-md-2">
              <FormInputDate value={this.state.filterDateStart}
                             direction={FormInputContainer.HORIZONTAL}
                             label="Filtro data / Da"
                             onChange={se=>{this.setState({filterDateStart: se})}} />
            </div>

            <div className="col-md-2">
              <FormInputDate value={this.state.filterDateEnd}
                             direction={FormInputContainer.HORIZONTAL}
                             label="Filtro data / A"
                             onChange={se=>{this.setState({filterDateEnd: se})}} />
            </div>
          </Row>

          {!this.state.data && <Loader />}
          {this.state.data && <React.Fragment>
            <Row>
              <div className="col-md-12 col-lg-12">
                <h4>Totale: {pieDataTotal}</h4>
                <ResponsivePie
                  {...defaultPieTemplate}
                  data={pieData}
                  height={400}
                  defs={[
                    {
                      id: 'dots',
                      type: 'patternDots',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      size: 4,
                      padding: 1,
                      stagger: true
                    },
                    {
                      id: 'lines',
                      type: 'patternLines',
                      background: 'inherit',
                      color: 'rgba(255, 255, 255, 0.3)',
                      rotation: -45,
                      lineWidth: 6,
                      spacing: 10
                    }
                  ]}
                  fill={[ {
                    match: { id: 'warning' },
                    id: 'lines'
                  } ]}
                  legends={[
                    {
                      anchor: 'bottom',
                      direction: 'row',
                      itemDirection: 'left-to-right',
                      translateY: 56,
                      itemWidth: 150,
                      itemHeight: 18,
                      itemTextColor: '#999',
                      symbolSize: 18,
                      symbolShape: 'circle',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemTextColor: '#000'
                          }
                        }
                      ]
                    }
                  ]}
                />
              </div>
            </Row>
            <Row>

              <div className={"col-md-12"}>

                <ResponsiveBar
                  data={barCharData}
                  keys={barChartKeys}
                  indexBy="pv"
                  height={400}
                  groupMode={"grouped"}
                  enableLabel={false}
                  margin={{ top: 20, right: 20, bottom: 180, left: 40 }}
                  padding={0.3}
                  colors={ (id,data)=> {
                    console.log( 'aaa', id );
                    return id.id == 'Lead' ? '#2f4050' : '#2a995b' } }
                  legends={[]}
                  animate={true}
                  axisBottom={{
                    tickRotation: -60
                  }}
                  motionStiffness={90}
                  motionDamping={15}
                />

              </div>

            </Row>
          </React.Fragment>}

        </BoxContainer>
      </div>
    </React.Fragment>;
  }

}

export { ReportLead };