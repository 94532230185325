import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputYear, FormInputMonth, FormInputSelect
} from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
  apiFormInit , apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";

class MarketingReportJobs extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      scheduleModal: false,
      reports: false,
      update: 1,
    };

  }

  componentDidMount(){
    /*this.updateInterval = setInterval( () => {
      this.setState({ update: this.state.update +1 });
    } , 2000 );*/
  }

  componentWillUnmount() {
    //clearInterval( this.updateInterval );
  }

  render(){

    const columns = {
      startAt: 'Iniziato il' ,
      endAt: 'Completato il' ,
      contractHistoriesDone: 'Storici completati',
      date: 'Mensilità',
      type: 'Tipo',
      Userid: 'Utente'
    };

    const row = row => {

      let month = '-';

      try {
        if( row.year > 2000 )
          month = moment().startOf('year').month( row.month ).year( row.year ).format( 'MMMM YYYY');
      } catch ( e ){}

      return <tr>
        <td>{moment( row.startAt ).isValid() ? moment( row.startAt ).format( 'DD/MM/YYYY HH:mm' ) : '-'}</td>
        <td>{moment( row.endAt ).isValid() ? moment( row.endAt ).format( 'DD/MM/YYYY HH:mm' ) : '-'}</td>
        <td>{row.contractHistoriesDone} / {row.contractHistoriesCount}</td>
        <td>{month}</td>
        <td>
          {row.type == 'normal' ? 'Piano compensi' : ''}
          {row.type == 'recurring' ? 'Ricorrente' : ''}
        </td>
        <td>
          {row.User ? formatLeadName( row.User ) : 'Totale'}
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Stato processo ricalcolo piani" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
        { can( 'SellingPoints.save' ) && <button className="btn btn-primary" onClick={se=>{
          se.preventDefault();
          this.setState({scheduleModal: true});
        }}>Nuovo schedulazione</button>}
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="MarketingReportJobs"
                                       update={this.state.update}
                                       filters={{ }}
                                       row={row}
                                       columns={columns}/>
                </div>
              </div>
            </div>
          </div> }
        </div>

      </div>

      {this.state.scheduleModal && <ScheduleUserJobModal onDone={res=>{
        this.setState({scheduleModal: false});
      }} id={this.state.scheduleModal === true ? false: this.state.scheduleModal} />}

    </div>];
  }
}



class ScheduleUserJobModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      active: 1,
      name: '',
      year: moment().year() + '',
      month: (moment().month() -1)+'',
      type: 'normal'
    };

    this.onSubmit = this.onSubmit.bind( this );
  }

  componentDidMount(){
  }

  onSubmit( se ){
    fetch( 'api' , 'MarketingReportJobs' , 'schedule' , this.state ).then( msg => {
      this.props.onDone();
    });
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <FormInputYear label="Anno" value={this.state.year }
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="year"
                       onChange={se=>this.setState({year: se})} />

        <FormInputMonth label="Mese" value={this.state.month }
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="month"
                       onChange={se=>this.setState({month: se})} />

        <FormInputSelect values={{
          'normal': 'Pagamento contratti',
          'recurring': 'Ricorrenti',
        }} label="Tipo" value={this.state.type}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="type"
                         onChange={se=>this.setState({type: se})} />

        <UserSelect value={this.state.User}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Utente provvigionato"
                    onChange={se=>this.setState({ User: se })} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Esegui"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}


export { MarketingReportJobs };