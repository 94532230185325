import React from 'react';
import './App.css';
import moment from 'moment';

import { Link } from 'react-router-dom';

import { formatLeadName } from './Leads.js';

import { Loader , fetch, FormDashline, 
    FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputSelect, FormInputDate, FormInputTime } from './Common.js';

import  { LocalCache, Breadcumbs,
    AbstractTableViewer, can, AbstractApiFormFooter , apiFormInit , apiFormSave, AppointmentOriginSelect,
    AgentSelect, AppointmentStateSelect, AgentAppointmentsSummary } from './AppCommon';

class Appointments extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      opened: [],
      appointmentModal: false,
      outcomeModal: false,
      filterOrigin: false,
      filterAgentId: false,
      filterInternalId: '',
      filterOutcome: false,
      filterDateStart: moment().set('hour',1)/*.subtract(1,'month')*/
    };

  }


  render(){

    const columns = can( 'Appointments.viewAll') ? {
      DataMin: 'Data primo' , 
      DataMax: 'Data ultimo' , 
      selles: 'Lead' ,
      Opera: 'Operatore',
      Agente: 'Agente',
      Oggetto: 'Oggetto',
      Luogo: 'Luogo',
      ContractKw: 'Contratti / Kw' , 
      Esito: 'Esito appuntamento' ,
      Action: 'Azioni'
    } : { 
      DataMin: 'Data primo' , 
      DataMax: 'Data ultimo' , 
      selles: 'Lead' ,
      Agente: 'Agente',
      Oggetto: 'Oggetto',
      Luogo: 'Luogo',
      ContractKw: 'Contratti / Kw' , 
      Esito: 'Esito appuntamento' ,
      Action: 'Azioni'
    };

    const row = appointment => {

      const outcomed = appointment.Children.filter( c => {
        return c.outcomed;
      }).length > 0;

        return <React.Fragment><tr>
            <td>{moment(appointment.minCreatedAt).format('D MMM YY HH:mm')}</td>
            <td>{moment(appointment.maxCreatedAt).format('D MMM YY HH:mm')}</td>
            <td>{appointment.Lead ? 
              <Link to={'/leads_list/detail/'+appointment.Lead.id} >
                <span>{formatLeadName( appointment.Lead )}</span>
              </Link> : '-'}</td>
            {can( 'Appointments.viewAll') && <td>
              {appointment.Authors.length == 0 && '-'}
              {appointment.Authors.length == 1 && <React.Fragment>
                {appointment.Authors[0].name} {appointment.Authors[0].surname}
              </React.Fragment>}
              {appointment.Authors.length > 1 && 
                <span> {appointment.Authors[0].name} {appointment.Authors[0].surname}
                  e altri ( {appointment.Authors.length-1} ) </span> }
            </td>}
            <td>
              {appointment.Agents.length == 0 && 'Nessun agente assegnato' }

              {appointment.Agents.length == 1 && 
                <span> {appointment.Agents[0].name} {appointment.Agents[0].surname} </span>}

              {appointment.Agents.length > 1 && 
                <span> {appointment.Agents[0].name} {appointment.Agents[0].surname}
                  e altri ( {appointment.Agents.length-1} ) </span> }
            </td>
            <td>{appointment.Children.length == 1 ? appointment.Children[0].subject : '-'}</td>
            <td>{appointment.Lead ? appointment.Lead.city : '-'}</td>
            <td>{`${appointment.Contracts ? appointment.Contracts.length : 0} / ${appointment.ContractsKwTotal?appointment.ContractsKwTotal:'-'} Kw`}</td>
            <td>{appointment.Children.length == 1 ? appointment.Children[0].outcome : '-' }</td>
            <td>
              {appointment.Children.length == 1 && <React.Fragment> 
                <button className="btn btn-default btn-sm" 
                  onClick={se=>this.setState({appointmentModal: appointment.Children[0].id})}>Vedi</button>
                {(!outcomed && can('Appointments.outcome') ) && <button className="btn btn-default btn-sm" 
                  onClick={se=>this.setState({outcomeModal: appointment.Children[0].id})}>Esita</button>}
              </React.Fragment>}

              {appointment.Children.length > 1 && <React.Fragment>
                {this.state.opened.indexOf( appointment.LeadId ) == -1 && 
                  <button className="btn btn-default" 
                    onClick={se=>{
                      let opened = this.state.opened;
                      opened.push( appointment.LeadId );
                      this.setState({opened: opened});
                    } } > Apri {appointment.Children.length}</button>}

                {this.state.opened.indexOf( appointment.LeadId ) != -1 && 
                  <button className="btn btn-default" 
                    onClick={se=>{
                      let opened = this.state.opened;
                      opened.splice( opened.indexOf( appointment.LeadId ) , 1 );
                      this.setState({opened: opened});
                    } } > Chiudi {appointment.Children.length}</button>}
              </React.Fragment> }
            </td>
        </tr>

        {this.state.opened.indexOf( appointment.LeadId ) != -1 && <React.Fragment>
          {appointment.Children.map( appointment => {
            return <tr>
                <td></td>
                <td>{moment(appointment.termDate).format('D MMM YY HH:mm')}</td>
                <td></td>
                <td>
                  {appointment.Agent && 
                    <span> {appointment.Agent.name} {appointment.Agent.surname} </span>}
                </td>
                <td>{appointment.subject}</td>
                <td></td>
                <td></td>
                <td>{appointment.outcome}</td>
                <td>
                  <button className="btn btn-default btn-sm" 
                    onClick={se=>this.setState({appointmentModal: appointment.id})}>Vedi</button>

                  {(!outcomed &&  can('Appointments.outcome') ) && <button className="btn btn-default btn-sm" 
                    onClick={se=>this.setState({outcomeModal: appointment.id})}>Esita</button>}
                </td>
            </tr>;
          })}
        </React.Fragment> }

        </React.Fragment>;
      };

    return [ <Breadcumbs title="Appuntamenti" items={{'Home': '/'}} /> , <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Appointments.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({appointmentModal: true});
          }}>Nuovo appuntamento</button>}
      </div>

      {this.state.appointmentModal && <NewAppointmentModal origin='callcenter' onDone={res=>{
        this.setState({appointmentModal: false});
      }} id={this.state.appointmentModal === true ? false: this.state.appointmentModal} />}

      {this.state.outcomeModal && <AppointmentOutcome origin='callcenter' onDone={res=>{
        this.setState({outcomeModal: false});
      }} id={this.state.outcomeModal === true ? false: this.state.outcomeModal} />}

      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-lg-4">
                    <FormInputDate value={this.state.filterDateStart}
                      label="Data inizio"
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>this.setState({filterDateStart: se})} />
                  </div>

                  { can('Appointments.viewAll') && <div className="col-lg-4">
                     <AgentSelect value={this.state.filterAgent}
                      direction={FormInputContainer.HORIZONTAL}
                      all={true}
                      onChange={se=>this.setState({filterAgentId: se ? se.id : null, filterAgent: se})} /> 
                  </div>}

                  <div className="col-lg-4">
                    <AppointmentOriginSelect value={this.state.filterOrigin}
                      direction={FormInputContainer.HORIZONTAL}
                      all={true}
                      onChange={se=>this.setState({filterOrigin: se})} />
                  </div>

                  <div className="col-lg-4">
                    <FormInputSelect value={this.state.filterAssigned}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Filtra per assegnamento"
                      values={{
                        'all' : 'Tutti',
                        'unassigned' : 'NON Assegnati',
                      }}
                      onChange={se=>this.setState({filterAssigned: se})} />
                  </div>

                  <div className="col-lg-4">
                    <FormInputSelect value={this.state.filterOutcome}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Stato appuntamento"
                      values={{
                        '' : 'Tutti',
                        'Programmato': 'Programmato',
                        'Chiuso': 'Chiuso',
                        'Confermato': 'Confermato',
                        'Disdetto': 'Disdetto',
                        'Ko': 'Ko',
                        'Riprogrammato': 'Riprogrammato',
                        'Trattativa': 'Trattativa'
                      }}
                      onChange={se=>this.setState({filterOutcome: se})} />
                  </div>

                  <div className="col-lg-4">
                    <FormInputText value={this.state.filterInternalId}
                      label="ID Appuntamento"
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>this.setState({filterInternalId: se.target.value})} />
                  </div>

                </div>
              </div>
          </div>
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="Appointments" 
                    filters={{ termDateStart: this.state.filterDateStart,
                      outcome: this.state.filterOutcome,
                      origin: this.state.filterOrigin, 
                      internalId: this.state.filterInternalId,
                      assigned: this.state.filterAssigned,
                      AgentId: this.state.filterAgentId }}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div> ];
  }
} 

class AppointmentDetailView extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true
    };

    this.reloadData();
  }

  reloadData(){
    this.setState({loading: true});

    fetch( 'api' , 'Appointments' , 'get' , this.props.id ).then( data => {
      this.setState({...data.body,loading: false});
    })
  }

  render(){
    if( this.state.loading ) return <Loader />

    return <div className="row">
      <div className="col-sm-12">
        <dl className="dl-horizontal">

          <dt>Lead</dt>
          <dd><Link to={"leads_list/detail/"+this.state.id}>
            {formatLeadName( this.state.Lead )}</Link></dd>

          <dt>Oggetto</dt><dd>{this.state.subject}</dd>
          <dt>Origine</dt><dd>{this.state.origin}</dd>
          <dt>Stato</dt><dd>{this.state.state}</dd>
          <dt>Data appuntamento</dt><dd>{moment( this.state.termDate ).format('DD/MM/YYYY HH:mm')}</dd>
          <dt>Esito</dt><dd>{this.state.outcome}</dd>
          <dt>Note</dt><dd>{this.state.note}</dd>
        </dl>
      </div>
    </div>;
  }
}

class AppointmentOutcome extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      //LeadId: this.props.LeadId,
      origin: this.props.origin || 'error',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'Appointments', this );
  }

  onSubmit(se){
    se.preventDefault();
    /*if( this.state.id )
      return;*/
    apiFormSave( 'Appointments', this , {
      ...this.state,
      outcomed: true
    });
  }

  componentDidUpdate(prevProps, prevState){
    if( !this.state.loading && prevState.loading ){
      this.setState({initialAgentId: this.state.AgentId });
    }

    if( !prevState.LeadId && this.state.LeadId ){
      fetch( 'api' , 'Leads' , 'get' , this.props.LeadId ).then( msg => {
        this.setState({
          id: false,
          Lead: msg.body});
      });
    }

    if( !prevState.Lead && this.state.Lead ){
      this.setState({
        name: this.state.Lead.name,
        surname: this.state.Lead.surname,
        businessName: this.state.Lead.businessName,
        address: this.state.Lead.address,
        civicNumber: this.state.Lead.civicNumber,
        cap: this.state.Lead.cap,
        fiscalCode: this.state.Lead.fiscalCode,
        city: this.state.Lead.city,
        province: this.state.Lead.province,
        telephone: this.state.Lead.telephone,
        telephoneSec: this.state.Lead.telephoneSec,
        email: this.state.Lead.email,

        id: false
      })
    }
  }

  render(){
    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <h2>Esita appuntamento</h2>

        <FormInputSelect values={{
              'Chiuso': 'Chiuso',
              'Disdetto': 'Disdetto',
              'Ko': 'Ko',
              'Riprogrammato': 'Riprogrammato',
              'Trattativa': 'Trattativa'
          }} label="Esito" value={this.state.outcome}
          direction={FormInputContainer.HORIZONTAL}
          validation={this.state.validation} name="outcome"
          onChange={se=>this.setState({outcome: se})} />

        <FormInputText label="Note" value={this.state.note}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="note"
            onChange={se=>this.setState({note: se.target.value})} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Esita"  direction={FormInputContainer.HORIZONTAL} 
          onSave={this.onSubmit} 
          saving={this.state.apiFormSaving}
          saveEnable={this.state.Lead ? true : false}
          onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
          
      </form>
    </Modal>;
  }
}

class NewAppointmentModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          LeadId: this.props.LeadId,
          termDate: moment().format(),
          origin: this.props.origin || 'error'
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        if( LocalCache.get('loginData').User.Role.machineName == 'agent' )
          this.setState({AgentId: LocalCache.get('loginData').User.id });
        apiFormInit( 'Appointments', this );

        if( this.props.LeadId && !this.state.Lead ){
          fetch( 'api' , 'Leads' , 'get' , this.props.LeadId ).then( msg => {
            this.setState({Lead: msg.body});
          });
        }

      }

      onSubmit(se){
        se.preventDefault();
        /*if( this.state.id )
          return;*/
        apiFormSave( 'Appointments', this);
      }

      componentDidUpdate(prevProps, prevState){
        if( !this.state.loading && prevState.loading ){
          this.setState({initialAgentId: this.state.AgentId });
        }

        if( !prevState.Lead && this.state.Lead ){
          this.setState({
            name: this.state.Lead.name,
            surname: this.state.Lead.surname,
            businessName: this.state.Lead.businessName,
            address: this.state.Lead.address,
            civicNumber: this.state.Lead.civicNumber,
            cap: this.state.Lead.cap,
            fiscalCode: this.state.Lead.fiscalCode,
            city: this.state.Lead.city,
            province: this.state.Lead.province,
            telephone: this.state.Lead.telephone,
            telephoneSec: this.state.Lead.telephoneSec,
            email: this.state.Lead.email,
          })
        }
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>

            <h2>{this.state.id ? 'Visualizza ' : 'Nuovo ' } appuntamento</h2>

            <div>
                
                <div className="row">
                    <div className="col-sm-12">
                        <FormInputText label="ID Appuntamento" value={this.state.internalId}
                            direction={FormInputContainer.HORIZONTAL}
                            validation={this.state.validation} name="internalId"
                            onChange={se=>this.setState({internalId: se.target.value})} />
                    </div>
                    <div className="col-sm-6">
                        <FormInputText label="Nome" value={this.state.name}
                            direction={FormInputContainer.HORIZONTAL}
                            requiredField={true}
                            validation={this.state.validation} name="name"
                            onChange={se=>this.setState({name: se.target.value})} />
                    </div>
                    <div className="col-sm-6">
                        <FormInputText label="Cognome" value={this.state.surname}
                            direction={FormInputContainer.HORIZONTAL}
                            requiredField={true}
                            validation={this.state.validation} name="surname"
                            onChange={se=>this.setState({surname: se.target.value})} />
                    </div>
                    <div className="col-sm-12">
                        <FormInputText label="Ragione sociale" value={this.state.businessName}
                            direction={FormInputContainer.HORIZONTAL}
                            validation={this.state.validation} name="businessName"
                            onChange={se=>this.setState({businessName: se.target.value})} />
                    </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <FormInputText label="Indirizzo" value={this.state.address}
                      validation={this.state.validation} name="address"
                      requiredField={true}
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>this.setState({address: se.target.value})} />
                  </div>
                  <div className="col-sm-3">
                    <FormInputText label="Civico" value={this.state.civicNumber}
                      validation={this.state.validation} name="civicNumber"
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>this.setState({civicNumber: se.target.value})} />
                  </div>
                  <div className="col-sm-3">
                    <FormInputText label="CAP" value={this.state.cap}
                      validation={this.state.validation} name="cap"
                      requiredField={true}
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>this.setState({cap: se.target.value})} />
                  </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                      <FormInputText label="Codice Fiscale / P.IVA" value={this.state.fiscalCode}
                          validation={this.state.validation} name="fiscalCode" requiredField={true}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>this.setState({fiscalCode: se.target.value})} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                      <FormInputText label="Provincia" value={this.state.province}
                          validation={this.state.validation} name="province"
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>this.setState({province: se.target.value})} />
                    </div>
                    <div className="col-sm-6">
                      <FormInputText label="Città" value={this.state.city}
                          direction={FormInputContainer.HORIZONTAL}
                          requiredField={true}
                          validation={this.state.validation} name="city"
                          onChange={se=>this.setState({city: se.target.value})} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-6">
                        <FormInputText label="Telefono" value={this.state.telephone}
                          direction={FormInputContainer.HORIZONTAL}
                          requiredField={true}
                          validation={this.state.validation} name="telephone"
                          onChange={se=>this.setState({telephone: se.target.value})} />
                    </div>
                    <div className="col-sm-6">
                      <FormInputText label="Telefono Secondario" value={this.state.telephoneSec}
                            direction={FormInputContainer.HORIZONTAL}
                            validation={this.state.validation} name="telephoneSec"
                            onChange={se=>this.setState({telephoneSec: se.target.value})} />
                    </div>
                </div>

                <FormInputText label="Email" value={this.state.email}
                    direction={FormInputContainer.HORIZONTAL}
                    validation={this.state.validation} name="email"
                    onChange={se=>this.setState({email: se.target.value})} />

            </div>

            <FormInputDate value={this.state.termDate}
                direction={FormInputContainer.HORIZONTAL}
                label="Data appuntamento"
                validation={this.state.validation} name="termDate"
                onChange={se=>{this.setState({termDate: se})}} />

            <FormInputTime value={this.state.termDate}
                direction={FormInputContainer.HORIZONTAL}
                label="Orario appuntamento"
                validation={this.state.validation} name="termDate"
                onChange={se=>{this.setState({termDate: se})}} />

            { can('Appointments.assignToAgents') && <React.Fragment>
              <AgentSelect value={this.state.Agent}
                validation={this.state.validation} name="AgentId"
                onChange={se=>this.setState({Agent: se, AgentId: se ? se.id : null})} /> 

              <AgentAppointmentsSummary agent={this.state.Agent} />
              
              </React.Fragment>}

            <FormInputSelect values={{
                    'Primo appuntamento': 'Primo appuntamento',
                    'Secondo appuntamento': 'Secondo appuntamento',
                    'Terzo appuntamento': 'Terzo appuntamento',
                }} label="Oggetto" value={this.state.subject}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="subject"
                onChange={se=>this.setState({subject: se})} />

            <FormDashline />      

            <FormInputSelect values={{
                    'Programmato': 'Programmato',
                    'Confermato': 'Confermato',
                    /*'Chiuso': 'Chiuso',
                    'Disdetto': 'Disdetto',
                    'Ko': 'Ko',
                    'Riprogrammato': 'Riprogrammato',
                    'Trattativa': 'Trattativa'*/
                }} label="Esito" value={this.state.outcome}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="outcome"
                onChange={se=>this.setState({outcome: se})} />

            <FormInputText label="Note" value={this.state.note}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="note"
                onChange={se=>this.setState({note: se.target.value})} />

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva Appuntamento"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              saveEnable={(!this.state.id || !this.state.initialAgentId || can('Appointments.alwaysSave'))}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

export { Appointments , NewAppointmentModal, AppointmentDetailView , AppointmentOutcome };