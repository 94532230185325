import React from 'react';
import './App.css';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Loader , fetch, FormDashline, 
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputSelect, FormInputDate, FormInputTime } from './Common.js';

import { formatLeadName } from './Leads';

import  { ChannelSelect , Breadcumbs, can,
    AbstractTableViewer, AbstractApiFormFooter , apiFormInit , apiFormSave,
     AgentSelect } from './AppCommon';
import { UserSelect } from './Users';

class Calls extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      callModal: false,
      filterAuthorId: false,
      filterDateStart: false,
      filterDateEnd: false,
    };

  }


  render(){

    const columns = { 
      createdAt: 'Data - Ora' , 
      lead: 'Lead' ,
      agent: 'Eseguita da',
      subject: 'Oggetto',
      outcome: 'Esito'
    };

    const row = call => {
        return <tr>
            <td>{moment(call.callDate).format('D MMMM YYYY HH:mm')}</td>
            <td>{formatLeadName( call.Lead )}</td>
            <td>{call.AuthorUser ? `${call.AuthorUser.name} ${call.AuthorUser.surname}` : '-' }</td>
            <td>{call.subject}</td>
            <td>{call.outcome}</td>
        </tr>
      };

    return [ <Breadcumbs title="Chiamate" items={{'Home': '/'}} /> , <div className="wrapper wrapper-content  animated fadeInRight">

      {this.state.callModal && <NewCallModal onDone={res=>{
        this.setState({callModal: false});
        window.location.reload();
      }} id={this.state.callModal === true ? false: this.state.callModal} />}

      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-lg-4">
                    { can('Calls.filterFrom') && <UserSelect value={this.state.filterAuthor}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Eseguite da"
                      all={true}
                      onChange={se=>this.setState({
                      filterAuthorId: se ? se.id : null,filterAuthor: se})} /> }
                  </div>

                </div>
              </div>
          </div>
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="Calls" 
                    filters={{ AuthorUserId: this.state.filterAuthorId }}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>] ;
  }
}


class CallsDetailView extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true
    };

    this.reloadData();
  }

  reloadData(){
    this.setState({loading: true});

    fetch( 'api' , 'Calls' , 'get' , this.props.id ).then( data => {
      this.setState({...data.body,loading: false});
    })
  }

  render(){
    if( this.state.loading ) return <Loader />

    return <div className="row">
      <div className="col-sm-12">
        <dl className="dl-horizontal">

          <dt>Lead</dt>
          <dd><Link to={"leads_list/detail/"+this.state.id}>
            {formatLeadName( this.state.Lead )}</Link></dd>

          <dt>Data chiamata</dt><dd>{moment( this.state.callDate ).format('DD/MM/YYYY HH:mm')}</dd>
          <dt>Oggetto</dt><dd>{this.state.subject}</dd>
          <dt>Esito</dt><dd>{this.state.outcome}</dd>
          <dt>Note</dt><dd>{this.state.note}</dd>

        </dl>
      </div>
    </div>;
  }
}

class NewCallModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          callDate: moment(),
          LeadId: this.props.leadId
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'Calls', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'Calls', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>

            <FormInputDate label="Data" value={this.state.callDate}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="callDate"
            onChange={se=>this.setState({callDate: se})} />

            <FormInputTime label="Ora" value={this.state.callDate}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="callDate"
            onChange={se=>this.setState({callDate: se})} />

            <FormDashline />

            <FormInputSelect values={{
                'Prima chiamata': 'Prima chiamata',
                'Seconda chiamata': 'Seconda chiamata',
                'Terza chiamata': 'Terza chiamata',
            }} label="Oggetto" value={this.state.subject}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="subject"
            onChange={se=>this.setState({subject: se})} />

            <FormDashline />      

            <FormInputSelect values={{
              'Non Interessato': 'Non Interessato', 
              'Richiamo': 'Richiamo', 
              'Preso Appuntamento' : 'Preso Appuntamento' , 
              'Segreteria' : 'Segreteria' , 
              'Non Risposta' : 'Non Risposta' , 
              'Già Cliente': 'Già Cliente',
            }} label="Esito" value={this.state.outcome}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="outcome"
            onChange={se=>this.setState({outcome: se})} />

            <FormInputText label="Note" value={this.state.note}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="note"
            onChange={se=>this.setState({note: se.target.value})} />

            <FormDashline />
            <AbstractApiFormFooter {...this.state} />

            <FormButtons saveLabel="Salva Chiamata"  direction={FormInputContainer.HORIZONTAL} 
            onCancel={this.props.onDone}
            saving={this.state.apiFormSaving}
            onSave={this.onSubmit} />
        
          </form>
        </Modal>;
      }

}

export { Calls , NewCallModal, CallsDetailView };