import React from "react";
import moment from "moment";
import {
    BoxContainer,
    fetch, FormButtons,
    FormDashline,
    FormInputContainer,
    FormInputMonth,
    FormInputRadio,
    FormInputYear,
    Loader,
    Modal,
    Row
} from "./Common";
import {AbstractApiFormFooter, apiFormSave, Breadcumbs, ChannelSelect} from "./AppCommon";
import {ResponsivePie} from "@nivo/pie";
import {ResponsiveBar} from "@nivo/bar";
import {ContractStateSelect} from "./ContractStates";
import {ProductSelect} from "./Products";
import {ContractVoidCauseSelect} from "./ContractVoidCauses";

class ReportStatesNulledChannelsStatesModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            channels: false,
            statesByChannelId: this.props.value ? this.props.value : {},
        };

        this.onSubmit = this.onSubmit.bind(this);

        this.updateData();
    }

    updateData(){
        fetch( 'api', 'Channels', 'select' ).then( msg => {
            this.setState({channels: msg.body})
        });
    }

    onSubmit(se){
        se.preventDefault();
        this.props.onClose( this.state.statesByChannelId );
    }

    render() {

        if( !this.state.channels )
            return <Loader />;

        return <Modal onClose={this.props.onClose}>
            <form onSubmit={this.onSubmit}>

                {this.state.channels.map( channel => {
                    return <React.Fragment>
                        <h3>{channel.name}</h3>
                        <ContractStateSelect isMulti
                            value={this.state.statesByChannelId[channel.id]}
                             filters={{ChannelId: channel.id}}
                            onChange={se=>{
                                let current = this.state.statesByChannelId;
                                current[channel.id] = se;
                                this.setState({statesByChannelId:current})
                        }}/>

                        <FormDashline />
                    </React.Fragment>;
                })}



                <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             onCancel={se=>{se.preventDefault();if( this.props.onClose ) this.props.onClose(); }} />

            </form>
        </Modal>;
    }
}

export default class ReportStatesNulled extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filterMonth: (moment().month()) + '',
            filterYear: moment().year() + '',
            filterChannel: 0,
            settingModal: false,
            statesSettingsModal: false
        };

        this.updateData();

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.filterMonth != this.state.filterMonth ||
            prevState.filterChannel != this.state.filterChannel ||
            prevState.filterYear != this.state.filterYear) {
            this.updateData();
        }

        if (this.state.settingModal.key)
            if (!this.state.settings[this.state.settingModal.key])
                this.setState({
                    settings: {
                        ...this.state.settings,
                        [this.state.settingModal.key]: {}
                    }
                })

    }

    updateData() {
        this.setState({loading: true});
        var payload = {
            filterMonth: this.state.filterMonth,
            filterChannel: this.state.filterChannel,
            filterYear: this.state.filterYear,

        };

        if(this.state.settings /*&& this.state.settings.length>0*/ )
            payload['settings'] = this.state.settings

        fetch('api', 'ContractsExploded', 'reportStatesNulled', payload).then(msg => {
            this.setState({
                loading: false,
                ...msg.body
            });
        });
    }



    settingModalGet(keyName, defaultValue = undefined) {
        return this.state.settings[this.state.settingModal.key] ?
            this.state.settings[this.state.settingModal.key][keyName] : defaultValue
    }

    settingModalSet(keyName, value) {
        this.setState({
            settings: {
                ...this.state.settings,
                [this.state.settingModal.key]: {
                    ...this.state.settings[this.state.settingModal.key],
                    [keyName]: value,
                }
            }
        });
    }

    getIdsUrl(key) {
       /* if (this.state.data[key])
            return `/contracts_list?cacheIds=${this.state.data[key].ids}`;*/
        return `/contracts_list?cacheIds=${key}`;
        return "#";
    }

    render() {

        let nulledBarData = [];
        let nulledBarDataKeys = {};
        let nulledTotal = 0;



        var pieData = [];

        var subBars = [];


        var colors = ['#1ab394','#ff9730','#e35f5f','#1c84c6','#1ab394'];
        var colorSel = '#ff9730';
        var cc = 0;

        if (this.state.records) {

            cc =0;


            // PIE


            this.state.channels.map((row,id) => {

                console.log(id);

                if(row && row.length>0) {

                    if(row[0].ChannelId==1){ // PENP
                        colorSel = '#1ab394';
                    }else if(row[0].ChannelId==2){ // Agenzia
                        colorSel = '#72bdec';
                    }else if(row[0].ChannelId==3){ // Business
                        colorSel = '#1c84c6';
                    }else if(row[0].ChannelId==4){ // Enelx
                        colorSel = '#b94cf5';
                    }


                    pieData.push({
                        id: row[0].brandName + ' / ' + row[0].channelName,
                        label: row[0].brandName + ' / ' + row[0].channelName,
                        color: colorSel,
                        value: this.state.channels[id]
                            .map(i => {
                                return i.count
                            })
                            .reduce((a, c) => {
                                return a + c;
                            }, 0)
                    });
                }
                cc++;
            });




            // BAR



           nulledBarData = this.state.records.map(row => {
                nulledTotal =+ row.count;
                nulledBarDataKeys[JSON.stringify(row)] = 1;

               if(row.ChannelId==1){ // PENP
                   colorSel = '#1ab394';
               }else if(row.ChannelId==2){ // Agenzia
                   colorSel = '#72bdec';
               }else if(row.ChannelId==3){ // Business
                   colorSel = '#1c84c6';
               }else if(row.ChannelId==4){ // Enelx
                   colorSel = '#b94cf5';
               }

                return {
                    ...row,
                    color: colorSel
                };
            });



           // SUB BARS


            for(var i =0; i<4; i++){


                subBars[i] = {data:[],keys:[],total: 0};

                try {
                    subBars[i].data = this.state.subBars['subBars'+(i+1)].map(row => {
                        subBars[i].total+=row.count;
                        subBars[i].keys[JSON.stringify(row)] = 1;
                        return {
                            ...row,
                            color: colors[0]
                        };
                    });
                } catch(e){
                    console.error(e);
                }

            }

        }

        const pieDataTotal = pieData.reduce((a, c) => {
            return a + c.value;
        }, 0);


        const defaultPieTemplate = {
            margin: {top: 40, right: 40, bottom: 80, left: 40},
            innerRadius: 0.5,
            cornerRadius: 5,
            enableRadialLabels: false,
            //radialLabel: 'label',
            borderWidth: 1,
            padAngle: 2,
            colors: (id, data) => {
                return id[`color`];
            },
        };

        const defaultBarTemplate = {
            keys: ["count"],
            margin: {left:30},
            layout: "horizontal",
            height: 600,
            padding: 0.5,
            animate: true,
            // colorBy: 'ChannelId',
            // colors: colors,
            isInteractive: false,
            colors: (id, data) => {
                return id.data.color;
            },
            enableLabel: true,
            //  label: d => { return `${d.id}: ${d.value}`; /*r.brandName + ' / ' + r.channelName + ' - ' + r.stateName*/ },

            labelSkipWidth: 0,
            axisLeft:{

                renderTick: (tick) => {

                    let text = '-error-';
                    //console.log(tick);

                    var o = {};
                    try {
                         o = JSON.parse(tick.value);



                        text = `${o.stateName} [ ${o.count} ]`;

                        if (o.channelName) {
                            text = `${o.channelName} - ${text}`;

                            if (o.brandName) {
                                text = `${o.brandName} / ${text}`;
                            }
                        }

                    } catch (e) {

                        console.log('ERRORE JSONNNNNN');

                    }

                    return (
                        <>
                            <g transform={`translate(${tick.x-25},${tick.y})`}>
                                <a xlinkHref={this.getIdsUrl(o.ids)}
                                   target="_blank">
                                <text style={{
                                fontFamily: 'FontAwesome',
                                    fill: '#007bff'
                            }} x="0" y="0">&#xf06e;</text>
                                </a>

                            </g>
                        <g transform={`translate(${tick.x},${tick.y - 27})`}>
                            <text
                                textAnchor="left"
                                dominantBaseline="middle"
                                style={{
                                    fontSize: 13,
                                }}
                            >
                                {text}
                            </text>
                        </g>
                            </>
                    );
                }

            },
            indexBy: 'raw',
            borderWidth: 1,
            borderRadius: 3,
            motionStiffness: 90,
            motionDamping: 15,
        }

        const defaultSubBarTemplate = {
            ...defaultBarTemplate,

            ...{
                margin: {left:30},
                axisLeft:{

                    renderTick: (tick) => {

                        let text = '-error-';
                        //console.log(tick);
                        var o = {};
                        try {
                             o = JSON.parse(tick.value);



                            text = `${o.stateName} [ ${o.count} ]`;

                            if (o.channelName) {
                                text = `${o.channelName} - ${text}`;

                                if (o.brandName) {
                                    text = `${o.brandName} / ${text}`;
                                }
                            }

                        } catch (e) {

                            console.log('ERRORE JSONNNNNN');

                        }

                        return (
                            <>
                                <g transform={`translate(${tick.x-25},${tick.y})`}>
                                    <a xlinkHref={this.getIdsUrl(o.ids)}
                                       target="_blank">
                                        <text style={{
                                            fontFamily: 'FontAwesome',
                                            fill: '#007bff'
                                        }} x="0" y="0">&#xf06e;</text>
                                    </a>

                                </g>
                            <g transform={`translate(${tick.x},${tick.y - 27})`}>
                                <text
                                    textAnchor="left"
                                    dominantBaseline="middle"
                                    style={{
                                        fontSize: 11,
                                    }}
                                >
                                    {text}
                                </text>
                            </g>
                                </>
                        );
                    }

                }
            }
        }


        return <React.Fragment>
            <Breadcumbs title="Report stati annullati" items={{'Report stati annullati': '/'}}/>

            {this.state.statesSettingsModal && <ReportStatesNulledChannelsStatesModal
                value={this.state.settings.statesByChannelId}
                onClose={se=>{

                    if( se ) {
                        this.state.settings.statesByChannelId = se;
                        this.setState({settings: this.state.settings});
                        this.updateData();
                    }

                    this.setState({statesSettingsModal: false})
                }}
            />}

            {this.state.settingModal && <Modal onClose={se => {
                this.updateData();
                this.setState({settingModal: false})
            }}>
                <h3>{this.state.settingModal.name}</h3>


                <Row>
                    <div className="col-md-12">
                        <ContractVoidCauseSelect value={
                            this.state.settings[this.state.settingModal.key] ?
                                this.state.settings[this.state.settingModal.key].states : undefined}
                                 onChange={se => {
                                     this.setState({
                                         settings: {
                                             ...this.state.settings,
                                             [this.state.settingModal.key]: {
                                                 ...this.state.settings[this.state.settingModal.key],
                                                 states: se
                                             }
                                         }
                                     });
                                 }}
                                 direction={FormInputContainer.HORIZONTAL}/>

                    </div>
                </Row>

                <FormButtons cancelLabel="Chiudi"  direction={FormInputContainer.HORIZONTAL}
                             saveEnable={false}
                             onCancel={se=>{this.updateData();
                                 this.setState({settingModal: false})}} />

            </Modal>}
            <div className="wrapper wrapper-content  animated fadeInRight">

                <Row>
                    <div className="col-lg-12">
                        <BoxContainer>
                            <Row>
                                <div className="col-lg-2">
                                    <FormInputMonth value={this.state.filterMonth}
                                                    allMonths={true}
                                                    onChange={se => {
                                                        this.setState({filterMonth: se});
                                                    }}/>
                                </div>
                                <div className="col-lg-2">
                                    <FormInputYear value={this.state.filterYear}
                                                   onChange={se => {
                                                       this.setState({filterYear: se});
                                                   }}/>
                                </div>
 {/*                               <div className="col-lg-4 col-md-4">
                                    <ChannelSelect value={this.state.filterChannel}
                                                   isMulti
                                                   onChange={se => {
                                                       this.setState({filterChannel: se});
                                                   }}/>
                                </div>*/}
                            </Row>
                        </BoxContainer>
                    </div>
                </Row>

                {this.state.loading && <Loader/>}
                {!this.state.loading && <React.Fragment>

                    <Row>
                        <div className="col-lg-5 col-md-12">
                            <BoxContainer title={`Resoconto stati ( Totali: ${pieDataTotal} )`}
                              tools={(<button className="btn btn-xs"
                                onClick={se => { this.setState({ statesSettingsModal: true }) }}>
                                  <i className="fa fa-gear"></i></button>)}>

                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsivePie
                                            {...defaultPieTemplate}
                                            data={pieData}
                                            margin={{bottom:50}}
                                            height={400}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                    size: 6,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: 'rgba(255, 255, 255, 0.3)',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[{
                                                match: {id: 'warning'},
                                                id: 'lines'
                                            }]}
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'bottom-left',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 50,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }]}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <BoxContainer title={`Dettagli stati annullati ( Totali: ${pieDataTotal} )`}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultBarTemplate}
                                            height={(Object.keys(nulledBarDataKeys).length*55) + 50}
                                            data={nulledBarData}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                    </Row>
                    {subBars.length>0 &&
                    <Row>

                        <div className=" col-12 col-md-6 col-lg-6 col-xl-3">
                            <BoxContainer title={`Motivo ( Totali: ${subBars[0].total} )`}
                            tools={(<button className="btn btn-xs"
                                        onClick={se => {
                                            this.setState({
                                                settingModal: {
                                                    name: 'Motivo',
                                                    key: 'subBars1'
                                                }
                                            })
                                        }}><i className="fa fa-gear"></i></button>)}
                            >
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultSubBarTemplate}
                                            height={(Object.keys(subBars[0].keys).length*55)+70}
                                            colors={['#ff9730']}
                                            data={subBars[0].data}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className=" col-12 col-md-6 col-lg-6 col-xl-3">
                            <BoxContainer title={`Recuperati ( Totali: ${subBars[1].total} )`}
                                          tools={(<button className="btn btn-xs"
                                                          onClick={se => {
                                                              this.setState({
                                                                  settingModal: {
                                                                      name: 'Recuperati',
                                                                      key: 'subBars2'
                                                                  }
                                                              })
                                                          }}><i className="fa fa-gear"></i></button>)}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultSubBarTemplate}
                                            height={(Object.keys(subBars[1].keys).length*55)+70}
                                            colors={['#ff9730']}
                                            data={subBars[1].data}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className=" col-12 col-md-6 col-lg-6 col-xl-3">
                            <BoxContainer title={`Da Recuperare ( Totali: ${subBars[2].total} )`}
                                          tools={(<button className="btn btn-xs"
                                                          onClick={se => {
                                                              this.setState({
                                                                  settingModal: {
                                                                      name: 'Da Recuperare',
                                                                      key: 'subBars3'
                                                                  }
                                                              })
                                                          }}><i className="fa fa-gear"></i></button>)}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultSubBarTemplate}
                                            height={(Object.keys(subBars[2].keys).length*55)+70}
                                            colors={['#ff9730']}
                                            data={subBars[2].data}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                        <div className=" col-12 col-md-6 col-lg-6 col-xl-3">
                            <BoxContainer title={`Non Recuperati ( Totali: ${subBars[3].total} )`}
                                          tools={(<button className="btn btn-xs"
                                                          onClick={se => {
                                                              this.setState({
                                                                  settingModal: {
                                                                      name: 'Non recuperati',
                                                                      key: 'subBars4'
                                                                  }
                                                              })
                                                          }}><i className="fa fa-gear"></i></button>)}>
                                <Row>
                                    <div className="col-lg-12">
                                        <ResponsiveBar
                                            {...defaultSubBarTemplate}
                                            height={(Object.keys(subBars[3].keys).length*50)+70}
                                            colors={['#ff9730']}
                                            data={subBars[3].data}
                                        />
                                    </div>
                                </Row>
                            </BoxContainer>
                        </div>

                    </Row>}

                </React.Fragment>}
            </div>
        </React.Fragment>;
    }
}