import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, Row, FormInputDate
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, RoleSelect
} from './AppCommon';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";

class UserManagers extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      userManagerModal: false,
      reports: false
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = {
      name: 'Utente' ,
      actions: 'Responsabile',
      from: 'Da',
      to: 'A',
      Action: ''
    };

    const row = userManager => {
      return <tr>
        <td>{formatLeadName( userManager.User )}</td>
        <td>{formatLeadName( userManager.Manager )}</td>
        <td>{moment(userManager.from).format('DD/MM/YY')}</td>
        <td>{moment(userManager.to).format('DD/MM/YY')}</td>
        <td>
          <div class="btn-group">
            {can('UserManagers.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({userManagerModal: userManager.id});
            }}>Vedi</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Responsabili" items={{'Home': '/' , 'Utenti':'/users_list'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
        { can( 'UserManagers.save' ) && <button className="btn btn-primary" onClick={se=>{
          se.preventDefault();
          this.setState({userManagerModal: true});
        }}>Nuovo responsabile</button>}
      </div>

      <div className="row">

        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <Row>
                <div className="col-md-4">
                  <UserSelect value={this.state.filterUsers}
                        direction={FormInputContainer.HORIZONTAL}
                        label="Utenti" isMulti
                        onChange={se=>this.setState({ filterUsers: se })} />
                </div>

                <div className="col-md-4">
                  <UserSelect value={this.state.filterManagers}
                              direction={FormInputContainer.HORIZONTAL}
                              label="Responsabili" isMulti
                              onChange={se=>this.setState({ filterManagers: se })} />
                </div>

              </Row>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="UserManagers"
                     filters={{
                       users: this.state.filterUsers,
                       managers: this.state.filterManagers,
                     }}
                     row={row}
                     columns={columns}/>
                </div>
              </div>
            </div>
          </div> }
        </div>
      </div>

      {this.state.userManagerModal && <NewUserManagerModal onDone={res=>{
        this.setState({userManagerModal: false});
      }} id={this.state.userManagerModal === true ? false: this.state.userManagerModal} />}




    </div>];
  }
}



class NewUserManagerModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      active: 1,
      name: '',
      from: moment(),
      to: moment(),
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'UserManagers', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'UserManagers', this);
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <UserSelect value={this.state.User}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Utente"
                    onChange={se=>this.setState({ User: se , UserId: se.id })} />

        <UserSelect value={this.state.Manager}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Responsabile"
                    onChange={se=>this.setState({ Manager: se , ManagerId: se.id })} />

        <FormInputDate value={this.state.from}
                       direction={FormInputContainer.HORIZONTAL}
                       label="Da"
                       validation={this.state.validation} name="from"
                       onChange={se=>{this.setState({from: se})}} />

        <FormInputDate value={this.state.to}
                       direction={FormInputContainer.HORIZONTAL}
                       label="A"
                       validation={this.state.validation} name="to"
                       onChange={se=>{this.setState({to: se})}} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva responsabile"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

export { UserManagers , NewUserManagerModal };