import React from "react";
import {
  BoxContainer,
  fetch,
  FormButtons,
  FormInputColorSimple,
  FormInputContainer,
  FormInputText,
  Loader,
  Modal
} from "./Common";
import {Breadcumbs} from "./AppCommon";
import {EnelxSellsStatesSelect} from "./EnelxSellsStates";

class NewBoxModal extends React.Component {

  constructor(props) {
    super( props );

    this.onSubmit = this.onSubmit.bind( this );
    this.onDelete = this.onDelete.bind( this );

    this.state = {};

    if( props.boxId >= 0 ){
      this.state = { ...this.props.boxes[this.props.boxId], };
    }
  }

  onDelete( se ){
    let boxes = this.props.boxes;

    boxes.splice( this.props.boxId , 1 );

    this.setState({ loading: true });

    fetch( 'api' , 'EnelxSells' , 'statesReport' , { boxes: boxes } ).then( msg => {
      this.props.onClose( boxes );
    });

  }

  onSubmit( se ){
    let boxes = this.props.boxes;

    let content = {
      title: this.state.title,
      elements: this.state.elements,
    };

    if( this.props.boxId >= 0 ){
      boxes[this.props.boxId] = content;
    } else {
      boxes.push( content );
    }

    this.setState({ loading: true });

    fetch( 'api' , 'EnelxSells' , 'statesReport' , { boxes: boxes } ).then( msg => {
      this.props.onClose( boxes );
    });
  }

  render(){

    if( this.state.loading )
      return <Loader />;

    return <Modal onClose={this.props.onClose}>
      <h4>Modifica scheda</h4>
      <FormInputText label="Nome scheda" value={this.state.title}
                     direction={FormInputContainer.HORIZONTAL}
                     validation={this.state.validation} name="title"
                     onChange={se=>this.setState({title: se.target.value})} />

      {this.props.boxId >= 0 &&
      <div className={"text-right"}>
        <button className={"btn btn-danger btn-xs text-right"} onClick={this.onDelete}>Elimina scheda</button>
      </div>}

      <FormButtons saveLabel="Salva" cancelLabel={false}  direction={FormInputContainer.HORIZONTAL}
                   onSave={this.onSubmit}       />

    </Modal>
  }
}

class NewElementModal extends React.Component {

  constructor(props) {
    super( props );

    this.onSubmit = this.onSubmit.bind( this );
    this.onDelete = this.onDelete.bind( this );

    this.state = {};

    if( props.elementId >= 0 ){
      this.state = { ...this.props.boxes[this.props.boxId].elements[props.elementId], };
    }
  }

  onDelete( se ){
    let boxes = this.props.boxes;
    let box = boxes[this.props.boxId];

    box.elements.splice( this.props.elementId , 1 );

    this.setState({ loading: true });

    fetch( 'api' , 'EnelxSells' , 'statesReport' , { boxes: boxes } ).then( msg => {
      this.props.onClose( boxes );
    });

  }

  onSubmit( se ){
    let boxes = this.props.boxes;
    let box = boxes[this.props.boxId];

    if( !box.elements )
      box.elements = [];

    let content = {
      title: this.state.title,
      color: this.state.color,
      states: this.state.states,
    };

    if( this.props.elementId >= 0 ){
      box.elements[this.props.elementId] = content;
    } else {
      box.elements.push( content );
    }

    this.setState({ loading: true });

    fetch( 'api' , 'EnelxSells' , 'statesReport' , { boxes: boxes } ).then( msg => {
      this.props.onClose( boxes );
    });
  }

  render(){

    if( this.state.loading )
      return <Loader />;

    return <Modal onClose={this.props.onClose}>
      <h4>Modifica elemento</h4>

      <FormInputText label="Nome elemento" value={this.state.title}
         direction={FormInputContainer.HORIZONTAL}
         validation={this.state.validation} name="title"
         onChange={se=>this.setState({title: se.target.value})} />

      <EnelxSellsStatesSelect value={this.state.states}
                              isMulti={true}
                              onChange={se=>{this.setState({states: se})}} />

      <FormInputColorSimple label="Colore" value={this.state.color}
                            colors={{
                              '#ffd966': 'Giallo',
                              '#d47272': 'Rosso',
                              '#eb9f65': 'Marrone',
                              '#98db8c': 'Verde',
                              '#8290e0': 'Blu',
                              '#bb8ee8': 'Viola',
                            }}
                            direction={FormInputContainer.HORIZONTAL}
                            validation={this.state.validation} name="color"
                            onChange={se=>this.setState({color: se.target.value})} />

      {this.props.elementId >= 0 &&
        <div className={"text-right"}>
          <button className={"btn btn-danger btn-xs text-right"} onClick={this.onDelete}>Elimina elemento</button>
        </div>}


      <FormButtons saveLabel="Salva" cancelLabel={false}  direction={FormInputContainer.HORIZONTAL}
                   onSave={this.onSubmit}       />

    </Modal>
  }
}

function ElementViewer( props ){
  return <div className={"row"}>
    <div className={"col-9"}>
      <span className={"label mr-1"} style={{backgroundColor: props.element.color}}>
        {props.element.total}
      </span>
      {props.element.title}
    </div>
    <div className={"col-3 text-right"}>
      <a href={props.element.uuid ? '/enelx?filterDateStart=01-01-2000&cacheIds=' + props.element.uuid : "#"} target={"_blank"}>
        <i className={"fa fa-eye"}></i> </a>
      <button className={"btn btn-xs btn-default"} onClick={props.onEdit}>
        <i className={"fa fa-edit"}></i>
      </button>
    </div>
  </div>;
}

function BoxViewer( props ){
  return <BoxContainer title={props.box.title} tools={<>

    <button className={"btn-white btn-xs"} onClick={se=>{
      se.preventDefault();
      props.onEdit();
    }}>
      <i className={"fa fa-pencil"}></i>
    </button>

  </>}>

    { props.box.elements && props.box.elements.map( (element,elementId) => {
      return <ElementViewer element={element} onEdit={se=>{
        se.preventDefault();
        props.onAdd( elementId )
      }} />
    })}

    <button className={"btn-white btn-xs"} onClick={se=>{
      se.preventDefault();
      props.onAdd( -1 )
    }}>
      <i className={"fa fa-plus"}>Aggiungi elemento</i>
    </button>

  </BoxContainer>;
}

class EnelxSellsStatesReport extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      boxes: [],
      newElementModal: null,
    };

    this.newElement = this.newElement.bind( this );

    this.reloadData();
  }

  reloadData(){
    this.setState({loading: true});

    fetch( 'api' , 'EnelxSells' , 'statesReport' ).then( msg => {
      this.setState({
        boxes: msg.body.boxes,
        loading: false,
      });
    });

  }

  newElement( se ){
    se.preventDefault();
  }

  deleteBox( se ){
    se.preventDefault();
  }

  render(){
    if( this.state.loading )
      return <Loader />;

    return [ <Breadcumbs title="Report stati EnelX" items={{'Home': '/'}} /> ,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className={"row mb-2"}>
          <div className={"col-12"}>
            <button className={"btn btn-primary"} onClick={se=>{
              se.preventDefault();
              this.setState({newBox: -1});
            }}>Nuova scheda</button>
          </div>
        </div>

        <div className={"row"}>

          {this.state.newElementModal != null && <NewElementModal onClose={se=>{
            this.setState({ newElementModal: null });
            this.reloadData();
          }} boxes={this.state.boxes}
            boxId={this.state.newElementModalBoxId}
            elementId={this.state.newElementModal}
          />}

          {this.state.newBox != null && <NewBoxModal onClose={se=>{
            this.setState({ newBox: null });
            this.reloadData();
          }} boxes={this.state.boxes} boxId={this.state.newBox} />}


          {this.state.boxes.map( (box,boxId) => {
            return <div className={"col-lg-4 col-md-4"}>
              <BoxViewer box={box} onAdd={ elementId =>{
                this.setState({newElementModal: elementId, newElementModalBoxId: boxId});
              }} onEdit={se=>{
                this.setState({ newBox: boxId });
              }} />
            </div>;
          })}
        </div>
      </div>
    ];
  }

}

export { EnelxSellsStatesReport };