import React from 'react';
import moment from 'moment';

import { fetch, fetchHook } from './Common.js';
    
import  { LocalCache } from './AppCommon';

class ChatBlock extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            messages: [],
            myUserId: LocalCache.get('loginData').User.id
        };
    }

    componentDidUpdate( prevProps , prevState ){
        if( prevProps.from != this.props.from )
            this.reloadData();
    }

    reloadData(){
        fetch( 'api' , 'Messages' , 'select' ).then( data => {
            this.setState({messages: data.body.reverse()});
            if( this.props.onLoad )
                this.props.onLoad();
        });
    }

    render(){

        let prevMessageFromUserId = 0;

        return <React.Fragment>
            <div id="DisplayPlaceholder" />
            { this.state.messages.map( msg => {
                const mine = msg.FromUserId == this.state.myUserId ? true : false ;

                let ret = <div className={ mine ? 'left' : 'right' }>
                    {prevMessageFromUserId != msg.FromUserId && <div className="author-name">
                        {msg.FromUser.name} {msg.FromUser.surname}
                        <small className="chat-date"> {moment(msg.createdAt).format('HH:mm')}</small>
                    </div> }
                    <div className={mine?"chat-message active":"chat-message"}>
                        {msg.content}
                    </div>
                </div>;

                prevMessageFromUserId = msg.FromUserId;

                return ret;
            })}

        </React.Fragment>
    }
}

class ChatWindow extends React.Component {

    constructor(props){
        super(props);

        this.contentRef = React.createRef();

        this.state = {
            text: '',
            myUserId: LocalCache.get('loginData').User.id,
            fromDate: moment().toDate()
        };

        this.onSend = this.onSend.bind(this);
    }

    installOnSaveHook(){

        if( this.onSaveHook )
            this.onSaveHook.abort();
    
        this.onSaveHook = fetchHook( 'Messages', 'onSave' );
    
        this.onSaveHook.then( data => {
          this.reloadData( true );
        }).catch( () => {
        });
      }

    componentDidMount(){
        this.reloadData();
    }

    componentWillUnmount(){
        if( !this.onSaveHook ) return;
        if( !this.onSaveHook.abort ) return;

        this.onSaveHook.abort();
    }

    reloadData(){
        this.setState({ fromDate: moment().toDate() });
        this.installOnSaveHook();
    }

    onSend(se){
        se.preventDefault();

        fetch( 'api' , 'Messages' , 'save' , {
            content: this.state.text,
        }).then( data => {
        });

        this.setState({text: ''});
    }

    render(){

        

        return <div className="small-chat-box fadeInRight animated" style={{width: '320px',height: '420px',display: 'block'}}>

        <div className="heading" draggable="true">
            <small className="chat-date float-right">
                
            </small>
            Chat Interna
        </div>
  
        <div className="content" style={{overflowY:'scroll', height: '330px'}} ref={this.contentRef}>
  
            <ChatBlock from={this.state.fromDate} 
                onLoad={se=>{this.contentRef.current.scrollTo(0,9999)}} />
  
        </div>

        <div className="form-chat" style={{position:'absolute',bottom: 0}}>
            <div className="input-group input-group-sm">
                <form onSubmit={this.onSend}>
                    <input type="text" className="form-control" 
                        value={this.state.text} 
                        style={{float: 'left',width: '72%',marginRight: '5px'}}
                        onChange={se=>{this.setState({text: se.target.value})}} /> 
                    <span className="input-group-btn"> 
                        <button className="btn btn-primary" type="button" >Invia</button> 
                    </span>
                </form>
            </div>
        </div>

        
  
    </div>;
    }
}

class Chat extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            open: false,
            unread: 0,
        };

        this.updateUnread();
    }

    updateUnread(){
        fetch( 'api' , 'Messages' , 'unread' ).then ( msg => {
            this.setState({unread: msg.body});
        });
    }

    render(){
        return <React.Fragment>

            {this.state.open && <ChatWindow /> }

             <div id="small-chat">

                {this.state.unread > 0 && 
                    <span class="badge badge-warning float-right">{this.state.unread}</span> }

                <a class="open-small-chat" onClick={se=>{se.preventDefault(); this.setState({open: true})}}>
                    <i class="fa fa-comments"></i>
                </a>

            </div>
        </React.Fragment>;
    }
}

export {Chat};