import React from 'react';
import './App.css';
import moment from 'moment';

import {
    Loader, FormDashline, fetch,
    FormInputText, FormButtons, FormInputContainer,
    Modal, FormInputRadio, BoxContainer, FormInputTextArea, FormInputSelect, FormInputColorSimple, ConfirmableButton
} from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
    apiFormInit , apiFormSave, formatEur, BrandSelect,
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {NewEvaluationFormsModal} from "./EvaluationForms";


class EvaluationFormStates extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            update: 0
        };

    }

    componentDidMount(){
    }

    onDelete( id ){
        fetch( 'api' , 'EvaluationFormStates' , 'delete' , id ).then( msg => {
            this.setState({update: this.state.update+1});
        });
    }

    render(){

        const columns = {
            name: 'Nome' ,
            default: 'Predefinito',
            createdAt: 'Creato il',
            actions: '',
        };

        const stateTag = state=>{

            let color = state.color;

            if(!color)
                color = '#eee';

            return <span style={{backgroundColor: color,padding:'5px',borderRadius:'5px',color:'#fff'}}>{state.name}</span>
        }

        const row = state => {
            return <tr>
                <td>{stateTag(state)}</td>
                <td>{state.default ? 'Si' : 'No'}</td>

                <td>{moment(state.createdAt).format('D MMMM YY HH:mm')}</td>

                <td>
                    <div class="btn-group">
                        {can('EvaluationFormState.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                            se.preventDefault();
                            this.setState({stateModal: state.id});
                        }}>Vedi</button> }
                        {can('EvaluationFormState.delete') &&
                          <ConfirmableButton className="btn-white btn btn-xs" onClick={()=>{
                              this.onDelete( state.id );
                          }}><i className="fa fa-trash"></i></ConfirmableButton>}
                    </div>
                </td>
            </tr>
        };

        return [ <Breadcumbs title="Stato schede valutative" items={{'Home': '/'}} />,
            <div className="wrapper wrapper-content  animated fadeInRight">

                <div className="row" style={{marginBottom: '15px'}}>
                    <div className={'col-12'}>
                        { can( 'EvaluationFormState.save' ) && <button className="btn btn-primary" onClick={se=>{
                            se.preventDefault();
                            this.setState({stateModal: true});
                        }}>Nuovo stato</button>}
                    </div>
                </div>

                <div className="row">

                    <div className="col-md-12 col-lg-12">
                        { this.state.loading ? <Loader /> : <div className="row">
                            <div className="col-sm-12">
                                <div className="ibox">
                                    <div className="ibox-content">
                                        <AbstractTableViewer model="EvaluationFormStates"
                                                             filters={{ }}
                                                             row={row}
                                                             update={this.state.update}
                                                             columns={columns}/>
                                    </div>
                                </div>
                            </div>
                        </div> }
                    </div>
                </div>

                {this.state.stateModal && <NewEvaluationFormStatesModal onDone={res=>{
                    this.setState({stateModal: false});
                }} id={this.state.stateModal === true ? false: this.state.stateModal} />}

            </div>];
    }
}



class NewEvaluationFormStatesModal extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            default: 0,
            color: '#ffc000'
        };

        this.onSubmit = this.onSubmit.bind(this);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        //if( prevState.type != this.state.type )
    }

    componentDidMount(){
        apiFormInit( 'EvaluationFormStates', this );
    }

    onSubmit(se){
        se.preventDefault();
        apiFormSave( 'EvaluationFormStates', this);
    }


    render(){

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <FormInputText label="Nome" value={this.state.name}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="name"
                               onChange={se => this.setState({name: se.target.value})}/>


                <FormInputColorSimple label="Colore" value={this.state.color}
                                      direction={FormInputContainer.HORIZONTAL}
                                      validation={this.state.validation} name="color"
                                      onChange={se=>this.setState({color: se.target.value})} />

                <FormInputRadio label="Predefinito" checked={this.state.default}
                                 direction={FormInputContainer.HORIZONTAL}
                                onClick={se=>this.setState({default: this.state.default ? false : true})} />


                <FormDashline />

                <AbstractApiFormFooter {...this.state} />


                <FormButtons saveLabel="Salva stato"  direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

            </form>
        </Modal>;
    }

}

class EvaluationFormStateSelect extends React.Component {
    render(){
        return <React.Fragment>
            <ApiMultiSelect
              model="EvaluationFormStates"
              cachedFetch={true}
              formatRow={row => {
                  return `${row.name}`;
              }}
              label="Stato"
              direction={FormInputContainer.HORIZONTAL}
              {...this.props}
            />
        </React.Fragment>;
    }
}

export { EvaluationFormStates , NewEvaluationFormStatesModal, EvaluationFormStateSelect };