import React from 'react';
import './App.css';
import moment from 'moment';
import './Ddt.css';

import { Loader , FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, ChannelSelect, ChannelDescription
} from './AppCommon';
import {UserSelect} from "./Users";
import {FormInputDate, FormInputSelect} from "./Common";
import {Link} from "react-router-dom";

class DdtCategories extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = {
      name: 'Nome' ,
      createdAt: 'Creata il' ,
      updatedAt: 'Modifica il' ,
    };

    const row = ddt => {
      return <tr>
        <td>{ddt.name}</td>
        <td>{ddt.createdAt}</td>
        <td>{ddt.updatedAt}</td>

        <td>
          <div class="btn-group">
            {can('DdtCategories.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({ddtCategoryModal: ddt.id});
            }}>Vedi</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Categorie DDT" items={{'Home': '/', 'Ddt': '/ddt'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          { can( 'DdtCategories.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({ddtCategoryModal: true});
          }}>Nuova categoria</button>}
        </div>


        <div className="row">

          <div className="col-sm-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Categorie Ddt</h5>
              </div>
              <div className="ibox-content">
                <div className="row">

                </div>

                { this.state.loading ? <Loader /> :

                  <AbstractTableViewer model="DdtCategories"
                                       filters={{
                                       }}
                                       row={row}
                                       columns={columns}/>
                }
              </div>
            </div>
          </div>
        </div>

        {this.state.ddtCategoryModal && <NewDdtCategoryModal onDone={res=>{
          this.setState({ddtCategoryModal: false});
        }} id={this.state.ddtCategoryModal === true ? false: this.state.ddtCategoryModal} />}

      </div>];
  }
}

class NewDdtCategoryModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      errors: false
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'DdtCategories', this);
  }

  componentDidMount(){
    apiFormInit( 'DdtCategories', this );
  }


  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <FormInputText label="Nome" value={this.state.name}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="name"
                       onChange={se=>this.setState({name: se.target.value})} />

        <FormDashline />

        {this.state.errors && <div className={"alert alert-danger"}>
          {this.state.errors}
        </div>}

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     saveEnable={!this.state.errors}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class DdtCategorySelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Categoria DDT" {...this.props} model="DdtCategories"
                           formatRow={row => { return row.name; }} filters={{}}
    />;
  }
}

export { DdtCategories , NewDdtCategoryModal, DdtCategorySelect };