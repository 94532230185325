import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter , 
    apiFormInit , apiFormSave, formatEur, BrandSelect, 
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';

class ChannelCodes extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      sellingPointModal: false,
      reports: false
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = { 
      name: 'Nome' , 
      actions: 'Azioni'
  };

    const row = sellingPoint => {
        return <tr>
          <td>{sellingPoint.name}</td>
          <td>
            <div class="btn-group">
                {can('ChannelCodes.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({sellingPointModal: sellingPoint.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Codici canale" items={{'Home': '/'}} />, 
    <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'ChannelCodes.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: true});
          }}>Nuovo codice canale</button>}
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-5">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="ChannelCodes" 
                        filters={{ }}
                        row={row}
                        columns={columns}/>
                  </div>
              </div>
            </div>
          </div> }
        </div>
    </div>

      {this.state.sellingPointModal && <NewChannelCodeModal onDone={res=>{
        this.setState({sellingPointModal: false});
      }} id={this.state.sellingPointModal === true ? false: this.state.sellingPointModal} />}

      


    </div>];
  }
}



class NewChannelCodeModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'ChannelCodes', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'ChannelCodes', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />
    
            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva codice canale"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class ChannelCodeSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Codice canale" {...this.props} model="ChannelCodes" 
      formatRow={row => { return row.name; }} filters={{}}
       />;
  }
}

export { ChannelCodes , ChannelCodeSelect, NewChannelCodeModal };