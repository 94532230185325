import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
  apiFormInit , apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';

class QuotationProducts extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      editModal: false,
      tableUpdate: 0,
    };

    this.onDelete = this.onDelete.bind( this );
  }

  onDelete( id ){
    fetch( 'api' , 'QuotationProducts' , 'delete' , id ).then( msg => {
      this.setState({ tableUpdate: this.state.tableUpdate+1 });
    });
  }

  render(){

    const columns = {
      name: 'Nome' ,
      price: 'Prezzo',
      actions: 'Azioni'
    };

    const row = product => {
      return <tr>
        <td>{product.name}</td>
        <td>{product.price}</td>
        <td>
          <div class="btn-group">
            {can('QuotationProducts.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({editModal: product.id});
            }}>Vedi</button> }
            { can( 'QuotationProducts.delete' ) &&
            <button className="btn btn-danger" onClick={se => {
              se.preventDefault();
              this.onDelete( product.id );
            }}>Elimina</button>}
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Prodotti" items={{'Home': '/', 'Preventivi': '/quotations'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
        { can( 'QuotationProducts.save' ) && <button className="btn btn-primary" onClick={se=>{
          se.preventDefault();
          this.setState({editModal: true});
        }}>Nuovo prodotto</button>}
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="QuotationProducts"
                                       tableUpdate={this.state.tableUpdate}
                                       filters={{ }}
                                       row={row}
                                       columns={columns}/>
                </div>
              </div>
            </div>
          </div> }
        </div>
      </div>

      {this.state.editModal && <QuotationProductModal onDone={res=>{
        this.setState({editModal: false});
      }} id={this.state.editModal === true ? false: this.state.editModal} />}

    </div>];
  }
}



class QuotationProductModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      active: 1,
      name: ''
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'QuotationProducts', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'QuotationProducts', this);
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <FormInputText label="Nome" value={this.state.name}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="name"
                       onChange={se=>this.setState({name: se.target.value})} />

        <FormInputText label="Prezzo" value={this.state.price}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="price"
                       onChange={se=>this.setState({price: se.target.value})} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva prodotto"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class QuotationProductsSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Prodotto" {...this.props} model="QuotationProducts"
                           formatRow={row => { return row.name; }} filters={{}}
    />;
  }
}

export { QuotationProducts , QuotationProductsSelect, QuotationProductModal };