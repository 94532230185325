import React from 'react';
import './App.css';
import moment from 'moment';

import {
    Loader, FormDashline, fetch,
    FormInputText, FormButtons, FormInputContainer,
    Modal, BoxContainer, FormInputDate, FormInputSelect, FormInputRadio
} from './Common.js';

import {
    AbstractTableViewer, can, AbstractApiFormFooter,
    apiFormInit, apiFormSave,
    ApiMultiSelect, Breadcumbs, FileUploadInput, AgentSelect
} from './AppCommon';
import {UserSelect} from "./Users";
import {EvaluationFormStateSelect} from "./EvaluationFormStates";
import {Link} from "react-router-dom";
import {NotePlainViewer, NotesEditor} from "./Notes";
import {ContractStateSelect, ContractStateViewer, PracticeStateSelect} from "./ContractStates";
import {ContractVoidStateViewer} from "./ContractVoidCauses";
import {SellingPointSelect} from "./SellingPoints";
import {showExportModal} from "./Exports";
import {formatLeadName} from "./Leads";

const operations = {
    'subentro': 'Subentro',
    'prima_attivazione': 'Prima Attivazione',
    'voltura_senza_accollo': 'Voltura Senza Accollo',
    'voltura_cambio_fornitore': 'Voltura Con Cambio Fornitore',
    'voltura_con_accollo': 'Voltura Con Accollo',
    'allaccio_e_attivazione_ele': 'Allaccio e Attivazione Ele',
    'predisposizione_presa': 'Predisposizione Presa',
    'gestione_attività': 'Gestione Attività',
    'modifica_impianto_gas': 'Modifica Impianto Gas (Spostamento contatore Gas)',
    'verifica_contatore': 'Verifica Contatore',


    'cessazione': 'Chiusura contatore',
    'modifica_potenza': 'Modifica Potenza e Tensione',
    'spostamento_contatore': 'Spostamento Contatore Ele',

    'fornitura_cantiere': 'Fornitura da Cantiere',
    'fornitura_straordinaria': 'Fornitura Straordinaria',

    'attivazione_vas': 'Attivazione vas',
    'variazione_vas': 'Variazione vas',
    'revoca_vas': 'Revoca vas',
    'variazione_sdd': 'Variazione sdd',
    'revoca_sdd': 'Revoca sdd',
    'attivazione_sdd': 'Attivazione SDD',
    'copia_documenti': 'Copia documenti',
    'informativa': 'Informativa',
    'cambio_uso': 'Cambio uso',
    'disalimentabilita': 'Disalimentabilità',
    'autolettura': 'Autolettura',
    'estratto_conto': 'Estratto conto',
    'estratto_conto_tv': 'Estratto conto tv',
    'modifica_anagrafica': 'Modifica anagrafica',
    'modifica_stato_residenza': 'Modifica stato residenza',
    'modifica_tipologia_bolletta': 'Modifica tipologia bolletta',
    'dimostrato_pagamento': 'Dimostrato pagamento',
    'fidelizzazione': 'Fidelizzazione',
    'cambio_prodotto': 'Cambio prodotto',
    'variazione_indirizzo_fornitura': 'Variazione indirizzo fornitura',
    'variazione_indirizzo_fatturazione': 'Variazione indirizzo fatturazione',
    'piano_di_rientro': 'Piano di rientro',
    'attivazione_fatturazione_elettronica': 'Attivazione fatturazione elettronica',
    'modifica_fatturazione_elettronica': 'Modifica fatturazione elettronica',
    'ricerca_pdr': 'Ricerca PDR',

};

const getOperationLabel = (name) => {
    return operations[name] ? operations[name] : null;
}


class PracticesTableNotes extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            columns: {
                lead: 'Cliente',
                note: 'Note Pratica',
                status: 'Stato',
                actions: 'Azioni'
            },
            row: practice => {

                let extra = {};
                try {
                    extra = JSON.parse(practice.extraJsonData);
                } catch (e) {
                    console.error('error parsing json');
                }

                function getExtra(name) {

                    let path = name.split('.');

                    let res = extra;

                    for (let i = 0; i < path.length; i++) {
                        if (!res[path[i]]) {
                            res = null;
                            break;
                        }
                        res = res[path[i]];
                    }

                    return res;

                }


                function formatHumanName(leadObject) {
                    try {

                        let result = `${leadObject.name} ${leadObject.surname}`;

                        if (leadObject.businessName) {
                            if (leadObject.businessName.length > 0) {
                                result = leadObject.businessName;

                                if (leadObject.name || leadObject.surname) {
                                    result = `${leadObject.businessName} (${leadObject.name || ''} ${leadObject.surname || ''})`;
                                }

                            }
                        }

                        return result;

                    } catch (e) {
                    }

                    return '-';
                }

                return <tr>


                    <td><Link to={'/leads_list/detail/' + practice.LeadId}><span>{formatHumanName(practice.Lead)}</span></Link></td>
                    <td><NotePlainViewer notes={practice.Notes}/></td>
                    <td>{practice.ContractState && <ContractStateViewer contract={{state: practice.state, ContractState: {type: practice.ContractState.type}}}/>}</td>
                    <td className="text-right">
                        <div class="btn-group">
                            {can('Practices.save') && <button class="btn-white btn btn-xs" onClick={se => {
                                se.preventDefault();
                                this.setState({practiceModal: practice.id});
                            }}>Vedi</button>}

                            {can('Practices.history') && <button className="btn-white btn btn-xs" onClick={se => {
                                se.preventDefault();
                                this.setState({historyModal: practice.id});
                            }}>Storico</button>}
                        </div>
                    </td>


                </tr>
            }
        }
    }


    render() {

        return (<AbstractTableViewer model="Practices"
                                     filters={this.props.getFilters}
                                     row={this.state.row}
                                     columns={this.state.columns}/>);

    }


}

class Practices extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            practiceModal: false,
            filterDateStart: moment().startOf('year'),
            filterDateEnd: moment(),
            filterStatePractice: 0,
            filterAgent: 0,
            filterSellingPoint: 0,
            tableView: 1,
            checkTableUpdate: 0,

        };

    }

    componentDidMount() {
        fetch('api', 'Practices', 'select', {filters: {}}).then(msg => {
            this.setState({telephones: msg.body});
        });
    }

    getFilters() {

        return {

            filterName: this.state.filterName,
            filterOperation: this.state.filterOperation,
            filterCustomerType: this.state.filterCustomerType,
            filterFiscalCode: this.state.filterFiscalCode,
            dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
            dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm'),
            ContractStateId: this.state.filterStatePractice ? this.state.filterStatePractice : false,
            AgentId: this.state.filterAgent ? this.state.filterAgent.id : null,
            SellingPointId: this.state.filterSellingPoint ? this.state.filterSellingPoint.id : null,
        };
    }

    getCheckFilters() {
        return {
            filterCheck: 1,
            checkTableUpdate : this.state.checkTableUpdate,
            pagination: {limit: 10}
        };
    }

    onCheck( id ){
        fetch( 'api' , 'Practices' , 'setCheck', id, 1 ).then( msg => {
            this.setState({ checkTableUpdate: this.state.checkTableUpdate+1})
        });

    }


    render() {

        const columns = {
            operation: 'Tipo Operazione',
            createdBy: 'Creato Da',
            lead: 'Cliente',
            createdAt: 'Data Creazione',
            updatedAt: 'Ultima Modifica',
            status: 'Stato',
            actions: 'Azioni'
        };




        function formatHumanName(leadObject) {
            try {

                let result = `${leadObject.name} ${leadObject.surname}`;

                if (leadObject.businessName) {
                    if (leadObject.businessName.length > 0) {
                        result = leadObject.businessName;

                        if (leadObject.name || leadObject.surname) {
                            result = `${leadObject.businessName} (${leadObject.name || ''} ${leadObject.surname || ''})`;
                        }

                    }
                }

                return result;

            } catch (e) {
            }

            return '-';
        }

        const row = practice => {


            let extra = {};
            try {
                extra = JSON.parse(practice.extraJsonData);
            } catch (e) {
                console.error('error parsing json');
            }


            function getExtra(name) {

                let path = name.split('.');

                let res = extra;

                for (let i = 0; i < path.length; i++) {
                    if (!res[path[i]]) {
                        res = null;
                        break;
                    }
                    res = res[path[i]];
                }

                return res;

            }

            return <tr>
                <td>{getOperationLabel(getExtra('operation'))}</td>
                <td><span>{formatHumanName(practice.Agent)}</span></td>
                <td><Link to={'/leads_list/detail/' + practice.LeadId}><span>{formatHumanName(practice.Lead)}</span></Link></td>
                <td>{moment(practice.createdAt).format('D MMMM YY HH:mm')}</td>
                <td>{moment(practice.updatedAt).format('D MMMM YY HH:mm')}</td>
                <td>{practice.ContractState && <ContractStateViewer contract={{state: practice.state, ContractState: {type: practice.ContractState.type}}}/>}</td>
                <td className="text-right">
                    <div class="btn-group">
                        {can('Practices.save') && <button class="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({practiceModal: practice.id});
                        }}>Vedi</button>}

                        {can('Practices.history') && <button className="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({historyModal: practice.id});
                        }}>Storico</button>}
                    </div>
                </td>
            </tr>
        };

        const columnsCheck = {
            id: '#',
            check: '',
            change: 'Modificato da',
            changeContext: 'Cambiamento',
            operation: 'Tipo Operazione',
            createdBy: 'Creato Da',
            lead: 'Cliente',
            createdAt: 'Data Creazione',
            updatedAt: 'Ultima Modifica',
            status: 'Stato',
            actions: '',
        };


        const rowCheck = practice => {

            let extra = {};
            try {
                extra = JSON.parse(practice.extraJsonData);
            } catch (e) {
                console.error('error parsing json');
            }


            function getExtra(name) {

                let path = name.split('.');

                let res = extra;

                for (let i = 0; i < path.length; i++) {
                    if (!res[path[i]]) {
                        res = null;
                        break;
                    }
                    res = res[path[i]];
                }

                return res;

            }
            return <tr>
                <td>{practice.id}</td>
                <td><button className={"btn btn-primary btn-sm"} onClick={se=>{
                    se.preventDefault();
                    this.onCheck( practice.id );
                }}>Conferma</button></td>
                <td>{formatLeadName(practice.LastUpdateUser)}</td>
                <td>{practice.lastUpdateContext}</td>
                <td>{getOperationLabel(getExtra('operation'))}</td>
                <td><span>{formatHumanName(practice.Agent)}</span></td>
                <td><Link to={'/leads_list/detail/' + practice.LeadId}><span>{formatHumanName(practice.Lead)}</span></Link></td>
                <td>{moment(practice.createdAt).format('D MMMM YY HH:mm')}</td>
                <td>{moment(practice.updatedAt).format('D MMMM YY HH:mm')}</td>
                <td>{practice.ContractState && <ContractStateViewer contract={{state: practice.state, ContractState: {type: practice.ContractState.type}}}/>}</td>
                <td className="text-right">
                    <div className="btn-group">
                        {can('Practices.save') && <button className="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({practiceModal: practice.id});
                        }}>Vedi</button>}

                        {can('Practices.history') && <button className="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({historyModal: practice.id});
                        }}>Storico</button>}
                    </div>
                </td>
            </tr>
        };

        return [<Breadcumbs title="Pratiche" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">

            <div className="row" style={{marginBottom: '15px'}}>
                <div className={'col-12'}>
                    {can('Practices.save') && <button className="btn btn-primary" onClick={se => {
                        se.preventDefault();
                        this.setState({practiceModal: true});
                    }}>Nuova Pratica</button>}

                    {can('Practices.export') && <button className="btn btn-primary" onClick={se => {
                        se.preventDefault();
                        showExportModal( 'Practices' , this.getFilters() );
                    }} style={{marginLeft: '10px'}}>{this.state.exporting ? 'In corso (Clicca per aggiornare)' : 'Esporta Excel'}</button>}

                </div>
            </div>

           {<div className="row">

                <div className="col-md-12 col-lg-12">
                    { this.state.loading ? <Loader /> : <div className="row">
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className={"ibox-title"}><h5>Da controllare</h5></div>
                                <div className="ibox-content">
                                    <AbstractTableViewer model="Practices"
                                                         filters={this.getCheckFilters()}
                                                         row={rowCheck}
                                                         limit={5}
                                                         defaultSort={[['updatedAt','DESC']]}
                                                         columns={columnsCheck}/>
                                </div>
                            </div>
                        </div>
                    </div> }
                </div>
            </div>}


            <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <div className="row">

                                <div className="col-12 col-md-3">
                                    <SellingPointSelect value={this.state.filterSellingPoint}
                                                        direction={FormInputContainer.HORIZONTAL}
                                                        onChange={se => this.setState({filterSellingPoint: se})}/>
                                </div>

                                <div className="col-12 col-md-3">
                                    <FormInputSelect value={this.state.filterCustomerType} direction={FormInputContainer.HORIZONTAL}
                                                     label="Tipologia Cliente"
                                                     values={{
                                                         '': 'Tutte',
                                                         'business': 'Business',
                                                         'residenziale': 'Residenziale'
                                                     }}

                                                     onChange={se => this.setState({filterCustomerType: se})}/>
                                </div>

                                <div className="col-12 col-md-3">
                                    <FormInputSelect value={this.state.filterOperation} direction={FormInputContainer.HORIZONTAL}
                                                     label="Operazione da Effettuare"
                                                     values={{
                                                         '': 'Tutte',
                                                         ...operations
                                                     }}
                                                     onChange={se => this.setState({filterOperation: se})}/>

                                </div>


                                <div className="col-12 col-md-3">
                                    <FormInputText
                                        onChange={se => {
                                            this.setState({filterName: se.target.value})
                                        }}
                                        direction={FormInputContainer.HORIZONTAL}
                                        label="Nome lead"
                                        value={this.state.filterName}/>
                                </div>


                                <div className="col-12 col-md-3">
                                    <FormInputText
                                        onChange={se => {
                                            this.setState({filterFiscalCode: se.target.value})
                                        }}
                                        direction={FormInputContainer.HORIZONTAL}
                                        label="Codice Fiscale"
                                        value={this.state.filterFiscalCode}/>
                                </div>

                                <div className="col-12 col-md-3">
                                    <PracticeStateSelect value={this.state.filterStatePractice}
                                                         direction={FormInputContainer.HORIZONTAL}
                                                         all={true}
                                                         isMulti
                                                         onChange={se => this.setState({filterStatePractice: se ? (se.length > 0 ? se : false) : false})}/>
                                </div>


                                <div className="col-12 col-md-3">
                                    <FormInputDate value={this.state.filterDateStart}
                                                   key="sdate"
                                                   label="Data inizio"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterDateStart: se})}/>
                                </div>

                                <div className="col-12 col-md-3">
                                    <FormInputDate value={this.state.filterDateEnd}
                                                   key="edate"
                                                   label="Data fine"
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   onChange={se => this.setState({filterDateEnd: se})}/>
                                </div>

                                {can('Practices.filterAgentId') && <div className="col-12 col-md-3">
                                    <AgentSelect value={this.state.filterAgent}
                                                 label="Caricato da"
                                                 direction={FormInputContainer.HORIZONTAL}
                                                 all={true}
                                                 onChange={se => this.setState({filterAgent: se})}/>
                                </div>}


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-12">
                    {this.state.loading ? <Loader/> :
                        <div className="ibox">
                            <div className="ibox-content">

                                <div className={'row'}>
                                    <div className={'col-12 col-lg-4'}>
                                        <FormInputSelect label="Visualizzazione" value={this.state.tableView}
                                                         direction={FormInputContainer.HORIZONTAL}
                                                         name="tableView"
                                                         values={{
                                                             1: 'Standard',
                                                             2: 'Note',

                                                         }}
                                                         onChange={se => {
                                                             this.setState({tableView: se})
                                                         }}/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className={'col-12'}>
                                        {this.state.tableView == 1 && <>

                                            <AbstractTableViewer model="Practices"
                                                                 filters={this.getFilters()}
                                                                 row={row}
                                                                 defaultSort={[['createdAt','DESC']]}
                                                                 columns={columns}/>

                                        </>}

                                        {this.state.tableView == 2 && <>
                                            <PracticesTableNotes getFilters={this.getFilters()}/>

                                        </>}

                                    </div>
                                </div>

                            </div>
                        </div>}
                </div>


            </div>

            {this.state.historyModal && <PracticesHistoryModal onDone={res => {
                this.setState({historyModal: false});
            }} id={this.state.historyModal}/>}

            {this.state.practiceModal && <NewPracticeModal onDone={res => {
                this.setState({practiceModal: false});
            }} id={this.state.practiceModal === true ? false : this.state.practiceModal}/>}


        </div>];
    }
}


class NewPracticeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            LeadId: this.props.leadId,
            /*state: 'Cartaceo da caricare',*/
            name: '',
            extraJsonData: '{}',
            loadingLeadData: false,
            Products: [],
            Notes: [],
        };

        this.onSubmit = this.onSubmit.bind(this);
        this.handleOperationChange = this.handleOperationChange.bind(this);

        this.handleInputExtraChange = this.handleInputExtraChange.bind(this);
        this.handleSelectExtraChange = this.handleSelectExtraChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);


    }

    componentDidMount() {

        fetch( 'api' , 'Practices' , 'fieldsByOperation' ).then( data => {
            this.setState( { fieldsByOperation: data.body } );
        });

        apiFormInit('Practices', this);

        this.loadLead();
    }

    componentDidUpdate(prevPros, prevState) {

        if (this.state.LeadId != prevState.LeadId) {
            this.setState({loadingLeadData: true});
            this.loadLead();
        }

    }


    checkFieldByOp( field ){
        if( !this.state.fieldsByOperation )
            return true;

        let op = this.getExtraJsonData('operation');

        if( !this.state.fieldsByOperation[op] )
            return true;

        if( typeof( this.state.fieldsByOperation[op][field] ) == "undefined" )
            return false;
        return this.state.fieldsByOperation[op][field];
    }

    onSubmit(se) {
        se.preventDefault();
        apiFormSave('Practices', this);
    }

    loadLead() {


        if (!this.state.LeadId) {
            return;
        }

        fetch('api', 'Leads', 'fastGet', this.state.LeadId).then(msg => {
            let o = {};

            ['name', 'surname', 'fiscalCode' ,'telephone','email'].map(key => {
                o[key] = msg.body[key];
            });

            this.setState(o, () => {
                //this.checkWhitelistNumber(this.state.telephone);
            });
        });
    }

    handleOperationChange(newOp) {
        let currentExtraJson = JSON.parse(this.state.extraJsonData);

        currentExtraJson = {
            ...currentExtraJson,
            operation: newOp
        };

        this.setState({extraJsonData: JSON.stringify(currentExtraJson)}, () => {

            if (newOp === 'allaccio_e_attivazione_ele') {
                // fornitura da cantiere / fornitura straordinaria
            }
            if (newOp === 'predisposizione_presa') {
                // ele / gas
            }

        });

    }

    handleInputExtraChange(name, e) {

        let currentExtraJson = JSON.parse(this.state.extraJsonData);

        currentExtraJson = {
            ...currentExtraJson,
            [name]: e.target.value
        };

        this.setState({extraJsonData: JSON.stringify(currentExtraJson)});


    }

    handleInputChange(name, e) {
        this.setState({[name]: e.target.value});
    }

    handleSelectExtraChange(name, val) {

        let currentExtraJson = JSON.parse(this.state.extraJsonData);

        currentExtraJson = {
            ...currentExtraJson,
            [name]: val
        };

        this.setState({extraJsonData: JSON.stringify(currentExtraJson)});
    }

    handleAddressChange(state) {

        let addressState = {...state};
        let addressType = addressState.type;
        delete addressState.type;

        let currentExtraJson = JSON.parse(this.state.extraJsonData);

        currentExtraJson = {
            ...currentExtraJson,
            ['address_' + addressType]: addressState
        };

        this.setState({extraJsonData: JSON.stringify(currentExtraJson)});

    }


    getExtraJsonData(name) {
        let currentExtraJson = JSON.parse(this.state.extraJsonData);

        let path = name.split('.');

        let res = currentExtraJson;

        for (let i = 0; i < path.length; i++) {
            if (!res[path[i]]) {
                res = null;
                break;
            }
            res = res[path[i]];
        }

        return res;

    }



    isReducedForm(){
        return this.getExtraJsonData('alreadyInserted');
    }

    render() {

        let contractStateFilter =
            this.state.id >= 1 ?
                {canBeSelected: true}
                : {canBeInitialState: true};

        if (can('Practices.canSelectAnyState'))
            contractStateFilter = {};

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>


                <div className="row">
                    <div className="col-12">

                        <FormInputRadio label="Già inserito a sistema" checked={this.getExtraJsonData('alreadyInserted')}
                                        direction={FormInputContainer.HORIZONTAL}
                                        onClick={se => this.handleSelectExtraChange('alreadyInserted', !this.getExtraJsonData('alreadyInserted'))}
                        />

                        <FormDashline/>

                    </div>


                    <div className="col-12">

                        <SellingPointSelect value={this.state.SellingPoint}
                                            direction={FormInputContainer.HORIZONTAL}
                                            validation={this.state.validation}
                                            name={'SellingPointId'}
                                            requiredField={true}
                                            onChange={se => this.setState({SellingPoint: se || null, SellingPointId: se ? se.id : null})}/>

                    </div>


                    <div className="col-12">
                        <FormInputSelect value={this.getExtraJsonData('customerType')} direction={FormInputContainer.HORIZONTAL}
                                         label="Tipologia Cliente"
                                         values={{
                                             'business': 'Business',
                                             'residenziale': 'Residenziale'
                                         }}
                                         requiredField={true}
                                         onChange={se => this.handleSelectExtraChange('customerType', se)}/>
                    </div>

                </div>


                <div className="row">
                    <div className="col-12">
                        <FormInputSelect value={this.getExtraJsonData('operation')} direction={FormInputContainer.HORIZONTAL}
                                         label="Operazione da Effettuare"
                                         values={operations}
                                         order={"ASC"}
                                         requiredField={true}
                                         onChange={this.handleOperationChange}/>
                    </div>

                    {can('Practices.editState') && <>

                        <div className="col-12">
                            <PracticeStateSelect value={this.state.ContractState}
                                                 requiredField={true}
                                                 order={"ASC"}
                                                 direction={FormInputContainer.HORIZONTAL}
                                                 {...contractStateFilter}
                                                 onChange={se => {
                                                     this.setState({
                                                         ContractState: se,
                                                         ContractStateId: se ? se.id : null,
                                                         state: se ? se.name : null
                                                     })
                                                 }}/>
                        </div>

                    </>}


                </div>


                {!this.isReducedForm() && <>

                  {/*  {this.getExtraJsonData('operation') === 'allaccio_e_attivazione_ele' && <>
                        <div className="row">
                            <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('fornitura')} direction={FormInputContainer.HORIZONTAL}
                                                 label="Tipo Fornitura"
                                                 values={{
                                                     'permanente': 'Permanente',
                                                     'cantiere': 'Fornitura da Cantiere',
                                                     'straordinaria': 'Fornitura Straordinaria'
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('fornitura', se)}/>
                            </div>

                        </div>
                    </>}*/}
                    {this.getExtraJsonData('operation') === 'predisposizione_presa' && <>
                        <div className="row">
                            <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('tipoPresa')} direction={FormInputContainer.HORIZONTAL}
                                                 label="Tipo Presa"
                                                 values={{
                                                     'ele': 'Elettricità',
                                                     'gas': 'Gas'
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('tipoPresa', se)}/>
                            </div>

                        </div>
                    </>}

                </>}

                {<>

                    <FormDashline/>


                    <div className="row">
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Nome" value={this.state.name}
                                           requiredField={true}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="name"
                                           onChange={se => this.handleInputChange('name', se)}/>
                        </div>
                        <div className="col-sm-6">
                            <FormInputText upperCase={true} label="Cognome" value={this.state.surname}
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           validation={this.state.validation} name="surname"
                                           onChange={se => this.handleInputChange('surname', se)}/>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12">
                            <FormInputText upperCase={true} label="Codice Fiscale" value={this.state.fiscalCode}
                                           requiredField={true}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="fiscalCode" requiredField={true}
                                           onChange={se => this.handleInputChange('fiscalCode', se)}/>
                        </div>


                        {this.getExtraJsonData('customerType') === 'business' && <>
                            <div className="col-12">
                                <FormInputText upperCase={true} label="P. IVA" value={this.getExtraJsonData('piva')}
                                               requiredField={true}
                                               direction={FormInputContainer.HORIZONTAL}
                                               validation={this.state.validation} name="piva"
                                               onChange={se => this.handleInputExtraChange('piva', se)}/>
                            </div>

                            {!this.isReducedForm() && <>

                                <div className="col-12">
                                    <FormInputText upperCase={true} label="Ragione Sociale" value={this.getExtraJsonData('rag_soc')}
                                                   requiredField={true}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="rag_soc"
                                                   onChange={se => this.handleInputExtraChange('rag_soc', se)}/>
                                </div>

                                <div className="col-12">
                                    <FormInputSelect value={this.getExtraJsonData('form_giur')} direction={FormInputContainer.HORIZONTAL}
                                                     requiredField={true}
                                                     validation={this.state.validation} name="form_giur"
                                                     label="Forma Giuridica"
                                                     values={{
                                                         'impresa_individuale': 'Impresa Individuale',
                                                         'business': 'Business',
                                                         'condominio': 'Condominio',
                                                         'onlus': 'Onlus',
                                                         'associazione': 'Associazione',
                                                     }}
                                                     onChange={se => this.handleSelectExtraChange('form_giur', se)}/>
                                </div>

                            </>}


                        </>}


                        <div className="col-12">
                            <FormInputText upperCase={true} label="Telefono" value={this.state.telephone}
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           validation={this.state.validation} name="telephone"
                                           onChange={se => this.handleInputChange('telephone', se)}/>
                        </div>

                        <div className="col-12">
                            <FormInputText lowerCase={true} label="Email" value={this.state.email}
                                           direction={FormInputContainer.HORIZONTAL}
                                           requiredField={true}
                                           validation={this.state.validation} name="email"
                                           onChange={se => this.handleInputChange('email', se)}/>
                        </div>


                        {!this.isReducedForm() && <>

                            { this.checkFieldByOp('address_residenza') && <div className="col-12">
                                <AddressForm value={this.getExtraJsonData('address_residenza')} validation={this.state.validation} label={'Indirizzo di Residenza / Sede Legale'}
                                             type={'residenza'}
                                             onChange={this.handleAddressChange}/>
                            </div> }

                            { this.checkFieldByOp('address_fatturazione') && <div className="col-12">
                                <AddressForm value={this.getExtraJsonData('address_fatturazione')} validation={this.state.validation} label={'Indirizzo di Fatturazione'}
                                             type={'fatturazione'}
                                             onChange={this.handleAddressChange}/>

                                <button type={'button'} className={'btn btn-primary my-2'} onClick={() => {
                                    this.handleAddressChange({...this.getExtraJsonData('address_residenza'), type: 'fatturazione'});
                                }}>Copia Residenza
                                </button>

                                <FormDashline/>

                            </div> }

                            { this.checkFieldByOp('titolo_immob') && <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('titolo_immob')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="titolo_immob"
                                                 label="Titolo sull'immobile"
                                                 values={{
                                                     'proprietario': 'Proprietario',
                                                     'affittuario': 'Affittuario',
                                                     'usufruttuario': 'Usuffrutturario',
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('titolo_immob', se)}/>
                            </div> }

                            { this.checkFieldByOp('address_fornitura') && <div className="col-12">
                                <AddressForm value={this.getExtraJsonData('address_fornitura')} validation={this.state.validation} label={'Indirizzo di Fornitura'} type={'fornitura'}
                                             onChange={this.handleAddressChange}/>

                                <button type={'button'} className={'btn btn-primary my-2'} onClick={() => {
                                    this.handleAddressChange({...this.getExtraJsonData('address_residenza'), type: 'fornitura'});
                                }}>Copia Residenza
                                </button>

                                <FormDashline/>

                            </div> }

                            { this.checkFieldByOp('tip_uso') && <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('tip_uso')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="tip_uso"
                                                 label="Tipologia d'uso"
                                                 values={{
                                                     'residente': 'domestico residente',
                                                     'non_residente': 'domestico non residente',
                                                     'altri_usi': 'Altri usi',
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('tip_uso', se)}/>
                            </div> }

                            { this.checkFieldByOp('bolletta_web') && <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('bolletta_web')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="bolletta_web"
                                                 label="Bolletta Web"
                                                 values={{
                                                     1: 'Si',
                                                     0: 'No',
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('bolletta_web', se)}/>
                            </div> }

                            { this.checkFieldByOp('datiCatastali') && <div className="col-12">
                                <FormInputText label="Dati catastali" value={this.getExtraJsonData('datiCatastali')}
                                               requiredField={true}
                                               direction={FormInputContainer.HORIZONTAL}
                                               validation={this.state.validation} name="datiCatastali"
                                               onChange={se => this.handleInputExtraChange('datiCatastali', se)}/>
                            </div> }

                            { this.checkFieldByOp('copy') && <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('copy')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="copy"
                                                 label="Copia"
                                                 values={{
                                                     Fattura: 'Fattura',
                                                     "Piano di rientro": 'Piano di rientro',
                                                     "Preventivo": 'Preventivo',
                                                     "Estratto conto": 'Estratto conto',
                                                     "Contratto": 'Contratto',
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('copy', se)}/>
                            </div> }

                            { this.checkFieldByOp('tipologia') && <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('tipologia')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="tipologia"
                                                 label="Tipologia"
                                                 values={{
                                                     Sintesi: 'Sintesi',
                                                     "Sintesi + Dettaglio": 'Sintesi + Dettaglio',
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('tipologia', se)}/>
                            </div> }

                            { this.checkFieldByOp('tipologia_contatore') && <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('tipologia_contatore')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="tipologia_contatore"
                                                 label="Tipologia"
                                                 values={{
                                                     "Entro i 10 mt": 'Entro i 10 mt',
                                                     "Oltre i 10 mt": "Oltre i 10 mt"
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('tipologia_contatore', se)}/>
                            </div> }

                            {this.getExtraJsonData('bolletta_web') == '1' && <>
                                <div className="col-12">
                                    <FormInputText label="Email " value={this.getExtraJsonData('email')}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="email"
                                                   onChange={se => this.handleInputExtraChange('email', se)}/>
                                </div>
                            </>}

                            { this.checkFieldByOp('rid') && <div className="col-12">

                                <FormInputRadio label="RID" checked={this.getExtraJsonData('rid')}
                                                direction={FormInputContainer.HORIZONTAL}
                                                onClick={se => this.handleSelectExtraChange('rid', !this.getExtraJsonData('rid'))}
                                />

                            </div> }

                            { (this.checkFieldByOp('iban')||this.getExtraJsonData('rid')) && <div className="col-12">
                                <FormInputText label="IBAN" value={this.getExtraJsonData('iban')}
                                               requiredField={true}
                                               direction={FormInputContainer.HORIZONTAL}
                                               validation={this.state.validation} name="iban"
                                               onChange={se => this.handleInputExtraChange('iban', se)}/>

                            </div>}

                            { this.checkFieldByOp('lettura') && <div className="col-12">
                                <FormInputText label="Lettura" value={this.getExtraJsonData('lettura')}
                                               requiredField={true}
                                               direction={FormInputContainer.HORIZONTAL}
                                               validation={this.state.validation} name="lettura"
                                               onChange={se => this.handleInputExtraChange('lettura', se)}/>

                            </div>}

                            {this.checkFieldByOp('podpdr') && <>

                                <div className="col-12">

                                    <FormInputText label="POD/PDR" value={this.getExtraJsonData('podpdr')}
                                                   requiredField={true}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="podpdr"
                                                   onChange={se => this.handleInputExtraChange('podpdr', se)}/>

                                </div>

                            </>}


                            {this.getExtraJsonData('operation') === 'voltura_con_accollo' && <>

                                <div className="col-12">
                                    <FormInputSelect value={this.getExtraJsonData('causale')} direction={FormInputContainer.HORIZONTAL}
                                                     requiredField={true}
                                                     validation={this.state.validation} name="causale"
                                                     label="Causale"
                                                     values={{
                                                         'mortis_causa': 'Mortis Causa',
                                                         'separazione': 'Separazione',
                                                     }}
                                                     onChange={se => this.handleSelectExtraChange('causale', se)}/>
                                </div>

                            </>}

                            {['voltura_senza_accollo', 'voltura_cambio_fornitore'].includes(this.getExtraJsonData('operation')) && <>

                                <div className="col-12">
                                    <AddressForm value={this.getExtraJsonData('address_fatturazione_uscente')}
                                                 validation={this.state.validation}
                                                 label={'Indirizzo di Fatturazione Uscente'}
                                                 type={'fatturazione_uscente'}
                                                 optional={true}
                                                 onChange={this.handleAddressChange}/>
                                </div>

                                <div className="col-12">
                                    <FormInputText label="Lettura Gas" value={this.getExtraJsonData('lettura_gas')}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="lettura_gas"
                                                   onChange={se => this.handleInputExtraChange('lettura_gas', se)}/>
                                </div>

                            </>}

                            {this.checkFieldByOp('kw') && <>
                                <div className="col-12">
                                    <FormInputText label="Kw" value={this.getExtraJsonData('kw')}
                                                   requiredField={true}
                                                   direction={FormInputContainer.HORIZONTAL}
                                                   validation={this.state.validation} name="kw"
                                                   onChange={se => this.handleInputExtraChange('kw', se)}/>
                                </div>
                            </>}

                            { this.checkFieldByOp('tensione') && <div className="col-12">
                                <FormInputText label="Tensione" value={this.getExtraJsonData('tensione')}
                                               requiredField={true}
                                               direction={FormInputContainer.HORIZONTAL}
                                               validation={this.state.validation} name="tensione"
                                               onChange={se => this.handleInputExtraChange('tensione', se)}/>
                            </div> }

                            { this.checkFieldByOp('data_inizio_fornitura') &&  <>
                                <div className="col-12">
                                    <FormInputDate value={this.getExtraJsonData('data_inizio_fornitura')} direction={FormInputContainer.HORIZONTAL}
                                                   requiredField={true}
                                                   validation={this.state.validation} name="data_inizio_fornitura"
                                                   label="Data di inizio"
                                                   onChange={se => this.handleSelectExtraChange('data_inizio_fornitura', se)}/>
                                </div>
                            </>}

                            { this.checkFieldByOp('data_fine_fornitura') &&  <>
                                <div className="col-12">
                                    <FormInputDate value={this.getExtraJsonData('data_fine_fornitura')} direction={FormInputContainer.HORIZONTAL}
                                                   requiredField={true}
                                                   validation={this.state.validation} name="data_fine_fornitura"
                                                   label="Data di fine"
                                                   onChange={se => this.handleSelectExtraChange('data_fine_fornitura', se)}/>
                                </div>
                            </>}

                        </>}

                    </div>

                    <div className={'row'}>
                        <div className="col-12">
                            <FormInputSelect value={this.getExtraJsonData('proposto_fibra')} direction={FormInputContainer.HORIZONTAL}
                                             requiredField={true}
                                             validation={this.state.validation} name="proposto_fibra"
                                             label="Hai proposto la fibra?"
                                             values={{
                                                 0: 'No',
                                                 1: 'Si'
                                             }}
                                             onChange={se => this.handleSelectExtraChange('proposto_fibra', se)}/>
                        </div>
                    </div>

                    {this.getExtraJsonData('proposto_fibra') == '0' && <>
                        <div className={'row'}>

                            <div className="col-12">
                                <FormInputSelect value={this.getExtraJsonData('motivo_proposto_fibra')} direction={FormInputContainer.HORIZONTAL}
                                                 requiredField={true}
                                                 validation={this.state.validation} name="motivo_proposto_fibra"
                                                 label="Perchè non l`hai proposta?"
                                                 values={{
                                                     1: 'No copertura fibra',
                                                     2: 'Vincolo contrattuale',
                                                     3: 'Fedele al vecchio gestore',
                                                     4: 'Non ha e non vuole internet',
                                                     5: 'Proposta non effettuata',
                                                     6: 'Altro'
                                                 }}
                                                 onChange={se => this.handleSelectExtraChange('motivo_proposto_fibra', se)}/>
                            </div>
                        </div>
                    </>}

                    <FormDashline/>

                    <div style={{border: '3px dashed #CCCCCC', padding: '20px'}}>
                        <NotesEditor notes={this.state.Notes} onChange={notes => {
                            this.setState({Notes: notes})
                        }} name={"Contracts"}/>
                    </div>

                    <FormDashline/>

                    <label>Allegati</label>
                    <FileUploadInput onChange={filesId => {
                        this.setState({filesId: filesId})
                    }}
                                     filesId={this.state.filesId}/>

                    <FormDashline/>

                </>}


                <FormDashline/>

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva pratica" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </form>
        </Modal>;
    }

}

class AddressForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            validation: null,
            type: props.type
        };


        this.handleInputExtraChange = this.handleInputExtraChange.bind(this);

    }

    componentDidMount(props) {
        if (this.props.value)
            this.setState(this.props.value);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value === null && this.props.value !== null)
            this.setState(this.props.value);

        if ((!this.state.validation && this.props.validation) ||
            (this.state.validation && !this.props.validation) ||
            (this.state.validation && this.props.validation &&
                (this.state.validation.fieldName != this.props.validation.fieldName.replace('address_' + this.props.type + '.', '') ||
                    this.state.validation.message != this.props.validation.message) &&
                this.props.validation.fieldName.match(new RegExp('^address_' + this.props.type)))) {

            console.log('cambiamento validation addressform');

            if (this.props.validation && this.props.validation.fieldName) {
                this.setState({
                    validation: {
                        fieldName: this.props.validation.fieldName.replace('address_' + this.props.type + '.', ''),
                        message: this.props.validation.message
                    }
                });
            } else {
                this.setState({validation: null});
            }
        }
    }


    handleInputExtraChange(name, e) {
        this.setState({[name]: e.target.value}, () => {
            let currenState = this.state;

            if (this.props.onChange)
                this.props.onChange(currenState);
        });
    }


    render() {

        const {addressType, label, optional} = this.props;


        return (
            <>
                {/*                <div className="row">
                    <div className="col-sm-6">
                        <FormInputText upperCase={true} label="Nome" value={this.state.name}
                                       requiredField={true}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="name"
                                       onChange={se =>this.handleInputExtraChange('name',se)}/>
                    </div>
                    <div className="col-sm-6">
                        <FormInputText upperCase={true} label="Cognome" value={this.state.surname}
                                       direction={FormInputContainer.HORIZONTAL}
                                       requiredField={true}
                                       validation={this.state.validation} name="surname"
                                       onChange={se =>this.handleInputExtraChange('surname',se)}/>
                    </div>

                </div>*/}

                <FormDashline/>

                <h4>{label}</h4>

                <div className="row">
                    <div className="col-sm-6">
                        <FormInputText upperCase={true} label="Indirizzo" value={this.state.address}
                                       requiredField={!optional && true}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="address"
                                       onChange={se => this.handleInputExtraChange('address', se)}/>
                    </div>
                    <div className="col-sm-3">
                        <FormInputText upperCase={true} label="Civico" value={this.state.civicNumber}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="civicNumber"
                                       onChange={se => this.handleInputExtraChange('civicNumber', se)}/>
                    </div>
                    <div className="col-sm-3">
                        <FormInputText upperCase={true} label="CAP" value={this.state.cap}
                                       requiredField={!optional && true}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="cap"
                                       onChange={se => this.handleInputExtraChange('cap', se)}/>
                    </div>
                </div>


                <div className="row">
                    <div className="col-sm-6">
                        <FormInputText upperCase={true} label="Provincia" value={this.state.province}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="province"
                                       onChange={se => this.handleInputExtraChange('province', se)}/>
                    </div>
                    <div className="col-sm-6">
                        <FormInputText upperCase={true} label="Città" value={this.state.city}
                                       direction={FormInputContainer.HORIZONTAL}
                                       requiredField={!optional && true}
                                       validation={this.state.validation} name="city"
                                       onChange={se => this.handleInputExtraChange('city', se)}/>
                    </div>
                </div>


                {/*<div className="row">
                    <div className="col-12">
                        <FormInputText label="Email " value={this.state.email}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="email"
                                       onChange={se =>this.handleInputExtraChange('email',se)}/>
                    </div>
                </div>*/}
            </>
        );
    }


}

class PracticesHistoryModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            ContractsHistories: []
        };

    }

    componentDidMount() {
        fetch('api', 'Practices', 'get', this.props.id).then(msg => {
            this.setState({...msg.body, loading: false});
        });
    }

    render() {
        return <Modal onClose={this.props.onDone}>
            {this.state.loading && <Loader/>}

            {!this.state.loading && <h2>Storico pratica</h2>}

            {this.state.ContractsHistories.length < 1 && !this.state.loading &&
                <p className="text-info">Nessun cambiamento di stato trovato</p>}

            <ul className="list-group clear-list m-t">
                {this.state.ContractsHistories.map(entry => {
                    return <li className="list-group-item" style={{width: '100%'}}>
              <span className="float-right">
                  {moment(entry.createdAt).format('DD/MM/YY HH:mm')}
              </span>
                        {entry.message}
                    </li>
                })}
            </ul>

            <button className="btn btn-primary" onClick={this.props.onDone}>Chiudi</button>
        </Modal>;
    }
}

export {Practices, NewPracticeModal, PracticesHistoryModal, getOperationLabel};