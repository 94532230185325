import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter , 
    apiFormInit , apiFormSave, formatEur, BrandSelect, 
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {TagsSelect} from "./Tags";

class SellingPoints extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      sellingPointModal: false,
      reports: false
    };

  }

  componentDidMount(){
    fetch( 'api' , 'MarketingReports' , 'select' , { filters: { modelName: 'SellingPoints' } } ).then( msg => {
      this.setState({reports: msg.body});
    });
  }

  render(){

    const columns = { 
      name: 'Nome' , 
      actions: 'Azioni'
  };

    const row = sellingPoint => {
        return <tr>
          <td>{sellingPoint.name}</td>
          <td>
            <div class="btn-group">
                {can('SellingPoints.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({sellingPointModal: sellingPoint.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Punti vendita" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'SellingPoints.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: true});
          }}>Nuovo punto vendita</button>}
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-5">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="SellingPoints" 
                        filters={{ }}
                        row={row}
                        columns={columns}/>
                  </div>
              </div>
            </div>
          </div> }
        </div>

        <div className="col-md-12 col-lg-7">
          {this.state.reports === false && <Loader />}

          {this.state.reports && <BoxContainer title="Report">

              <table className="table table-striped table-hover" style={{width: '100%'}}>
                <thead>
                    <tr>
                        <th>Punto vendita</th>
                        <th>Mese riferimento</th>
                        <th>Ultima modifica</th>
                        <th className="text-right">Totale</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.reports.map( report => {
                        return <tr>
                            <td>{report.SellingPoint.name}</td>
                            <td>{report.month+1}/{report.year}</td>
                            <td>{moment(report.createdAt).format('YYYY-MM-DD HH:mm')}</td>
                            <td className="text-right">{formatEur( report.totalEur )}</td>
                            <td className="text-right"><a href={"/marketing_report/"+report.id} className="btn btn-default btn-xs">Apri</a></td>
                        </tr>
                    })}
                </tbody>
            </table>
          </BoxContainer>}
        </div>

      </div>

      {this.state.sellingPointModal && <NewSellingPointModal onDone={res=>{
        this.setState({sellingPointModal: false});
      }} id={this.state.sellingPointModal === true ? false: this.state.sellingPointModal} />}

      


    </div>];
  }
}



class NewSellingPointModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'SellingPoints', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'SellingPoints', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />

            <FormInputText label="Costo mensile" value={this.state.monthlyCosts}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="monthlyCosts"
                onChange={se=>this.setState({monthlyCosts: se.target.value})} />

            <TagsSelect
              direction={FormInputContainer.HORIZONTAL}
              onChange={tags=>{ this.setState({TagsCity: tags})}}
              value={this.state.TagsCity} canCreate={true}
              label={"Citta di provenienza"}
              entityName={"SellingPointCities"} entityId={this.state.id} />

            <TagsSelect
              direction={FormInputContainer.HORIZONTAL}
              onChange={tags=>{ this.setState({TagsChannel: tags})}}
              value={this.state.TagsChannel} canCreate={true}
              label={"Canali aggiuntivi"}
              entityName={"SellingPointChannels"} entityId={this.state.id} />

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva punto vendita"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class SellingPointSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Punto vendita" {...this.props} model="SellingPoints" 
      formatRow={row => { return row.name; }} filters={{}}
       />;
  }
}

export { SellingPoints , SellingPointSelect, NewSellingPointModal };