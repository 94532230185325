import React from 'react';
import './App.css';
import moment from 'moment';
import './Products.css';

import {
  Loader,
  FormDashline,
  fetch,
  FormInputText,
  FormButtons,
  FormInputContainer,
  Modal,
  queryToObject,
  FormInputColorSimple, FormInputRadio, FormInputSelect
} from './Common.js';

import {
  AbstractTableViewer,
  can,
  AbstractApiFormFooter,
  apiFormInit,
  apiFormSave,
  ApiMultiSelect,
} from './AppCommon';
import {EnelxSellsBusinessTypeSelect} from "./EnelxSells";
import {EnelxSellsStates, EnelxSellsStatesSelect, EnelxSellsStateViewer} from "./EnelxSellsStates";

class EnelxSellSubStateModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'EnelxSellsSubStates', this );
  }


  onSubmit(se){
    se.preventDefault();

    let payload = { ...this.state };

    apiFormSave( 'EnelxSellsSubStates', this , payload );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <div className={"row"}>
          <div className={"col-12"}>

            <FormInputText label="Nome" value={this.state.name}
               direction={FormInputContainer.HORIZONTAL}
               validation={this.state.validation} name="name"
               onChange={se=>this.setState({name: se.target.value})} />

          </div>

          <div className={"col-12"}>
            <EnelxSellsStatesSelect
              label={"Stato padre"}
              direction={FormInputContainer.HORIZONTAL}
              value={this.state.EnelxSellsState}
              onChange={se=>this.setState({EnelxSellsState: se, EnelxSellsStateId: se?se.id:null})} />
          </div>

        </div>

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class EnelxSellsSubStateViewer extends React.Component {

  constructor(props) {
    super( props );
    this.state = {};
  }

  reloadState(){
    if( !this.props.entity && this.props.id && !this.state.entity ){
      fetch( 'api' , 'EnelxSellsSubStates' , 'get' , this.props.id ).then( msg => {
        this.setState({ entity: msg.body });
      })
    }
  }

  componentDidMount() {
    this.reloadState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.reloadState();
  }

  render(){
    let entity = {
      name: '-'
    };

    if( this.props.entity && this.props.entity.id )
      entity = this.props.entity;

    if( this.state.entity )
      entity = this.state.entity;

    return <span className={"label label-default"}>{entity.name}</span>;
  }
}

class EnelxSellsSubStatesSelect extends React.Component {
  render(){
    return <React.Fragment>
      <ApiMultiSelect
        model="EnelxSellsSubStates"
        cachedFetch={true}
        formatRow={row => {
          return `${row.name}`;
        }}
        label="Sotto stato"
        direction={FormInputContainer.HORIZONTAL}
        {...this.props}
      />
    </React.Fragment>;
  }
}

class EnelxSellsSubStates extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      ...(queryToObject()),
    };

  }


  getFilters(){
    return {
      EnelxSellsStateId: this.state.filterState
    };
  }

  render(){

    const columns = {
      name: { label: 'Nome' , sortBy: 'name' },
      createdAt: { label: 'Creato il' , sortBy: 'createdAt' } ,
      state: { label: 'Stato padre' },
      actions: 'Azioni'
    }

    const row = (entity) => {

      return <React.Fragment><tr style={{}}>
        <td><EnelxSellsSubStateViewer entity={entity} /></td>
        <td>{moment(entity.createdAt).format('DD/MM/YY HH:mm')}</td>
        <td>{entity.EnelxSellsState ? <EnelxSellsStateViewer entity={entity.EnelxSellsState} /> : '-'}</td>
        <td>
          <div className="btn-group">
            {can('EnelxSellsSubStates.save') &&
            <button className="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({ enelxSellsSubStateModal: entity.id });
            }}>Vedi</button>}
          </div>
        </td>
      </tr>
      </React.Fragment>
    };

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className="row">

                <div className={"col-4"}>
                  <EnelxSellsStatesSelect
                    all={true}
                    label={"Stato padre"}
                    value={this.state.filterState}
                    onChange={se=>this.setState({filterState: se})} />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{marginBottom: '15px'}}>
        <div className="col-sm-12">
          { can( 'EnelxSellsSubStates.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({enelxSellsSubStateModal: true});
          }}>Nuovo Sotto stato</button>}
        </div>
      </div>

      {this.state.enelxSellsSubStateModal && <EnelxSellSubStateModal onDone={res=>{
        this.setState({enelxSellsSubStateModal: false});
      }} id={this.state.enelxSellsSubStateModal === true ? false: this.state.enelxSellsSubStateModal} />}

      <div className="row">
        <div className="col-sm-12">
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className={"row"}>
                <div className={"col-12"}>
                  <AbstractTableViewer model="EnelxSellsSubStates"
                                       filters={this.getFilters()}
                                       row={row}
                                       columns={columns}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}


export { EnelxSellsSubStates, EnelxSellsSubStateViewer, EnelxSellsSubStatesSelect };