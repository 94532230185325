import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputTextArea, FormInputSelect
} from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter ,
  apiFormInit , apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";

function EvaluationFormAreasTypes(){
  return {
    formSep: 'Scheda SEP',
    formCorporate: 'Scheda Corporate',
    formBusiness: 'Scheda Consumer Business',
    formFormazione: 'Scheda Formazione',
    formBackoffice: 'Scheda Back Office',
    formEnelx: 'Scheda EnelX',
    formCallCenter: 'Scheda Call Center',
    formPratiche: 'Scheda Gestione pratiche',
    formAmministrazione: 'Scheda Amministrazione',
    formReclami: 'Scheda Reclami',
    formAssistenza: 'Scheda Assistenza'
  };
}

class EvaluationFormAreas extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      sellingPointModal: false,
      reports: false,
      update: 0,
    };

  }

  componentDidMount(){
  }

  onDelete( id ){

    fetch( 'api' , 'EvaluationFormAreas' , 'delete' , id ).then( msg => {
      this.setState({ update: this.state.update+1 });
    });

  }

  render(){

    const columns = {
      name: 'Tipo form' ,
      menu: 'Macro Area',
      author: 'Area',
      actions: '',
    };

    const row = area => {
      return <tr>
        <td>{EvaluationFormAreasTypes()[area.formType]}</td>
        <td>{area.area}</td>
        <td>{area.subArea}</td>
        <td>
          <div class="btn-group">
            {can('EvaluationFormAreas.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: area.id});
            }}>Vedi</button> }

            {can('EvaluationFormAreas.delete') && <button class="btn btn-danger btn-xs ml-2" onClick={se=>{
              se.preventDefault();
              this.onDelete( area.id )
            }}>Cancella</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Aree schede valutative" items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          { can( 'EvaluationFormAreas.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({sellingPointModal: true});
          }}>Nuova area</button>}
        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="EvaluationFormAreas"
                                         filters={{
                                           update: this.state.update
                                         }}
                                         row={row}
                                         columns={columns}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

        {this.state.sellingPointModal && <NewEvaluationFormAreasModal onDone={res=>{
          this.setState({sellingPointModal: false});
        }} id={this.state.sellingPointModal === true ? false: this.state.sellingPointModal} />}

      </div>];
  }
}



class NewEvaluationFormAreasModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'EvaluationFormAreas', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'EvaluationFormAreas', this);
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <FormInputSelect label="Tipo form" value={this.state.formType}
                         values={EvaluationFormAreasTypes()}
                         direction={FormInputContainer.HORIZONTAL}
                         onChange={se=>this.setState({formType: se})} />

        <FormInputText label="Macro area" value={this.state.area}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="area"
                       onChange={se=>this.setState({area: se.target.value})} />

        <FormInputText label="Area" value={this.state.subArea}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="subArea"
                       onChange={se=>this.setState({subArea: se.target.value})} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva area"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

export { EvaluationFormAreas , NewEvaluationFormAreasModal };