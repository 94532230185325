
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie'

class PieChart extends React.Component {
  constructor(props){
    super(props);
  }
  
  render(){
  
    let pieData = [{
      id:"success",
      label: 'Positivi',
      color: "#1ab394",
      ...this.props.success,
    },{
      id:"error",
      label: 'Negativi',
      color: "#e35f5f",
      ...this.props.error,
    }];
    
    if( this.props.warning ){
      pieData.push( {
        id:"warning",
        label: 'Neutri',
        color: "#FF9730",
        ...this.props.warning,
      } );
    }
    
    const sprops = {
      height: 400,
      margin: { top: 40, right: 40, bottom: 80, left: 40 },
      innerRadius: 0.5,
      cornerRadius: 5,
      radialLabel: 'label',
      borderWidth: 1,
      padAngle: 2,
      data: pieData,
      defs:[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ],
      fill:[ {
        match: { id: 'warning' },
        id: 'lines'
      } ],
      legends: [
        {
          anchor: 'bottom',
          direction: 'row',
          translateY: 56,
          itemWidth: 100,
          itemHeight: 18,
          itemTextColor: '#999',
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ],
      colors: (id,data)=>{
        return id[`color`];
      },
      ...this.props
    };
    
    return <ResponsivePie
      {...sprops} />;
  }
}

export { PieChart }