import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputTextArea, FormInputDate, FormInputSelect
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, formatDateTime, LocalCache, FileUploadInput
} from './AppCommon';
import {UserSelect} from "./Users";
import FullCalendar, {formatDate} from "@fullcalendar/react";
import {formatLeadName} from "./Leads";
import {NotesEditor} from "./Notes";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {TagsSelect} from "./Tags";

function canSchedule(){
  try {
    if( LocalCache.get('loginData').User.id == /*11*/ 838 )
      return true;
  } catch (e) {

  }

  return false;
}

class Tickets extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      TicketModal: false,
      reports: false,
      calendarTickets: [],

      filterStatus: '',
    };

    this.handleDateClick = this.handleDateClick.bind(this);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.renderEventContent = this.renderEventContent.bind( this );

    this.reloadCalendarTickets();
  }

  getFilters(){
    return {
      status: this.state.filterStatus
    }
  }

  reloadCalendarTickets(){
    let filters = this.getFilters();
    filters.filters = { statusNot: 'wait_schedule' };

    fetch('api', 'Tickets', 'select', filters).then(data => {
      let events = data.body.map(event => {

        return {
          id: event.id,
          title: event.title,
          extendedProps: {
            ...event
          },
          start: event.startDate,
          end: event.deliveryDate,
        }
      });

      this.setState({calendarTickets: events});
    });
  }

  renderEventContent( eventContent ){
    console.log(eventContent.event.extendedProps);

    let eventData = eventContent.event.extendedProps;

    let bgColor = ColorForTicket( eventData.status );

    return (
      <>
        <div style={{width: '100%', height: '100%', top: 0, left: 0, backgroundColor: bgColor, zIndex: 1, padding: ' 0.2rem 0.6rem'}}>

          <label htmlFor={'labelEvent' + eventContent.event.id} style={{display:'block'}}>

            <span style={{zIndex: 2, display: 'flex', flexDirection: 'column', textAlign: 'center', color: '#ffffff'}}>
                <b>{eventContent.event.title}</b>
                </span>
          </label>
        </div>
      </>
    )
  }

  handleDateClick(arg) {
    console.log(arg);

  }

  handleEventClick(arg) {
    console.log(arg);
    this.setState({TicketModal: arg.event.id});
  }

  render(){

    const columns = { 
      name: 'Titolo' ,
      requestedBy: 'Richiesta da',
      startDate: 'Data inzio',
      deliveryDate: 'Data fine',
      status: 'Stato',
      actions: 'Azioni'
  };

    let states = Object.fromEntries( Object.keys( getStates() ).map( k => {
      return [ k , getStates(k).label ]
    }) );

    states[''] = 'Tutti';

    const row = Ticket => {
        return <tr>
          <td>{Ticket.title}</td>
          <td>{formatLeadName( Ticket.RequestedByUser )}</td>
          <td>{formatDateTime( Ticket.startDate )}</td>
          <td>{formatDateTime( Ticket.deliveryDate )}</td>
          <td><TicketStatus status={Ticket.status} /></td>
          <td>
            <div class="btn-group">
                {can('Tickets.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({TicketModal: Ticket.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Ticket" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Tickets.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({TicketModal: true});
          }}>Nuovo ticket</button>}
      </div>

      <div className="row">

        <div className="col-xl-8 col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <FullCalendar defaultView="dayGridMonth"
                            locale='it'
                            firstDay={1}
                            eventContent={this.renderEventContent}
                            eventClassNames={'flex-column align-items-start p-0'}
                            plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                            dateClick={this.handleDateClick}
                            eventClick={this.handleEventClick}
                            events={this.state.calendarTickets}/>
            </div>
          </div>
        </div>

        <div className="col-xl-4 col-sm-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <BoxContainer title={"In attesa di schedulazione"}>
                <AbstractTableViewer model="Tickets"
                                     filters={{status: 'wait_schedule'}}
                                     row={Ticket => {
                                       return <tr>
                                         <td>{Ticket.title}</td>
                                         <td>{formatLeadName( Ticket.RequestedByUser )}</td>
                                         <td><TicketStatus status={Ticket.status} /></td>
                                       </tr>
                                     }}
                                     columns={{
                                       name: 'Titolo' ,
                                       requestedBy: 'Richiesta da',
                                       status: 'Stato',
                                     }}/>
              </BoxContainer>

            </div>
          </div> }
        </div>

      </div>

      <div className={"row"}>
        <div className="col-md-12">
          <BoxContainer title={"Tutti i ticket"}>

            <div className={"row"}>
              <div className={"col-md-4"}>
                <FormInputSelect label={"Stato"} value={this.state.filterStatus}
                                 values={states} direction={FormInputContainer.HORIZONTAL}
                                 name={"status"}
                                 onChange={se=>this.setState({filterStatus: se})} />
              </div>
              <div className={"col-md-12"}>
                <AbstractTableViewer model="Tickets"
                                     filters={this.getFilters()}
                                     row={row}
                                     columns={columns}/>
              </div>
            </div>

          </BoxContainer>
        </div>
      </div>

      {this.state.TicketModal && <NewTicketModal onDone={res=>{
        this.setState({TicketModal: false});
      }} id={this.state.TicketModal === true ? false: this.state.TicketModal} />}

    </div>];
  }
}

function getStates( id = null ){
  let states = {
    wait_schedule: { bgColor: '#a0a0a0' , label: 'In attesa schedulazione' },
    pending: { bgColor: '#a0a0a0' , label: 'In attesa' },
    in_progress: { bgColor: '#f8ac59' , label: 'In lavorazione' },
    ko: { bgColor: '#ed5565' , label: 'KO' },
    done_unverified: { bgColor: '#1c84c6' , label: 'Completato non verificato' },
    done: { bgColor: '#21a439' , label: 'Completato verificato' },
  };

  if( id ){
    if( states[id] ) return states[id];
    return { bgColor: '#ed5565' , label: 'Stato non valido' };
  }

  return states;
}

function ColorForTicket( status ){
  return getStates( status ).bgColor;
}

function TicketStatus( props ){
  let state = getStates( props.status );
  return <span className={"label label-warning"} style={{backgroundColor: state.bgColor}}>{state.label}</span>;
}

class NewTicketModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          startDate: moment(),
          deliveryDate: moment().add( '2' , 'days' ),
          Notes: [],
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'Tickets', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'Tickets', this);
      }
    
      render(){

      let states = Object.fromEntries( Object.keys( getStates() ).map( k => {
        return [ k , getStates(k).label ]
      }) );

        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>

            { (canSchedule()||this.state.status=='done_unverified') && <FormInputSelect label={"Stato"} value={this.state.status}
                             values={states} direction={FormInputContainer.HORIZONTAL}
                             name={"status"}
                             onChange={se=>this.setState({status: se})} /> }

            { (!canSchedule() && this.state.status) && <>
              <h4>Stato:</h4>
              <TicketStatus status={this.state.status}/>
            </>}

            <FormInputText label="Titolo" value={this.state.title}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="title"
                onChange={se=>this.setState({title: se.target.value})} />

            <FormInputTextArea label="Problema" value={this.state.problem}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="problem"
                               onChange={se=>this.setState({problem: se.target.value})} />

            <FormInputTextArea label="Richiesta" value={this.state.description}
                           direction={FormInputContainer.HORIZONTAL}
                           validation={this.state.validation} name="description"
                           onChange={se=>this.setState({description: se.target.value})} />

            { can('Tickets.tagsSave' ) && <TagsSelect
              direction={FormInputContainer.HORIZONTAL} label={"Area"}
              onChange={tags=>{ this.setState({Tags: tags})}}
              value={this.state.Tags} canCreate={true}
              entityName={"Tickets"} entityId={this.state.id} /> }

            <UserSelect label="Richiesto da" value={this.state.RequestedByUser}
                        direction={FormInputContainer.HORIZONTAL} name={"RequestedByUserId"}
                        onChange={se=>this.setState({RequestedByUserId: se.id, RequestedByUser: se })} />

            <h4>Inserito il</h4>
            <p>{formatDateTime( this.state.createdAt )}</p>

            { canSchedule() && <FormInputDate value={this.state.startDate}
                           direction={FormInputContainer.HORIZONTAL}
                           label="Data inizio lavori"
                           validation={this.state.validation} name="startDate"
                           onChange={se=>{this.setState({startDate: se})}} /> }

            { canSchedule() && <FormInputDate value={this.state.deliveryDate}
                           direction={FormInputContainer.HORIZONTAL}
                           label="Data fine lavori"
                           validation={this.state.validation} name="deliveryDate"
                           onChange={se=>{this.setState({deliveryDate: se})}} /> }

            <FormDashline />

            <div style={{border: '3px dashed #CCCCCC', padding: '20px'}}>
              <NotesEditor notes={this.state.Notes} onChange={notes => {
                this.setState({Notes: notes})
              }} name={"Tickets"} id={this.state.id} />
            </div>

            <FormDashline />

            <label>Allegati</label>
            <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
                             filesId={this.state.filesId} />

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva ticket"  direction={FormInputContainer.HORIZONTAL}
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

export { Tickets , NewTicketModal };