import React from 'react';
import './App.css';
import moment from 'moment';

import {
    Loader,
    FormDashline,
    fetch,
    FormInputText,
    FormButtons,
    FormInputContainer,
    Modal,
    BoxContainer,
    FormInputDate,
    FormInputSelect,
    FormInputTime,
    FormInputTextArea,
    Row,
    FormInputMonth,
    FormInputYear
} from './Common.js';

import {
    AbstractTableViewer, can, AbstractApiFormFooter,
    apiFormInit, apiFormSave,
    ApiMultiSelect, Breadcumbs, FileUploadInput, LocalCache, formatDateTime
} from './AppCommon';


import FullCalendar from "@fullcalendar/react";

import dayGridPlugin from "@fullcalendar/daygrid";

import timeGridPlugin from "@fullcalendar/timegrid";

import interactionPlugin from "@fullcalendar/interaction";


import {UserSelect} from "./Users";
import {SellingPointSelect} from "./SellingPoints";
import {showExportModal} from "./Exports";


const formatHumanName = (userObject) => {
    try {

        let result = `${userObject.name} ${userObject.surname}`;

        if (userObject.businessName) {
            if (userObject.businessName.length > 0) {
                result = userObject.businessName;

                if (userObject.name || userObject.surname) {
                    result = `${userObject.businessName} (${userObject.name || ''} ${userObject.surname || ''})`;
                }

            }
        }

        return result;

    } catch (e) {
    }

    return '-';
}

class Shifts extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shifts: [],
            shiftModal: false,
            shiftModalFromDate: moment().set('hour',9),
            shiftModalToDate: moment().set('hour', 12),

            filterSellingPoint: null,
            filterSubject: null,

            filterSellingPointId: null,
            filterSubjectUserId: null,


            shiftDeleteMode: false,
            shiftsToDelete: [],

        };


        this.handleDateClick = this.handleDateClick.bind(this);
        this.handleEventClick = this.handleEventClick.bind(this);
        this.renderEventContent = this.renderEventContent.bind(this);
        this.deleteShifts = this.deleteShifts.bind(this);

    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.filterSellingPointId !== this.state.filterSellingPointId || prevState.filterSubjectUserId !== this.state.filterSubjectUserId) {
            this.reloadData();
        }

    }

    reloadData() {
        fetch('api', 'Shifts', 'select', {filters: this.getFilters()}).then(data => {
            let events = data.body.map(event => {

                return {
                    id: event.id,
                    title: formatHumanName(event.Subject) /*+ '( dal ' + moment(event.fromDate).format('D MMMM YY HH:mm')  +  ' al ' + moment(event.toDate).format('D MMMM YY HH:mm')  + ')'*/,
                    extendedProps: {
                        SellingPoint: event.SellingPoint,
                        Subject: event.Subject,
                        category: event.category,
                        note: event.note,
                    },
                    start: event.fromDate,
                    end: event.toDate,
                }
            });

            this.setState({shifts: events});
        });
    }

    getFilters() {

        return {
            filterSellingPointId: this.state.filterSellingPointId,
            filterSubjectUserId: this.state.filterSubjectUserId,
        };
    }

    handleDateClick(arg) {
        console.log(arg);


        let start = moment(arg.date);
        start.set('hour', 9);

        let end = moment(arg.date);
        end.set('hour', 12);

        this.setState({
            shiftModal: -1,
            shiftModalFromDate: start,
            shiftModalToDate: end
        });
    }

    handleEventClick(arg) {
        console.log(arg);
        this.setState({
            shiftModal: arg.event.id,
            shiftModalFromDate: arg.event.start,
            shiftModalToDate: arg.event.end
        });
    }

    deleteShifts(){


        fetch('api', 'Shifts', 'deleteMore', { ids: this.state.shiftsToDelete}).then(res => {
            this.setState({loading: false, apiFormSaving: false});
            if (res.body) {
                this.setState({
                    shiftsToDelete: []
                });
                this.reloadData();
            } else {
                this.setState({validation: res.body});
            }
        });

    }


    renderEventContent(eventContent) {
        console.log(eventContent.event);

        let bgColor = '#27a627';

        if (eventContent.event.extendedProps.category === 'assente') {
            bgColor = '#e13535';
        } else if (eventContent.event.extendedProps.category === 'ferie' || eventContent.event.extendedProps.category === 'malattia') {
            bgColor = '#ecd227';
        } else if (moment(eventContent.event.start).hour() > 13) {
            bgColor = '#2462e7';
        }


        return (
            <>
                <div style={{width: '100%', height: '100%', top: 0, left: 0, backgroundColor: bgColor, zIndex: 1, padding: ' 0.2rem 0.6rem'}}>

                    <label htmlFor={'labelEvent' + eventContent.event.id} style={{display:'block'}}>
                        {this.state.shiftDeleteMode && <input name={'labelEvent' + eventContent.event.id} id={'labelEvent' + eventContent.event.id} type='checkbox' style={{position: 'absolute', top: '50%', left: '1rem', zIndex: 2, transform: 'translateY(-50%)'}} onChange={()=>{

                            let shiftsToDelete = this.state.shiftsToDelete;

                            if (shiftsToDelete.includes(eventContent.event.id)) {
                                shiftsToDelete = shiftsToDelete.filter(id => id !== eventContent.event.id);
                            } else {
                                shiftsToDelete.push(eventContent.event.id);
                            }

                            this.setState({shiftsToDelete: shiftsToDelete});

                        }}/>}

                        <span style={{zIndex: 2, display: 'flex', flexDirection: 'column', textAlign: 'center', color: '#ffffff'}}>
                <b>{eventContent.event.extendedProps.SellingPoint.name}</b>
                <i>dalle {moment(eventContent.event.start).format('HH:mm')} alle {moment(eventContent.event.end).format('HH:mm')}</i>
                <b>{eventContent.event.title}</b>
                            <i>{eventContent.event.extendedProps.category} / {eventContent.event.extendedProps.note}</i>
                </span>
                    </label>
                </div>
                {/*<div style={{position:'absolute',top:0,right:0}}><input type="checkbox" /></div>*/}
            </>
        )
    }

    render() {


        return [<Breadcumbs title="Turni" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">

            <div className="row" style={{marginBottom: '15px'}}>
                <div className={'col-12'}>

                    {can('Shifts.export') && <button className="btn btn-primary" onClick={se => {
                        se.preventDefault();
                        showExportModal( 'Shifts' , this.getFilters() );
                    }} style={{marginRight: '10px'}}>Esporta Excel</button>}

                    {can('Shifts.save') && <button className="btn btn-primary" onClick={se => {
                        se.preventDefault();
                        this.setState({shiftModal: true});
                    }}>Nuovo Turno</button>}

                    {can('Shifts.delete') && <button className="btn btn-primary ml-2" onClick={se => {
                        se.preventDefault();
                        this.setState({shiftDeleteMode: !this.state.shiftDeleteMode});
                    }}>{this.state.shiftDeleteMode ? <> Chiudi Eliminazione Turni</> : <> Elimina Turni</>}</button>}


                    {can('Shifts.delete') && this.state.shiftDeleteMode && this.state.shiftsToDelete.length>0 && <button className="btn btn-danger ml-2" onClick={se => {
                        se.preventDefault();
                        this.deleteShifts();
                        this.setState({shiftDeleteMode: false});
                    }}>Elimina Turni Selezionati ({this.state.shiftsToDelete.length})</button>}

                </div>
            </div>


            <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <div className="row">

                                <div className="col-12 col-md-4">
                                    <SellingPointSelect value={this.state.filterSellingPoint}
                                                        direction={FormInputContainer.HORIZONTAL}
                                                        onChange={se => this.setState({filterSellingPoint: se ? se : null, filterSellingPointId: se ? se.id : null})}/>
                                </div>

                                <div className="col-12 col-md-4">
                                    <UserSelect value={this.state.filterSubject}
                                                direction={FormInputContainer.HORIZONTAL}

                                                label="Consulente"
                                                onChange={se => this.setState({filterSubject: se, filterSubjectUserId: se ? se.id : null})}/>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-12">
                    {this.state.loading ? <Loader/> :
                        <div className="ibox">
                            <div className="ibox-content">
                                {/*   <AbstractTableViewer model="Practices"
                                                     filters={this.getFilters()}
                                                     row={row}
                                                     columns={columns}/>*/}

                                <FullCalendar defaultView="dayGridMonth"
                                              locale='it'
                                              firstDay={1}
                                              eventContent={this.renderEventContent}
                                              eventClassNames={'flex-column align-items-start p-0'}
                                              plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                                              dateClick={this.handleDateClick}
                                              eventClick={!this.state.shiftDeleteMode ? this.handleEventClick : null}
                                              events={this.state.shifts}/>


                            </div>
                        </div>}
                </div>


            </div>


            {this.state.shiftModal && <NewShiftModal
                fromDate={this.state.shiftModalFromDate}
                toDate={this.state.shiftModalToDate}
                onDone={res => {
                    this.reloadData();
                    this.setState({shiftModal: false});
                }} id={this.state.shiftModal === true ? false : this.state.shiftModal}/>}


        </div>];
    }
}


class NewShiftModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            fromDate: props.fromDate,
            toDate: props.toDate,
        };

        this.onSubmit = this.onSubmit.bind(this);

        this.handleInputChange = this.handleInputChange.bind(this);
        this.deleteShift = this.deleteShift.bind(this);


    }

    componentDidMount() {
        apiFormInit('Shifts', this);

    }


    onSubmit(se) {
        se.preventDefault();
        apiFormSave('Shifts', this);
    }


    handleInputChange(name, val) {
        this.setState({[name]: val});
    }

    deleteShift(se) {
        se.preventDefault();

        fetch('api', 'Shifts', 'delete', this.props.id).then(res => {
            this.setState({loading: false, apiFormSaving: false});
            if (res.body) {
                if (this.props.onDone)
                    this.props.onDone(res);
            } else {
                this.setState({validation: res.body});
            }
        });

    }


    render() {


        let days = {};

        for (let i = 0; i < 30; i++)
            days[i + 1] = (i + 1) + ' Giorn' + (i === 0 ? 'o' : 'i');


        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <div className="row">
                    <div className="col-12">
                        <h3>{this.state.id && this.state.Subject ? 'Modifica Turno ' + formatHumanName(this.state.Subject) : 'Nuovo Turno'}</h3>
                    </div>
                </div>


                <div className="row">

                    <div className="col-12">
                        <SellingPointSelect value={this.state.SellingPoint}
                                            direction={FormInputContainer.HORIZONTAL}
                                            requiredField={true}
                                            onChange={se => this.setState({SellingPoint: se, SellingPointId: se.id,})}/>
                    </div>

                    <div className="col-12">
                        <UserSelect value={this.state.Subject}
                                    direction={FormInputContainer.HORIZONTAL}
                                    requiredField={true}
                                    label="Consulente"
                                    onChange={se => this.setState({Subject: se, SubjectUserId: se.id})}/>
                    </div>

                    <div className="col-12">
                        <FormInputDate value={this.state.fromDate} direction={FormInputContainer.HORIZONTAL}
                                       requiredField={true}
                                       validation={this.state.validation} name="fromDate"
                                       label="Da"
                                       onChange={se => this.handleInputChange('fromDate', se)}/>
                    </div>
                    <div className="col-12">
                        <FormInputTime value={this.state.fromDate} direction={FormInputContainer.HORIZONTAL}
                                       requiredField={true}
                                       validation={this.state.validation} name="fromTime"
                                       label="Alle"
                                       onChange={se => this.handleInputChange('fromDate', se)}/>
                    </div>


                    <div className="col-12">
                        <FormInputDate value={this.state.toDate} direction={FormInputContainer.HORIZONTAL}
                                       requiredField={true}
                                       validation={this.state.validation} name="toDate"
                                       label="Al"
                                       onChange={se => this.handleInputChange('toDate', se)}/>
                    </div>

                    <div className="col-12">
                        <FormInputTime value={this.state.toDate} direction={FormInputContainer.HORIZONTAL}
                                       requiredField={true}
                                       validation={this.state.validation} name="toTime"
                                       label="Alle"
                                       onChange={se => this.handleInputChange('toDate', se)}/>
                    </div>

                    <div className="col-12">

                        <FormInputTextArea label="Note" value={this.state.note}
                                           direction={FormInputContainer.HORIZONTAL}
                                           validation={this.state.validation} name="note"
                                           onChange={se => this.setState({note: se.target.value})}/>


                    </div>

                    <div className="col-12">
                        <FormInputSelect value={this.state.category} direction={FormInputContainer.HORIZONTAL}
                                         label="Categoria"
                                         values={{
                                             'lavorativo': 'Lavorativo',
                                             'assente': 'Assente',
                                             'ferie': 'Ferie',
                                             'malattia': 'Malattia',
                                         }}
                                         onChange={se => this.handleInputChange('category', se)}/>

                    </div>

                    {!this.state.id &&
                        <div className="col-12">
                            <FormInputSelect value={this.state.repeatDays} direction={FormInputContainer.HORIZONTAL}
                                             label="Ripeti per"
                                             values={days}
                                             onChange={se => this.handleInputChange('repeatDays', se)}/>

                        </div>}

                    {this.state.id &&
                        <div className="col-12 text-left">

                            <button className="btn btn-danger" onClick={this.deleteShift}>Elimina Turno</button>

                        </div>}


                </div>

                <FormDashline/>

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva turno" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </form>
        </Modal>;
    }

}


class ShiftsReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            shifts: [],

            filterSellingPoint: null,
            filterSubject: null,

            filterSellingPointId: null,
            filterSubjectUserId: null,


            filterFromDate: moment().startOf('month'),
            filterToDate: moment(),


        };


    }


    getFilters() {

        return {
            filterSellingPointId: this.state.filterSellingPointId,
            filterSubjectUserId: this.state.filterSubjectUserId
        };
    }

    changeFilter(newState) {
        this.setState(newState, () => {
            //this.reloadData();
        })
    }


    render() {


        return [<Breadcumbs title="Report Turni" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">


            <div className="row">
                <div className="col-sm-12">
                    <div className="ibox">
                        <div className="ibox-content">

                            <div className="row">


                                <div className="col-12 col-md-4">
                                    <UserSelect value={this.state.filterSubject}
                                                direction={FormInputContainer.HORIZONTAL}
                                                requiredField={true}
                                                label="Consulente"
                                                onChange={se => this.changeFilter({filterSubject: se, filterSubjectUserId: se ? se.id : null})}/>
                                </div>

                                <div className="col-12 col-md-4">
                                    <FormInputDate value={this.state.filterFromDate} direction={FormInputContainer.HORIZONTAL}

                                                   name="filterFromDate"
                                                   label="Da"
                                                   onChange={se => this.changeFilter({filterFromDate: se})}/>
                                </div>
                                <div className="col-12 col-md-4">
                                    <FormInputDate value={this.state.filterToDate} direction={FormInputContainer.HORIZONTAL}

                                                   name="filterToDate"
                                                   label="A"
                                                   onChange={se => this.changeFilter({filterToDate: se})}/>
                                </div>

                                <div className="col-12 col-md-4">
                                    <SellingPointSelect value={this.state.filterSellingPoint}
                                                        direction={FormInputContainer.HORIZONTAL}
                                                        onChange={se => this.changeFilter({filterSellingPoint: se ? se : null, filterSellingPointId: se ? se.id : null})}/>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className="row">

                <div className="col-12">
                    {this.state.loading ? <Loader/> :
                        <div className="ibox">
                            <div className="ibox-content">
                                <div className={'row'}>
                                    <div className={'col-3'}>
                                        <UserShiftsHoursBox filterSubjectUserId={this.state.filterSubjectUserId}
                                                            filterFromDate={this.state.filterFromDate}
                                                            filterToDate={this.state.filterToDate}
                                                            filterSellingPointId={this.state.filterSellingPointId}
                                                            filterCategory={'lavorativo'}
                                                            label={'Lavorativo'}
                                                            extraClass={'bg-success'}
                                        />
                                    </div>
                                    <div className={'col-3'}>

                                        <UserShiftsHoursBox filterSubjectUserId={this.state.filterSubjectUserId}
                                                            filterFromDate={this.state.filterFromDate}
                                                            filterToDate={this.state.filterToDate}
                                                            filterSellingPointId={this.state.filterSellingPointId}
                                                            filterCategory={'assente'}
                                                            label={'Assente'}
                                                            extraClass={'bg-danger'}/>
                                    </div>
                                    <div className={'col-3'}>

                                        <UserShiftsHoursBox filterSubjectUserId={this.state.filterSubjectUserId}
                                                            filterFromDate={this.state.filterFromDate}
                                                            filterToDate={this.state.filterToDate}
                                                            filterSellingPointId={this.state.filterSellingPointId}
                                                            filterCategory={'ferie'}
                                                            label={'Ferie'}
                                                            extraClass={'bg-warning'}/>

                                    </div>
                                    <div className={'col-3'}>

                                        <UserShiftsHoursBox filterSubjectUserId={this.state.filterSubjectUserId}
                                                            filterFromDate={this.state.filterFromDate}
                                                            filterToDate={this.state.filterToDate}
                                                            filterSellingPointId={this.state.filterSellingPointId}
                                                            filterCategory={'malattia'}
                                                            label={'Malattia'}
                                                            extraClass={'bg-warning'}/>

                                    </div>
                                </div>
                            </div>
                        </div>}
                </div>


            </div>


        </div>];
    }
}

function ShiftsAndLoginsReportDetailModal({ onDone, id }){

    const [ data, setData ] = React.useState( false );

    React.useEffect( () => {
        fetch( 'api' , 'Shifts' , 'shiftsAndLoginsReportDetail' , id ).then( msg => {
            setData( msg.body );
        })
    } , [] );

    return <Modal onClose={onDone}>
        {!data && <Loader />}
        {data && <>
            <h1>Turno</h1>
            <p><b>Utente: </b>{formatHumanName(data.shift.Subject)}</p>
            <p><b>Punto vendita: </b>{data.shift.SellingPoint.name}</p>
            <p><b>Dalle </b> {formatDateTime( data.shift.fromDate )} <b> alle </b> {formatDateTime( data.shift.toDate )}</p>
            <h4>Note:</h4>
            <p>{data.shift.note}</p>

            <h1>Accessi effettuati</h1>

            {data.logins.map( login => {
                return <p>
                    <b>{login.SellingPoint?login.SellingPoint.name:'??'}</b>:
                    dalle {formatDateTime(login.createdAt)} alle {formatDateTime(login.lastActivityAt)}</p>;
            })}
        </>}
    </Modal>
}

function ShiftsAndLoginsReport({}){

    const [ filters , setFilters ] = React.useState({
        year: moment().year() +'',
        month: moment().month() +'',
        isValid: '',
        tolerance: LocalCache.get( 'ShiftsAndLoginsReport_tolerance' ) ? LocalCache.get( 'ShiftsAndLoginsReport_tolerance' ) : 30,
    });

    React.useEffect( () => {
        LocalCache.set( 'ShiftsAndLoginsReport_tolerance' , filters.tolerance );
    } , [ filters ] );

    const [ detailModal , setDetailModal ] = React.useState( false );

    const onDetails = id => {
        setDetailModal( id );
    };

    const columns = {
        'user': 'Utente',
        'day': 'Data turno',
        'sellingPoint': 'Punto vendita',
        'entrance': 'IN Turno',
        'entrance_l': 'IN Login',
        'exit': 'OUT Turno',
        'exit_l': 'OUT Login',
        'actions': '',
    };

    const row = entity => {

        return <tr style={{
            backgroundColor: entity.isValid ? '#aee1ae' : '#e78484',
            color: entity.isValid ? 'black' : 'white',
        }}>
            <td>{formatHumanName( entity.SubjectUser )}</td>
            <td>{moment(entity.fromDate).format('l' )}</td>
            <td>{entity.SellingPoint.name}</td>
            <td>{moment(entity.fromDate).format('LT' )}</td>
            <td>{entity.LoginTokensIn_createdAt ? moment(entity.LoginTokensIn_createdAt).format('LT' ) : '-'}</td>
            <td>{moment(entity.toDate).format('LT' )}</td>
            <td>{entity.LoginTokensOut_lastActivityAt ? moment(entity.LoginTokensOut_lastActivityAt).format('LT' ) : '-'}</td>
            <td>
                <button className="btn btn-xs btn-default" onClick={ se => {
                    se.preventDefault();
                    onDetails(entity.id);
                }} style={{color: 'black'}}>Dettaglio</button>
            </td>
        </tr> ;
    }

    return <>
        <Breadcumbs title="Report Accessi e turni" items={{'Home': '/'}}/>
        <div className="wrapper wrapper-content  animated fadeInRight">

            {detailModal && <ShiftsAndLoginsReportDetailModal
              onDone={res => {
                  setDetailModal( false );
              }} id={detailModal}/>}

            <Row>
                <div className="col-lg-2">
                    <FormInputMonth value={filters.month}
                                    label={"A partire da / mese"}
                                    onChange={se=>{setFilters({...filters,month: se})}} />
                </div>

                <div className="col-lg-2">
                    <FormInputYear value={filters.year}
                                   onChange={se=>{setFilters({...filters,year: se})}} />
                </div>
                <div className="col-lg-2">
                    <FormInputText value={filters.tolerance} label="Minuti di tolleranza"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>{setFilters({...filters,tolerance: se.target.value})}} />
                </div>
                <div className="col-md-2">
                    <FormInputSelect
                      direction={FormInputContainer.HORIZONTAL}
                      value={filters.isValid}
                      label="Turno valido"
                      values={{
                          '': 'Tutti',
                          '0': 'Non valido',
                          '1': 'Valido',
                      }}
                      onChange={se => {
                          setFilters({...filters,isValid: se})
                      }} />
                </div>
                <div className="col-md-4">
                    <UserSelect label="Utente"
                                isMulti
                                value={filters.SubjectUsers}
                                direction={FormInputContainer.HORIZONTAL}
                                onChange={se=>setFilters({...filters,SubjectUsers: se})} />
                </div>
            </Row>
            <Row>
                <div className="col-md-12">
                    <BoxContainer>
                            <div className="col-md-12">
                                <AbstractTableViewer model="Shifts"
                                                     filters={filters}
                                                     row={row}
                                                     method="shiftsAndLoginsReport"
                                                     infoMethod="shiftsAndLoginsReportInfo"
                                                     columns={columns}/>
                            </div>
                    </BoxContainer>
                </div>
            </Row>
        </div>
    </>
}

class UserShiftsHoursBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: null,
            shifts: []
        };
    }

    componentDidMount() {
        this.reloadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.filterSubjectUserId !== this.props.filterSubjectUserId ||
            prevProps.filterFromDate !== this.props.filterFromDate ||
            prevProps.filterToDate !== this.props.filterToDate ||
            prevProps.filterSellingPointId !== this.props.filterSellingPointId) {
            this.reloadData();
        }
    }

    reloadData() {
        fetch('api', 'Shifts', 'select', {filters: this.getFilters()}).then(data => {
            this.setState({shifts: data.body});
        });
    }

    getFilters() {

        return {
            filterSellingPointId: this.props.filterSellingPointId,
            filterSubjectUserId: this.props.filterSubjectUserId,

            filterFromDate: this.props.filterFromDate,
            filterToDate: this.props.filterToDate,
            filterCategory: this.props.filterCategory
        };
    }

    groupByAndCount(arr, keyGetter){
        const out = {};
        for (let item of arr) {
            const key = keyGetter(item);

            let minutes = moment(item.toDate).diff(moment(item.fromDate), 'minutes');
            if(out[key]===undefined)
                out[key] = minutes;
            else
                out[key] += minutes;
        }

        for( let k in out ){
            let hours = Math.floor( out[k] / 60 );
            let minutes = out[k] - (hours*60);
            out[k] = `${hours} ore ${minutes}m`;
        }

        return out;
    };


    render() {

        let minutes = 0;
        let grouped = {};

        if (this.state.shifts) {
            this.state.shifts.map(s => {
                minutes += moment(s.toDate).diff(moment(s.fromDate), 'minutes')
            });


            grouped = this.groupByAndCount(this.state.shifts, (item) => item.SellingPoint.name);


            console.log(grouped);

        }

        let hours = Math.floor( minutes / 60 );
        minutes -= hours * 60;

        return <>
            <div className={'user-shift-hours-box w-100 bg-info text-center py-3 ' + (this.props.extraClass || null)}>
                <h2 className={'text-center font-weight-bold'}>{this.props.label}</h2>

                <div className={'hours mb-2'}><span className={'display-4'}>{hours}</span> Ore {minutes}m</div>

                <div>
                    {Object.keys(grouped).map(key => {
                        return <div className={'d-flex'}>
                            <div className={'w-50 text-center  font-bold'}>{key}</div>
                            <div className={'w-50 text-center font-bold'}>{grouped[key]}</div>
                        </div>
                    })}
                </div>
            </div>
        </>

    }
}


export {Shifts, NewShiftModal, ShiftsReport, UserShiftsHoursBox, ShiftsAndLoginsReport};