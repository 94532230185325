import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, Row, FormInputDate
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, RoleSelect
} from './AppCommon';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";
import {ContractTypeRoleSelect} from "./Contracts";

class AutoContractRoles extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      autoContractRolesModal: false,
      reports: false,
      update: 1
    };

    this.onRegen = this.onRegen.bind( this );
  }

  onRegen( se ){

    se.preventDefault();

    this.setState({ update: this.state.update+1 });

    setTimeout( ()=>{
      this.setState({ update: this.state.update+1 });
    } , 1000 );

    fetch( 'api' , 'AutoContractRoles' , 'regen' ).then( msg => {
      this.setState({ update: this.state.update+1 });
    });
  }

  onDelete( id ){

    fetch( 'api' , 'AutoContractRoles' , 'delete' , id ).then( msg => {
      this.setState({ update: this.state.update+1 });
    });

  }

  componentDidMount(){
  }

  render(){

    const columns = {
      name: 'Autore contratto' ,
      target: 'Utente',
      /*from: 'Tipo contratto',*/
      to: 'Ruolo',
      Action: ''
    };

    const row = entry => {
      return <tr>
        <td>{formatLeadName( entry.Author )}</td>
        <td>{formatLeadName( entry.Target )}</td>
        <td>{entry.ContractTypeRole ? `${entry.ContractTypeRole.ContractType.name} / ${entry.ContractTypeRole.name}` : '-' }</td>
        <td>
          <div class="btn-group">
            {can('AutoContractRoles.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({autoContractRolesModal: entry.id});
            }}>Vedi</button> }

            {can('AutoContractRoles.delete') && <button class="btn btn-danger btn-xs ml-2" onClick={se=>{
              se.preventDefault();
              this.onDelete( entry.id )
            }}>Cancella</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Auto inserimento ruoli" items={{'Home': '/' , 'Utenti':'/users_list'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
        { can( 'AutoContractRoles.save' ) && <button className="btn btn-primary" onClick={se=>{
          se.preventDefault();
          this.setState({autoContractRolesModal: true});
        }}>Nuovo</button>}

        { can( 'AutoContractRoles.save' ) && <button className="btn btn-danger ml-4" onClick={this.onRegen}>Rigenera</button>}
      </div>

      <div className="row">

        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <Row>

                <div className="col-md-4">
                  <UserSelect value={this.state.filterUsers}
                              direction={FormInputContainer.HORIZONTAL}
                              label="Autore contratto" isMulti
                              onChange={se=>this.setState({ filterUsers: se })} />
                </div>

                <div className="col-md-4">
                  <UserSelect value={this.state.filterTargets}
                              direction={FormInputContainer.HORIZONTAL}
                              label="Utenti" isMulti
                              onChange={se=>this.setState({ filterTargets: se })} />
                </div>

              </Row>
            </div>
          </div>
        </div>

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                <div className="ibox-content">
                  <AbstractTableViewer model="AutoContractRoles"
                                       filters={{
                                         users: this.state.filterUsers,
                                         targets: this.state.filterTargets,
                                         update: this.state.update
                                       }}
                                       row={row}
                                       columns={columns}/>
                </div>
              </div>
            </div>
          </div> }
        </div>
      </div>

      {this.state.autoContractRolesModal && <NewAutoContractRolesModal onDone={res=>{
        this.setState({autoContractRolesModal: false});
      }} id={this.state.autoContractRolesModal === true ? false: this.state.autoContractRolesModal} />}




    </div>];
  }
}



class NewAutoContractRolesModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'AutoContractRoles', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'AutoContractRoles', this);
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <UserSelect value={this.state.Author}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Autore contratto"
                    onChange={se=>this.setState({ Author: se , AuthorUserId: se.id })} />

        <UserSelect value={this.state.Target}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Utente"
                    onChange={se=>this.setState({ Target: se , TargetUserId: se.id })} />

        {/*<ContractTypesSelect value={this.state.ContractType}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setState({ ContractType: se , ContractTypeId: se.id })} />*/}

        <ContractTypeRoleSelect value={this.state.ContractTypeRole}
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se=>this.setState({ ContractTypeRole: se , ContractTypeRoleId: se.id })} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

export { AutoContractRoles , NewAutoContractRolesModal };