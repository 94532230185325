import React from 'react';
import moment from 'moment';

import {
  Loader, fetch, FormDashline,
  FormInputText, FormButtons, FormInputContainer, Modal, FormInputSelect, FormInputRadio
} from './Common.js';

import {
  Breadcumbs, apiFormInit, apiFormSave, AbstractApiFormFooter, can,
  FileUploadInput, ConfirmableButton, LocalCache
} from './AppCommon';
import {formatLeadName} from "./Leads";

class EditTemplateModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exportInfo: false,
      apiName: this.props.apiName,
      global: 0
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'ExportTemplates', this );

    fetch( 'api' , this.props.apiName , 'exportInfo' ).then( msg => {
      this.setState({
        exportInfo: msg.body,
      })
    });
  }

  onDelete( se ){
    fetch( 'api' , 'ExportTemplates' , 'delete' , this.state.id ).then( msg => {
      this.props.onDone( 1 );
    });
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'ExportTemplates', this);
  }

  getColumns(){
    let columnsMachineNames = [];
    try {
      columnsMachineNames = JSON.parse( this.state.columnsMachineNames );
    } catch (e) { }

    if( !columnsMachineNames )
      columnsMachineNames = [];

    return columnsMachineNames;
  }

  checkColumn( column ){
    let columnsMachineNames = this.getColumns();

    if( columnsMachineNames.indexOf( column.value || column.machineName ) >= 0 ){
      columnsMachineNames = columnsMachineNames.filter( c => {
        return c != (column.value || column.machineName);
      })
    } else {
      columnsMachineNames.push( column.value || column.machineName  );
    }

    this.setState({
      columnsMachineNames: JSON.stringify( columnsMachineNames )
    });

  }

  render(){

    if( !this.state.exportInfo )
      return <Loader />;

    let columnsMachineNames = this.getColumns();

    return <>
      <form onSubmit={this.onSubmit}>

        <h3>Modifica template {this.state.name}</h3>
        {this.state.AuthorUser && <p>Creato da {formatLeadName( this.state.AuthorUser )}</p>}

        <div className={"row"}>
          <div className={"col-12"}>
            <FormInputText
              direction={FormInputContainer.HORIZONTAL}
              label={"Nome template"}
              validation={this.state.validation} name="name"
              value={this.state.name}
              onChange={se=>{
                this.setState({name: se.target.value})
              }} />
          </div>

          <div className={"col-12"}>
            <FormInputRadio label={"Pubblico"} checked={this.state.global}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=> this.setState({ global: this.state.global ? 0 : 1})} />

          </div>
        </div>

        <FormDashline />

        <h3>Gestisci colonne</h3>

        <div className={"row"}>
          {this.state.exportInfo.columns.map( column => {
            let check = columnsMachineNames.indexOf( column.value || column.machineName ) >= 0;

            return <div className={"col-12"}>
              <FormInputRadio label={column.label} checked={check}
               direction={FormInputContainer.HORIZONTAL}
               onClick={se=>this.checkColumn( column )} />
            </div>
          })}
        </div>

        <FormDashline />

        {(this.state.id && this.state.AuthorUserId == LocalCache.get('loginData').User.id) && <div className={"row"}>
          <div className={"col-12 text-right"}>
            <a href={"#"} className={"btn btn-danger"} onClick={this.onDelete}>Elimina</a>
          </div>
        </div> }

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saveEnable={(this.state.AuthorUserId == LocalCache.get('loginData').User.id) || can('Users.delete')}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />
      </form>
    </>;

  }
}

class ExportModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      apiName: 'ContractsExploded',
      templates: false,
      editTemplateId: false, // -1 for new
      loading: false,
    }

    //this.loadData()

    this.onNewTemplate = this.onNewTemplate.bind( this );
    this.onEditTemplate = this.onEditTemplate.bind( this );
    this.onSaveTemplate = this.onSaveTemplate.bind( this );
    this.onExport = this.onExport.bind( this );
    this.onClose = this.onClose.bind( this );
  }

  componentDidMount() {
    ExportModal.instance = this;
  }

  loadData(){
    this.setState({
      loading: true
    });

    fetch( 'api' , 'ExportTemplates' , 'select' , { filters: { apiName: this.state.apiName } } ).then( msg => {
      this.setState({ templates: msg.body, loading: false })
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( this.state.visible && !this.state.templates && !this.state.loading ){
      this.loadData();
    }
  }

  onEditTemplate( se ){
    se.preventDefault();

    let id = this.state.template;

    this.setState({
      editTemplateId: id
    });
  }

  onNewTemplate( se ){
    se.preventDefault();
    this.setState({
      editTemplateId: -1
    });
  }

  onSaveTemplate( se ){
    this.setState({
      templates: false,
      editTemplateId: false,
    });
  }

  onExport( se ){
    se.preventDefault();

    let payload = {
      filters: this.state.filters,
      templateId: this.state.template
    };

    fetch( 'api' , this.state.apiName , 'autoExport' , payload ).then( msg => {
      this.onClose();
      if( ExportsStatus.instance )
        ExportsStatus.instance.changeUpdateInterval( true );
    });
  }

  onClose( se ){
    this.setState({visible: false});
  }

  render() {

    if( !this.state.visible )
      return null;

    if( this.state.loading || !this.state.templates ){
      return <Modal>
        <Loader />
      </Modal>;
    }

    if( this.state.editTemplateId !== false ){
      return <Modal>
        <EditTemplateModal id={this.state.editTemplateId}
          apiName={this.state.apiName}
          onDone={this.onSaveTemplate} />
      </Modal>
    }

    const templates = Object.fromEntries( this.state.templates.map( template => {
      return [ template.id, template.name ];
    }) );

    return <Modal onClose={this.onClose}>

      <h3>Esporta dati</h3>

      <div className={"row"}>
        <div className={"col-12"}>
          <span>Seleziona template</span>
        </div>
        <div className={"col-10"}>
          <FormInputSelect
            direction={FormInputContainer.HORIZONTAL}
            label={false}
            value={this.state.template}
            onChange={se=>{this.setState({template: se})}}
            values={templates} />
        </div>
        <div className={"col-2"}>
          {this.state.template &&
            <button className={"btn btn-default"}
              onClick={this.onEditTemplate}>
              <i className={"fa fa-pencil"}></i></button> }
        </div>
      </div>

      <div className={"row"}>
        <div className={"col-12"}>
          <button className={"btn btn-default"} onClick={this.onNewTemplate}>Nuovo template</button>
        </div>
      </div>

      <FormDashline />

      <div className={"row"}>
        <div className={"col-12"}>
          {this.state.template &&
            <button className={"btn btn-primary"} onClick={this.onExport}>Avvia esportazione</button> }
        </div>
      </div>
    </Modal>;
  }
}

function showExportModal( apiName , filters = {} ){
  if( !ExportModal.instance )
    return;

  ExportModal.instance.setState({
    visible: true,
    apiName: apiName,
    filters: filters
  });
}

class ExportsStatus extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      exports: []
    };

    this.SLOW_INTERVAL = 1000 * 60 * 15;
    this.FAST_INTERVAL = 1000 * 1;

    this.updateInterval = this.FAST_INTERVAL;

    this.onDownload = this.onDownload.bind( this );

    ExportsStatus.instance = this;
  }

  changeUpdateInterval( toFast ){
    if( this.checkInterval )
      clearTimeout( this.checkInterval );

    this.updateInterval = toFast ? this.FAST_INTERVAL : this.SLOW_INTERVAL;
    console.log( `Changing update interval to ${this.updateInterval}` );
    this.scheduleUpdate();
  }

  scheduleUpdate(){
    if( this.checkInterval )
      clearTimeout( this.checkInterval );

    this.checkInterval = setTimeout( () => {
      this.updateData();
    } , this.updateInterval );
  }

  updateData(){
    fetch( 'api' , 'ExportTemplates' , 'allTickets' ).then( msg => {

      if( msg.body ){
        this.setState({ exports: msg.body });

        let runningExports = msg.body.filter( exp => {
          return !exp.complete
        });

        if( runningExports.length == 0 && this.updateInterval == this.FAST_INTERVAL )
          this.changeUpdateInterval( false );

      }

      this.scheduleUpdate();

    }).catch( err => {
      this.scheduleUpdate();
    });
  }

  onDelete( exp ){
    fetch( 'api' , 'ExportTemplates' , 'close' , exp.uuid ).then( msg => {
      this.updateData();
    });
  }

  componentDidMount() {
    this.checkInterval = setTimeout( () => {
      this.updateData();
    } , this.updateInterval );
  }

  componentWillUnmount() {

  }

  onDownload( exp ){
    window.location = `${window.location.protocol}//${window.location.hostname}/api/Files/download/${exp.file.id}/${exp.file.filename}`;
  }

  render(){

    if( !this.state.exports ) return null;
    if( !this.state.exports.length ) return null;

    let runningExports = this.state.exports.filter( exp => {
      return !exp.complete
    });

    if( runningExports.length == 0 && this.updateInterval == this.FAST_INTERVAL )
      this.changeUpdateInterval( false );

    return <div className={"ExportsStatus"}>

      <div className={"ExportsStatusBody"}>
        {runningExports.length == 0 && <h3>Esportazioni pronte</h3>}
        {runningExports.length > 0 && <>
          {this.state.exports.length > 1 && <h3>{this.state.exports.length} esportazioni in corso</h3>}
          {this.state.exports.length == 1 && <h3>Esportazione in corso</h3>}
        </>}
      </div>

      {this.state.exports.map( exp => {
        return <div className={"ExportsStatusBody"}>

          {!exp.complete && <>
            <div className={"row"}>
              <div className={"col-10"}>
                <div className="progress progress-mini">
                  <div style={{width: exp.percentage + "%"}} className="progress-bar"></div>
                </div>
              </div>
              <div className={"col-2"}>
                <i className={"fa fa-spinner fa-pulse"}></i>
              </div>
            </div>
          </>}

          {exp.complete && <>
            <i className={"fa fa-times"} onClick={se=>this.onDelete(exp)}></i>
            <div className={"row"} onClick={se=>this.onDownload(exp)}>
              <div className={"col-10"}>
                <p>{exp.filename}</p>
              </div>
              <div className={"col-2"}>
                <i className={"fa fa-download"}></i>
              </div>
            </div>
          </>}

        </div>
      } )}


    </div>
  }
}

export { ExportModal, showExportModal, ExportsStatus }