import React from 'react';
import moment from 'moment';

import { Loader , fetch, FormDashline,
  FormInputText, FormButtons, FormInputContainer , Modal } from './Common.js';

import  { Breadcumbs , apiFormInit, apiFormSave, AbstractApiFormFooter, can,
  FileUploadInput, ConfirmableButton } from './AppCommon';

  class Folders extends React.Component {
      constructor(props){
          super(props);

          this.state = {
              loading: false,
              folders: [],
              folderModal: false
          };

          this.selectFolder = this.selectFolder.bind(this);
      }

      componentDidMount(){
          fetch('api','Folders','select').then( data => {
              this.setState({folders: data.body});
          });
      }
      
      selectFolder( id ){
        this.setState({selectedFolder: id, 
            selectedFolderContent: false});

        fetch('api','Folders','get', id).then( data => {
          this.setState({selectedFolderContent: data.body});
          window.scrollTo( 0,0 )
        });
      }

      render(){

        if( this.state.loading )
            return <Loader />;

        return <React.Fragment>
            <Breadcumbs title="Documenti" items={{'Home': '/'}}></Breadcumbs>
              <div className="wrapper wrapper-content">
                  <div className="row">
                      <div className="col-lg-3">
                          <div className="ibox">
                              <div className="ibox-content">
                                  <div className="file-manager">
                                    <h5>Cartelle</h5>
                                    <ul class="folder-list" style={{padding: 0}}>
                                        {this.state.folders.map( folder => 
                                            <li>
                                                {can('Folders.save') && <a data-toggle="modal" style={{float: 'left'}} href="#" 
                                                    onClick={se=>{se.preventDefault();this.setState({folderModal: folder.id})}}>
                                                    <i class="fa fa-pencil-square-o"></i>
                                                </a>}
                                                <a href="#" onClick={se=>{se.preventDefault();this.selectFolder(folder.id)}}>
                                                    <i class="fa fa-folder"></i> {folder.name}
                                                </a>
                                            </li>
                                        )}

                                    </ul>

                                    <div class="hr-line-dashed"></div>

                                    {can('Folders.save') && <a data-toggle="modal" class="btn btn-primary btn-xs" href="#"
                                        onClick={se=>{se.preventDefault();this.setState({folderModal: true})}}>Nuova Cartella</a>}

                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-9">
                          
                            { !this.state.selectedFolder && <h2 
                            style={{textAlign: 'center', color: '#c1c1c1', marginTop: '4em'}}>
                                Seleziona una cartella</h2>}

                            { this.state.selectedFolder && <FolderView id={this.state.selectedFolder} /> }
                      </div>
                  </div>
              </div>
              {this.state.folderModal && <NewFolderModal id={this.state.folderModal} 
                onDone={()=>{this.setState({folderModal: false}); window.location.reload()}} />}
          </React.Fragment>;
      }
  }

  
class FolderView extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            loading: true,
            addingNewFile: false
        };

        this.updateFiles = this.updateFiles.bind(this);
    }

    reloadData(){
        fetch('api','Folders','get', this.props.id).then( data => {
            this.setState({
                ...data.body,
                addingNewFile: false
            });
        });
    }

    componentDidMount(){
        this.reloadData();
    }

    componentDidUpdate( prevProps, prevState ){
        if( prevProps.id != this.props.id )
            this.reloadData();

        /*if( JSON.stringify( this.state.filesId ) != JSON.stringify( prevState.filesId ) ){
            fetch('api','Folders','save', this.state).then( data => {
                this.reloadData();
            });
        }*/
    }

    updateFiles( filesId ){
      fetch('api','Folders','save', {...this.state, filesId }).then( data => {
        this.reloadData();
      });
    }
    
    onDelete( file ){
        fetch('api','Files','delete', file.id ).then( data => {
            this.reloadData();
        });

        this.setState({
            Files: this.state.Files.filter( f => { return f.id != file.id } ) 
        } );
    }

    render(){

        if( this.loading )
            return <Loader />;

        return <div className="row">

            <div className="col-sm-12">
                <h1>{this.state.name}</h1>
            </div>

            { can( 'Folders.save' ) && <div className="col-sm-12">
                <FileUploadInput showFiles={false} onChange={filesId=>{
                        this.updateFiles( filesId );
                    }} filesId={this.state.filesId} />
            </div>}
            <div className="col-sm-12">
            
                {this.state.Files && this.state.Files.map( file =>
                    <div className="file-box">
                        <div className="file">
                            <a href={`${window.location.protocol}//${window.location.hostname}/api/Files/download/${file.id}/${file.filename}`}>
                                <span className="corner"></span>
                                <div className="icon">
                                    <i className="fa fa-file"></i>
                                </div>
                                <div class="file-name">{file.filename}<br />
                                    <small>Aggiunto: {moment(file.createdAt).format("D MMMM YYYY H:mm:SS")}</small>
                                    { can('Folders.delete') && <div className="text-right">

                                        <ConfirmableButton className="btn btn-xs btn-danger" 
                                            onClick={se=>{
                                                se.preventDefault();
                                                this.onDelete(file)
                                            }}>Elimina</ConfirmableButton>
                                    </div>}
                                </div>
                                
                            </a>
                        </div>
                    </div> )}
            
            </div>
            
        </div>;
    }
}

class NewFolderModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'Folders', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'Folders', this);
      }
    
      render(){
    
        return <Modal>
          <form onSubmit={this.onSubmit}>
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />                   
    
            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

  export { Folders , NewFolderModal }