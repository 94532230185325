import React from "react";
import moment from "moment";
import {
  BoxContainer,
  fetch,
  FormInputContainer,
  FormInputMonth,
  FormInputSelect,
  FormInputYear,
  Loader,
  Row
} from "./Common";
import {Breadcumbs, can, ChannelSelect, ContractTypesSelect, formatEur} from "./AppCommon";
import {ProductSelect} from "./Products";
import {UserSelect} from "./Users";

function ReportObjectivesPlanRange( props ){
  const range = props.range;

  let outcome = '-';

  try {

    if( range.filter.filterData.type == 'perContract' ){
      outcome = 'Il valore di ogni contratto è ' +
        formatEur( range.filter.filterData.valueEur );
    }

    if( range.filter.filterData.type == 'fixed' ){
      outcome = 'Bonus fisso ' +
        formatEur( range.filter.filterData.valueEur );
    }

  } catch (e) { }

  try {
    return <React.Fragment>

      <div className="row  m-t-sm">
        <div className="col-sm-8">
          <div className="font-bold"># CONTRATTI ATTUALI</div>
          {range.min + range.count -1}
        </div>
        <div className="col-sm-4 text-right">
          <div className="font-bold">OBBIETTIVO</div>
          <h4>{range.max}</h4>
        </div>
      </div>

      <div>
        <h4>Al raggiungimento:</h4>
        <p>{outcome}</p>
      </div>

      <div>
        <div className="progress progress-mini">
          <div style={{width: range.percent + "%"}} className="progress-bar"></div>
        </div>
        <span>Completamento scaglione:</span>
        <div className="stat-percent">{range.percent}%</div>
      </div>

      <hr />

    </React.Fragment>;
  } catch (e) { }

  return null;

}

class ReportObjectivesPlan extends React.Component {

  constructor( props ) {
    super( props );

    this.state = {
      opened: false
    }

    this.toggleOpened = this.toggleOpened.bind( this );
  }

  toggleOpened( se ){
    se.preventDefault();

    this.setState({
      opened: !this.state.opened
    });
  }

  render(){
    let plan = this.props.plan;

    let currentRange = false;

    try {

      plan.ranges = plan.ranges
        .sort( (a,b) => {
          return a.min - b.min
        })
        .map( range => {
          return {
            ...range,
            percent: parseInt( (range.count / range.max) * 100 ),
          }
        });

      currentRange = plan.ranges.filter( range => {
        return range.count > range.min && range.count < range.max;
      })[0];
    } catch (e) { }

    try {

      if( !currentRange )
        currentRange = plan.ranges[0];

    } catch (e) { }

    try {

      if( plan.ranges.length < 1 )
        return null;

      const boxTools = <React.Fragment>
        {plan.report && <a className={"btn btn-primary btn-xs"} target={"_blank"}
                  href={"/marketing_report/" + plan.report.id}>Vedi report</a>}
        </React.Fragment>;

      return <div className={"col-md-6"}>
        <BoxContainer title={plan.plan.name} tools={boxTools}>
          <h4> Report "{plan.plan.name}"</h4>

          {this.state.opened && <React.Fragment>
            {plan.ranges.map( range => {
              return <ReportObjectivesPlanRange range={range} />;
            })}
          </React.Fragment>}

          {!this.state.opened && <React.Fragment>

            {currentRange && <ReportObjectivesPlanRange range={currentRange} /> }

            {!currentRange && <div>
              <div className="alert alert-info">
                Non ci sono scaglioni in corso per questo piano compensi ( Usa il pulsante "Vedi tutti" per i dettagli )
              </div>
            </div>}

          </React.Fragment>}

          <button onClick={this.toggleOpened} className={"btn btn-xs"}>
            {this.state.opened ? 'Chiudi' : 'Vedi tutti gli scaglioni'}</button>

        </BoxContainer>
      </div>;

    } catch (e) {
    }

    return null;
  }
}

class ReportObjectives extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterMonth: (moment().month()) +'',
      filterYear: moment().year() +'',
    };

  }
  componentDidMount(){
    this.updateData();
  }

  updateData(){
    this.setState({data: false});
    fetch( 'api' , 'ReportObjectives' , 'select' , this.state ).then( msg => {
      this.setState({data: msg.body});
    });
  }

  componentDidUpdate(prevProps,prevState){
    if( prevState.filterMonth != this.state.filterMonth ||
      prevState.filterUsers != this.state.filterUsers ||
      prevState.filterYear != this.state.filterYear ){
      this.updateData();
    }
  }

  render(){

    return <React.Fragment>
      <Breadcumbs title="Report obbiettivi" items={{'Home': '/'}} />

      <div className="wrapper wrapper-content  animated fadeInRight">

        <Row>

          { can('MarketingReports.agentReportAll') && <div className="col-lg-4">
            <UserSelect value={this.state.filterUsers}
                        direction={FormInputContainer.HORIZONTAL}
                        label="Utenti provvigionati"
                        isMulti
                        onChange={se=>this.setState({
                          filterUsers: se})} />
          </div>}

          <div className="col-lg-2">
            <FormInputMonth value={this.state.filterMonth}
                            onChange={se=>{this.setState({filterMonth: se});}} />
          </div>

          <div className="col-lg-2">
            <FormInputYear value={this.state.filterYear}
                           onChange={se=>this.setState({filterYear: se})} />
          </div>
        </Row>
        <Row>

          <div className={"col-md-12"}>
            {!this.state.data && <Loader />}
            {this.state.data && <React.Fragment>
              <Row>
                {Object.keys(this.state.data).map( uid => {
                  const user = this.state.data[uid];

                  return <React.Fragment>
                    <div className={"col-md-12"}>
                      <h2>{user.user.name} {user.user.surname}</h2>

                      {user.values.length == 0 ?
                        <p className={"text-info"}>Non ci sono elementi per questo utente</p> : null}

                    </div>

                    {user.values.map( item => {
                      return <ReportObjectivesPlan plan={item} />;
                    })}
                  </React.Fragment>;

                })}
              </Row>
            </React.Fragment>}
          </div>

        </Row>

      </div>


    </React.Fragment>;
  }
}

export { ReportObjectives };