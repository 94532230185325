import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, queryToObject
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, formatDateTime
} from './AppCommon';
import {QuotationProductsSelect} from "./QuotationProducts";

class Quotations extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      tableUpdate: 0,
    };

    this.onDelete = this.onDelete.bind( this );
  }

  onDelete( id ){
    fetch( 'api' , 'Quotations' , 'delete' , id ).then( msg => {
      this.setState({ tableUpdate: this.state.tableUpdate+1 });
    });
  }

  render(){

    const columns = {
      name: '#' ,
      createdAt: 'Data creazione',
      price: 'Riferimento',
      total: 'Totale',
      actions: 'Azioni'
    };

    const row = quotation => {
      return <tr>
        <td>{quotation.year}/{quotation.progressive}</td>
        <td>{formatDateTime(quotation.createdAt)}</td>
        <td>{quotation.reference}</td>
        <td>{formatEur( quotation.total )}</td>
        <td>
          <div class="btn-group">
            {can('Quotations.save') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              window.location = '/quotation?id=' + quotation.id;
            }}>Vedi</button> }
            { can( 'Quotations.delete' ) &&
            <button className="btn btn-danger" onClick={se => {
              se.preventDefault();
              this.onDelete( quotation.id );
            }}>Elimina</button>}
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Preventivi" items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Quotations.save' ) && <a className="btn btn-primary" href={"/quotation"}>Nuovo preventivo</a>}
        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="Quotations"
                                         tableUpdate={this.state.tableUpdate}
                                         filters={{ }}
                                         row={row}
                                         columns={columns}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

      </div>];
  }
}

class QuotationScreen extends React.Component {

  constructor( props ) {
    super( props );

    this.state = {
      loading: true,
      firstProduct: false,
        ...queryToObject()
    };

    this.save = this.save.bind( this );
    this.reloadData = this.reloadData.bind( this );

    this.onChangeProduct = this.onChangeProduct.bind( this );
    this.onChangeQty = this.onChangeQty.bind( this );
    this.onDelete = this.onDelete.bind( this );
    this.onAdd = this.onAdd.bind( this );

    this.reloadData();
    this.loadFirstProducts();
  }

  loadFirstProducts(){
    fetch( 'api' , 'QuotationProducts' , 'select' ).then( msg => {
      this.setState({ firstProduct: msg.body[0] });
    });
  }

  reloadData(){
    this.setState({loading: true});

    if( !this.state.id ){
      fetch( 'api' , 'Quotations' , 'save' , this.state ).then( msg => {
        window.location = '/quotation?id=' + msg.body.object.id;
      })
    } else {
      fetch( 'api' , 'Quotations' , 'get' , this.state.id ).then( msg => {
        this.setState({
          ...msg.body,
          loading: false
        })
      })
    }

  }

  onChangeProduct( rowIndex, product ){
    let products = this.state.Products;
    products[rowIndex] = product;
    products[rowIndex].QuotationsProducts = { qty: 1 };
    this.setState({ Products: products });
  }

  onChangeQty( rowIndex , qty ){
    let products = this.state.Products;
    products[rowIndex].QuotationsProducts.qty = qty;
    this.setState({ Products: products });
  }

  onDelete( rowIndex ){
    let products = this.state.Products.filter( (p,pIndex) => {
      return pIndex != rowIndex;
    });
    this.setState({ Products: products });
  }

  onAdd( row ){
    let prod = this.state.Products;
    prod.push({
      ...this.state.firstProduct,
      QuotationsProducts: { qty: 1 }
    });
    this.setState({Products: prod});
  }

  save(){
    this.setState({loading: true});

    fetch( 'api' , 'Quotations' , 'save' , this.state ).then( msg => {
      this.setState({
        ...msg.body,
      });

      window.location = '/quotations';
    });

  }

  render(){
    const bread = <Breadcumbs title="Modifica preventivo" items={{'Home': '/', 'Preventivi': '/quotations'}} />;

    if( this.state.loading || !this.state.firstProduct )
      return [ bread , <Loader /> ];

    return [ bread , <div className="wrapper wrapper-content  animated fadeInRight">
      <div className="row">
        <div className={"col-md-10 offset-md-1"}>
          <div className="ibox">
            <div className="ibox-content p-5">

              <div className={"row "}>
                <div className={"col-md-12 mb-5"}>
                  <h2>Preventivo {this.state.year} / {this.state.progressive}</h2>
                </div>
                <div className={"col-md-5"}>
                  <FormInputText value={this.state.reference}
                                 onChange={se=>{this.setState({reference: se.target.value})}}
                                 direction={FormInputContainer.HORIZONTAL}
                                 label={"Riferimento"} />
                </div>
              </div>

              <table className={"table"}>
                <thead>
                <tr>
                  <th >Prodotto</th>
                  <th width={100} className="text-right">Qty</th>
                  <th width={100} className="text-right">Prezzo</th>
                  <th width={100} className="text-right">Totale</th>
                  <th width={50}></th>
                </tr>
                </thead>
                {this.state.Products.map( (row,rowIndex) => {
                  return <tr>
                    <td>
                      <QuotationProductsSelect
                        value={row}
                        label={false}
                        direction={FormInputContainer.HORIZONTAL}
                        onChange={ prod => this.onChangeProduct( rowIndex , prod )}
                      />
                    </td>
                    <td>
                      <FormInputText value={row.QuotationsProducts.qty}
                                 onChange={se=>{this.onChangeQty(rowIndex, se.target.value)}}
                                 direction={FormInputContainer.HORIZONTAL}
                                 label={false} />
                    </td>
                    <td className="text-right">
                      {formatEur(row.price)}
                    </td>
                    <td className="text-right">
                      {formatEur(row.price * row.QuotationsProducts.qty)}
                    </td>
                    <td>
                      <button className={"btn btn-xs btn-danger"} onClick={ se => {
                        se.preventDefault();
                        this.onDelete( rowIndex );
                      }}>
                        <i className={"fa fa-times"}></i>
                      </button>
                    </td>
                  </tr>
                })}

                <tr>
                  <td>
                    Ricavo
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <FormInputText value={this.state.return}
                                   onChange={se=>{this.setState({return: se.target.value})}}
                                   direction={FormInputContainer.HORIZONTAL}
                                   label={false} />
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>
                    <button className={"btn btn-default"} onClick={this.onAdd}>
                      <i className={"fa fa-plus"}></i> Aggiungi riga
                    </button>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className={"text-right"}>
                    {formatEur( this.state.Products.reduce( (acc,cur) => {
                      return acc + (cur.price * cur.QuotationsProducts.qty);
                    } , isNaN(parseFloat(this.state.return) ) ? 0 : parseFloat(this.state.return) ))}
                  </td>
                  <td></td>
                </tr>

              </table>

              <div className={"row"}>
                <div className={"col-md-12"}>
                  <FormButtons onSave={this.save} onCancel={ se => {
                    window.location = '/quotations';
                  }} />
                </div>
              </div>

            </div>
          </div>
          </div>
        </div>
      </div> ];
  }

}

export { Quotations, QuotationScreen };