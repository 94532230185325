import React from 'react';
import './App.css';

import './Ddt.css';

import {FormInputContainer, Loader} from './Common.js';

import {
  AbstractTableViewer, can, Breadcumbs,
} from './AppCommon';
import moment from "moment";
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";

class DdtRangeView extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterUsers: []
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = {
      createdAt: 'Assegnato il',
      users: 'Utente',
      range: 'Range',
      contracts: 'Contratti disp. / usati',
      contractsDatesS: 'Data primo contratto',
      contractsDatesE: 'Data ultimo contratto',
    };

    const row = ddt => {
      return <tr>
        <td>{moment(ddt.createdAt).format('DD/MM/YY HH:mm')}</td>
        <td>{formatLeadName(ddt.User)}</td>
        <td>{ddt.rangeStart} / {ddt.rangeEnd}</td>
        <td>{ddt.rangeEnd-ddt.rangeStart+1} / {ddt.Contracts.length}</td>
        <td>
          {ddt.Contracts.length > 0 && <>
            {moment(ddt.Contracts[0].createdAt).format('DD/MM/YY HH:mm')}
          </>}
        </td>
        <td>
          {ddt.Contracts.length > 0 && <>
            {moment(ddt.Contracts[ddt.Contracts.length-1].createdAt).format('DD/MM/YY HH:mm')}
          </>}
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Range DDT" items={{'Home': '/', 'Ddt': '/ddt'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row">

          <div className="col-sm-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Range Ddt</h5>
              </div>
              <div className="ibox-content">
                <div className="row">
                  <div className={"col-md-3"}>
                    <UserSelect value={this.state.filterUsers}
                                direction={FormInputContainer.HORIZONTAL}
                                label="Utenti" isMulti
                                onChange={se=>this.setState({ filterUsers: se })} />
                  </div>
                </div>

                { this.state.loading ? <Loader /> :

                  <AbstractTableViewer model="DdtUsers"
                                       filters={{
                                         Users: this.state.filterUsers,
                                       }}
                                       row={row}
                                       columns={columns}/>
                }
              </div>
            </div>
          </div>
        </div>


      </div>];
  }
}


export { DdtRangeView };