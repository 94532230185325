import React from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, FormInputSelect,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';


import  { AbstractTableViewer, can, AbstractApiFormFooter , 
    apiFormInit , apiFormSave, formatEur, BrandSelect, 
    ApiMultiSelect, ContractTypesSelect, Breadcumbs } from './AppCommon';

class OperationTypes extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      operationTypeModal: false,
      reports: false
    };

  }

  componentDidMount(){
  }

  render(){

    const columns = { 
      name: 'Nome' , 
      type: 'Tipo',
      value: 'Valore',
      actions: 'Azioni'
  };

    const row = operationType => {
        return <tr>
          <td>{operationType.name}</td>
          <td>{operationType.type}</td>
          <td>{formatEur( operationType.valueEur )}</td>
          <td>
            <div class="btn-group">
                {can('OperationTypes.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({operationTypeModal: operationType.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
      };

    return [ <Breadcumbs title="Tipo Operazione" items={{'Home': '/'}} />, <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'OperationTypes.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({operationTypeModal: true});
          }}>Nuovo tipo operazione</button>}
      </div>

      <div className="row">

        <div className="col-md-12 col-lg-12">
          { this.state.loading ? <Loader /> : <div className="row">
            <div className="col-sm-12">
              <div className="ibox">
                  <div className="ibox-content">
                    <AbstractTableViewer model="OperationTypes" 
                        filters={{ }}
                        row={row}
                        columns={columns}/>
                  </div>
              </div>
            </div>
          </div> }
        </div>

      </div>

      {this.state.operationTypeModal && <NewOperationTypeModal onDone={res=>{
        this.setState({operationTypeModal: false});
      }} id={this.state.operationTypeModal === true ? false: this.state.operationTypeModal} />}

      


    </div>];
  }
}



class NewOperationTypeModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'OperationTypes', this );
      }

      onSubmit(se){
        se.preventDefault();
        apiFormSave( 'OperationTypes', this);
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                onChange={se=>this.setState({name: se.target.value})} />

            <FormInputSelect label="Tipo" value={this.state.type}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="type"
                values={{
                    'Semplice' : 'Semplice',
                    'Complessa' : 'Complessa',
                }}
                onChange={se=>this.setState({type: se})} />

            <FormInputText label="Valore EURO" value={this.state.valueEur}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="valueEur"
                onChange={se=>this.setState({valueEur: se.target.value})} />
    
            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva tipo operazione"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class OperationTypeSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Tipo operazione" {...this.props} model="OperationTypes" 
      formatRow={row => { return row.name; }} filters={{type:this.props.filterType}}
       />;
  }
}

export { OperationTypes , OperationTypeSelect, NewOperationTypeModal };