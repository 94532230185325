import React from 'react';
import './App.css';

import './Ddt.css';

import {
  FormInputContainer,
  FormInputMonth,
  FormInputSelect,
  FormInputYear,
  Loader,
  fetch,
  FormInputText, Modal, FormInputDate, FormButtons, FormInputRange
} from './Common.js';

import {
  AbstractTableViewer, can, Breadcumbs, formatEur, ChannelSelect, FileUploadInput, EleGasSelect,
} from './AppCommon';
import moment from "moment";
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";
import {ContractStateViewer} from "./ContractStates";
import {MarketingReportPlanFiltersSelect, MarketingReportsSelect} from "./MarketingPlans";


class PayedButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ...this.props
    };

    this.onClick = this.onClick.bind( this );
  }

  onClick( se ){
    se.preventDefault();

    this.setState({
      loading: true
    });

    fetch( 'api' , 'MarketingReportsEditor' , 'payRow' , this.props.id ).then( msg => {
      this.setState({ ...msg.body, loading: false });
      this.props.onUpdate();
    });
  }

  render() {

    return <button className={"btn btn-xs " + (this.state.payedAt?'btn-primary':'btn-danger')} onClick={this.onClick}>
      {this.state.loading && <i className={"fa fa-spinner fa-pulse"} />}

      {!this.state.loading && <>
        {this.state.payedAt && <span>Si</span>}
        {!this.state.payedAt && <span>No</span>}
      </>}
    </button>

  }

}

class MarketingReportsEditorTotals extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.reloadData();
  }

  reloadData(){
    this.setState({loading: true});

    fetch( 'api' , 'MarketingReportsEditor' , 'totals' , { filters: this.props.filters } ).then( msg => {
      this.setState({
        ...msg.body,
        loading: false
      })
    });

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( JSON.stringify( prevProps.filters ) != JSON.stringify( this.props.filters ) ||
      prevProps.update != this.props.update ){
      this.reloadData();
    }
  }

  render() {
    if( this.state.loading )
      return <Loader />;

    return <div className={"row"}>
      <div className={"col-md-4"}>
        <div className="widget style1 navy-bg">
          <div className="row">
            <div className="col-md-4">
              <i className="fa fa-eur fa-5x"></i>
            </div>
            <div className="col-md-8 text-right">
              <span> Totale pagato </span>
              <h2 className="font-bold">{formatEur(this.state.totalPayedEur)}</h2>
            </div>
          </div>
        </div>
      </div>
      <div className={"col-md-4"}>
        <div className="widget style1 navy-bg">
          <div className="row">
            <div className="col-md-4">

            </div>
            <div className="col-md-8 text-right">
              <span> Totale contratti pagati </span>
              <h2 className="font-bold">{this.state.total}</h2>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

class EditorPriceModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this.props
    };

    this.onSave = this.onSave.bind(this);
  }

  onSave( se ){
    se.preventDefault();

    let payload = { filters: this.props.filters, price: this.state.price };

    fetch( 'api' , 'MarketingReportsEditor' , 'updatePrice' , payload ).then( msg => {
      this.props.onSave();
    });

  }

  render() {
    return <Modal onClose={this.props.onClose}>
      <h4>Modifica prezzo</h4>
      <FormInputText
        direction={FormInputContainer.HORIZONTAL}
        value={this.state.price}
        onChange={se=>{
          this.setState({price: se.target.value});
        }} />

      <button className={"btn btn-primary"} onClick={this.onSave}>Salva</button>

    </Modal>
  }
}


class ImportContractsRowsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      configId: false,
      importDate: moment(),
      phase: 'form' // form | loading | results
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(se) {
    se.preventDefault();

    this.setState({phase: 'loading'});

    let timeout = setTimeout(() => {
      this.setState({phase: 'results', results: false});
    }, 5000);

    fetch('api', 'MarketingReportsEditor', 'import', this.state).then(data => {
      clearTimeout(timeout);
      if (data.body)
        this.setState({phase: 'results', results: data.body});
    });
  }

  render() {
    return <Modal onClose={this.props.onDone}>
      {this.state.phase == 'form' && <form onSubmit={this.onSubmit}>

        <label>File da importare</label>

        <FileUploadInput onChange={filesId => {
          this.setState({filesId: filesId})
        }}
                         filesId={this.state.filesId}/>

        <MarketingReportsSelect
           value={this.state.MarketingReport}
           direction={FormInputContainer.HORIZONTAL}
           onChange={se=>this.setState({MarketingReport: se})} />

        <FormButtons saveLabel="Carica file" direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     onCancel={se => {
                       se.preventDefault();
                       if (this.props.onDone) this.props.onDone();
                     }}/>
      </form>}

      {this.state.phase == 'loading' && <Loader/>}

      {this.state.phase == 'results' && <React.Fragment>
        <h3>Importazione in corso</h3>

        {!this.state.results && <h4>...</h4>}

        {this.state.results && <React.Fragment>
          <p>Contratti aggiungi: {this.state.results.contractsImported}</p>
          <p>Contratti saltati perche' gia presenti: {this.state.results.skipped}</p>

        </React.Fragment>}

        <button className="btn btn-primary" onClick={se => {
          this.props.onDone()
        }}>
          Chiudi
        </button>


      </React.Fragment>}
    </Modal>;
  }
}

class MarketingReportsEditor extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterUsers: [],
      filterYear: moment().year().toString(),
      filterMonth: moment().subtract( 1 , 'month' ).month().toString(),
      filterStateType: '',
      filterRid: '',
      filterType: '',
      filterMargin: '',
      filterWebPayment: '',
      totalUpdate: 0,
      updatePriceModal: false,
    };

    this.onPayAll = this.onPayAll.bind( this );
    this.onUnPayAll = this.onUnPayAll.bind( this );
  }

  onPayAll(se){
    se.preventDefault();

    this.setState({loading:true});
    fetch( 'api' , 'MarketingReportsEditor' , 'payAll' , { filters: this.getFilters() } ).then( msg => {
      this.setState({loading:false,totalUpdate: this.state.totalUpdate+1});
    });
  }

  onUnPayAll(se){
    se.preventDefault();

    this.setState({loading:true});
    fetch( 'api' , 'MarketingReportsEditor' , 'unpayAll' , { filters: this.getFilters() } ).then( msg => {
      this.setState({loading:false,totalUpdate: this.state.totalUpdate+1});
    });
  }

  componentDidMount(){
  }

  getFilters() {
    return {
      Users: this.state.filterUsers,
      month: this.state.filterMonth,
      year: this.state.filterYear,
      stateType: this.state.filterStateType,
      eleGas: this.state.filterEleGas,
      rid: this.state.filterRid,
      type: this.state.filterType,
      margin: this.state.filterMargin,
      webPayment: this.state.filterWebPayment,
      uniqueUuid: this.state.filterUniqueUuid,
      kw: this.state.filterKw,
    };
  }

  render(){

    const columns = {
      contract: 'Contratto',
      name: 'Nome',
      gasEle: 'Gas/Luce/Fibra',
      kw: 'Kw',
      rid: 'Rid',
      boll: 'Bolletta',
      state: 'Stato',
      ins30: 'Data Ins.Sistema',
      tip: 'Tipo',
      marg: 'Margi',
      valueEur: 'Valore',
      payed: 'Pagato'
    };

    const row = row => {

      const exploded = row.ContractsExploded;

      let Products = JSON.parse( exploded.Products_json );
      let businessType = '-';
      let margins = '-';

      if( Products ){

        businessType = Object.keys( Object.fromEntries( Products.map( p => {
          return [ p.businessType , 1 ];
        })) ).join(', ');

        margins = Object.keys( Object.fromEntries( Products.map( p => {
          return [ p.marginType , 1 ];
        })) );

        margins = margins.map( margin => {
          if( margin == 'high' ) return 'ALTA';
          if( margin == 'low' ) return 'BASSA';
          if( margin == 'none' ) return 'UNICA';
          return margin;
        }).join(', ');
      }

      return <tr>
        <td>{row.ContractsExploded.uniqueUuid}</td>
        <td>{row.ContractsExploded.Lead_name_surname}</td>
        <td>
          {row.ContractsExploded.gasOrEle == 'ele' ? 'Luce' : '' }
          {row.ContractsExploded.gasOrEle == 'gas' ? 'Gas' : '' }
          {row.ContractsExploded.gasOrEle == 'fibra' ? 'Fibra' : '' }
        </td>
        <td>{row.ContractsExploded.kw}</td>
        <td>{row.ContractsExploded.rid > 0 ? 'Si' : 'No' }</td>
        <td>{row.ContractsExploded.webPayment > 0 ? 'Si' : 'No' }</td>
        <td><ContractStateViewer contract={row.ContractsExploded} /></td>
        <td>{row.ContractsExploded.ContractsHistories_lastMarketing_date ?
          moment( row.ContractsExploded.ContractsHistories_lastMarketing_date ).format('DD/MM/YY'):
          '-'}</td>
        <td>{businessType}</td>
        <td>{margins}</td>
        <td>{formatEur(row.valueEur)}</td>
        <td><PayedButton {...row} onUpdate={s=>{ this.setState({totalUpdate: this.state.totalUpdate+1})}} /></td>
      </tr>
    };

    return [ <Breadcumbs title="Editor report compensi" items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row">

          <div className="col-sm-12">
            <div className="ibox">
              <div className="ibox-title">
                <h5>Editor report compensi</h5>
              </div>
              <div className="ibox-content">
                <div className="row">

                  <div className="col-lg-2">
                    <FormInputText label="ID Contratto" value={this.state.filterUniqueUuid}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({filterUniqueUuid: se.target.value})}/>
                  </div>

                  <div className={"col-md-3"}>
                    <FormInputMonth value={this.state.filterMonth}
                                direction={FormInputContainer.HORIZONTAL}
                                onChange={se=>this.setState({ filterMonth: se })} />
                  </div>
                  <div className={"col-md-3"}>
                    <FormInputYear value={this.state.filterYear}
                                    direction={FormInputContainer.HORIZONTAL}
                                    onChange={se=>this.setState({ filterYear: se })} />
                  </div>
                  <div className={"col-md-3"}>
                    <UserSelect value={this.state.filterUsers}
                                direction={FormInputContainer.HORIZONTAL}
                                label="Utenti" isMulti
                                onChange={se=>this.setState({ filterUsers: se })} />
                  </div>
                  <div className="col-lg-2">
                    <FormInputSelect label="Tipo stato" value={this.state.filterStateType}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterStateType"
                                     values={{
                                       '': 'Tutti',
                                       'positive': 'Pagabile',
                                       'no': 'Neutro',
                                       'negative': 'Non Pagabile',
                                     }}
                                     onChange={se => {
                                       this.setState({filterStateType: se})
                                     }}/>
                  </div>

                  <div className={"col-md-2"}>
                    <FormInputSelect label="Rid" value={this.state.filterRid}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterRid"
                                     values={{
                                       '': 'Tutti',
                                       '1': 'Si',
                                       '0': 'No',
                                     }}
                                     onChange={se => {
                                       this.setState({filterRid: se})
                                     }}/>
                  </div>

                  <div className={"col-md-2"}>
                    <FormInputSelect label="Tipologia" value={this.state.filterType}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterType"
                                     values={{
                                       '': 'Tutti',
                                       'residential': 'Residenziale',
                                       'business': 'Business',
                                     }}
                                     onChange={se => {
                                       this.setState({filterType: se})
                                     }}/>
                  </div>

                  <div className={"col-md-2"}>
                    <FormInputSelect label="Marginalita" value={this.state.filterMargin}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterMargin"
                                     values={{
                                       '': 'Tutti',
                                       'high': 'Alta',
                                       'low': 'Bassa',
                                       'none': 'Unica',
                                     }}
                                     onChange={se => {
                                       this.setState({filterMargin: se})
                                     }}/>
                  </div>

                  <div className={"col-md-2"}>
                    <FormInputSelect label="Bolletta" value={this.state.filterWebPayment}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterWebPayment"
                                     values={{
                                       '': 'Tutti',
                                       '1': 'Si',
                                       '0': 'No',
                                     }}
                                     onChange={se => {
                                       this.setState({filterWebPayment: se})
                                     }}/>
                  </div>

                  <div className={"col-md-2"}>
                    <EleGasSelect value={this.state.filterEleGas}
                                  onChange={se => {
                                    this.setState({filterEleGas: se})
                                  }} />

                  </div>

                  <div className="col-xl-2 col-lg-4">
                    <FormInputRange label="Kw" value={this.state.filterKw}
                                    direction={FormInputContainer.HORIZONTAL}
                                    name="filterKw"
                                    onChange={se => {
                                      this.setState({filterKw: se})
                                    }}/>
                  </div>

                </div>

                <div className={"row"}>
                  <div className="col-12">
                    <button className={"btn btn-primary btn-xs"} onClick={this.onPayAll} >Paga tutti</button>
                    <button className={"btn btn-danger btn-xs"} onClick={this.onUnPayAll}>Non Pagare tutti</button>
                    <button className={"btn btn-default btn-xs"} onClick={se=>{
                      se.preventDefault();
                      this.setState({updatePriceModal: true})
                    }}>Modifica tutti i prezzi</button>
                    <button className={"btn btn-default btn-xs"} onClick={se=>{
                      se.preventDefault();
                      this.setState({importContracts: true})
                    }}>Importa contratti</button>
                  </div>
                </div>

                {this.state.updatePriceModal && <EditorPriceModal
                  onClose={()=>{ this.setState({updatePriceModal: false})}}
                  filters={this.getFilters()}
                  onSave={()=>{
                    this.setState({
                      totalUpdate: this.state.totalUpdate+1,
                      updatePriceModal: false
                    } )
                  }}
                />}

                {this.state.importContracts && <ImportContractsRowsModal
                  onDone={()=>{ this.setState({importContracts: false})}}
                />}

                { (this.state.loading||this.state.updatePriceModal) ? <Loader /> : <>

                  <MarketingReportsEditorTotals update={this.state.totalUpdate} filters={this.getFilters()} />

                  <AbstractTableViewer model="MarketingReportsEditor"
                                       filters={this.getFilters()}
                                       row={row}
                                       columns={columns}/>
                </>}
              </div>
            </div>
          </div>
        </div>


      </div>];
  }
}


export { MarketingReportsEditor };