import React, {useState} from 'react';
import moment from 'moment';

import {
  Loader, fetch, FormDashline, FormInputText, FormButtons, Row, FormInputRadio,
  FormInputContainer, Modal, FormInputPassword, FormInputTextArea, FormInputArray, FormInputDate, BoxContainer
} from './Common.js';
  

import {
  RoleSelect, apiFormInit, apiFormSave, can, Breadcumbs,
  AbstractTableViewer, AbstractApiFormFooter, ApiMultiSelect, formatDateTime
} from './AppCommon';
import { MarketingPlans, MarketingReportPlanFiltersSelect } from './MarketingPlans.js';
import {TagsSelect} from "./Tags";
import {formatLeadName} from "./Leads";


class UsersNewWizard extends React.Component {
  constructor(props){
    super(props);

    this.state = {
      currentTab: 0
    };

    this.prevTab = this.prevTab.bind(this);
    this.nextTab = this.nextTab.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  prevTab(se){
    se.preventDefault();
    this.setTab(this.state.currentTab-1); 
  }

  nextTab(se){
    se.preventDefault();
    this.setTab(this.state.currentTab+1);
  }

  onSave(se){
    if( se )
      se.preventDefault();

    fetch( 'orm' , 'Users' , 'save' , this.state ).then( res => {
      //Route.pushLocation(`/users_list`);
    });
  }

  setTab( tabNumber ){
    if( tabNumber < 0 )
      tabNumber = 0;
    if( tabNumber > 3 )
      tabNumber = 3;

    this.setState({currentTab: tabNumber});
  }

  render(){
    return <div className="wrapper wrapper-content  animated fadeInRight">
        <div className="row">
          <div className="col-sm-12">
            <div className="ibox">
              <div class="ibox-title">
                <h5>Inserimento Nuovo Utente</h5>
              </div>

              <div className="ibox-content">
                <h2>Scheda dati</h2>
                <p>Per accedere al prossimo step bisogna compilare tutti i campi</p>

                <form id="desied_crm_users_form" action="#" class="wizard-big wizard clearfix" role="application" novalidate="novalidate">
                  <div class="steps clearfix">
                    <ul>
                      <li className={"first " + (this.state.currentTab == 0 ? 'current' : 'disabled')}>
                        <a href="#" onClick={se=>{se.preventDefault();this.setTab(0)}}>1. Account</a></li>
                      <li className={this.state.currentTab == 1 ? 'current' : 'disabled'}><a href="#" 
                        onClick={se=>{se.preventDefault();this.setTab(0)}}>2. Profilo</a></li>
                      <li className={this.state.currentTab == 2 ? 'current' : 'disabled'}><a href="#">3. Account</a></li>
                      <li className={"last " + (this.state.currentTab == 3 ? 'current' : 'disabled')}><a href="#">3. Fine</a></li>
                    </ul>
                  </div>
                  <div className="content clearfix">
                    {this.state.currentTab == 0 && <fieldset className="body current">
                      <h2>Dati Account</h2>

                      <div className="col-sm-12 col-md-6">
                        <FormInputText label="Username" value={this.state.username}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>this.setState({username: se.target.value})} />

                        <FormInputPassword label="Password" value={this.state.password}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>this.setState({password: se.target.value})} />

                        <FormInputPassword label="Conferma password" value={this.state.password_confirm}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=>this.setState({password_confirm: se.target.value})} />
                      </div>

                    </fieldset> }

                    {this.state.currentTab == 1 && <fieldset className="body current">
                      <h2>Profilo</h2>

                      <div className="row">

                        <div className="col-sm-12 col-md-4">
                          <FormInputText label="Nome" value={this.state.name}
                            direction={FormInputContainer.HORIZONTAL}
                            onChange={se=>this.setState({name: se.target.value})} />

                          <FormInputText label="Email" value={this.state.email}
                            direction={FormInputContainer.HORIZONTAL}
                            onChange={se=>this.setState({email: se.target.value})} />

                          <FormInputText label="Telefono" value={this.state.telephone}
                            direction={FormInputContainer.HORIZONTAL}
                            onChange={se=>this.setState({telephone: se.target.value})} />

                        </div>

                        <div className="col-sm-12 col-md-4">

                          <FormInputText label="Cognome" value={this.state.surname}
                            direction={FormInputContainer.HORIZONTAL}
                            onChange={se=>this.setState({surname: se.target.value})} />

                        </div>
                        
                      </div>


                    </fieldset> }

                    {this.state.currentTab == 2 && <fieldset className="body current">
                      <h2>Account</h2>
                      <div className="col-sm-12 col-md-4">
                        <RoleSelect value={this.state.RoleId} onChange={se => {this.setState({RoleId: se})}} />
                      </div>
                    </fieldset> }

                    {this.state.currentTab == 3 && <fieldset className="body current">
                      <h2>Fine</h2>
                    </fieldset> }

                  </div>

                  <div class="actions clearfix">
                    <ul role="menu" aria-label="Pagination">
                      <li class="disabled" aria-disabled="true">
                        <a href="#previous" onClick={this.prevTab}>Indietro</a></li>

                      {this.state.currentTab < 3 && <li aria-hidden="false" aria-disabled="false">
                        <a href="#next" onClick={this.nextTab}>Avanti</a></li>}

                      {this.state.currentTab == 3 && <li aria-hidden="true" >
                        <a href="#finish" onClick={this.onSave} role="menuitem">Fine</a></li> }
                    </ul>
                  </div>

                </form>

              </div>
            </div>
          </div>
      </div>
    </div>;
  }

}

function UsersLogs({}){

  const [ filters , setFilters ] = useState({
    dateStart: moment()
  });

  const row = log => {
    let logout = log.lastActivityAt;
    if( moment().diff( logout , 'minutes') < 10 )
      logout = '-'
    else
      logout = formatDateTime( log.lastActivityAt );

    return <tr>
      <td>{formatLeadName( log.User )}</td>
      <td>{log.SellingPoint? log.SellingPoint.name : '-'}</td>
      <td>{formatDateTime( log.createdAt )}</td>
      <td>{logout}</td>
    </tr>
  };

  const columns = {
    name:             'Nome' ,
    sellingPoint :    'Punto vendita',
    loginAt:          'Data accesso',
    logoutAt:         'Data logout',
  };

  return <>
    <Breadcumbs title="Storico accessi" items={{'Home': '/','Utenti' :'/users'}} />

    <div className="wrapper wrapper-content  animated fadeInRight">
      <BoxContainer>
        <Row>
          <div className="col-md-4">
            <UserSelect value={filters.users}
                        direction={FormInputContainer.HORIZONTAL}
                        label="Utenti"
                        isMulti
                        onChange={se=>setFilters({ ...filters, users: se })} />
          </div>
          <div className="col-md-4">
            <RoleSelect value={filters.roles}
                        direction={FormInputContainer.HORIZONTAL}
                        label="Ruoli"
                        isMulti
                        onChange={se=>setFilters({ ...filters, roles: se })} />
          </div>
          <div className="col-md-4">
            <FormInputDate value={filters.dateStart}
                           key="dateStart"
                           label="A partire da"
                           direction={FormInputContainer.HORIZONTAL}
                           onChange={se => setFilters({ ...filters, dateStart: se})}/>
          </div>
        </Row>
        <Row>
          <div className="col-md-12">
            <AbstractTableViewer model="Users"
                                 filters={filters}
                                 method="accessLogs"
                                 infoMethod="accessLogsInfo"
                                 row={row}
                                 columns={columns}/>
          </div>
        </Row>
      </BoxContainer>
    </div>
  </>;
}

class Users extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      userModal: false,
      filterText: '',
      filterRole: false
    };

  }


  render(){

    const columns = { 
      name: 'Nome' , 
      selles: 'Ruolo' ,
      createdAt: 'Data inserimento',
      actions: 'Azioni'
  };

    const row = user => {
        return <tr>
          <td>{user.name} {user.surname}</td>
          <td>{user.Role ? user.Role.name : '-'}</td>
          <td>{moment(user.createdAt).format('D MMMM YY HH:mm')}</td>
          <td>
            <div class="btn-group">
                <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({userModal: user.id});
                }}>Vedi</button>
            </div>
          </td>
        </tr>
      };

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Users.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({userModal: true});
          }}>Nuovo utente</button>}
      </div>

      {this.state.userModal && can( 'Users.save' ) &&  <NewUserModal onDone={res=>{
        this.setState({userModal: false});
      }} id={this.state.userModal === true ? false: this.state.userModal} />}

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <Row>
                <div className="col-md-4">
                  <FormInputText label="Cerca per nome" 
                    value={this.state.filterText}
                    onChange={se=>{this.setState({filterText: se.target.value})}}
                    direction={FormInputContainer.HORIZONTAL} />
                </div>
                <div className="col-md-4">
                  <RoleSelect
                    value={this.state.filterRole}
                    onChange={se=>{this.setState({filterRole: se})}} />
                </div>
              </Row>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="Users" 
                    filters={{ text: this.state.filterText, role: this.state.filterRole }}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}

class Agents extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      userModal: false
    };

  }


  render(){

    const columns = { 
      name: 'Nome' , 
      createdAt: 'Data inserimento',
      //marketings: 'Piani compensi',
      actions: 'Azioni'
  };

    const row = user => {
        return <tr>
          <td>{user.name} {user.surname}</td>
          <td>{moment(user.createdAt).format('D MMMM YY HH:mm')}</td>
          {/*<td>{user.MarketingPlans.map( p => { return p.name; } ).join( ', ' ) }</td>*/}
          <td>
            <div class="btn-group">
                <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({userModal: user.id});
                }}>Vedi</button>
            </div>
          </td>
        </tr>
      };

    return <React.Fragment> 

      <Breadcumbs title="Agenti" items={{'Home': '/'}} />

      <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Users.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({userModal: true});
          }}>Nuovo agente</button>}
      </div>

      {this.state.userModal && can( 'Users.agent_save' ) && <NewAgentModal onDone={res=>{
        this.setState({userModal: false});
      }} id={this.state.userModal === true ? false: this.state.userModal} />}

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="Users" 
                    filters={{ role: 'agent' }}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>

    </React.Fragment>;
  }
}

class UserSelect extends React.Component {
  render(){

    let filters = false;

    if( this.props.onlyNetwork )
      filters = { onlyNetwork: true };

    return <React.Fragment>
      <ApiMultiSelect 
        model="Users"
        cachedFetch={true}
        filters={filters}
        formatRow={row => {
            return `${row.name} ${row.surname} ${row.city?'['+row.city+']':''} ${row.note?row.note:''}`;
        }} 
        label="Utente" 
        direction={FormInputContainer.HORIZONTAL} 
        {...this.props} 
      />
    </React.Fragment>;
  }
}


class NewUserModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'Users', this );
      }

      onSubmit(se){
        se.preventDefault();
        
        let payload = this.state;

        if( payload.MarketingReportPlanFilters )
          payload.MarketingReportPlanFilters = payload.MarketingReportPlanFilters.map( f => {
            return { ...f , Children: [] };
          });

        if( !payload.Managers )
          payload.Managers = [];

        apiFormSave( 'Users', this , payload );
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          { !this.state.loading && <form onSubmit={this.onSubmit}>
    
            <FormInputText label="Nome" value={this.state.name}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="name"
                requiredField={true}
                onChange={se=>this.setState({name: se.target.value})} />

            <FormInputText label="Cognome" value={this.state.surname}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="surname"
                onChange={se=>this.setState({surname: se.target.value})} />

            <FormInputText label="Utente" value={this.state.username}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="username"
                requiredField={true}
                onChange={se=>this.setState({username: se.target.value})} />

            <FormInputText label="Password" value={this.state.password || ''}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="password"
                onChange={se=>this.setState({password: se.target.value})} />

            <FormInputText label="Città" value={this.state.city}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="city"
              onChange={se=>this.setState({city: se.target.value})} />

            <FormInputText label="Email" value={this.state.email}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="email"
                onChange={se=>this.setState({email: se.target.value})} />

            <FormInputText label="Telefono" value={this.state.telephone}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="telephone"
                onChange={se=>this.setState({telephone: se.target.value})} />
    
            <RoleSelect value={this.state.Role}
              validation={this.state.validation} name="RoleId"
              direction={FormInputContainer.HORIZONTAL}
              onChange={se=>this.setState({RoleId: se ? se.id : null,Role: se})} />

            { can('Users.tagsSave' ) && <TagsSelect
              direction={FormInputContainer.HORIZONTAL}
              onChange={tags=>{ this.setState({Tags: tags})}}
              value={this.state.Tags} canCreate={true}
              entityName={"Users"} entityId={this.state.id} /> }

            <FormInputTextArea label="Note" value={this.state.note}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="note"
                onChange={se=>this.setState({note: se.target.value})} />

            <FormInputRadio label="Visibile nei report" checked={this.state.reportVisibility}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se => {
                              this.setState({
                                reportVisibility: this.state.reportVisibility ? false : true
                              })
                            }}/>

            <FormDashline />

            {<MarketingReportPlanFiltersSelect isMulti
              value={this.state.MarketingReportPlanFilters}
              direction={FormInputContainer.HORIZONTAL}
              onChange={se=>this.setState({MarketingReportPlanFilters: se})}
              filters={{planType: 'perAgent'}} /> }

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL} 
              saving={this.state.apiFormSaving}
              onSave={this.onSubmit} 
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form> }
        </Modal>;
      }

}


class NewAgentModal extends React.Component {

  constructor(props){
      super(props);
  
      this.state = {
        active: 1,
        name: '',
        loading: true,
      };

      this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
      apiFormInit( 'Users', this );


      fetch( 'api' , 'Roles' , 'select' ).then( msg => {
        msg.body.map( role => {
          if( role.machineName == 'agent' && role.id > 0 )
            this.setState({RoleId: role.id, loading: false});
        })

      })
    }

    onSubmit(se){
      se.preventDefault();

      let payload = this.state;

      if( payload.MarketingReportPlanFilters )
        payload.MarketingReportPlanFilters = payload.MarketingReportPlanFilters.map( f => {
          return { ...f , Children: [] };
        });

      apiFormSave( 'Users', this , payload );
    }
  
    render(){
  
      return <Modal onClose={this.props.onDone}>
        { !this.state.loading && <form onSubmit={this.onSubmit}>
  
          <FormInputText label="Nome" value={this.state.name}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="name"
              onChange={se=>this.setState({name: se.target.value})} />

          <FormInputText label="Cognome" value={this.state.surname}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="surname"
              onChange={se=>this.setState({surname: se.target.value})} />

          <FormInputText label="Utente" value={this.state.username}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="username"
              onChange={se=>this.setState({username: se.target.value})} />

          <FormInputText label="Password" value={this.state.password || ''}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="password"
              onChange={se=>this.setState({password: se.target.value})} />

          <FormInputText label="Città" value={this.state.city}
            direction={FormInputContainer.HORIZONTAL}
            validation={this.state.validation} name="city"
            onChange={se=>this.setState({city: se.target.value})} />

            <FormInputText label="Email" value={this.state.email}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="email"
                onChange={se=>this.setState({email: se.target.value})} />

            <FormInputText label="Telefono" value={this.state.telephone}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="telephone"
                onChange={se=>this.setState({telephone: se.target.value})} />

            <FormInputTextArea label="Note" value={this.state.note}
                direction={FormInputContainer.HORIZONTAL}
                validation={this.state.validation} name="note"
                onChange={se=>this.setState({note: se.target.value})} />

          <FormDashline />

          {<MarketingReportPlanFiltersSelect isMulti
            value={this.state.MarketingReportPlanFilters}
            direction={FormInputContainer.HORIZONTAL}
            onChange={se=>this.setState({MarketingReportPlanFilters: se})}
            filters={{planType: 'perAgent'}} /> }

          <AbstractApiFormFooter {...this.state} />
  
          <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL} 
            onSave={this.onSubmit} 
            saving={this.state.apiFormSaving}
            onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

        </form> }
      </Modal>;
    }

}


class UsersMultiSelect extends React.Component {
  render(){
      return <ApiMultiSelect model="Users" formatRow={row => {
          return `${row.name} ${row.surname}`;
      }} label="Utenti" {...this.props} />;
  }
}

export { Users , NewUserModal, UsersNewWizard, UsersMultiSelect, UserSelect , Agents , UsersLogs };