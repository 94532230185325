import React from 'react';
import './App.css';
import moment from 'moment';
import { LocalCache } from "./AppCommon";


import { Link } from "react-router-dom";

import { Loader , FormDashline,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, fetch, BoxContainer } from './Common.js';

import  { Breadcumbs, can, AbstractApiFormFooter , apiFormInit , apiFormSave,
    BrandSelect, ApiMultiSelect, ContractTypesSelect, formatEur } from './AppCommon';
import {FormInputSelect} from "./Common";


class SettingsPages extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            gdrive: false
        };
    }

    reloadData(){
        fetch( 'api' , 'Settings' , 'select' ).then( msg => {
            this.setState(msg.body);
        });
    }

    componentDidMount(){
        this.reloadData();
    }

    render(){
        return <React.Fragment>
            <Breadcumbs title="Impostazioni sistema" items={{'Home': '/'}} /> 
            <div className="wrapper wrapper-content  animated fadeInRight">

                <div className="col-md-12">
                    <BoxContainer title="Google Drive">
                        {this.state.gdrive ? <React.Fragment>
                            <div className="row">
                                <div className="col-md-12">
                                    Stato accesso a Google Drive : {this.state.gdrive.status ?
                                        <span className="label label-success">Funzionante</span> :
                                        <span className="label label-danger">Errore</span> }
                                </div>
                                {this.state.gdrive.url && <React.Fragment>
                                    <div className="col-md-12">

                                        <a href={this.state.gdrive.url} target="_blank">Clicca qui per richiedere il codice</a>

                                        <FormInputText value={this.state.gdriveCode} 
                                            label="Inserisci il codice di autorizzazione"
                                            onChange={se=>{this.setState({gdriveCode: se.target.value})}} />

                                        <button className="btn btn-primary" onClick={se=>{
                                            this.setState({gdrive: false});
                                            fetch( 'api' , 'Settings' , 'save' , { gdrive: { code: this.state.gdriveCode } } ).then( () => {
                                                this.reloadData();
                                            })
                                        }}>Autorizza</button>

                                    </div>
                                </React.Fragment>}
                                <div className="col-md-12">
                                    <button className="btn btn-primary" onClick={se=>{
                                        this.setState({gdrive: false});
                                        fetch( 'api' , 'Settings' , 'save' , { gdrive: { new: true } } ).then( () => {
                                            this.reloadData();
                                        })
                                    }}>Richiedi nuovo accesso</button>
                                </div>
                            </div>
                        </React.Fragment> : <Loader />}
                    </BoxContainer>

                    <BoxContainer title={"Backup"}>
                        <a href={`${window.location.protocol}//${window.location.hostname}/api/Settings/backup_files?logintoken=${LocalCache.get('loginToken')}`} className={"btn btn-primary"}>Scarica backup files</a>
                        <a href={`${window.location.protocol}//${window.location.hostname}/api/Settings/backup_db?logintoken=${LocalCache.get('loginToken')}`} className={"btn btn-primary ml-2"}>Scarica backup database</a>
                    </BoxContainer>

                    <BoxContainer title={"Contratti"}>
                        <div className="row">
                            <div className="col-md-12">

                                <FormInputSelect values={{
                                        '1': 'Abilitato',
                                        '0': 'Disabilitato',
                                    }} value={this.state.ddtCheckEnable}
                                 direction={FormInputContainer.VERTICAL}
                                 label="Controlla ID contratto nei DDT"
                                 name="ddtCheckEnable"
                                 onChange={se=>{
                                     this.setState({ddtCheckEnable: se });
                                 }} />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12">
                                <a className={"btn btn-primary"} href="#" onClick={se=>{
                                    se.preventDefault();
                                    fetch( 'api' , 'Settings' , 'save' , { ddtCheckEnable: this.state.ddtCheckEnable } ).then( () => {
                                        this.reloadData();
                                    })
                                }}>Salva</a>
                            </div>
                        </div>


                    </BoxContainer>
                </div>
            </div>
        </React.Fragment>;
    }
}

export { SettingsPages };