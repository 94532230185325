import React from 'react';
import './App.css';
import moment from 'moment';

import {
    Loader, FormDashline, fetch,
    FormInputText, FormButtons, FormInputContainer,
    Modal, FormInputRadio, BoxContainer
} from './Common.js';

import {
    AbstractTableViewer, can, AbstractApiFormFooter,
    apiFormInit, apiFormSave, formatEur, BrandSelect,
    ApiMultiSelect, ContractTypesSelect, Breadcumbs
} from './AppCommon';

class ContractVoidCauses extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            leadVoidCauseModal: false,
        };

    }

    componentDidMount() {
    }

    render() {

        const columns = {
            name: 'Nome',
            isEnabled: 'Abilitato',
            actions: 'Azioni'
        };

        const row = leadVoidCause => {
            return <tr>
                <td><span style={leadVoidCause.isEnabled ? {} : { color: 'lightgrey' }}>{leadVoidCause.name}</span></td>
                <td><span className={"label " + (leadVoidCause.isEnabled ? "label-primary" : "label-danger")}>
                    {leadVoidCause.isEnabled ? 'Si' : 'No' }
                </span></td>
                <td>
                    <div class="btn-group">
                        {can('ContractVoidCauses.save') && <button class="btn-white btn btn-xs" onClick={se => {
                            se.preventDefault();
                            this.setState({leadVoidCauseModal: leadVoidCause.id});
                        }}>Vedi</button>}
                    </div>
                </td>
            </tr>
        };

        return [<Breadcumbs title="Sotto stati contratto" items={{'Home': '/'}}/>, <div className="wrapper wrapper-content  animated fadeInRight">

            <div className="row" style={{marginBottom: '15px'}}>
                {can('ContractVoidCauses.save') && <button className="btn btn-primary" onClick={se => {
                    se.preventDefault();
                    this.setState({leadVoidCauseModal: true});
                }}>Nuovo sotto stato</button>}
            </div>

            <div className="row">

                <div className="col-md-12 col-lg-12">
                    {this.state.loading ? <Loader/> : <div className="row">
                        <div className="col-sm-12">
                            <div className="ibox">
                                <div className="ibox-content">
                                    <AbstractTableViewer model="ContractVoidCauses"
                                                         filters={{}}
                                                         row={row}
                                                         columns={columns}/>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>

            </div>

            {this.state.leadVoidCauseModal && <NewContractVoidCauseModal onDone={res => {
                this.setState({leadVoidCauseModal: false});
            }} id={this.state.leadVoidCauseModal === true ? false : this.state.leadVoidCauseModal}/>}


        </div>];
    }
}


class ContractVoidStateViewer extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            states: []
        };


        this.loadData = this.loadData.bind(this);


    }

    componentDidMount() {

        this.setState({states: []});
        this.loadData();
    }


    loadData() {

        var { ids } = this.props;

        if (ids && ids.length > 0) {

            ids = ids.split("-");

            for (var i = 0; i < ids.length; i++) {

                if (  ids[i].length===0 || !Number.isInteger(parseInt(ids[i])) || parseInt(ids[i])<1 ) continue;


                fetch('api', 'ContractVoidCauses', 'get', ids[i]).then(data => {
                    //console.log('VOID STATES');

                    let newStates = this.state.states;
                    newStates.push(data.body.name);
                    this.setState({states: newStates, loading: false});
                    //console.log(data.body);
                    //this.setState({loading: false, causes: {...this.state.causes ,...data.body}});
                });

            }
        }


    }

    render() {

        if (this.state.loading)
            return <p>/</p>;

        var states = [];

        this.state.states.map((s) => {
            states.push(<div className={'mb-1'}><span className={'label label-info'}>{s}</span></div>);
        });


        return states;

    }

}


class NewContractVoidCauseModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            active: 1,
            name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        apiFormInit('ContractVoidCauses', this);
    }

    onSubmit(se) {
        se.preventDefault();
        apiFormSave('ContractVoidCauses', this);
    }

    render() {

        return <Modal onClose={this.props.onDone}>
            <form onSubmit={this.onSubmit}>

                <FormInputText label="Nome" value={this.state.name}
                               direction={FormInputContainer.HORIZONTAL}
                               validation={this.state.validation} name="name"
                               onChange={se => this.setState({name: se.target.value})}/>

                <FormInputRadio label="Abilitato"
                                checked={this.state.isEnabled}
                                direction={FormInputContainer.HORIZONTAL}
                                onClick={se=>{
                                    this.setState({isEnabled:
                                          this.state.isEnabled ? false : true })}} />

                <FormDashline/>

                <AbstractApiFormFooter {...this.state} />

                <FormButtons saveLabel="Salva sotto stato" direction={FormInputContainer.HORIZONTAL}
                             onSave={this.onSubmit}
                             saving={this.state.apiFormSaving}
                             onCancel={se => {
                                 se.preventDefault();
                                 if (this.props.onDone) this.props.onDone();
                             }}/>

            </form>
        </Modal>;
    }

}

class ContractVoidCauseSelect extends React.Component {
    render() {
        return <ApiMultiSelect label="Sotto stato contratto" {...this.props} model="ContractVoidCauses"
                               isMulti formatRow={row => {
            return row.name;
        }} filters={{
            isEnabled: 1
        }}/>;
    }
}

export {ContractVoidCauses, ContractVoidCauseSelect, NewContractVoidCauseModal, ContractVoidStateViewer};