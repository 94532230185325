
import React, {useEffect} from 'react';
import './App.css';
import moment from 'moment';

import { Loader , FormDashline, fetch, FormInputCurrency,
  FormInputText, FormButtons, FormInputContainer ,
  Modal, FormInputRadio, BoxContainer } from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect, formatMultilineText,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, LocalCache, ScrollPagination
} from './AppCommon';
import {FormInputTextArea} from "./Common";
import {RoleSelect} from "./AppCommon";
import {formatLeadName} from "./Leads";

function ModelVersionControlModal(props) {

  const filters = {EntityId: props.id, EntityName: props.model,};

  const [ resolvedValues, setResolvedValues ] = React.useState({} );
  const [ columnDependenciesLoading, setColumnDependenciesLoading ] = React.useState([] );

  const formatName = ( name ) => {
    try {
      return props.columns[name].label;
    } catch (e) { }
    return name;
  }

  const formatValue = ( name, value ) => {
    try {
      return props.columns[name].format( value );
    } catch (e) { }
    return value;
  }

  return <Modal onClose={props.onDone}>
    <ScrollPagination filters={filters} model="ModelVersionControl" limit="50" row={ n => {

      let valueFrom = 'Caricamento...';
      let valueTo = 'Caricamento...';

      if( resolvedValues[n.columnName+ JSON.stringify( n.columnValueFrom )] ) {
        valueFrom = resolvedValues[n.columnName+ JSON.stringify( n.columnValueFrom )];
      } else {
        let from = formatValue( n.columnName , n.columnValueFrom );
        if( from['then'] ){
          from['then']( d => {
            setResolvedValues( { ...resolvedValues, [n.columnName+ JSON.stringify( n.columnValueFrom )]: d } );
          } );
        } else {
          valueFrom = from;
        }
      }

      if( resolvedValues[n.columnName+ JSON.stringify( n.columnValueTo )] ) {
        valueTo = resolvedValues[n.columnName+ JSON.stringify( n.columnValueTo )];
      } else {
        let to = formatValue( n.columnName , n.columnValueTo );
        if( to['then'] ){
          to['then']( d => {
            setResolvedValues( { ...resolvedValues, [n.columnName+ JSON.stringify( n.columnValueTo )]: d } );
          } );
        } else {
          valueTo = to;
        }
      }

      return <div className={"row"}>
        <div className={"col-6"}>
          <h4>{formatName( n.columnName )}</h4>
        </div>
        <div className={"col-6 text-right"}>
          <p style={{margin: 0}}>{formatLeadName(n.User)}</p>
          <span style={{fontSize: '8pt', fontStyle: 'italic'}}>{moment(n.savedAt).format('DD/MM/YYYY HH:mm' )}</span>
        </div>
        <div className={"col-1"}><h4 style={{margin: 0}}>Da</h4></div>
        <div className={"col-11"}>{valueFrom}</div>
        <div className={"col-1"}><h4 style={{margin: 0}}>A</h4></div>
        <div className={"col-11"}>{valueTo}</div>
      </div>
    }} />
  </Modal>
}

function ModelVersionControlModal_formatBool( value ){
  return value > 0 ? 'Sì' : 'No';
}

function ModelVersionControlModal_formatEnum( value , options ){
  let found = Object.keys( options ).filter( k => {
    return k == value;
  } );
  if( found.length )
    return options[found[0]];
  return value;
}

function ModelVersionControlModal_formatEntity( value , modelName , propertyName = 'name' ){
  return new Promise( success => {
    fetch('api', modelName , 'get', value).then(msg => {
      success(msg.body[propertyName]);
    });
  });
}

function ModelVersionControlModal_formatDate( value ){
  return moment(value).format('DD/MM/YYYY HH:mm');
}

export { ModelVersionControlModal , ModelVersionControlModal_formatBool , ModelVersionControlModal_formatEntity,
  ModelVersionControlModal_formatEnum , ModelVersionControlModal_formatDate };