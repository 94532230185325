import React from 'react';
import './App.css';
import moment from 'moment';

import {
  fetch, FormDashline,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputDate,
  FormInputTime, FormInputRadio, BoxContainer, Loader
} from './Common.js';

import { UsersMultiSelect } from './Users';

import {apiFormInit, apiFormSave, Breadcumbs, can, formatDateTime, LocalCache} from './AppCommon';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import {RoleSelect} from "./Roles";
import {formatLeadName} from "./Leads";

function ActiveUsers( props ){

  const [ data, setData ] = React.useState( false );
  const [ filteredData, setFilteredData ] = React.useState( false );
  const [ openedSp, setOpenedSp ] = React.useState( [] );
  const [ filter, setFilter ] = React.useState( "" );

  let updateHandle = false;

  const updateData = () => {
    fetch( 'api' , 'Users' , 'activeUsers' ).then( msg => {
      setData( msg.body );
    });
  };

  React.useEffect( () => {
    const q = (filter || "").toLowerCase().trim();

    if( !filter.length ){
      setFilteredData( data );
      return;
    }

    if( !data || !data.users || !data.sellingPoints ) return;

    let u = data.users.filter( user => {
      let name = formatLeadName( user.User );
      return name.toLowerCase().indexOf( filter ) >= 0
        || (user.SellingPoint ? user.SellingPoint.name.toLowerCase().indexOf( filter ) >= 0 : false );
    });

    setFilteredData({
      users: u,
      sellingPoints: data.sellingPoints,
    });

  } , [ data , filter ] );

  React.useEffect( () => {

    updateData();

    updateHandle = setInterval( () => {
      updateData();
    } , 5000 );

    return () => {
      if( updateHandle )
        clearInterval( updateHandle );
    }
  } , [] );

  return <>
    <Breadcumbs title="Utenti attivi" items={{'Home': '/'}} />
    <div className="wrapper wrapper-content  animated fadeInRight">
      <div className={"row"}>

        {!data && <Loader />}

        {data && <>
          <div className={"col-md-8"}>
            <FormInputText label="Filtro" value={filter}
                           direction={FormInputContainer.HORIZONTAL}
                           placeholder="Filtra per nome o punto vendita"
                           onChange={se=>setFilter(se.target.value)} />
          </div>
          <div className={"col-md-8"}>
            <BoxContainer title={"Utenti attivi"}>
              <table className={"table"}>
                <thead>
                <tr>
                  <th>Nome</th>
                  <th>Punto vendita</th>
                  <th>Ultima attività</th>
                </tr>
                </thead>
                <tbody>
                {(filteredData && filteredData.users && filteredData.users.map) && filteredData.users.map( token => {
                  return <tr>
                    <td>{formatLeadName(token.User)}</td>
                    <td>{token.SellingPoint ? token.SellingPoint.name : '-'}</td>
                    <td>{formatDateTime( token.lastActivityAt) }</td>
                  </tr>
                })}
                </tbody>
              </table>
            </BoxContainer>
          </div>

          <div className={"col-md-4"}>
            <BoxContainer title={"Punti vendita attivi"}>
              <table className={"table"}>
                <thead>
                <tr>
                  <th>Nome</th>
                  <th>Utenti attivi</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {(filteredData && filteredData.sellingPoints && filteredData.sellingPoints.map) && filteredData.sellingPoints.map( sp => {
                  let isOpen = openedSp.indexOf( sp.id ) >= 0;

                  return <>
                    <tr>
                      <td>{sp.name}</td>
                      <td>{sp.count}</td>
                      <td>
                        <a href={"#"} className={"btn btn-xs btn-default"} onClick={ se =>{
                          se.preventDefault();
                          if( !isOpen ){
                            let osp = openedSp.concat( [] );
                            osp.push( sp.id );
                            setOpenedSp( osp );
                          } else {
                            let osp = openedSp.filter( o => o != sp.id );
                            setOpenedSp( osp );
                          }
                        }}>
                          {isOpen ?
                            <i className={"fa fa-minus"}></i> :
                            <i className={"fa fa-plus"}></i> }
                          </a>
                      </td>
                    </tr>
                    {isOpen && <>
                      {sp.users.map( user => {
                        return <tr style={{backgroundColor: '#efefef'}}>
                          <td>{formatLeadName(user)}</td>
                          <td></td>
                          <td></td>
                        </tr>
                      })}
                    </>}
                  </>;
                })}
                </tbody>
              </table>
            </BoxContainer>
          </div>
        </>}


      </div>

    </div>
  </>
}

export { ActiveUsers };