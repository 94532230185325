
import React, {useEffect, useState} from 'react';

import {fetch, Loader} from './Common.js';

export default function BackgroundTasksSummary( {} ){

  const longInterval = 1000 * 60 * 5;
  const shortInterval = 1000 * 3;

  const [ tasks , setTasks ] = useState( [] );
  const [ expanded , setExpanded ] = useState( false );
  const [ totalPercentage , setTotalPercentage ] = useState( 0 );
  const [ runningTasksCount , setRunningTasksCount ] = useState( 0 );
  const [ taskDetailView , setTaskDetailView ] = useState( null );
  const [ timeoutInterval , setTimeoutInterval ] = useState( 0 );
  const [ timeoutHandle , setTimeoutHandle ] = useState( null );

  const reloadData = async () => {
    return new Promise( success => {

      fetch( 'api' , 'BackgroundTasks' , 'select' ).then( ( response ) => {
        let tasks = response.body;

        setTasks( tasks );

        let percentage = 0;

        tasks.forEach( task => {
          percentage += task.progress || 0;
        });

        if( tasks.length > 0 )
          percentage = percentage / tasks.length;
        else
          percentage = 100;

        setTotalPercentage( percentage );

        setRunningTasksCount( tasks.filter( task => task.progress !== 100 ).length );

        success()
      })
    });

  }

  window.easylifeBgTasksUpdate = () => {
    window.easylifeBgTasksUpdateFlag = 1;
  }

  useEffect( () => {
    if( totalPercentage === 100 )
      setTimeoutInterval( longInterval );
    else
      setTimeoutInterval( shortInterval );
  } , [ totalPercentage ]);

  useEffect( () => {
    setTimeoutHandle( currentHandle => {
      if( currentHandle )
        clearTimeout( currentHandle );

      return setTimeout( () => {
        reloadData().then( () => {
          setTimeoutHandle( null );
        });
      } , timeoutInterval );

    } );
  } , [ timeoutInterval ] );

  useEffect( () => {
    if( !timeoutHandle ){
      setTimeoutHandle( setTimeout( () => {
        reloadData().then( () => {
          setTimeoutHandle( null );
        });
      } , timeoutInterval ) );
    }
  } , [ timeoutHandle, timeoutInterval ]);

  useEffect( () => {
    setTimeoutInterval( shortInterval );

    setInterval( () => {
      if( window.easylifeBgTasksUpdateFlag ){
        reloadData();
        window.easylifeBgTasksUpdateFlag = 0;
      }
    } , 1000 );
  } , [] );

  const onMainContainerClick = se => {
    if( !expanded ) setExpanded( true );
  }

  const onCloseClick = se => {
    se.preventDefault()
    setExpanded( false );
  }

  const onTaskDelete = se => {
    se.preventDefault();
    fetch( 'api' , 'BackgroundTasks' , 'save' , { id: taskDetailView.id, visible: 0 } ).then( () => {
      reloadData()
      setTaskDetailView( null );
    });
  }


  if( tasks.length === 0 )
    return null;

  return <div style={{
    padding: '10px',
    backgroundColor: '#2f4050',
    border: '1px solid #ddd',
    borderRadius: '5px',
    position: 'fixed',
    color: 'white',
    bottom: '10px',
    right: '10px',
    minWidth: expanded ? '400px' : '',
    maxWidth: '80vw',
    cursor: 'pointer',
    zIndex: 10000,
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden'
  }} onClick={onMainContainerClick}>
    {expanded === false && <>
      { (totalPercentage >= 0 && totalPercentage < 100 ) && <>
        <i className="fa fa-spinner fa-pulse" style={{fontSize:'10pt'}}></i>
        <span style={{marginLeft: '10px',marginRight: '10px'}}>
        {runningTasksCount} Task in esecuzione</span>
        <span>{Math.floor(totalPercentage)} %</span>
      </>}
      {totalPercentage === 100 && <>
        <i className="fa fa-check" style={{fontSize:'10pt'}}></i>
        <span style={{}}> {tasks.length} Tasks completati</span>
      </>}
    </>}

    {(expanded === true && taskDetailView == null ) && <>

      <div className={"row mb-2"}>
        <div className={"col-10"}>
          <span className={"font-bold"}>Operazioni in attesa</span>

        </div>
        <div className={"col-2 text-right"}>
          <a onClick={onCloseClick}>
            <i className="fa fa-times"></i>
          </a>
        </div>
      </div>

      {tasks.map( task => {
        const completed = task.progress === 100;

        return <div className={"row mb-4"} key={task.id} onClick={ se => { setTaskDetailView( task )}}>

          <div className={"col-6 font-bold"}>
            <span>{task.name}</span>
          </div>
          <div className={"col-6 text-right"}>
            {task.progress === 100 && <i className="fa fa-check"></i>}
            {task.progress !== 100 && <span>
              <i className="fa fa-spinner fa-pulse"></i>
            </span>}
          </div>
          <div className={"col-12"}>
            <div className="progress progress-mini">
              <div style={{width: task.progress + "%", backgroundColor: completed?'':'orange'}} className="progress-bar"></div>
            </div>
          </div>
        </div>;
      })}
    </>}

    {(expanded === true && taskDetailView != null ) && <>
      <div className={"row mb-2"}>
        <div className={"col-10"}>
          <span className={"font-bold"}>Dettaglio Task</span>
        </div>
        <div className={"col-2 text-right"}>
          <a onClick={ se => { setTaskDetailView( null )}}>
            <i className="fa fa-times"></i>
          </a>
        </div>

      </div>
      <div className={"row"}>
        <div className={"col-12"}>
          <span className={"font-bold"}>{taskDetailView.name}</span>
        </div>
        <div className={"col-12 mb-2"}>
          <div className="progress progress-mini">
            <div style={{width: taskDetailView.progress + "%", backgroundColor: taskDetailView.progress === 100?'':'orange'}} className="progress-bar"></div>
          </div>
        </div>

        <div className={"col-12 my-4"}>
          <h4>Risultato:</h4>
          <div style={{whiteSpace:'pre-wrap'}}>
            {taskDetailView.result}
          </div>
        </div>

        <div className={"col-12"}>
          <a onClick={onTaskDelete} className={"btn btn-secondary btn-block"}>Elimina</a>
        </div>
      </div>
    </>}

  </div>;
}