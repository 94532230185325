import React from 'react';
import './App.css';
import moment from 'moment';

import {
  Loader, FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, BoxContainer, FormInputTextArea, FormInputSelect, FormInputDate
} from './Common.js';

import {
  AbstractTableViewer, can, AbstractApiFormFooter,
  apiFormInit, apiFormSave, formatEur, BrandSelect,
  ApiMultiSelect, ContractTypesSelect, Breadcumbs, ChannelSelect, AgentSelect, FileUploadInput
} from './AppCommon';
import {formatLeadName} from "./Leads";
import {UserSelect} from "./Users";
import {NotePlainViewer, NotesEditor} from "./Notes";
import {ContractStateSelect} from "./ContractStates";
import {SellingPointSelect} from "./SellingPoints";
import {EvaluationFormStateSelect} from "./EvaluationFormStates";
import {showExportModal} from "./Exports";
const stateTag = state=>{

  if(!state) return '/';

  let color = state.color;

  if(!color)
    color = '#eee';

  return <span style={{backgroundColor: color,padding:'5px',borderRadius:'5px',color:'#fff'}}>{state.name}</span>
}

class EvaluationForms extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      sellingPointModal: false,
      reports: false,

      filterType: 0,
      filterArea: 'Tutte',
      filterVote: '',
      filterState: 0,
      filterDateStart: moment().startOf('year'),
      filterDateEnd: moment(),
      filterCheck: '-1',

      checkTableUpdate: 0,
    };

  }

  componentDidMount(){
    this.getFormStates();
    this.updateTree();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( prevState.filterType != this.state.filterType ) {
      this.updateTree();
      //this.getFormStates();
    }

  }

  updateTree(){

    if(this.state.filterType===0){
      return this.setState({tree: {'Tutte':[]}});
    }

    fetch( 'api' , 'EvaluationForms' , 'areas', this.state.filterType ).then( msg => {
      this.setState({ tree: {...{'Tutte': []},...msg.body} });
    });
  }

  getFilters() {

    return {
      type: this.state.filterType,
      area: this.state.filterArea,
      TargetUserId: this.state.filterTargetUser ? this.state.filterTargetUser.id : null,
      AuthorUserId: this.state.filterAuthorUser ? this.state.filterAuthorUser.id : null,
      EvaluationFormStateId: this.state.filterState ? this.state.filterState.id : 0,
      vote: this.state.filterVote,
      dateStart: moment(this.state.filterDateStart).set('hour', 0).format('YYYY-MM-DD HH:mm'),
      dateEnd: moment(this.state.filterDateEnd).set('hour', 23).format('YYYY-MM-DD HH:mm'),
      check: this.state.filterCheck
    };
  }


  getCheckFilters() {
    return {
      filterCheck: 1,
      checkTableUpdate : this.state.checkTableUpdate,
    };
  }

  getFormStates(){
    fetch( 'api' , 'EvaluationFormStates' , 'list' ).then( msg => {

      let states = {0:'Tutti'};

      for(const[key,value] of Object.entries(msg.body)){
        states[key] = value.name;
      }

      this.setState({ stateList: states});
    });
  }

  onCheck( id ){
    fetch( 'api' , 'EvaluationForms' , 'setCheck', id, 1 ).then( msg => {
      this.setState({ checkTableUpdate: this.state.checkTableUpdate+1})
    });

  }

  render(){

    const columns = {
      id: '#',
      name: 'Autore' ,
      menu: 'Creato il',
      author: 'Utente',
      type: 'Scheda',
      area: 'Macro area',
      note: 'Note',
      voto: 'Voto',
      state: 'Stato',
      actions: '',
    };


    const toType = function( type ){
      let types = NewEvaluationFormsModal.getTypes();
      return types[type];
    }

    const row = page => {
      return <tr>
        <td>{page.id}</td>
        <td>{formatLeadName( page.Author )}</td>
        <td>{moment(page.createdAt).format('D MMMM YY HH:mm')}</td>
        <td>{formatLeadName( page.Target )}</td>
        <td>{toType(page.type)}</td>
        <td>{page.area}</td>
        <td><NotePlainViewer notes={page.Notes} /></td>
        <td>{page.vote}</td>
        <td>{stateTag(page.EvaluationFormState)}</td>
        <td>
          <div class="btn-group">
            {can('EvaluationForms.save') && <button class="btn-white btn btn-xs mr-2" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: page.id});
            }}>Vedi</button> }
            {can('EvaluationForms.history') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({historyModal: page.id});
            }}>Storico</button> }
          </div>
        </td>
      </tr>
    };

    const columnsCheck = {
      id: '#',
      check: '',
      change: 'Modificato da',
      changeContext: 'Cambiamento',
      name: 'Autore' ,
      menu: 'Creato il',
      author: 'Utente',
      type: 'Scheda',
      area: 'Macro area',
      state: 'Stato',
      actions: '',
    };


    const rowCheck = page => {
      return <tr>
        <td>{page.id}</td>
        <td><button className={"btn btn-primary btn-sm"} onClick={se=>{
          se.preventDefault();
          this.onCheck( page.id );
        }}>Conferma</button></td>
        <td>{formatLeadName(page.LastUpdateUser)}</td>
        <td>{page.lastUpdateContext}</td>
        <td>{formatLeadName( page.Author )}</td>
        <td>{moment(page.createdAt).format('D MMMM YY HH:mm')}</td>
        <td>{formatLeadName( page.Target )}</td>
        <td>{toType(page.type)}</td>
        <td>{page.area}</td>
        <td>{stateTag(page.EvaluationFormState)}</td>
        <td>
          <div class="btn-group">
            {can('EvaluationForms.save') && <button class="btn-white btn btn-xs mr-2" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: page.id});
            }}>Vedi</button> }
            {can('EvaluationForms.history') && <button class="btn-white btn btn-xs" onClick={se=>{
              se.preventDefault();
              this.setState({historyModal: page.id});
            }}>Storico</button> }
          </div>
        </td>
      </tr>
    };

    return [ <Breadcumbs title="Schede valutative" items={{'Home': '/'}} />,
      <div className="wrapper wrapper-content  animated fadeInRight">

        <div className="row" style={{marginBottom: '15px'}}>
          <div className={'col-12'}>
            { can( 'EvaluationForms.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({sellingPointModal: true});
            }}>Nuova valutazione</button>}

            {can('EvaluationForms.export') && <button className="btn btn-primary" onClick={se => {
              se.preventDefault();
              showExportModal( 'EvaluationForms' , this.getFilters() );
            }} style={{marginLeft: '10px'}}>Esporta Excel</button>}

          </div>
        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className={"ibox-title"}><h5>Da controllare</h5></div>
                  <div className="ibox-content">
                    <AbstractTableViewer model="EvaluationForms"
                                         filters={this.getCheckFilters()}
                                         row={rowCheck}
                                         columns={columnsCheck}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <UserSelect value={this.state.filterTargetUser}
                                direction={FormInputContainer.HORIZONTAL}
                                label="Utente"
                                onChange={se=>this.setState({ filterTargetUser: se , filterTargetUserId: se ? se.id : null })} />
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <UserSelect value={this.state.filterAuthorUser}
                                direction={FormInputContainer.HORIZONTAL}
                                label="Autore"
                                onChange={se=>this.setState({ filterAuthorUser: se , filterAuthorUserId: se ? se.id : null })} />
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">

                  <FormInputSelect label="Tipo Scheda" value={this.state.filterType}
                                   values={{
                                     0: 'Tutte',
                                     formSep: 'Scheda SEP',
                                     formCorporate: 'Scheda Corporate',
                                     formBusiness: 'Scheda Consumer Business',
                                     formFormazione: 'Scheda Formazione',
                                     formBackoffice: 'Scheda Back Office',
                                     formEnelx: 'Scheda EnelX',
                                     formCallCenter: 'Scheda Call Center',
                                     formPratiche: 'Scheda Gestione pratiche',
                                     formAmministrazione: 'Scheda Amministrazione',
                                     formReclami: 'Scheda Reclami',
                                     formAssistenza: 'Scheda Assistenza'
                                   }}
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se=>this.setState({filterType: se})} />
                  </div>



                    {this.state.tree && <>

                    <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                      <FormInputSelect label="Macro area" value={this.state.filterArea}
                                       values={Object.fromEntries(Object.keys(this.state.tree).map( v => {
                                         return [ v,v ];
                                       }) )}
                                       direction={FormInputContainer.HORIZONTAL}
                                       validation={this.state.validation} name="area"
                                       onChange={se=>this.setState({filterArea: se})} />
                    </div>


                    </>}



                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <FormInputDate value={this.state.filterDateStart}
                                   key="sdate"
                                   label="Data inizio"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({filterDateStart: se})}/>
                  </div>

                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <FormInputDate value={this.state.filterDateEnd}
                                   key="edate"
                                   label="Data fine"
                                   direction={FormInputContainer.HORIZONTAL}
                                   onChange={se => this.setState({filterDateEnd: se})}/>
                  </div>


                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <FormInputSelect label="Voto" value={this.state.filterVote}
                                     direction={FormInputContainer.HORIZONTAL}
                                     name="filterVote"
                                     values={{
                                       '10' : 'Eccellente',
                                       '9' : 'Ottimo',
                                       '8' : 'Buono',
                                       '7' : 'Discreto',
                                       '6' : 'Sufficiente',
                                       '5' : 'Mediocre',
                                       '4' : 'Insufficiente',
                                       '3' : 'Scarso',
                                       '2' : 'Nullo',
                                       '': 'Tutti',
                                     }}
                                     onChange={se => {
                                       this.setState({filterVote: se})
                                     }}/>
                  </div>


                  <div className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <EvaluationFormStateSelect value={this.state.filterState}
                                               direction={FormInputContainer.HORIZONTAL}
                                               onChange={se=>this.setState({ filterState: se })} />
                  </div>




                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">

          <div className="col-md-12 col-lg-12">
            { this.state.loading ? <Loader /> : <div className="row">
              <div className="col-sm-12">
                <div className="ibox">
                  <div className={"ibox-title"}><h5>Tutte le schede</h5></div>
                  <div className="ibox-content">
                    <AbstractTableViewer model="EvaluationForms"
                                         filters={this.getFilters()}
                                         row={row}
                                         columns={columns}/>
                  </div>
                </div>
              </div>
            </div> }
          </div>
        </div>

        {this.state.sellingPointModal && <NewEvaluationFormsModal onDone={res=>{
          this.setState({sellingPointModal: false});
        }} id={this.state.sellingPointModal === true ? false: this.state.sellingPointModal} />}


        {this.state.historyModal && <HistoryEvaluationFormsModal onDone={res=>{
          this.setState({historyModal: false});
        }} id={this.state.historyModal === true ? false: this.state.historyModal} />}

      </div>];
  }
}



class NewEvaluationFormsModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      Notes: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  updateTree(){
    this.setState({tree: false });
    fetch( 'api' , 'EvaluationForms' , 'areas', this.state.type ).then( msg => {
      this.setState({ tree: msg.body  });
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if( prevState.type != this.state.type ) {
      this.setState({ area: null , subArea: null });
      this.updateTree();
    }
  }

  componentDidMount(){
    this.setState({tree: false});
    apiFormInit( 'EvaluationForms', this );
  }

  onSubmit(se){
    se.preventDefault();
    apiFormSave( 'EvaluationForms', this)
  }

  static getTypes(){
    let types = {};

    let permissions = {
      formSep: 'Scheda SEP',
      formCorporate: 'Scheda Corporate',
      formBusiness: 'Scheda Consumer Business',
      formFormazione: 'Scheda Formazione',
      formBackoffice: 'Scheda Back Office',
      formEnelx: 'Scheda EnelX',
      formCallCenter: 'Scheda Call Center',
      formPratiche: 'Scheda Gestione pratiche',
      formAmministrazione: 'Scheda Amministrazione',
      formReclami: 'Scheda Reclami',
      formAssistenza: 'Scheda Assistenza'
    };

    Object.keys( permissions ).filter( perm => {
      return can( 'EvaluationForms.' + perm );
    }).map( perm => {
      types[perm] = permissions[perm];
    });

    return types;
  }

  render(){

    if (this.state.loading)
      return <Modal>
        <Loader/>
      </Modal>;

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <h2>Scheda N. {this.state.id}</h2>

        <FormInputSelect label="Scheda" value={this.state.type}
                         values={NewEvaluationFormsModal.getTypes()}
                         direction={FormInputContainer.HORIZONTAL}
                         onChange={se=>this.setState({type: se})} />

        <UserSelect value={this.state.Target}
                    direction={FormInputContainer.HORIZONTAL}
                    label="Utente"
                    onChange={se=>this.setState({ Target: se , TargetUserId: se.id })} />

        {this.state.tree && <>
          <FormInputSelect label="Macro area" value={this.state.area}
                         values={Object.fromEntries(Object.keys(this.state.tree).map( v => {
                           return [ v,v ];
                         }) )}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="area"
                         onChange={se=>this.setState({area: se})} />

          { ( this.state.tree[this.state.area] && this.state.tree[this.state.area].length )  ?
          <FormInputSelect label="Area" value={this.state.subArea}
                         values={Object.fromEntries(this.state.tree[this.state.area].map( v => {
                           return [ v , v ];
                         }))}
                         direction={FormInputContainer.HORIZONTAL}
                         validation={this.state.validation} name="subArea"
                         onChange={se=>this.setState({subArea: se})} /> : null}

        </>}

        <FormInputSelect label="Voto" value={typeof this.state.vote !== "undefined" ? this.state.vote.toString() : undefined}
                       values={{
                         '10' : 'Eccellente',
                         '9' : 'Ottimo',
                         '8' : 'Buono',
                         '7' : 'Discreto',
                         '6' : 'Sufficiente',
                         '5' : 'Mediocre',
                         '4' : 'Insufficiente',
                         '3' : 'Scarso',
                         '2' : 'Nullo',
                       }}
                       direction={FormInputContainer.HORIZONTAL}
                       validation={this.state.validation} name="vote"
                       onChange={se=>this.setState({vote: se})} />

        <FormDashline />

        <EvaluationFormStateSelect value={this.state.EvaluationFormState}
                    direction={FormInputContainer.HORIZONTAL}
                    onChange={se=>this.setState({ EvaluationFormState: se, EvaluationFormStateId:  se ? se.id : null })} />

        <FormDashline />

        {can( 'EvaluationForms.involveUsers') &&
          <UserSelect value={this.state.InvolvedUsers}
                      direction={FormInputContainer.HORIZONTAL}
                      label="Utenti coinvolti" isMulti
                      onChange={se=>this.setState({ InvolvedUsers: se })} />}

        <NotesEditor notes={this.state.Notes} onChange={ notes => {
          this.setState({Notes: notes})
        }} name={"EvaluationForms"} />

        <FormDashline />

        <label>Allegati</label>
        <FileUploadInput onChange={filesId=>{this.setState({filesId: filesId})}}
                         filesId={this.state.filesId} />

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva scheda"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class HistoryEvaluationFormsModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {

    };

    //this.onSubmit = this.onSubmit.bind(this);
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    // if( prevState.type != this.state.type ) {
    //
    // }

  }

  componentDidMount(){
    apiFormInit( 'EvaluationForms', this );
  }


  render(){

    if (this.state.loading)
      return <Modal>
        <Loader/>
      </Modal>;

    return <Modal onClose={this.props.onDone}>



      {this.state.History && this.state.History.map(h=>{
        return <>
        <p>Stato cambiato in {stateTag(h.State)} da <b>{h.User.name} {h.User.surname}</b> il {moment(h.createdAt).format('YYYY-MM-DD HH:mm:ss')}</p><hr/>
      </>
      })}


        <AbstractApiFormFooter {...this.state} />


    </Modal>;
  }

}


class EvaluationFormsCheckCheckbox extends React.Component{
  constructor(props) {
    super();

    this.state = {
      id: props.form.id,
      check: props.form.check,
      loading: true,
    };


    this.setCheck = this.setCheck.bind(this);

  }

  componentDidMount(props) {
    //this.setState({})
    this.getFormChecks();
  }


  getFormChecks(){
    fetch( 'api' , 'EvaluationForms' , 'checks', this.state.id ).then( msg => {
      this.setState({ check: msg.body, loading: false  });
    });

  }


  setCheck(){
    fetch( 'api' , 'EvaluationForms' , 'setCheck', this.state.id, !this.state.check ? 1 : 0 ).then( msg => {
      this.setState({ check: !this.state.check  });
      if( this.props.onCheck )
        this.props.onCheck( this.state.id );
    });
  }


  render() {


    return <>
        {!this.state.loading && <FormInputRadio label={this.state.check ? "Revisionato" : "Da Revisionare"} checked={this.state.check} onClick={this.setCheck}
                        direction={FormInputContainer.HORIZONTAL}/>}
        </>;
  }


}

export { EvaluationForms , NewEvaluationFormsModal, HistoryEvaluationFormsModal, EvaluationFormsCheckCheckbox };