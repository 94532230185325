import React from 'react';
import './App.css';
import moment from 'moment';
import './Products.css';

import {
  Loader,
  FormDashline,
  fetch,
  FormInputText,
  FormButtons,
  FormInputContainer,
  Modal,
  queryToObject,
  FormInputColorSimple, FormInputRadio, FormInputSelect,
  ConfirmableButton,
} from './Common.js';

import {
  AbstractTableViewer,
  can,
  AbstractApiFormFooter,
  apiFormInit,
  apiFormSave,
  ApiMultiSelect,
} from './AppCommon';
import {EnelxSellsBusinessTypeSelect} from "./EnelxSells";
import Select from "react-select";

class EnelxSellStateModal extends React.Component {

  constructor(props){
    super(props);

    this.state = {
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount(){
    apiFormInit( 'EnelxSellsStates', this );
  }


  onSubmit(se){
    se.preventDefault();

    let payload = { ...this.state };

    apiFormSave( 'EnelxSellsStates', this , payload );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  render(){

    return <Modal onClose={this.props.onDone}>
      <form onSubmit={this.onSubmit}>

        <div className={"row"}>
          <div className={"col-12"}>

            <FormInputText label="Nome" value={this.state.name}
               direction={FormInputContainer.HORIZONTAL}
               validation={this.state.validation} name="name"
               onChange={se=>this.setState({name: se.target.value})} />

          </div>

          <div className="col-12">
            <EnelxSellsBusinessTypeSelect
              value={this.state.category}
              name="category"
              onChange={se => {
                this.setState({category: se})
              }} />
          </div>

          <div className="col-12">
            <FormInputSelect
              direction={FormInputContainer.HORIZONTAL}
              value={this.state.type}
              label="Tipologia"
              name="type"
              values={{
                'positive': 'Positivo',
                'neutral': 'Neutrale',
                'negative': 'Negativo',
              }}
              onChange={se => {
                this.setState({type: se})
              }} />
          </div>

          <div className={"col-12"}>

            <FormInputColorSimple label="Colore" value={this.state.color}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="color"
              onChange={se=>this.setState({color: se.target.value})} />

          </div>

          <div className={"col-12"}>
            <FormInputRadio label="Predefinito" checked={this.state.default}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>this.setState({default: this.state.default ? false : true})} />
          </div>

          <div className={"col-12"}>
            <EnelxSellsStatesSelect
              label={"Possibili stati di destinazione"}
              isMulti
              direction={FormInputContainer.HORIZONTAL}
              value={this.state.Targets}
              onChange={se=>this.setState({Targets: se})} />
          </div>

        </div>

        <FormDashline />

        <AbstractApiFormFooter {...this.state} />

        <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                     onSave={this.onSubmit}
                     saving={this.state.apiFormSaving}
                     onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

      </form>
    </Modal>;
  }

}

class EnelxSellsStateViewer extends React.Component {

  constructor(props) {
    super( props );
    this.state = {};
  }

  reloadState(){
    if( !this.props.entity && this.props.id && !this.state.entity ){
      fetch( 'api' , 'EnelxSellsStates' , 'get' , this.props.id ).then( msg => {
        this.setState({ entity: msg.body });
      })
    }
  }

  componentDidMount() {
    this.reloadState();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.reloadState();
  }

  render(){
    let entity = {
      color: 'black',
      name: '-'
    };

    if( this.props.entity && this.props.entity.id )
      entity = this.props.entity;

    if( this.state.entity )
      entity = this.state.entity;

    return <span className={"label"} style={{backgroundColor: entity.color, color: 'white'}}>{entity.name}</span>;
  }
}

class EnelxSellsStatesSelect extends React.Component {
  render(){
    return <React.Fragment>
      <ApiMultiSelect
        model="EnelxSellsStates"
        cachedFetch={true}
        filters={{
          EnelxSellsStateIdFrom: this.props.value ? this.props.value.id : -1
        }}
        formatRow={row => {
          return `${row.category} / ${row.name}`;
        }}
        label="Stato"
        direction={FormInputContainer.HORIZONTAL}
        {...this.props}
      />
    </React.Fragment>;
  }
}

class EnelxSellsStates extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      ...(queryToObject()),
      filterBusinessType: '0',
      update: 0,
    };

  }


  getFilters(){
    return {
      businessType: this.state.filterBusinessType
    };
  }

  onDelete( id ){
    fetch( 'api' , 'EnelxSellsStates' , 'delete' , id ).then( msg => {
      this.setState({update: this.state.update+1});
    });
  }
  render(){

    const columns = {
      name: { label: 'Nome' , sortBy: 'name' },
      default: 'Predefinito',
      targets: 'Destinazioni',
      createdAt: { label: 'Creato il' , sortBy: 'createdAt' } ,
      category: { label: 'Settore business' },
      actions: 'Azioni'
    }

    const row = (entity) => {

      return <React.Fragment><tr style={{}}>
        <td><EnelxSellsStateViewer entity={entity} /></td>
        <td>{entity.default ? 'Si' : 'No'}</td>
        <td>
          {entity.Targets.length == 0 && <span>-</span>}
          {(entity.Targets.length > 0 && entity.Targets.length < 5) && <>
            {entity.Targets.map( target => {
              return <EnelxSellsStateViewer entity={target} />
            })}
          </>}
          {entity.Targets.length >= 5 && <span><strong>{entity.Targets.length}</strong> Destinazioni</span>}
        </td>
        <td>{moment(entity.createdAt).format('DD/MM/YY HH:mm')}</td>
        <td>{entity.category}</td>
        <td>
          <div className="btn-group">
            {can('EnelxSellsStates.save') &&
              <button className="btn-white btn btn-xs" onClick={se=>{
                se.preventDefault();
                this.setState({ enelxSellsModal: entity.id });
              }}>Vedi</button>}
            {can('EnelxSellsStates.delete') &&
              <ConfirmableButton className="btn-white btn btn-xs" onClick={()=>{
                this.onDelete( entity.id );
              }}><i className="fa fa-trash"></i></ConfirmableButton>}
          </div>
        </td>
      </tr>
      </React.Fragment>
    };

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className="row">

                <div className={"col-4"}>
                  <EnelxSellsBusinessTypeSelect
                    all={true}
                    value={this.state.filterBusinessType}
                    onChange={se=>this.setState({filterBusinessType: se})} />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row" style={{marginBottom: '15px'}}>
        <div className="col-sm-12">
          { can( 'EnelxSellsStates.save' ) && <button className="btn btn-primary" onClick={se=>{
            se.preventDefault();
            this.setState({enelxSellsModal: true});
          }}>Nuovo Stato</button>}
        </div>
      </div>

      {this.state.enelxSellsModal && <EnelxSellStateModal onDone={res=>{
        this.setState({enelxSellsModal: false});
      }} id={this.state.enelxSellsModal === true ? false: this.state.enelxSellsModal} />}

      <div className="row">
        <div className="col-sm-12">
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
            <div className="ibox-content">
              <div className={"row"}>
                <div className={"col-12"}>
                  <AbstractTableViewer model="EnelxSellsStates"
                                       filters={this.getFilters()}
                                       row={row}
                                       update={this.state.update}
                                       columns={columns}/>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}


export { EnelxSellsStates, EnelxSellsStateViewer, EnelxSellsStatesSelect };