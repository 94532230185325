import React from 'react';
import './App.css';
import moment from 'moment';
import './Products.css';

import { Link } from "react-router-dom";

import {
  Loader, FormDashline, fetch,
  FormInputText, FormButtons, FormInputContainer,
  Modal, FormInputRadio, FormInputSelect, FormInputDate, FormInputTime, queryToObject
} from './Common.js';

import  { AbstractTableViewer, can, AbstractApiFormFooter , apiFormInit , apiFormSave,
    BrandSelect, ApiMultiSelect, ContractTypesSelect, formatEur, FormAutoInput } from './AppCommon';
import {TagsSelect} from "./Tags";
import {
  ModelVersionControlModal, ModelVersionControlModal_formatBool, ModelVersionControlModal_formatDate,
  ModelVersionControlModal_formatEntity,
  ModelVersionControlModal_formatEnum
} from "./ModelVersionControl";

const ProductsVersionControlColumnsSpecs = {
  name: { label: 'Nome' },
  businessType: { label: 'Settore di business' , format: value => {
    return ModelVersionControlModal_formatEnum( value , {
      residential: 'Residenziale',
      business: 'Business',
    });
  } },
  CategoryId: { label: 'Categoria' , format: value => {
    return ModelVersionControlModal_formatEntity( value , 'ProductCategories');
  }},
  active: { label: 'Attivo' , format: ModelVersionControlModal_formatBool },
  BrandId: { label: 'Brand' , format: value => {
      return ModelVersionControlModal_formatEntity( value , 'Brands');
  }},
  ProductId: { label: 'Prodotto Padre' , format: value => {
    return ModelVersionControlModal_formatEntity( value , 'Products');
  }},
  marketingValueEur: { label: 'Valore commerciale (EUR)' },
  marginType: { label: 'Marginalità' , format: value => {
      return ModelVersionControlModal_formatEnum( value , {
        'low': 'Bassa',
        'high': 'Alta',
        'none': 'Unica',
      })
  } },
  hasDates: { label: 'Date di validità' , format: ModelVersionControlModal_formatBool },
  availabilityStartDate: { label: 'Data inizio' , format: ModelVersionControlModal_formatDate },
  availabilityEndDate: { label: 'Data fine' , format: ModelVersionControlModal_formatDate },
  availabilityDaysAfterEndDate: { label: 'Giorni di validità oltre data' },
}

class Products extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      productModal: false,
      filterActive: "1",
    };

    let query = queryToObject();

    if( query.id )
      this.state.productModal = query.id;

  }


  render(){

    const columns = { 
      name: 'Nome' , 
      selles: 'Venduti' ,
      marketingValueEur: 'Valore',
      fromDate: 'Dal',
      toDate: 'Al',
      active: 'Attivo',
      actions: 'Azioni'
  };

    const row = (product,index,arg2,prefixName) => {
        const style= product.active ? {} : { color: '#c3c3c3'};
        
        return <React.Fragment><tr style={style}>
          <td>{prefixName}{product.name}</td>
          <td>{product.selles}</td>
          <td>{formatEur( product.marketingValueEur )}</td>
          <td>{product.availabilityStartDate ?
            moment(product.availabilityStartDate).format('D MMMM YY')
            :'-'}</td>
          <td>{ product.availabilityEndDate ?
            moment(product.availabilityEndDate).format('D MMMM YY')
            : '-'}</td>
          <td>{product.active ? 
              <i class="fa fa-check text-navy"></i> : 
              <i class="fa fa-times text-danger"></i>}</td>
          <td>
            <div class="btn-group">
                {can('Products.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({productModal: product.id});
                }}>Vedi</button> }
              {can('Products.versionControl') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({productModalVersionControl: product.id});
              }}><i className="fa fa-history"></i></button> }
            </div>
          </td>
        </tr>
        {product.Children ? product.Children.map( child => {
          return row(child , index, arg2, <span style={{marginLeft: '20px',marginRight: '10px'}}><i className="fa fa-arrow-up"></i></span> );
        }) : null}
        </React.Fragment>
      };

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'Products.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({productModal: true});
          }}>Nuovo prodotto</button>}

          { can( 'ProductCategories.save' ) && <Link style={{marginLeft: '10px'}} className="btn btn-primary" to="/products_categories_list">Gestisci categorie</Link>}
      </div>

      {this.state.productModalVersionControl && <ModelVersionControlModal onDone={res=>{
        this.setState({productModalVersionControl: false});
      }} id={this.state.productModalVersionControl} model={"Products"} columns={ProductsVersionControlColumnsSpecs} />}

      {this.state.productModal && <NewProductModal onDone={res=>{
        this.setState({productModal: false});
        //window.location.reload();
      }} id={this.state.productModal === true ? false: this.state.productModal} />}

      <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <div className="row">

                  <div className="col-lg-4">
                    <BrandSelect value={this.state.filterBrand}
                      direction={FormInputContainer.HORIZONTAL}
                      all={true}
                      onChange={se=>this.setState({filterBrand: se, filterBrandId: se ? se.id : null})} />
                  </div>

                  <div className="col-lg-4">
                    <ProductCategorySelect value={this.state.filterCategory}
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>{this.setState({filterCategory: se})}} />
                  </div>

                  <div className="col-lg-4">
                    <ContractTypesSelect value={this.state.filterContractType}
                      direction={FormInputContainer.HORIZONTAL}
                      onChange={se=>{this.setState({filterContractType: se})}} />
                  </div>

                  <div className="col-lg-4">
                    <FormInputSelect value={this.state.filterActive}
                       label={"Visualizzazione"}
                       values={{
                         1: 'Abilitati',
                         0: 'Tutti',
                       }}
                       direction={FormInputContainer.HORIZONTAL}
                       onChange={se=>{this.setState({filterActive: se})}} />
                  </div>

                  <div className={"col-lg-4"}>
                    <FormInputText value={this.state.filterText}
                      label={"Nome prodotto"}
                      onChange={se=>{this.setState({filterText: se.target.value})}}
                      direction={FormInputContainer.HORIZONTAL} />
                  </div>

                  <div className="col-lg-4">
                    <FormInputSelect value={this.state.filterBusinessType}
                                     label={"Settore di business"}
                                     values={{
                                       'all': 'Tutti',
                                       'business': 'Business',
                                       'residential': 'Residenziale',
                                     }}
                                     direction={FormInputContainer.HORIZONTAL}
                                     onChange={se=>{this.setState({filterBusinessType: se})}} />
                  </div>

                </div>
              </div>
          </div>
        </div>
      </div>

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="Products" 
                    filters={{ 
                      BrandId: this.state.filterBrandId, 
                      ContractType: this.state.filterContractType,
                      active: this.state.filterActive > 0 ? "1" : null,
                      text: this.state.filterText ,
                      businessType: this.state.filterBusinessType,
                      category: this.state.filterCategory }}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}

class NewProductCategoryModal extends React.Component {

  constructor(props){
      super(props);
  
      this.state = {
        name: ''
      };

      this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount(){
      apiFormInit( 'ProductCategories', this );
    }

    onSubmit(se){
      se.preventDefault();
      apiFormSave( 'ProductCategories', this);
    }
  
    render(){
  
      return <Modal onClose={this.props.onDone}>
        <form onSubmit={this.onSubmit}>
  
          <FormInputText label="Nome" value={this.state.name}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="name"
              onChange={se=>this.setState({name: se.target.value})} />
  
          <FormDashline />

          <AbstractApiFormFooter {...this.state} />
  
          <FormButtons saveLabel="Salva Categoria"  direction={FormInputContainer.HORIZONTAL} 
            onSave={this.onSubmit} 
            saving={this.state.apiFormSaving}
            onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

        </form>
      </Modal>;
    }

}

class ProductCategorySelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Categoria prodotto" {...this.props} model="ProductCategories" 
      formatRow={row => { return row.name; }} filters={{}}
       />;
  }
}

class ProductCategories extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      productCategoryModal: false
    };

  }


  render(){

    const columns = { 
      name: 'Nome' , 
      count: 'Prodotti associati' ,
      actions: 'Azioni'
    };

    const row = (productCategory) => {
        
        return <React.Fragment><tr>
          <td>{productCategory.name}</td>
          <td>{0}</td>
          <td>
            <div class="btn-group">
                {can('ProductCategories.save') && <button class="btn-white btn btn-xs" onClick={se=>{
                  se.preventDefault();
                  this.setState({productCategoryModal: productCategory.id});
                }}>Vedi</button> }
            </div>
          </td>
        </tr>
        </React.Fragment>
      };

    return <div className="wrapper wrapper-content  animated fadeInRight">

      <div className="row" style={{marginBottom: '15px'}}>
          { can( 'ProductCategories.save' ) && <button className="btn btn-primary" onClick={se=>{
              se.preventDefault();
              this.setState({productCategoryModal: true});
          }}>Nuovo categoria</button>}
      </div>

      {this.state.productCategoryModal && <NewProductCategoryModal onDone={res=>{
        this.setState({productCategoryModal: false});
        //window.location.reload();
      }} id={this.state.productCategoryModal === true ? false: this.state.productCategoryModal} />}

      { this.state.loading ? <Loader /> : <div className="row">
        <div className="col-sm-12">
          <div className="ibox">
              <div className="ibox-content">
                <AbstractTableViewer model="ProductCategories" 
                    filters={{}}
                    row={row}
                    columns={columns}/>
              </div>
          </div>
        </div>
      </div> }


    </div>;
  }
}



class NewProductModal extends React.Component {

    constructor(props){
        super(props);
    
        this.state = {
          active: 1,
          availabilityDaysAfterEndDate: 15,
          name: ''
        };

        this.onSubmit = this.onSubmit.bind(this);
      }

      componentDidMount(){
        apiFormInit( 'Products', this );
      }


      onSubmit(se){
        se.preventDefault();

        let payload = { ...this.state };

        if( payload.Parent )
          payload.Parent.Children = [];

        if( payload.ContractTypes ){
          payload.ContractTypes = payload.ContractTypes.map( 
            ty => { return { ...ty, Products: [] }});
        }

        apiFormSave( 'Products', this , payload );
      }
    
      render(){
    
        return <Modal onClose={this.props.onDone}>
          <form onSubmit={this.onSubmit}>
    
            <FormAutoInput model="Products" field="name" 
              state={this.state} 
              onChange={se=>this.setState({name: se.target.value})} />
    
            <ContractTypesSelect value={this.state.ContractTypes}
              label="Modulistica contratto abilitato"
              validation={this.state.validation}
              isMulti
              direction={FormInputContainer.HORIZONTAL}
              onChange={se=>this.setState({ContractTypes: se})} />

            <ProductCategorySelect value={this.state.ProductCategory}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="CategoryId"
              onChange={se=>{this.setState({ProductCategory: se, CategoryId: se ? se.id : null})}}/>

            <FormInputSelect values={{
              'residential': 'Residenziale',
              'business': 'Business',
            }} value={this.state.businessType}
             direction={FormInputContainer.HORIZONTAL}
             label="Settore di business"
             validation={this.state.validation} name="businessType"
             onChange={se=>{this.setState({businessType: se})}} />

            <FormAutoInput model="Products" field="active" 
              state={this.state} 
              onChange={se=>this.setState({active: se})} />

            <BrandSelect value={this.state.Brand}
              direction={FormInputContainer.HORIZONTAL}
              validation={this.state.validation} name="BrandId"
              onChange={se=>{this.setState({Brand: se, BrandId: se ? se.id : null})}} />

            <ProductSelect value={this.state.Parent}
              hideCategories={true}
              direction={FormInputContainer.HORIZONTAL}
              label="Prodotto Padre"
              isMulti={false}
              validation={this.state.validation} name="ProductId"
              onChange={se=>{this.setState({Parent: se, ProductId: se ? (se[0] ? se[0].id : null ) : null})}} />

            <FormInputText value={this.state.marketingValueEur}
              direction={FormInputContainer.HORIZONTAL}
              label="Valore commerciale (EUR)" 
              validation={this.state.validation} name="marketingValueEur"
              onChange={se=>{this.setState({marketingValueEur: se.target.value})}} />

            <FormInputSelect values={{
                'low': 'Bassa',
                'high': 'Alta',
                'none': 'Unica',
              }} value={this.state.marginType}
              direction={FormInputContainer.HORIZONTAL}
              label="Marginalità"
              validation={this.state.validation} name="marginType"
              onChange={se=>{this.setState({marginType: se})}} />

            { can('Products.tagsSave' ) && <TagsSelect
              direction={FormInputContainer.HORIZONTAL}
              onChange={tags=>{ this.setState({Tags: tags})}}
              value={this.state.Tags} canCreate={true}
              entityName={"Products"} entityId={this.state.id} /> }

            <FormDashline />

            <FormInputRadio label="Date di validità" checked={(this.state.hasDates||this.state.availabilityStartDate)}
                            direction={FormInputContainer.HORIZONTAL}
                            onClick={se=>{
                              if( this.state.hasDates ){
                                this.setState({
                                  availabilityStartDate: null,
                                  availabilityEndDate: null,
                                })
                              }
                              this.setState({hasDates:
                                this.state.hasDates ? false : true })
                            }} />

            {(this.state.hasDates||this.state.availabilityStartDate) && <>
              <FormInputDate label="Data inizio" value={this.state.availabilityStartDate}
                             direction={FormInputContainer.HORIZONTAL}
                             validation={this.state.validation} name="availabilityStartDate"
                             onChange={se=>this.setState({availabilityStartDate: se})} />

              <FormInputDate label="Data fine" value={this.state.availabilityEndDate}
                             validation={this.state.validation} name="availabilityEndDate"
                             direction={FormInputContainer.HORIZONTAL}
                             onChange={se=>this.setState({availabilityEndDate: se})} />

              <FormInputText value={this.state.availabilityDaysAfterEndDate}
                             direction={FormInputContainer.HORIZONTAL}
                             label="Giorni di validità oltre data"
                             validation={this.state.validation} name="availabilityDaysAfterEndDate"
                             onChange={se=>{this.setState({availabilityDaysAfterEndDate: se.target.value})}} />

            </>}

            <FormDashline />

            <AbstractApiFormFooter {...this.state} />
    
            <FormButtons saveLabel="Salva Prodotto"  direction={FormInputContainer.HORIZONTAL} 
              onSave={this.onSubmit} 
              saving={this.state.apiFormSaving}
              onCancel={se=>{se.preventDefault();if( this.props.onDone ) this.props.onDone(); }} />

          </form>
        </Modal>;
      }

}

class AdvancedProductSelectProductsList extends React.Component {
  render(){
    return this.props.products.map( p => {
        if( p.ProductId != this.props.ProductId )
          return null;

        return <React.Fragment>
          <li className="list-group-item" key={p.id}
            onClick={se=>{this.props.onSelected( p )}}>
              <small class="pull-right text-muted"> 
                {p.ProductCategory ? p.ProductCategory.name : '-'}</small>
              {p.Parent ? <span style={{marginLeft: '30px'}}> / </span> : ''}
              <span>{p.name}</span>
              {p.availabilityEndDate && <span style={{color: 'gray',marginLeft:'5px', fontSize: '8pt'}}>
                {moment(p.availabilityStartDate).format('DD/MM/YYYY')} -
                {moment(p.availabilityEndDate).format('DD/MM/YYYY')}
              </span>}
          </li>

          <AdvancedProductSelectProductsList 
            {...this.props} 
            ProductId={p.id} />
        </React.Fragment>;
      });
  }
}

class AdvancedProductSelect extends React.Component {
  constructor(props){
    super(props);

    this.state={
      loading: true,
      products: [],
      selectedProducts: [], // { id: 0 , qty: 1 }
    };

    this.onAdd = this.onAdd.bind(this);

    this.searchInputRef = React.createRef();
  }

  componentDidMount(){
    
    this.updateProducts();

    fetch( 'api' , 'ProductCategories' , 'select' ).then( msg => {
      this.setState({
        categories: msg.body
      });
    });

  }

  updateProducts(){
    fetch( 'api' , 'Products' , 'select' , { filters: { active: true } } ).then( msg => {
      this.mergeToProductsList( msg.body );

      fetch( 'api' , 'Products' , 'select' , { filters: { active: true, subproduct: this.props.values } } ).then( smsg => {
        this.mergeToProductsList( smsg.body );

      });

    });
  }

  onAdd( se ){
    se.preventDefault();
    this.setState({adding: true});
  }

  componentDidUpdate( prevProps, prevState ){

    if( JSON.stringify( prevProps.values ) !== JSON.stringify( this.props.values ) ){
      this.updateProducts();
    }

    if( this.state.loading ){
      if( this.state.products && this.state.categories && this.state.products.length ){
        this.updateProducts();
        this.setState( { loading: false } );
      }
    }

  }

  mergeToProductsList( products ){
    let current = this.state.products;

    products.map( p => {
      let found = false;

      current.map( cur => {
        if( cur.id == p.id ){
          found = true;
          return p;
        }

        return cur;
      });

      if( !found )
        current.push( p );
      
    });

    this.setState({products: current});
  }

  getThroughModelName(){
    return this.props.throughModelName || "ContractProducts";
  }

  addProduct( id ){

    let newValues = this.props.values;

    let found = false;

    newValues = newValues.map( v => {
      if( v.id == id ){
        found = true;
        if( v.canHaveQtyInContracts )
          return { ...v, [this.getThroughModelName()]: { ...v[this.getThroughModelName()], qty: v[this.getThroughModelName()].qty+1 } };
        return v;
      }
      
      return v;
    });

    if( !found ){
      newValues.push(  { 
          ...this.fetchFromProductsCache( id ),
        [this.getThroughModelName()]: { qty: 1 }
      });
    }

    this.props.onChange( newValues );

  }

  sumQty( se, productId , qty ){
    se.preventDefault();

    let newValues = this.props.values;

    newValues = newValues.map( v => {
      if( v.id == productId ){
        return { ...v,
          [this.getThroughModelName()]: {
            ...v[this.getThroughModelName()], qty: v[this.getThroughModelName()].qty+qty } };
      }
      return v;
    });

    this.props.onChange( newValues );
  }

  fetchFromProductsCache( id ){
    return this.state.products.filter( p => {
      return p.id == id;
    })[0];
  }

  onDelete( se, productId ){
    se.preventDefault();
    let newValues = this.props.values.filter( p => {
      if( p.id == productId || this.fetchFromProductsCache(p.id).ProductId == productId )
        return false;
      return true;
    });

    // Rimuovo tutti i prodotti figli del prodotto rimosso
    newValues = newValues.filter( p => {
      return p.ProductId != productId;
    });

    this.props.onChange( newValues );
    
    this.setState({products: []});
    this.updateProducts();
  }
  
  render(){

    if( this.state.loading )
      return <Loader />;

    const sprops = {
      label: 'Prodotti',
      ...this.props
    };


    let filteredProducts = this.state.products;
    if( this.state.filterName ){
      try {
        filteredProducts = filteredProducts.filter( p => {

          if( p.name.toLowerCase().indexOf( this.state.filterName.toLowerCase() ) != -1 )
            return true;

          return p.Children.filter( c => {
            return c.name.toLowerCase().indexOf( this.state.filterName.toLowerCase() ) != -1;
          }).length > 0;

        });
      } catch (e){}
    }

    if( this.state.filterCategory ){
      try {
        filteredProducts = filteredProducts.filter( p => {
          if( p.ProductCategory )
            return p.ProductCategory.name == this.state.filterCategory.name;
          return false;
        });
      } catch (e){}
    }

    return <div className="row AdvancedProductSelect">
      <div className="col-md-12">
        <h4>{sprops.label}</h4>
      </div>

      {!this.state.adding && <div className="col-md-12">
        <button className="btn btn-xs btn-primary" onClick={this.onAdd}>Aggiungi prodotto</button>
      </div>}

      {this.state.adding && <React.Fragment>

        <div className="col-md-12">
          <FormInputText label="Filtra per nome" ref={this.searchInputRef}
            value={this.state.filterName} onChange={se=>{
              this.setState({filterName: se.target.value})
            }} direction={FormInputContainer.HORIZONTAL} />
        </div>

        <div className="col-md-12">
          <ProductCategorySelect label="Filtra per categoria" 
            value={this.state.filterCategory} onChange={se=>{
              this.setState({filterCategory: se})
            }} direction={FormInputContainer.HORIZONTAL} />
        </div>

        <div className="col-md-12" style={{maxHeight: '30vh', overflowY:'scroll'}}>
        
          <div className="row">
            <div className="col-md-12">
              <ul className="list-group elements-list products">
                <AdvancedProductSelectProductsList 
                  products={filteredProducts}
                  onSelected={p=>{this.addProduct( p.id )}} />
              </ul>
            </div>
          </div>


        </div>
      </React.Fragment>}

      {this.state.adding && <div className="col-md-12">
        <button className="btn btn-xs btn-primary pull-right" onClick={se=>{
          se.preventDefault();
          this.setState({adding: false});
        }}>Chiudi</button>
      </div>}

      {this.props.values.length == 0 && <div className="col-md-12"> 
        <p className="noProduct">Nessun prodotto selezionato</p>
      </div>}

      <div className="col-md-12">
        <ul className="list-group elements-list selectedProducts">
          {this.props.values.map( product => {
            return <li className="list-group-item" >
              <small class="pull-right text-muted"> 
                {product.ProductCategory ? product.ProductCategory.name : '-'}</small>
              <strong>{product.name}</strong>
              {can('Products.save') && <Link to={"/products_list?id="+product.id} ><i className={"fa fa-eye ml-2"}></i></Link> }
            
              <div className="small m-t-xs">

                <span class="">
                  {product.canHaveQtyInContracts && <button className="btn btn-xs btn-primary" 
                    style={{width: '25px', marginRight: '5px'}}
                    onClick={se=>{this.sumQty(se, product.id, -1)}}>-</button>}
                  Qty: <strong>{product[this.getThroughModelName()].qty}</strong>
                  {product.canHaveQtyInContracts && <button className="btn btn-xs btn-primary" 
                    style={{width: '25px', marginLeft: '5px'}}
                    onClick={se=>{this.sumQty(se, product.id, 1)}}>+</button>}
                </span>

                <span class="pull-right">
                  <button className="btn btn-xs btn-danger" onClick={s=>{this.onDelete(s, product.id)}}>
                    <i className="fa fa-trash"></i></button>
                </span>

              </div>
            </li>;
          })}
        </ul>
      </div>

    </div>;
  }
}

class ProductSelect extends React.Component {
  constructor(props){
    super(props);

    this.state={
      filterCategory: false
    };

  }

  render(){

    const showCategories = this.props.hideCategories ? false : true ;

    return <React.Fragment>

      { showCategories && <ProductCategorySelect value={this.state.filterCategory}
        direction={FormInputContainer.HORIZONTAL}
        onChange={se=>{this.setState({filterCategory: se})}} /> }

      <ApiMultiSelect label="Prodotto" {...this.props} model="Products" 
        isMulti
        formatRow={row => {
          if( row.availabilityEndDate )
            return `${row.name} ${moment(row.availabilityStartDate).format('DD/MM/YYYY')} - ${moment(row.availabilityEndDate).format('DD/MM/YYYY')}`;
          return row.name;
        }} filters={{
          ChannelId: this.props.channelFilter, 
          category: this.state.filterCategory,
          active: true}}
      />

    </React.Fragment>;

    return ;
  }
}

class SubProductSelect extends React.Component {
  render(){
    return <ApiMultiSelect label="Prodotto" {...this.props} model="Products" 
      formatRow={row => { return `${row.name} ${moment(row.availabilityStartDate).format('DD/MM/YYYY')} - ${moment(row.availabilityEndDate).format('DD/MM/YYYY')}`;
      }} filters={{subproduct: this.props.Products, active: true}}
       />;
  }
}

export { AdvancedProductSelect, Products , ProductSelect, NewProductModal, SubProductSelect, ProductCategories };