import React from "react";
import moment from "moment";
import {
  BoxContainer,
  fetch,
  FormInputContainer,
  FormInputDate,
  FormInputSelect,
  Loader,
  Row
} from "./Common";

import {Breadcumbs, RoleSelect} from "./AppCommon";


class ReportAppointments extends React.Component {

  constructor(props){
    super(props);

    this.state = {
      filterDateStart: moment().subtract( 1 , 'month' ),
      filterDateEnd: moment(),
      infoOpen: false,
    };
  }

  componentDidMount(){
    this.updateData();
  }

  updateData(){
    this.setState({data: false});
    fetch( 'api' , 'ReportAppointments' , 'select' , this.state ).then( msg => {
      this.setState({data: msg.body});
    });
  }

  componentDidUpdate(prevProps,prevState){
    if( this.state.filterRole != prevState.filterRole ||
      this.state.filterDateStart != prevState.filterDateStart ||
      this.state.filterType != prevState.filterType ||
      this.state.filterDateEnd != prevState.filterDateEnd ){
      this.updateData();
    }
  }

  render(){

    let data = false;
    let states = [];

    if( this.state.data ){
      data = this.state.data.rows;
      states = this.state.data.states;
    }

    return <React.Fragment>
      <Breadcumbs title="Report Appuntamenti" items={{'Home': '/'}} />
      <div className="wrapper wrapper-content  animated fadeInRight">

        <BoxContainer>

          <Row>
            <div className={"col-md-12"}>
              <div className="alert alert-primary" role="alert">
                { this.state.infoOpen && <React.Fragment>
                  <p>Dati live</p>
                  <p>Il  seguente report mostra dei dati totali elaborati in base agli appuntamenti, per ogni utente del sistema.</p>
                  <p>Totale appuntamenti: rappresenta il numero totale di appuntamenti in caso di di filtro operatore, oppure il numero totale di appuntamenti assegnati in caso di filtro agente.</p>
                  <p>Totale appuntamenti chiusi: il numero totale di appuntamenti chiusi per ogni singolo Lead in cui l'agente o operatore è stato coinvolto.</p>
                  <p>Totale appuntamenti chiusi: il numero totale di contratti effettuati in cui l'agente o operatore è stato coinvolto.</p>
                  <p>Kw: somma totale dei Kw presente nei contratti.</p>
                  <p>Dopo le colonne indica sopra, è presente un conteggio degli appuntamenti suddiviso per ultimo stato degli stessi.</p>
                  <button className={"btn btn-default"} onClick={se=>{this.setState({infoOpen:false})}}>Chiudi info</button>
                </React.Fragment>}

                { !this.state.infoOpen && <React.Fragment>
                  <button className={"btn btn-default"} onClick={se=>{this.setState({infoOpen: true})}}>
                    Mostra info</button>
                </React.Fragment>}

              </div>
            </div>
          </Row>

          {true && <Row>
            <div className="col-md-2">
              <FormInputSelect value={this.state.filterType}
                 label="Tipo Report"
                 values={{'agent': 'Agenti' , 'operator': 'Operatore'}}
                 direction={FormInputContainer.HORIZONTAL}
                 onChange={se=>{this.setState({filterType: se})}} />
            </div>
            <div className="col-md-2">
              <FormInputDate value={this.state.filterDateStart}
                             direction={FormInputContainer.HORIZONTAL}
                             label="Filtro data / Da"
                             onChange={se=>{this.setState({filterDateStart: se})}} />
            </div>

            <div className="col-md-2">
              <FormInputDate value={this.state.filterDateEnd}
                             direction={FormInputContainer.HORIZONTAL}
                             label="Filtro data / A"
                             onChange={se=>{this.setState({filterDateEnd: se})}} />
            </div>

            <div className="col-md-4">
              <RoleSelect
                value={this.state.filterRole}
                isMulti
                onChange={se=>{this.setState({filterRole: se})}} />
            </div>

          </Row>}


          <div className="row">

            {!data && <Loader />}

            {data && <React.Fragment>

              <table className="table table-hover table-mail">
                <thead>
                  <tr>
                    <th>Nome operatore</th>
                    <th>Totale appuntamenti</th>
                    <th>Totale appuntamenti chiusi</th>
                    <th>Totale contratti</th>
                    <th>Kw</th>
                    {states.map( state => {
                      return <th>{state}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {data.map( row => {
                    return <tr>
                      <td>{row.name}</td>
                      <td>{row.appointmentsTotal}</td>
                      <td>{row.appointmentsClosedTotal}</td>
                      <td>{row.contractsTotal}</td>
                      <td>{row.kw}</td>
                      {states.map( state => {
                        return <td>{row[state] ? row[state] : 0 }</td>;
                      })}
                    </tr>;
                  })}
                </tbody>
              </table>

            </React.Fragment>}
          </div>
        </BoxContainer>
      </div>


    </React.Fragment>;
  }
}

export { ReportAppointments };
