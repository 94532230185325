import React from "react";
import moment from "moment";
import {
  BoxContainer,
  fetch, FormButtons, FormInputColorSimple, FormInputContainer,
  FormInputMonth, FormInputRadio, FormInputSelect,
  FormInputText,
  FormInputYear,
  Loader,
  MenuButton,
  Modal,
  Row
} from "./Common";
import {ChannelSelect} from "./AppCommon";
import {ProductSelect} from "./Products";
import {ContractStateSelect} from "./ContractStates";


function ContractReportMonthlyBoxContent( props ){

  const columns = props.columns || 1;

  const [ modalVisible , setModalVisible ] = React.useState( false );


  const onAdd = se => {
    se.preventDefault();
    setModalVisible( true );
  };

  const onModalClose = se => {
    setModalVisible( false );
  };

  const onModalSave = se => {
    let items = props.box.items;

    if( modalVisible === true )
      items.push( se );
    else
      items[modalVisible] = se;

    props.onChange( { ...props.box , items: items } );
  };

  let columnsContent = [];

  for (let i = 0,c = 0; i < props.box.items.length; i++, c++) {
    if( c >= columns ) c = 0;

    if( !columnsContent[c] ) columnsContent[c] = [];

    const item = props.box.items[i];

    console.log( [i,c,columnsContent]);
    columnsContent[c].push({item, itemIndex: i});

  }

  const colMd = Math.floor( 12 / columns );

  return <Row>

    { modalVisible !== false && <ContractReportMonthlyItemModal
      item={modalVisible !== true ? props.box.items[modalVisible] : modalVisible}
      onClose={onModalClose}
      setItem={onModalSave}
    /> }

    {columnsContent.map( column => {
      return <div className={"col-md-"+colMd}>
        <ul className={"list-group clear-list"}>
          {column.map( columnData => {
            const item = columnData.item;
            const itemIndex = columnData.itemIndex;
            return <li className="list-group-item fist-item">
              {item.totals && <>
                        <span className="label label-primary" style={{backgroundColor: item.color}}>
                            {item.totals.tot}</span>
                &nbsp;{item.title}
              </>}

              <MenuButton className="btn btn-xs pull-right" actions={{
                Modifica: () => { setModalVisible( itemIndex ); },
                'Sposta su': () => { setModalVisible( itemIndex ); },
                'Sposta giu': () => { setModalVisible( itemIndex ); },
                Elimina: () => { setModalVisible( itemIndex ); },
              }}>
                <i className="fa fa-gear"></i>
              </MenuButton>


              <button className="btn btn-xs pull-right" onClick={se=>{
                se.preventDefault();
                props.onEye( itemIndex );
              }} ><i className="fa fa-eye"></i></button>



            </li>
          })}
        </ul>
      </div>
    })}

    <div className={"col-md-12"}>
      <button className={"btn btn-primary btn-xs"} onClick={onAdd}>Aggiungi</button>
    </div>

  </Row>;
}

function ContractReportMonthlyItemModal( props ){

  const [ title , setTitle ] = React.useState( typeof( props.item ) == 'object' ? props.item.title : 'Senza titolo' );
  const [ channels , setChannels ] = React.useState( typeof( props.item ) == 'object' ? props.item.channels : [] );
  const [ contractStates , setContractStates ] = React.useState( typeof( props.item ) == 'object' ? props.item.contractStates : [] );
  const [ color , setColor ] = React.useState( typeof( props.item ) == 'object' ? props.item.color : [] );
  const [ products , setProducts ] = React.useState( typeof( props.item ) == 'object' ? props.item.products : [] );
  const [ showKw , setShowKw ] = React.useState( typeof( props.item ) == 'object' ? props.item.showKw : [] );
  const [ showM3 , setShowM3 ] = React.useState( typeof( props.item ) == 'object' ? props.item.showM3 : [] );
  const [ eleGas , setEleGas ] = React.useState( typeof( props.item ) == 'object' ? props.item.eleGas : [] );
  const [ noChannel , setNoChannel ] = React.useState( typeof( props.item ) == 'object' ? props.item.noChannel : [] );

  const onSubmit = se => {
    props.setItem( { title, channels, contractStates, color, products, showKw, showM3, eleGas, noChannel } );
  };

  const onClose = se => {
    if( props.onClose ) props.onClose( );
  };

  return <Modal >
    <h3>Item editor</h3>

    <FormInputText label="Titolo" value={title}
                   direction={FormInputContainer.HORIZONTAL}
                   name="title"
                   onChange={se=>setTitle( se.target.value )} />

    <FormInputColorSimple label="Colore" value={color}
                          direction={FormInputContainer.HORIZONTAL}
                          onChange={se=> setColor(se.target.value)} />

    <FormInputRadio label="Senza canale"
                    checked={noChannel}
                    direction={FormInputContainer.HORIZONTAL}
                    onClick={se=> setNoChannel( noChannel?false:true)} />

    {!noChannel && <ChannelSelect value={channels}
                                  label="Canale"
                                  direction={FormInputContainer.HORIZONTAL}
                                  isMulti
                                  onChange={se=>setChannels(se)} /> }

    <ProductSelect value={products}
                   direction={FormInputContainer.HORIZONTAL}
                   hideCategories={true} isMulti all={true}
                   onChange={se => setProducts( se )}/>

    <FormInputRadio label="Visualizza Kw"
                    checked={showKw}
                    direction={FormInputContainer.HORIZONTAL}
                    onClick={se=> setShowKw( showKw?false:true)} />

    <FormInputRadio label="Visualizza M3"
                    checked={showM3}
                    direction={FormInputContainer.HORIZONTAL}
                    onClick={se=> setShowM3( showM3?false:true)} />

    <FormInputSelect label="Oggetto" value={eleGas}
                     direction={FormInputContainer.HORIZONTAL}
                     values={{
                       '': 'Entrambi',
                       'ele': 'Elettrico',
                       'gas': 'Gas',
                     }} onChange={se => {
      setEleGas( se );
    }}/>

    <ContractStateSelect value={contractStates}
                         requiredField={true}
                         direction={FormInputContainer.HORIZONTAL}
                         isMulti
                         onChange={se => { setContractStates( se ) }}/>

    <FormButtons saveLabel="Salva"  direction={FormInputContainer.HORIZONTAL}
                 onSave={onSubmit}
                 onCancel={onClose} />
  </Modal>
}

function ContractReportMonthly2( props ){

  const [ filterMonth , setFilterMonth ] = React.useState( moment().month() );
  const [ filterYear , setFilterYear ] = React.useState( moment().year() );
  const [ loading , setLoading ] = React.useState( true );
  const [ data, setData ] = React.useState( false );

  const basePayload = { month: filterMonth , year: filterYear };

  React.useEffect( () => {
    fetch('api', 'ContractsExploded', 'reportMonthContract2', { ...basePayload } ).then( msg => {
      setLoading( false );
      setData( msg.body );
    });
  } , [] );

  const onBoxChange = (key,box) => {
    let boxes = data;

    setLoading( true );

    boxes[key] = box;

    fetch('api', 'ContractsExploded', 'reportMonthContract2', { boxes, ...basePayload } ).then( msg => {
      setLoading( false );
      setData( msg.body );
    });

  };

  const onEye = ( boxKey, itemIndex ) => {

    const payload = {
      ...basePayload,
      eye: {
        boxKey,
        itemIndex
      }
    };

    setLoading( true );

    fetch('api', 'ContractsExploded', 'reportMonthContract2', payload ).then( msg => {
    });
  };

  if( loading || !data )
    return <Loader />;

  return <>
    <Row>
      <div className={"col-md-12"}>
        <BoxContainer title={"Filtri"}>
          <Row>
            <div className={"col-md-4"}>
              <FormInputMonth value={filterMonth}
                              onChange={se=>{setFilterMonth( se )}} />
            </div>
            <div className={"col-md-4"}>
              <FormInputYear value={filterYear}
                             onChange={se=>{setFilterYear( se )}} />
            </div>
          </Row>
        </BoxContainer>
      </div>
      <div className={"col-md-12"}>
        <BoxContainer title={"Senza canale"}>
          <ContractReportMonthlyBoxContent box={data['no_channel']} columns={2}
                                           onChange={ box => { onBoxChange( 'no_channel' , box )}}
                                           onEye={ itemIndex => { onEye( 'no_channel' , itemIndex )} }/>
        </BoxContainer>
      </div>
    </Row>
    { /* <_ContractReportMonthly /> */ }
  </>;
}

export default ContractReportMonthly2;