import React, { Component } from 'react';
import accounting from 'accounting';
import { findDOMNode } from 'react-dom';
import { isNumber } from 'util';
import moment from 'moment';
import superagent from 'superagent';

import './SocketIoDebugger.css';

import CurrencyInput from 'react-currency-input';

import { Link } from 'react-router-dom';

moment.defineLocale('it', {
    months : 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split('_'),
    monthsShort : 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
    weekdays : 'domenica_lunedì_martedì_mercoledì_giovedì_venerdì_sabato'.split('_'),
    weekdaysShort : 'dom_lun_mar_mer_gio_ven_sab'.split('_'),
    weekdaysMin : 'do_lu_ma_me_gi_ve_sa'.split('_'),
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay: '[Oggi alle] LT',
        nextDay: '[Domani alle] LT',
        nextWeek: 'dddd [alle] LT',
        lastDay: '[Ieri alle] LT',
        lastWeek: function () {
            switch (this.day()) {
                case 0:
                    return '[la scorsa] dddd [alle] LT';
                default:
                    return '[lo scorso] dddd [alle] LT';
            }
        },
        sameElse: 'L'
    },
    relativeTime : {
        future : function (s) {
            return ((/^[0-9].+$/).test(s) ? 'tra' : 'in') + ' ' + s;
        },
        past : '%s fa',
        s : 'alcuni secondi',
        ss : '%d secondi',
        m : 'un minuto',
        mm : '%d minuti',
        h : 'un\'ora',
        hh : '%d ore',
        d : 'un giorno',
        dd : '%d giorni',
        M : 'un mese',
        MM : '%d mesi',
        y : 'un anno',
        yy : '%d anni'
    },
    dayOfMonthOrdinalParse : /\d{1,2}º/,
    ordinal: '%dº',
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // The week that contains Jan 4th is the first week of the year.
    }
}); 

function queryToObject(){
    let found = window.location.search.substr(1).split( '&' )
    return Object.fromEntries( found.map( item => {
        return decodeURI( item ).split( '=' )
    }) );
}

function t(arg){
    return arg;
}

class Globals {

    
    static addEvent( object , eventName ){

        if( typeof( object ) !== 'object' && typeof( object ) !== 'function' )
            return false;

        const onEventName = 'on' + eventName;
        const listenersEventName = '__' + eventName + '_listeners';

        if( typeof( object[onEventName] ) !== 'undefined' || typeof( object[listenersEventName] ) !== 'undefined' )
            return false;

        object[listenersEventName] = [];

        object[onEventName] = function( cb ){
            if( typeof( cb ) !== 'function' )
                return () => {};

            object[listenersEventName].push( cb );

            const currentLength = object[listenersEventName].length;

            return () => {
                console.log( `Removing event listner ${currentLength-1}` );
                object[listenersEventName].splice( currentLength-1 , 1 );
            };
        }

        if( typeof( object['trigger'+eventName] ) === 'undefined' ){
            object['trigger'+eventName] = function( ){
                for(let i=0; i < object[listenersEventName].length;i++)
                    object[listenersEventName][i].apply( object , arguments );
            }
        }

    }

}

const LocalCache = {

    name: function( name ){
        return '_00001_' + name;
    },

    set: function( name , value, timeoutInSeconds = false ){
        localStorage.setItem( LocalCache.name( name ) , JSON.stringify( value ) );
        if( timeoutInSeconds )
            localStorage.setItem( 'timeout_' + LocalCache.name( name ) , (new Date()).getTime() + (timeoutInSeconds*1000) );
    },

    get: function( name ){
        let savedValue = localStorage.getItem( LocalCache.name( name ) );
        if( savedValue ){
            try {
                savedValue = JSON.parse( savedValue );
            } catch( e ){ savedValue = false }
        }

        const timeout = localStorage.getItem( 'timeout_' + LocalCache.name( name ) );
        if( timeout ){
            if( (new Date()).getTime() - timeout > 0 )
                savedValue = false;
        }

        return savedValue;
    },

    clear: function( name ){
        localStorage.removeItem( LocalCache.name( name ) );
        localStorage.removeItem( 'timeout_' + LocalCache.name( name ) );
    },

    groupSet: function( groupName , value ){
        return LocalCache.set( 'cacheGroups_' + groupName , value );
    },

    groupGet: function( groupName ){
        let g = LocalCache.get( 'cacheGroups_' + groupName );
        if( typeof( g ) !== 'object' || g === null )
            return [];
        return g;
    },

    groupAppendName: function( groupName , name ){
        if( typeof( name ) !== 'string' || typeof( groupName ) !== 'string' ) 
            return;

        let g = LocalCache.groupGet( groupName );
        if( g.indexOf( name ) === -1 )
            g.push( name );

        this.groupSet( groupName , g );
    },

    groupClear: function( groupName ){
        let g = LocalCache.groupGet( groupName );
        g.map( item => {
            LocalCache.clear( item );
        });

        LocalCache.groupSet( groupName , [] );
    }
}


const Utils = {

    getUrlParam: function( index , defaultValue = undefined ){ // positive or negative
        const parts = window.location.pathname.split( '/' );

        if( index < 0 ){
            index = parts.length + index;
            if( index < 0 )
                return defaultValue;
        }

        if( index >= parts.length )
            return defaultValue;

        return decodeURIComponent( parts[index] );
    },

    truncate: function( text , max = 25 , suffix = '...' , exact = false ){
        if( !text ) return '';
        if( !text.length ) return '';

        if( text.length <= max ) return text;

        if( !exact ) 
            return text.substr( 0 , max ) + '...';

        return text;
    },

    formatCurrency: function( value , currency = 'EUR' ){

        if( currency === 'EUR' )
            return accounting.formatMoney( value , '€ ' , 2 , '.' , ',' );

        return value;
    },

    formatPercent: function( value ){
        if( value <= 0 )
            return '-';
        return (100*(1-value)).toFixed(0) + '%';
    }
}

class Loader extends Component {

    render(){
        return <div className="middle-box text-center animated Loader ">
            <i className="fa fa-spinner fa-pulse" style={{fontSize:'40pt'}}></i>
        </div>;
    }
}

class BoxContainer extends Component {
    render(){

        let title = null;

        const tools = this.props.tools ? this.props.tools : false;

        if( this.props.title ){
            title = <div className="ibox-title" style={{...this.props.style}}>
                <h5>{this.props.title} <small></small></h5>

                {tools ? <div className="ibox-tools">
                    {tools}
                </div> : undefined }

                {/* <div className="ibox-tools">
                    <a className="collapse-link">
                        <i className="fa fa-chevron-up"></i>
                    </a>
                    <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i className="fa fa-wrench"></i>
                    </a>
                    <ul className="dropdown-menu dropdown-user">
                        <li><a href="#">Config option 1</a>
                        </li>
                        <li><a href="#">Config option 2</a>
                        </li>
                    </ul>
                    <a className="close-link">
                        <i className="fa fa-times"></i>
                    </a>
                </div> */}

            </div>;
        }

        return <div className="ibox float-e-margins" {...this.props}>
            {title}
            <div className="ibox-content">
                {this.props.children}
            </div>

        </div>;
    }
}


class FormInputRangeSlider extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            startValue: 0,
            endValue: 1,
            svgWidthInPixels: 200,
            currentAnchorGrabbing: 0
        };

        this.onMouseDown = this.onMouseDown.bind(this);
        this.onMouseUp = this.onMouseUp.bind(this);
        this.onMouseMove = this.onMouseMove.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);

        this.containerRef = React.createRef();
    }

    onMouseLeave(sEvent) {
        if (this.state.currentAnchorGrabbing > 0)
            this.setState({
                currentAnchorGrabbing: 0
            });
    }

    onMouseDown(sEvent) {
        if (this.getBoundingClientRect()) {
            const x = sEvent.clientX - this.getBoundingClientRect().x;

            const renderInfo = this.getRenderInfo();

            if (
                x > renderInfo.startAnchorInPixel &&
                x < renderInfo.startAnchorInPixel + renderInfo.anchorPixelSize
            ) {
                this.setState({
                    currentAnchorGrabbing: 1
                });
            }

            if (
                x > renderInfo.endAnchorInPixel &&
                x < renderInfo.endAnchorInPixel + renderInfo.anchorPixelSize
            ) {
                this.setState({
                    currentAnchorGrabbing: 2
                });
            }
        }
    }

    onMouseMove(sEvent) {
        if (this.state.currentAnchorGrabbing > 0) {
            if (this.getBoundingClientRect()) {
                const x = sEvent.clientX - this.getBoundingClientRect().x;

                let newValue = x / this.state.svgWidthInPixels;

                if (this.state.currentAnchorGrabbing === 1) {
                    if (newValue > this.state.endValue) newValue = this.state.endValue;

                    this.setState({
                        startValue: newValue
                    });
                }

                if (this.state.currentAnchorGrabbing === 2) {
                    if (newValue < this.state.startValue)
                        newValue = this.state.startValue;

                    this.setState({
                        endValue: newValue
                    });
                }
            }
        }
    }

    onMouseUp() {
        if (this.state.currentAnchorGrabbing > 0)
            this.setState({
                currentAnchorGrabbing: 0
            });
    }

    getBoundingClientRect() {
        if (this.containerRef.current) {
            return this.containerRef.current.getBoundingClientRect();
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        let callOnChange = false;

        if (
            prevState.startValue !== this.state.startValue ||
            prevState.endValue !== this.state.endValue
        ) {
            callOnChange = true;
        }

        if (callOnChange && this.props.onChange) {
            let update = {
                min: this.state.startValue,
                max: this.state.endValue
            };

            if (typeof this.props.min === "number" && typeof this.props.max === "number") {
                update.min = (update.min * (this.props.max - this.props.min)) + this.props.min;
                update.max = (update.max * (this.props.max - this.props.min)) + this.props.min;
            }

            this.props.onChange(update);
        }
    }

    componentDidMount() {
        if (this.getBoundingClientRect()) {
            this.setState({ svgWidthInPixels: this.getBoundingClientRect().width });
        }
    }

    getRenderInfo() {
        let info = {
            svgWidthInPixels: this.state.svgWidthInPixels,
            anchorPixelSize: 16,
            anchorColor: "#1ab394",
            backgroundSelectedColor: "#4BE1C3",
            backgroundColor: "#DFDFDF"
        };

        info.startAnchorInPixel =
            this.state.startValue * (info.svgWidthInPixels - info.anchorPixelSize);

        info.endAnchorInPixel =
            this.state.endValue * (info.svgWidthInPixels - info.anchorPixelSize);

        if (
            info.startAnchorInPixel >
            info.endAnchorInPixel - info.anchorPixelSize * 1
        )
            info.startAnchorInPixel =
                info.endAnchorInPixel - info.anchorPixelSize * 1;

        return info;
    }

    render() {
        const renderInfo = this.getRenderInfo();

        const anchorStyle = {
            width: renderInfo.anchorPixelSize,
            height: renderInfo.anchorPixelSize,
            fill: renderInfo.anchorColor
        };

        return (
            <svg
                width="100%"
                height="30px"
                ref={this.containerRef}
                onMouseMove={this.onMouseMove}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onMouseUp}
                onMouseLeave={this.onMouseLeave}
            >
                <rect
                    y="5"
                    rx="3"
                    ry="3"
                    height="10"
                    width={renderInfo.svgWidthInPixels}
                    style={{ fill: renderInfo.backgroundColor }}
                />

                <rect
                    x={renderInfo.startAnchorInPixel}
                    y="5"
                    height="10"
                    width={renderInfo.endAnchorInPixel - renderInfo.startAnchorInPixel}
                    style={{ fill: renderInfo.backgroundSelectedColor }}
                />

                <rect
                    x={renderInfo.startAnchorInPixel}
                    y="2"
                    rx="3"
                    ry="3"
                    style={anchorStyle}
                />
                <rect
                    x={renderInfo.endAnchorInPixel}
                    y="2"
                    rx="3"
                    ry="3"
                    style={anchorStyle}
                />
            </svg>
        );
    }
}

class FormInputRange extends Component {

    constructor(props){
        super(props);

        this.state = {
            from: '',
            to: '',
        };

        this.onFromChange = this.onFromChange.bind(this);
        this.onToChange = this.onToChange.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.value && this.props.value.from != this.state.from ){
            this.setState({ from: this.props.value.from });
        }

        if( this.props.value && this.props.value.to != this.state.to ){
            this.setState({ to: this.props.value.to });
        }
    }

    onFromChange( se ){
        if( this.props.onChange ){
            this.props.onChange({ from: se.target.value, to: this.state.to });
        }
    }

    onToChange( se ){
        if( this.props.onChange ){
            this.props.onChange({ to: se.target.value, from: this.state.from });
        }
    }

    render(){
        return <div className={"row"}>
            <div className={"col-6"}>
                <FormInputText {...this.props}
                   onChange={this.onFromChange}
                   value={this.state.from} label={this.props.label +' (Da)'} />
            </div>
            <div className={"col-6"}>
                <FormInputText {...this.props}
                    onChange={this.onToChange}
                    value={this.state.to} label={"A"} />
            </div>
        </div>;
    }
}

class FormInputImage extends Component {
    constructor(props){
        super(props);

        this.state = {
            state: 'idle'
        };

        this.fileInput = React.createRef();

        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    onChange(se){
        console.log( this.fileInput.current.files[0] );

        this.setState({ state: 'loading' });

        /*pweb().fetch( 'user' , 'uploadLogo' , { logo: this.fileInput.current.files[0] } , ( data) => {
            this.setState({state: 'idle'});
            PriWebApp.store.dispatch( PriWebApp.setUserData( data.data.userData ) );
        });*/
    }

    onClick(se){

        if( se.target === this.fileInput.current )
            return;

        se.preventDefault();
        if( this.state.state === 'idle' )
            this.fileInput.current.click();
    }

    render(){
        const props = {
            src: false,
            onUploadComplete: () => {},
            ...this.props
        };

        return <div className="FormInputImage" onClick={this.onClick}>

            {this.state.state==='loading' ? (
                <div className="FormInputImagePlaceholder" >
                    <i className="fa fa-sync-alt fa-spin"></i>
                </div>
            ):(
                <div>
                    {props.src ? (<img alt="Logo" src={props.src} />) : (
                        <div className="FormInputImagePlaceholder" >
                            {this.state.state==='idle' ? (
                                <span>{t('NO IMAGE')} <br /><i className="fa fa-upload"></i></span>
                            ):(<span></span>)}
                        </div>
                    )}

                    <div className="FormInputImageUploadIcon" >
                        <i className="fa fa-upload"></i>
                    </div>

                </div>
            )}

            
            
            <input style={{display: 'none'}} type="file" ref={this.fileInput} onChange={this.onChange} />
        </div>;
    }
}

class ContainerHeader extends Component {

    constructor(props){
        super(props);

        /*this.state = pweb().store.getState();*/
    }

    componentDidMount(){
        /* this.setState( pweb().store.getState() );

        pwebs().dispatch( pweb().hideLoader() );
        pwebs().subscribe( () => { this.setState( pweb().store.getState() )} ); */
    }

    render(){

        if( !this.state.breadcrumbsArray.length )
            return null;
            
        let lastLabel = false;
        let count=0;
        let breadcrumbs = this.state.breadcrumbsArray.map( item => {
            lastLabel = item.label;
            if( item.title )
                lastLabel = item.title;
                
            count++;
            const active = count === this.state.breadcrumbsArray.length;
            return <li className={active?'active':''} key={count} ><Link to={item.url}>{item.label}</Link></li>;
        } );

        return <div className="row wrapper border-bottom white-bg page-heading">
            <div className="col-lg-10">
                <h2>{lastLabel}</h2>
                <ol className="breadcrumb">
                    {breadcrumbs}
                </ol>
            </div>
            <div className="col-lg-2">
            </div>
        </div>;
    }
}

class NavigationMenuSubItem extends Component {
    constructor(props){
        super(props);

        this.state = { l: window.location.pathname };
    }

    componentDidMount(){
        /*Route.onLocationChange( () => {
            this.setState({l : window.location.pathname } );
        });*/
    }

    render(){
        let active = false;
        if( (new RegExp( '^'+this.props.url )).exec( this.state.l ) )
            active = true;
        return <li className={active?'active':''}><Link to={this.props.url} >{this.props.label} {this.props.children}</Link></li>;
    }
}

class NavigationMenuItem extends Component {
    constructor(props){
        super(props);

        this.state = { l: window.location.pathname };
    }

    componentDidMount(){
        /*Route.onLocationChange( () => {
            this.setState({l : window.location.pathname } );
        });*/
    }

    render(){
        let child = null;
        let preChild = <Link href={this.props.url} >
            <i className={this.props.fa}></i> 
            <span className="nav-label">{this.props.label}</span> 
        </Link>;
        let r = new RegExp( '^' + (this.props.urlMatch ? this.props.urlMatch : this.props.url ) );
        let active = false;

        if( r.exec( this.state.l ) )
            active = true;

        if( this.props.children ){

            preChild = <Link href={this.props.url} >
                <i className={this.props.fa}></i> 
                <span className="nav-label">{this.props.label}</span> 
                <span className="fa arrow"></span>
            </Link>;

            child = <ul className={'nav nav-second-level ' + (active?'':'collapse')}>{this.props.children}</ul>;
        }

        return <li className={active ? 'active' : ''} >
                {preChild}
                {child}
        </li>;
    }
}

function DownloadNavigationMenu(props){

    return Object.keys( props.item ).map( key => {  
        if( !isNaN( parseInt ( key ) ) )
            return undefined;

        let url = props.baseUrl + `${key}`;

        let active = false;
        if( (new RegExp( '^'+url )).exec( window.location.pathname ) )
            active = true;

        return <li className={active?'active':''}>
            <Link href={url} >{ t(key) } </Link>
            <ul className="nav nav-third-level">
                <DownloadNavigationMenu item={props.item[key]} baseUrl={props.baseUrl + key + '/'} />
            </ul>
        </li>;
    });

}

function Row(props){
    const s_props = {
        ...props,
        className: 'row ' + ( props.className ? props.className : '' )
    };

    return <div {...s_props}>{props.children}</div>
}

function FormInputSelectOption(props){
    return <option {...props}>{props.label}</option>;
}


class FormInputSelect extends Component {
    render(){
        const sprops = { 
            value: '',
            values: false,
            onChange: () => {},
            ...this.props 
        };

        let chl = this.props.children;

        if( sprops.values ){
            let selection_found = false;
            const values_keys = Object.keys( sprops.values );

            if( sprops.order ){
                values_keys.sort( ( a,b) => {
                    if( sprops.values[a] < sprops.values[b] ) return -1;
                    if( sprops.values[a] > sprops.values[b] ) return 1;
                    return 0;
                });
            }

            chl = values_keys.map( k => {
                const selection = k == sprops.value ? { selected:'selected'} : {};
                if( k === sprops.value )
                    selection_found = true;

                return <FormInputSelectOption key={k} label={sprops.values[k]} value={k} {...selection} />
            });

            if( !selection_found && values_keys.length > 0 )
                sprops.onChange( values_keys[0] );
        }

        const disabled = this.props.disabled ? {disabled:true}:{};

        return <FormInputContainer {...sprops}>
            <select className="form-control" onChange={se=>{sprops.onChange(se.target.value)}} 
                {...disabled} value={sprops.value}>
                    {chl}
                </select>
        </FormInputContainer>;
    }
}

class FormInputRadio extends Component {

    constructor(props){
        super(props);

        this.ref = React.createRef();
    }

    componentDidMount(){
        /*eslint-disable no-undef*/
        /*setTimeout( ()=>{

            const el = findDOMNode( this.ref.current );
            $(el).iCheck({
                checkboxClass: 'icheckbox_square-green',
                radioClass: 'iradio_square-green',
            }).on('ifChecked',()=>{ console.log( 'a' ); this.props.onChange( true ) })
            .on('ifUnchecked',()=>{ console.log( 'b' ); this.props.onChange( false ) });
        } , 50 );*/
        
        /*eslint-enable no-undef*/
    }

    componentDidUpdate( prevProps , prevState ){
        /*eslint-disable no-undef*/
        /*setTimeout( ()=>{

            const el = findDOMNode( this.ref.current );
            console.log( 'ich' + (this.props.checked == true) );
            $(el).iCheck( this.props.checked == true ? 'check' : 'uncheck' );
        } , 50 );*/
        
        /*eslint-enable no-undef*/
    }

    render(){

        const classes= this.props.checked ? "checked icheckbox_square-green": "icheckbox_square-green";

        return <div className="i-checks" {...this.props}>
            <label className=""> 
                <div className={classes} style={{position: 'relative'}} onClick={s=>{s.preventDefault()}}>
                    <input type="checkbox" style={{position: 'absolute', opacity: 0}} />
                </div><i></i> {this.props.label} 
            </label>
        </div>;
    }
}

class FormInputSelect2 extends Component {
    constructor(props){
        super(props);

        this.ref = React.createRef();
    }

    componentDidUpdate(prevProps, prevState){
        /*eslint-disable no-undef*/
        const el = findDOMNode( this.ref.current );
        $(el).find('select').select2('destroy');  
        /*eslint-enable no-undef*/

        this.initSelect2();
    }

    initSelect2(){
        /*eslint-disable no-undef*/
        
        const el = findDOMNode( this.ref.current );
        console.log( el );
        $(el).find('select').select2({ width: '100%'/* , data: Object.keys(this.props.values).map( v => { return { id: v, value: this.props.values[v] }; })*/ })
            .on('select2:select' , e => {
                console.log( this.props.onChange( e.params.data.id ) );
            });
        console.log( 'Init selcet2');
        /*eslint-enable no-undef*/
    }

    componentDidMount(){
        this.initSelect2();
    }

    render(){
        return <FormInputSelect {...this.props} ref={this.ref} >
            {this.props.children}
        </FormInputSelect>;
    }
}

function FormDashline(){
    return <div className="hr-line-dashed"></div>;
}

class FormInputContainer extends Component {

    static VERTICAL = 'V';
    static HORIZONTAL = 'H';

    render(){
        const s_props = { 
            label: false, 
            className: '',
            direction: FormInputContainer.VERTICAL 
        , ...this.props };

        const label = s_props.label ? 
            <label 
                style={{fontWeight: this.props.requiredField?'bold':'normal'}}
                className={'control-label' + (s_props.direction === FormInputContainer.VERTICAL ? ' col-sm-2' : '')}>
            {this.props.requiredField?'* ':''}{s_props.label}</label> 
            : '';

        let validation = null;

        if( this.props.validation && this.props.validation.fieldName == this.props.name ){
            let msg = 'Campo non valido';
            if( this.props.validation.message )
                msg = this.props.validation.message;

            validation = <div className="invalid-feedback">{msg}</div>;
        }

        return <div className={s_props.className + " form-group"+(this.props.error?' has-error':'')}>
            {label}
            <div className={s_props.direction === FormInputContainer.VERTICAL ? ' col-sm-10' : ''}>
                {s_props.children}
                {this.props.errorLabel&&this.props.error?<span class="help-block">{this.props.errorLabel}</span>:null}
                {validation}
            </div>
        </div>;
    }
}

function ConfirmableButton({ children, className , onClick }){

    const [ confirm , setConfirm ] = React.useState( false );

    const onConfirm = se => {
        se.preventDefault();
        setConfirm( false );
        if( onClick ) onClick();
    }

    const onShowConfirmClick = se => {
        se.preventDefault();
        if( !confirm )
            setConfirm( true );
    }

    const onReset = se => {
        se.preventDefault();
        setConfirm( false );
    }

    return <button className={className} onClick={onShowConfirmClick}>
        {confirm &&<span style={{position: 'relative'}}>
            <div style={{
                position: 'absolute',
                backgroundColor: 'white',
                borderRadius: '5px',
                padding: '10px',
                border: '1px solid red',
                right: '20px'
            }}>
                <span style={{marginRight: '10px', fontWeight: 'bold'}}>Sicuro?</span>
                <button className="btn btn-danger btn-sm" onClick={onConfirm}>Si</button>
                <button className="btn btn-default btn-sm" onClick={onReset}>Annulla</button>
            </div>
        </span>}
        {children}
    </button>
}

function FormButtons(props){

    let s_props = { 
        cancelLabel: t('Annulla'),
        onCancel: () => {},

        saveLabel: t('Salva'),
        onSave: se => {},

        direction: FormInputContainer.VERTICAL,

        saveEnable: typeof( props.saveEnable ) !== 'undefined' ? props.saveEnable : true
    , ...props };

    if( props.saving ){
        s_props.saveLabel = <React.Fragment>
            <i className="fa fa-spinner fa-pulse"></i> Salvataggio
        </React.Fragment>;
    }

    let cancel = null;
    if( s_props.cancelLabel )
        cancel = <a className="btn btn-white" href="#"
                        onClick={se => {se.preventDefault(); s_props.onCancel(se)}}>
                    {s_props.cancelLabel}</a>;
                

    return <div className="form-group">
        <div className={ s_props.direction === FormInputContainer.VERTICAL ? "col-sm-4 col-sm-offset-2" : ""}>

            {cancel}

            { s_props.saveEnable && <a className="btn btn-primary" style={{marginLeft: '10px'}}
                href="#"
                onClick={s_props.onSave}>
            {s_props.saveLabel}</a> }

        </div>
    </div>;
}

class FormInputDate extends Component {
    constructor(props){
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            value: moment(this.props.value).format('DD/MM/YYYY'),
            focus: false
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidUpdate( prevProps, prevState ){
        if( prevProps.value != this.props.value && !this.state.focus ){
            
            let valueFormatted = moment( this.props.value ).isValid() ? this.props.value : moment();
            
            // this.props.value puo essere stringa o moment
            try {
                valueFormatted = this.props.value.format('YYYY-MM-DD HH:mm');
            } catch( e ){}
            
            valueFormatted = moment( valueFormatted , 'YYYY-MM-DD HH:mm' );

            /* eslint-disable no-undef */
            jQuery(this.inputRef.current).datepicker('setDate', valueFormatted.toDate() );
            this.setState({value: valueFormatted.format('DD/MM/YYYY')});
            /* eslint-enable no-undef */
        }
    }

    componentDidMount(){
        /* eslint-disable no-undef */
        jQuery(this.inputRef.current).datepicker({
            format: 'dd/mm/yyyy',
            autoclose: true,
        }).on('changeDate', e => {

            let sDate = moment().year( e.date.getYear() + 1900 ).month( e.date.getMonth() ).date( e.date.getDate() );

            let valueFormatted = moment( this.props.value ).isValid() ? this.props.value : moment();
            
            // this.props.value puo essere stringa o moment
            try {
                valueFormatted = this.props.value.format('YYYY-MM-DD HH:mm');
            } catch( e ){}
            
            valueFormatted = moment( valueFormatted , 'YYYY-MM-DD HH:mm' );
            
            if( sDate.format( 'YYYY-MM-DD' ) != valueFormatted.format('YYYY-MM-DD') )
                this.props.onChange( sDate.format( 'YYYY-MM-DD ' ) + (valueFormatted.format( 'HH:mm' ) ) );
        }).datepicker('setDate', new Date( this.props.value ) );

        /* eslint-enable no-undef */
    }

    componentWillUnmount(){

    }

    onChange( se ){

        this.setState({value: se.target.value});

        let match = se.target.value.match( /^([0-9]{1,2})[\/-]([0-9]{1,2})[\/-]([0-9]{4})$/i );

        if( !match ){
            match = se.target.value.match( /^([0-9]{1,2})[\/-]([0-9]{1,2})[\/-]*$/i );
            if( match ){
                match[3] = moment().year();
            }
        }

        if( match ){

            const v = moment(this.props.value)
                .date(match[1])
                .month(match[2] -1)
                .year(match[3])
                .format( 'YYYY-MM-DD HH:mm' );

            if( this.props.onChange )
                this.props.onChange( v );

        }
    }

    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        const disabled = this.props.disabled ? {disabled:true}:{};

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        return <FormInputContainer {...this.props} >
            <input type="text" className="form-control" 
                {...disabled}
                {...additionalProps}
                value={this.state.value}
                ref={this.inputRef} 
                onChange={this.onChange}
                onFocus={se=>{this.setState({focus: true})}}
                onBlur={se=>{this.setState({
                    value: moment(this.props.value).format('DD/MM/YYYY'),
                    focus: false})}} />
        </FormInputContainer>;
    }

    focus(){
        this.inputRef.current.focus();
    }
}


class FormInputTime extends Component {
    constructor(props){
        super(props);

        this.inputRef = React.createRef();

        this.state = {
            value: moment(this.props.value).format('HH:mm')
        };
    }

    componentDidUpdate( prevProps, prevState ){
        if( prevProps.value != this.props.value ){

            this.setState({value:moment(this.props.value).format('HH:mm')});

            /* eslint-disable no-undef */
            //jQuery(this.inputRef.current).datepicker('setDate', new Date( this.props.value ) );
            /* eslint-enable no-undef */
        }
    }

    componentDidMount(){
        /* eslint-disable no-undef */
        /*jQuery(this.inputRef.current).clockpicker({
            afterDone: e => { 
                this.props.onChange( moment(this.props.value ).format( 'YYYY-MM-DD ' + this.inputRef.current.value ) ) 
            },
            donetext: 'Ok',
        })*/
        /* eslint-enable no-undef */
    }

    componentWillUnmount(){

    }

    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        return <FormInputContainer {...this.props} >
            <input type="text" className="form-control" {...additionalProps}
                onFocus={se=>{this.setState({focus: true})}}
                onBlur={se=>{this.setState({focus: false, value: moment(this.props.value).format('HH:mm')})}}
                onChange={se=>{

                    let value = se.target.value;

                    if( value.match( /^[0-9]{1,2}$/i ) )
                        value = value + ":00";
                    else {
                        value = value.replace( /[\.,]/g , ':' );
                    }

                    var newDate = moment( this.props.value ).format( 'YYYY-MM-DD ' + value );

                    if( moment( newDate ).isValid() && this.props.onChange &&value.match( /[0-9]{2}:[0-9]{2}/ ) )
                        this.props.onChange( newDate );

                    if( this.state.focus )
                        this.setState({value: se.target.value, realValue: value});
                    else
                        this.setState({value: value, realValue: value});
                }}

                ref={this.inputRef} value={this.state.value} />
        </FormInputContainer>;
    }

    focus(){
        this.inputRef.current.focus();
    }
}


class FormInputTextArea extends Component {
    constructor(props){
        super(props);

        this.inputRef = React.createRef();
    }

    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        let className = ' form-control';
        if( this.props.validation && this.props.validation.fieldName == this.props.name ){
            className += ' is-invalid';
        }

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        return <FormInputContainer {...this.props} >
            <textarea className={className} value={this.props.value} {...additionalProps}
                onChange={se => {inputProps.onChange(se)}} ref={this.inputRef} />
        </FormInputContainer>;
    }

    focus(){
        this.inputRef.current.focus();
    }
}

class FormInputCurrency extends Component {
    constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            value: this.props.value
        };
    }

    onFocus( se ){
        this.setState( {
            value: this.props.value,
            focus: true
        } );
    }

    onBlur( se ){
        this.setState( {
            focus: false
        } );
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( this.props.value != prevProps.value )
            this.setState({ value: this.props.value });
    }

    onChange( se ){

        let value = se.target.value;

        if( typeof( this.props.onChange ) == 'function' ){
            this.props.onChange({
                ...se,
                target: {
                    ...se.target,
                    value: value
                }
            });
        }
    }

    render(){

        const formattedValue = accounting.formatMoney( this.props.value , "€ ", 2, ".", ",");;

        const inputProps = {
            ...this.props,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onChange: s => { return this.onChange( s ) },
            value: this.state.focus ? this.props.value : formattedValue
        };

        return <FormInputText {...inputProps} value={this.state.value} />;
    }

}

function FormInputColorSimple_ColorBlock( props ){
    return <div style={{
        backgroundColor: props.color,
        width: '100px',
        height: '30px',
        border: '2px solid #868686',
        borderRadius: '2px',
        margin: '10px',
        float: 'left',}} onClick={se=>{ se.preventDefault(); props.onClick( props.color )}}></div>
}

class FormInputColorSimple extends Component {
    constructor(props){
        super(props);

        this.state = {
            value: this.props.value,
            showDialog: false,
        };

        this.showColorDialog = this.showColorDialog.bind( this );
        this.onSelectColor = this.onSelectColor.bind( this );

        this.availableColors = this.props.colors || {
            '#ffc000': 'Giallo',
            '#d63a3a': 'Rosso',
            '#a35d27': 'Marrone',
            '#6bcc5a': 'Verde',
            '#4d62db': 'Blu',
            '#944ddb': 'Viola',
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if( prevProps.value != this.props.value )
            this.setState({ value: this.props.value})
    }

    showColorDialog(){
        this.setState({showDialog: true});
    }

    onSelectColor( color ){

        this.setState({
            value: color,
            showDialog: false
        });

        if( this.props.onChange )
            this.props.onChange( { target: { value: color } } );
    }
    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        let className = ' form-control';
        if( this.props.validation && this.props.validation.fieldName == this.props.name ){
            className += ' is-invalid';
        }

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        const disabled = this.props.disabled ? {disabled:true}:{};

        let currentColorKey = Object.keys(this.availableColors).filter( color => {
            return color == this.state.value
        });
        if( currentColorKey.length > 0 ){
            currentColorKey = currentColorKey[0];
            //this.onSelectColor( currentColorKey );
        } else
            currentColorKey = Object.keys(this.availableColors)[0];


        return <FormInputContainer {...this.props} >
            <div style={{
                cursor: 'pointer',
                position: 'relative'
            }}>

                {this.state.showDialog && <React.Fragment>
                    <div style={{
                        position: 'absolute',
                        top: '2.5rem',
                        left: 0,
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid grey',
                        padding: '20px',
                        boxShadow: '0 0 20px #bdbdbd',
                        zIndex: 500
                    }}>
                        {Object.keys(this.availableColors).map( color => {
                            return <FormInputColorSimple_ColorBlock color={color} onClick={this.onSelectColor} />;
                        })}
                    </div>
                </React.Fragment>}

                <div onClick={this.showColorDialog}  style={{
                    backgroundColor: currentColorKey,
                    width: '100px',
                    height: '20px',
                    border: '2px solid #868686',
                    borderRadius: '2px',
                    margin: '10px',
                    float: 'left',}}></div>
                <span style={{
                    lineHeight: '37px',
                    fontWeight: 'bold'
                }}>{this.availableColors[currentColorKey]}</span>
            </div>
        </FormInputContainer>;
    }

}


class FormInputText extends Component {
    constructor(props){
        super(props);

        this.inputRef = React.createRef();

        this.onChange = this.onChange.bind(this);

        this.inputDelayHandle = false;

        this.state = {
            notDelayedValue: this.props.value
        };
    }

    componentDidUpdate( prevProps ){
        if( prevProps.value != this.props.value ){
            this.setState({
                notDelayedValue: this.props.value
            });
        }
    }

    onChange( se ){

        let value = se.target.value;

        if( this.props.upperCase )
            value = se.target.value.toUpperCase();
        if( this.props.lowerCase )
            value = se.target.value.toLowerCase();

        this.setState({
            notDelayedValue: value
        });

        const delay = typeof( this.props.delay ) == 'number' ? this.props.delay : 500;

        if( this.inputDelayHandle ){
            clearTimeout( this.inputDelayHandle );
            this.inputDelayHandle = false;
        }

        this.inputDelayHandle = setTimeout( () => {

            if( typeof( this.props.onChange ) == 'function' ){
                this.props.onChange({
                    ...se,
                    target: {
                        ...se.target,
                        value: this.state.notDelayedValue
                    }
                });
            }

        } , delay );

    }

    render(){

        const inputProps = {
            enable: true,
            onChange: () => {},
            ...this.props
        };

        let className = ' form-control';
        if( this.props.validation && this.props.validation.fieldName == this.props.name ){
            className += ' is-invalid';
        }

        let additionalProps = this.props.inputProps || {};
        if( !inputProps.enable )
            additionalProps['readOnly'] = true;

        const disabled = this.props.disabled ? {disabled:true}:{};


        return <FormInputContainer {...this.props} >
            <input type="text" className={className} value={this.state.notDelayedValue} {...additionalProps}
                placeholder={this.props.placeholder}
                {...disabled} onChange={this.onChange} ref={this.inputRef} />

        </FormInputContainer>;
    }

    focus(){
        this.inputRef.current.focus();
    }
}

class GoogleMap extends Component {

    constructor(props){
        super(props);

        if( !FormInputMap.mapCounter )
            FormInputMap.mapCounter = 0;

        FormInputMap.mapCounter++;

        this.state = {
            key: `_map_c_${FormInputMap.mapCounter}`,
            lat: props.lat,
            lng: props.lng,
            googleApiReady: this.googleExist(),
            mapInitalized: false,
        };

    }

    googleExist(){
        return  typeof( google ) !== 'undefined';
    }

    checkGoogleApi(){
        console.log( 'Checking google' );
        this.setState({ googleApiReady: this.googleExist() })
    }

    componentDidMount(){

        if( !this.googleExist() ){

            console.log( `Loading Google Map Scripts` );
            var script = document.createElement("script");
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyB_W9CC2FHzVHpAChOj2OvEGafOa28tmdA`;
            document.head.appendChild(script);

            this.googleLoadingTimer = setInterval( () => { 
                this.checkGoogleApi(); 
                if( this.googleExist() )
                    clearInterval( this.googleLoadingTimer );
            } , 100 );
        }

    }

    render(){

        if( this.state.googleApiReady && !this.state.mapInitalized ){
            /*eslint-disable no-undef*/
            const element = document.getElementById( this.state.key );
            if( element ){
                this.map = new google.maps.Map( element , {
                    center: {lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng)},
                    zoom: parseFloat(this.props.zoom)
                });
            } else 
                return null;
            
            console.log( `Initializing map ${this.state.key}`)
            /*eslint-enalbe no-undef*/

            this.setState({mapInitalized: true});

            if( this.props.onReady && this.map )
                this.props.onReady( this.map );
        }

        return <div id={this.state.key} {...this.props}></div>;
    }
}

class FormInputMap extends Component {

    constructor(props){
        super(props);

        this.onMapReady = this.onMapReady.bind(this);

        this.state = {
            lat: typeof( props.lat ) === 'number' ? props.lat : parseFloat( props.lat ),
            lng: typeof( props.lng ) === 'number' ? props.lng : parseFloat( props.lng ),
        };

        this.mapDragging = false;
    }

    onMapReady( map ){
        this.map = map;
        this.forceUpdate();

        this.map.addListener( 'dragstart', () => {
            this.mapDragging = true;
        });
        this.map.addListener( 'dragend', () => {
            this.mapDragging = false;
        });

        this.map.addListener( 'center_changed', () => {
            const center = this.map.getCenter();
            
            if( this.props.onChange && isNumber( center.lat() ) )
                this.props.onChange( center.lat() , center.lng() );
        });
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            lat: typeof( nextProps.lat ) === 'number' ? nextProps.lat : parseFloat( nextProps.lat ),
            lng: typeof( nextProps.lng ) === 'number' ? nextProps.lng : parseFloat( nextProps.lng ),
        });
    }

    render(){

        if( !this.marker && this.map ){
            this.marker = new google.maps.Marker({position: {
                lat: this.state.lat,
                lng: this.state.lng 
            } , map: this.map});
        } else {
            if( this.marker ){
                const loc = new google.maps.LatLng( this.state.lat, this.state.lng );

                this.marker.setPosition( loc );

                if( !this.mapDragging )
                    this.map.panTo( loc );
            }
            
        }

        return <FormInputContainer label={this.props.label} direction={this.props.direction}>

            <div className="row" style={{marginBottom: '10px'}}>

                <div className="col-sm-6">
                    <label>{t('Latitude')}</label>
                </div>
                <div className="col-sm-6">
                    <label>{t('Longitude')}</label>
                </div>

                <div className="col-sm-6">
                    <input className="form-control" value={this.state.lat} 
                        onChange={se => { this.props.onChange( se.target.value , this.state.lng ) }} />
                </div>
                <div className="col-sm-6">
                    <input className="form-control" value={this.state.lng}
                        onChange={se => { this.props.onChange( this.state.lat , se.target.value ) }} />
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12">
                    <GoogleMap lat={this.props.lat} lng={this.props.lng} zoom="18" 
                        onReady={this.onMapReady} style={{height: '400px'}} />
                </div>
            </div>
            
        </FormInputContainer>
    }

}

class SweetAlert extends Component {
    constructor(props){
        super(props);

        this.state = pweb().store.getState().sweetAlert;

        pwebs().subscribe( () => { this.setState( pweb().store.getState().sweetAlert )} );
    }

    static close(){
        pwebs().dispatch( pweb().closeAlert() );
    }

    static show( title , message , buttons ){
        SweetAlert.buttons = buttons;
        pwebs().dispatch( pweb().showAlert( title , message , buttons ) );
    }

    render(){
        
        if( !this.state.visible )
            return null;

        return <div className="SweetAlert" >
            <div className="SweetAlertBg" ></div>

            <div className="SweetAlertBox" onClick={se=>{se.preventDefault()}}>
                <h3>{this.state.title}</h3>
                <p>{this.state.content}</p>

                <div className="SweetAlertButtons">{this.state.buttons.map( button => {
                    return <button className="btn btn-default" {...button}>{button.caption}</button>
                })}</div>
            </div>

        </div>;
    }
}

class Modal extends Component {
    constructor(props){
        super(props);

        this.modalRef = React.createRef();
    }

    componentDidMount(){
        /* eslint-disable no-undef */
        jQuery(this.modalRef.current).modal({
            backdrop: 'static'
        }).on( 'hidden.bs.modal', e => {
            if( this.props.onClose )
                this.props.onClose();
        } );
        jQuery('.modal-backdrop').hide();
        /* eslint-enable no-undef */
    }

    componentWillUnmount(){
        /* eslint-disable no-undef */
        jQuery(this.modalRef.current).modal('hide');
        jQuery('.modal-backdrop').hide();
        /* eslint-enable no-undef */
    }

    render(){
        return <div ref={this.modalRef} className="modal fade ">
            <div className="modal-dialog" style={{zIndex: 3000}}>
                <div className="modal-content">
                    <div className="modal-body text-left">
                        <div className="row">
                            <div className="col-sm-12 text-right">
                                <i className="fa fa-times" onClick={se=>{
                                    /* eslint-disable no-undef */
                                    jQuery(this.modalRef.current).modal('hide');
                                    jQuery('.modal-backdrop').hide();
                                    if( this.props.onClose )
                                        this.props.onClose();
                                    /* eslint-enable no-undef */
                                }}></i>
                            </div>
                        </div>
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>;
    }
}

class FormInputPassword extends Component {
    render(){
        return <FormInputContainer {...this.props} >
            <input type="password" className="form-control" value={this.props.value}
                onChange={se => {if(this.props.onChange) this.props.onChange(se)}} />
        </FormInputContainer>;
    }
}

class BigBannerStats extends Component {
    render(){
        return <div class="widget style1 navy-bg">
            <div class="row">
                <div class="col-xs-4">
                    <i class="fa fa-truck fa-3x"></i>
                </div>
                <div class="col-xs-8 text-right">
                    <span> {this.props.label} {moment().year()-1} / {moment().year()}</span>
                    {this.props.value && ( 
                        <h3 class="font-bold">{this.props.value}</h3>
                    )} {!this.props.value && ( 
                        <h3 class="font-bold"><i className="fa fa-spinner fa-spin"></i></h3>
                    )}                            
                </div>
            </div>
        </div>;
    }
}

class PaginationButtons extends Component {
    render(){

        const props = {
            onPageClicked: () => {},
            range: 3,
            max: 1,
            currentPage: 1,
            ...this.props
        };

        let pages_numbers = [];

        for(let p = props.currentPage - props.range;p < props.currentPage+props.range;p++){
            if( p < 0 || p > props.max-1 )
                continue;
            pages_numbers.push(p);
        }

        const pages = pages_numbers.map( page_number => {
            return <li key={page_number} className={'paginate_button'+(page_number===props.currentPage?' active':'')} style={{zIndex:0}}>
                <a href="#" onClick={se=>{se.preventDefault();props.onPageClicked(page_number)}} >
                    {page_number+1}</a>
            </li>;
        });

        return <div className="paging_simple_numbers">
            <ul className="pagination">

                { props.currentPage > 1 ? (
                    <li className="paginate_button previous">
                        <a href="#" onClick={se=>{se.preventDefault();props.onPageClicked(props.currentPage-1)}} >
                            {t('Previous')}</a>
                    </li>
                ) : undefined }

                {pages}

                { props.currentPage < props.max-1 ? (
                    <li className="paginate_button next">
                        <a href="#" onClick={se=>{se.preventDefault();props.onPageClicked(props.currentPage+1)}} >
                            {t('Next')}</a>
                    </li>
                ) : undefined }
            </ul>
        </div>;
    }
}

class ScrollBasedList extends Component {
    constructor(props){
        super(props);

        this.state = {
            visibleItems: [ this.props.requestNextItemIdentifier() ],
            itemsLoading: 1,
            stopRequired: false,
        };

        this.placeholderRef = React.createRef();

        this.checkNextItems = this.checkNextItems.bind(this);
    }

    componentWillReceiveProps(){
        this.setState({ 
            visibleItems: [ this.props.requestNextItemIdentifier() ],
            itemsLoading: 1 });
    }

    componentDidMount(){
        this.timerHandle = setInterval( this.checkNextItems , 250 );
    }

    componentWillUnmount(){
        clearInterval( this.timerHandle );
    }

    checkNextItems(){

        if( !this.placeholderRef ) return;
        if( !this.placeholderRef.current ) return;

        const rect = this.placeholderRef.current.getBoundingClientRect();

        const wHeight = window.innerHeight * 1.5;
        
        if( rect.top < wHeight && this.state.itemsLoading < 2 && !this.state.stopRequired ){

            const new_item = this.props.requestNextItemIdentifier( this.state.visibleItems[
                this.state.visibleItems.length-1
            ] );

            this.setState({
                visibleItems: this.state.visibleItems.concat( [ new_item ] ),
                itemsLoading: this.state.itemsLoading+1
            });
        }
        
    }

    render(){
        return <div>
            {this.state.visibleItems.map( item => {
                return this.props.requestItem( item , stopRequired => { 
                    this.setState({
                        itemsLoading: this.state.itemsLoading-1,
                        stopRequired: stopRequired,
                    });
                } );
            })}

            <div ref={this.placeholderRef} />

        </div>;
    }
}

class FormInputYear extends React.Component {
    render(){
        let currentYear = moment().year();
        let years = {};
        for (let y = currentYear; y >= 2019; y--) {
            years[y] = y;
        }

        return <FormInputSelect 
            direction={FormInputContainer.HORIZONTAL}
            label="Anno"
            values={years} {...this.props} />;
    }
}

function MenuButton( props ){

    const [ open , setOpen ] = React.useState( false );

    const onClick = se => {
        se.preventDefault();
        setOpen( !open );
    };

    const actions = props.actions || {};

    return <button className={"btn " + (props.className||"")} onClick={onClick} style={{position: 'relative'}} >
        {props.children}

        { open && <div className={"dropdown-menu"} style={{display: 'block', right: 0, left: 'auto'}}>
            {Object.keys( actions ).map( actionKey => {
                return <a className={"dropdown-item"} onClick={ se => {
                    if( typeof( actions[actionKey] ) == 'function' )
                        actions[actionKey]( actionKey );
                    setOpen( false );
                }}>{actionKey}</a>
            })}
        </div>}
    </button>
}

class FormInputArray extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.onItemDelete = this.onItemDelete.bind( this );
        this.onItemChange = this.onItemChange.bind( this );
        this.onItemAdd = this.onItemAdd.bind( this );
    }

    onItemAdd( se ){

        se.preventDefault();

        if( !this.props.onChange || !this.props.items )
            return;

        this.props.onChange( this.props.items.concat({}) );
    }

    onItemDelete( index ){
        if( !this.props.onChange || !this.props.items )
            return;

        this.props.onChange( this.props.items.filter( (item,i) => {
            return index != i;
        }));
    }

    onItemChange( newItemState , index ){
        if( !this.props.onChange || !this.props.items )
            return;

        let itemFound = 0;
        this.props.onChange( this.props.items.map( (item,i) => {
            if( i == index )
                return newItemState;
            return item;
        }));
    }

    render(){

        if( !this.props.model ) return <p>Invalid model</p>;
        if( !this.props.items ) return <p>Invalid items</p>;
        if( !this.props.renderItem ) return <p>Invalid renderItem</p>;

        return <>

            {this.props.items.length == 0 && <p
              style={{textAlign:'center',fontStyle: 'italic', margin: '20px'}}>
                Non ci sono elementi</p>}

            {this.props.items.map( (item,i) => {
                return <>
                    {this.props.renderItem( item, (newState) => { this.onItemChange( newState, i ) } )}
                    <div>
                        <button className={"btn btn-xs btn-danger text-right"}
                            onClick={ se => { se.preventDefault(); this.onItemDelete(i) } }>Elimina</button>
                    </div>
                </>;
            })}

            <button className={"btn btn-xs btn-primary"}
                    onClick={this.onItemAdd}>Aggiungi</button>
        </>
    }
}

class FormInputMonth extends React.Component {
    render(){

        let values = {
            '0': 'Gennaio',
            '1': 'Febbraio',
            '2': 'Marzo',
            '3': 'Aprile',
            '4': 'Maggio',
            '5': 'Giugno',
            '6': 'Luglio',
            '7': 'Agosto',
            '8': 'Settembre',
            '9': 'Ottobre',
            '10': 'Novembre',
            '11': 'Dicembre',
        };

        if( this.props.allMonths ){
            values[-1] = 'Tutto l\'anno';
        }

        return <FormInputSelect 
        direction={FormInputContainer.HORIZONTAL}
        label="Mese riferimento"
        values={values} {...this.props} />;
    }
}

class SocketIo {

    static _instance = false;

    static instance(){
        if( !SocketIo._instance && typeof( io ) != 'undefined' ){ // eslint-disable-line
            console.log( 'Initializing socket io');
            SocketIo._instance = new SocketIo();
        }

        return SocketIo._instance;
    }

    constructor() {
        this.socket = io( `${window.location.protocol}//${window.location.hostname}`, { // eslint-disable-line
            transports: ['websocket'],
            secure: true,
        } ); // eslint-disable-line

        this.socket.on( 'requestResult' , this.onRequestResult.bind( this ) );

        this.lastRequestId = 0;

        this.requests = {};

        this.requestsHistory = [];
    }

    pushToHistory( type, data ){

        this.requestsHistory.push({
            at: moment(),
            type: type,
            data: data
        });

        if( type == 'in' ){
            this.requestsHistory = this.requestsHistory.map( req => {
                if( req.data.requestId == data.requestId )
                    req.response = data;
                return req;
            })
        }

        if( this.requestsHistory.length > 1024 ){
            this.requestsHistory = this.requestsHistory.slice( 512 );
        }
    }

    onRequestResult( requestResultMessage ){

        this.pushToHistory( 'in' , requestResultMessage );

        if( this.requests[requestResultMessage.requestId] ){

            if( this.requests[requestResultMessage.requestId].callback ){
                this.requests[requestResultMessage.requestId].callback({
                    body: requestResultMessage.result
                });
            }
        }

    }

    request( parameters , postData ){
        if( !this.socket.connected  || !LocalCache.get('loginToken') )
            return false;

        let requestData = {
            loginToken: LocalCache.get('loginToken'),
            params: parameters,
            postData: postData,
            requestId: this.lastRequestId++,
        };

        this.socket.emit( 'request' , requestData );

        requestData.then = cb => {
            requestData.callback = cb;

            return { catch: () => {} };
        };

        requestData.abort = () => {
            delete this.requests[requestData.requestId];
        };

        this.requests[requestData.requestId] = requestData;

        this.pushToHistory( 'out' , requestData );

        return requestData;
    }

}

class SocketIoDebuggerRequest extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(){
        this.setState({
            open: !this.state.open
        });
    }

    render(){
        return <div className={"row SocketIoDebuggerRequest"} onClick={this.toggle}>
            <div className={"col-1"}>{this.props.type}</div>
            <div className={"col-5"}>{this.props.at.format('HH:mm:ss.SSS')}</div>
            <div className={"col-5"}>{this.props.data.params.join('/')}</div>
            <div className={"col-1"}>{this.props.response ? null : 'W'}</div>

            {this.state.open && <div className={"col-12"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <h4>Request:</h4>
                        {JSON.stringify( this.props.data.postData )}</div>
                    {this.props.response && <div className={"col-12"}>
                        <h4>Response:</h4>
                        {JSON.stringify( this.props.response.result)}</div>}
                </div>
            </div>}
        </div>;
    }
}

class SocketIoDebugger extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {
        setInterval( () => {
            this.forceUpdate();
        } , 3000 );
    }

    toggle(){
        this.setState({
            visible: !this.state.visible
        });
    }

    render(){

        return <div className={"SocketIoDebugger"}>
            <div className={"SocketIoDebuggerButton"} onClick={this.toggle}></div>

            { this.state.visible && <div className={"socketIoDebuggerContainer"}>
                <div className={"row"}>
                    {SocketIo.instance().requestsHistory.filter( req => {
                        return req.type == 'out';
                    }).map( req => {
                        return <div className={"SocketIoDebuggerRequest col-12"}>
                            <SocketIoDebuggerRequest {...req} />
                        </div>
                    }) }
                </div>
            </div> }
        </div>;
    }
}

function fetch(){

    const base_url = `${window.location.protocol}//${window.location.hostname}`;

    let url = base_url;

    let cb = false;

    let postData = false;

    let requestParameters = [];

    for( let i = 0; i < arguments.length ; i++ ){

        if( typeof ( arguments[i] ) === 'object' ){
            postData = arguments[i];
            continue;
        }

        if( typeof ( arguments[i] ) === 'function' ){
            cb = arguments[i];
            break;
        }

        requestParameters.push( arguments[i] );

        url += '/' + encodeURIComponent( arguments[i] );
    }

    url += '?t=' + (new Date()).getTime();

    //console.debug( 'Fetching at url ' , url , arguments );

    let request = false;

    let fileLoaded = false;

    if( !postData ){
        request = superagent.get( url )
    } else {
        request = superagent.post( url );

        for( var key in postData ){
            if( !postData[key] )
                continue;
                
            if( postData[key].size && postData[key].name ){
                // IS FILE
                const file = postData[key];
                fileLoaded = true;
                request = request.attach( file.name, file );
                console.log( 'Attaching file to post' );
                delete postData[key];
            }
        }

        if( !fileLoaded ){
            request = request.set('Content-Type', 'application/json');
            request = request.send( postData );
        }
    }

    if( LocalCache.get('loginToken') ){
        request.set( 'loginToken', LocalCache.get('loginToken') );
    }

    /*let ioInstance = SocketIo.instance();
    if( ioInstance ){
        let ioReq = ioInstance.request( requestParameters , postData );
        if( ioReq )
            return ioReq;
    }*/

    return request;
}

function fetchHook( modelName , hook ){
    let data = fetch( 'api' , modelName , 'hook' , hook );
    return data;
}

export { fetch,  Loader , FormInputPassword, FormInputMap, fetchHook, FormInputCurrency,
    GoogleMap, FormInputText, FormButtons, FormInputContainer , FormDashline, FormInputSelect,
    FormInputSelectOption, ContainerHeader, BoxContainer, FormInputTextArea, FormInputYear,
    Utils, PaginationButtons, FormInputImage, SweetAlert, Row, BigBannerStats, FormInputSelect2,
    NavigationMenuItem , NavigationMenuSubItem , DownloadNavigationMenu, ScrollBasedList, FormInputColorSimple,
    FormInputRadio, Modal , FormInputDate, FormInputTime, FormInputMonth, FormInputRange,
    FormInputArray, SocketIoDebugger, queryToObject, MenuButton, ConfirmableButton };
